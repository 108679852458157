/*eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import {
	DIALOG_SUB_TITILE,
	DIALOG_TITILE,
	BASIC_DETAILS_STEP_HEADING,
	RESIDENCE_PERMANENT_ADDRESS,
	AADHAR_CONFIRMATION_TITLE,
	GO_BACK_ACTION,
	PROCEED_ACTION,
	DIALOG_TITILE1,
} from "../../utils/cms";
import AddressForm from "../../components/AddressDetailsComponent/AddressForm";
import AddressCard from "./CardComponents/AddressCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PermanentDetailsTerms from "./PermanentDetailsTerms";

function ETBResidenceDetails({
	values,
	setValues,
	isDisabled,
	setIsDisabled,
	setLoading,
	setModalOptions,
	disable
}) {
	const navigate = useNavigate();
	const [address, setAddress] = useState(true);
	const storeValues = useSelector((state) => state);
	const {
		pageDetails: { residenceDetails, currentApplication },
	} = storeValues || {};
	const [errors, setErrors] = useState();
	const [currentAddressOptions, setCurrentAddressOptions] = useState();
	const [permanentAddressOptions, setPermanentAddressOptions] = useState();
	const [currentAddressValues, setcurrentAddressValues] = useState();
	const [permanentAddressValues, setpermanentAddressValues] = useState();
	const [currentAddressErrors, currentAddressSetErrors] = useState();
	const [permanentAddressErrors, permanentAddressSetErrors] = useState();
	const [showTerms, setShowTerms] = useState(false);

	useEffect(() => {
		setValues({
			currentAddresType: true,
			permanentAddresType: true,
		});
	}, []);

	useEffect(() => {
		if (
			(values?.currentAddresType && values?.permanentAddresType) ||
			(values?.currentAddresType === false &&
				values?.permanentAddresType === false)
		) {
			setValues({
				currentAddresType: true,
				permanentAddresType: true,
			});
		}
	}, [values?.currentAddresType, values?.permanentAddresType]);

	useEffect(() => {
		if (values?.currentAddresType === false) {
			setValues({
				...values,
				addressType: "currentAddress",
				...currentAddressValues,
			});
			setErrors({ ...currentAddressErrors });
		}
	}, [
		currentAddressValues,
		values?.currentAddresType,
		currentAddressErrors,
	]);

	useEffect(() => {
		if (values?.permanentAddresType === false) {
			setValues({
				...values,
				addressType: "permanentAddress",
				...permanentAddressValues,
			});
			setErrors({ ...permanentAddressErrors });
		}
	}, [
		permanentAddressValues,
		values?.permanentAddresType,
		permanentAddressErrors,
	]);

	useEffect(() => {
		if (
			(values?.currentAddresType && values?.permanentAddresType) ||
			(!errors?.addressLine1 &&
				!errors?.addressLine2 &&
				values?.addressLine1?.length >= 10 &&
				values?.addressLine2?.length >= 3 &&
				values?.pinCode?.length === 6 &&
				values?.cityName &&
				values?.districtName &&
				values?.stateName)
		) {
			if (isDisabled) {
				setIsDisabled(false);
			}
		} else {
			if (!isDisabled) {
				setIsDisabled(true);
			}
		}
		if (values?.currentAddresType && values?.permanentAddresType) {
			setAddress(false);
		} else if (values?.currentAddresType) {
			setAddress(true);
		} else if (
			(values?.currentAddresType === false &&
			values?.permanentAddresType === false) || values?.currentAddresType === false
		) {
			setIsDisabled(true);
			setModalOptions({
				errorTitle: AADHAR_CONFIRMATION_TITLE,
				errorContent: values?.permanentAddresType === false ? DIALOG_TITILE : DIALOG_TITILE1,
				errorSubContent: DIALOG_SUB_TITILE,
				openErrorModal: true,
				secondaryAction: true,
				primaryAction: true,
				secondaryBtnText: GO_BACK_ACTION,
				primaryBtnText: PROCEED_ACTION,
				callBackAction: (val) => {
					if (val === "continue") {
						navigate("/verification/aadharVerify");
					}
					setValues({
						currentAddresType: true,
						permanentAddresType: true,
					});
					setModalOptions({});
				},
			});
		}
	}, [values, errors]);

	function showTermsAction(updatedValues, name, checked) {
		if (
			name === "currentAddresType" &&
			!updatedValues?.currentAddresType &&
			updatedValues?.permanentAddresType
		) {
			//setShowTerms(true);
		}
	}

	return (
		<>
			<div
				style={{ display: showTerms ? "none" : "block" }}
				className="etb-user-details"
			>
				<div className="heading">
					{BASIC_DETAILS_STEP_HEADING.replace(
						"#category#",
						"Residence Address "
					)}
					{<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
				</div>
				<AddressCard
					HeadingAddress="Current Address"
					values={values}
					setValues={setValues}
					addressData={residenceDetails?.address.currentAddress}
					afterInputChangeCallback={showTermsAction}
					addressFreezed={residenceDetails?.addressFreeze}
					disable = {disable}
				/>
				<AddressCard
					HeadingAddress="Permanent Address"
					values={values}
					setValues={setValues}
					addressData={
						residenceDetails?.address.permanentAddress
					}
					afterInputChangeCallback={showTermsAction}
					addressFreezed={residenceDetails?.addressFreeze}
					disable = {disable}
				/>
				{address &&
					!values?.permanentAddresType && (
						<div className="address-form-container">
							<div className="heading-toggle">
								{RESIDENCE_PERMANENT_ADDRESS}
							</div>
							<AddressForm
								values={
									!values?.currentAddresType
										? currentAddressValues
										: permanentAddressValues
								}
								setValues={
									!values?.currentAddresType
										? setcurrentAddressValues
										: setpermanentAddressValues
								}
								globalOptions={
									!values?.currentAddresType
										? currentAddressOptions
										: permanentAddressOptions
								}
								setGlobalOptions={
									!values?.currentAddresType
										? setCurrentAddressOptions
										: setPermanentAddressOptions
								}
								setLoading={setLoading}
								clearForm={false}
								errors={
									!values?.currentAddresType
										? currentAddressErrors
										: permanentAddressErrors
								}
								setErrors={
									!values?.currentAddresType
										? currentAddressSetErrors
										: permanentAddressSetErrors
								}
							/>
						</div>
					)}
			</div>
			{showTerms && (
				<PermanentDetailsTerms setShowTerms={setShowTerms} />
			)}
		</>
	);
}
export default ETBResidenceDetails;
