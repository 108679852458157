import { getJourneyType, getVehicleType } from "../../utils/utils";

export const initailOfferPayloadGenerate = (storeValues, isFinalOffer) => {
    const {
        pageDetails: {
            journeyType,
            currentApplication,
            professionlDetails,
            manufactureCode,
            subCategory,
            stateCode,
            preApprovedJourney,
            perfios,
            aggregatorJourney
        },
        userDetails
    } = storeValues || {};
    let payload = {
        D2CApplicantId: currentApplication?.d2CApplicantID,
        SFApplicantId: currentApplication?.sfapplicantId,
        loanApplicationId: currentApplication?.loanApplicationId,
        leadNumber: currentApplication?.leadId,
        documentId: currentApplication?.documentId,
        reportType: "CIBIL_EQUIFAX",
        companyName: "",
        panValid: "Y",
        tuScore: "True",
        plScore: "False",
        customerType: currentApplication?.customerType,
        existingCustomerCode: "",
        enquiryFor: "Borrower",
        product: getVehicleType(journeyType),
        channelType: aggregatorJourney ? "Aggregator" : "IndusMobile",
        baseLocation: "",
        customerSalutation: "",
        email: userDetails?.email,
        requestSource: "SF",
        postOfficeID: "",
        vehicleType: getJourneyType(journeyType),
        noofCoborrowers: "0",
        makerId: "0",
        applicantType: "Borrower",
        proposalBasedOn: "Borrower",
        offerScreenType: isFinalOffer ? 'Final Offer' : 'Initial Offer',
        offerRunCount: 0,
        fundedInsurance: "0",
        manufactureCode: currentApplication?.manufactureCode || manufactureCode || "",
        subCategory: currentApplication?.subCategory || subCategory || "",
        requiredLoanAmountFromSlider: "0", //added in emi handler
        requiredTenureFromSlider: "0", // added in emi handler
        incomePerMonth: currentApplication?.incomePerMonth || professionlDetails?.declaredIncome || "0",
        existingEMI: currentApplication?.existingEMI || professionlDetails?.existingEMI || "0",
        abbFlag: currentApplication?.abbFlag || ((perfios?.radioActive === 'abb' && perfios?.totalAvgBal !== "0") ? 'Y' : 'N') || "N",
        abbValue: currentApplication?.abbValue || perfios?.totalAvgBal || "0",
        engineBankIrr: "",
        engineLTV: "",
        engineRiskBand: "",
        newexistingEMI: "",
        stateCode: currentApplication?.stateCode || stateCode || "",
        isPACustomer: preApprovedJourney || false,
        sumofAllLowerEMIsFromEMIEngine: ""
    };
    return payload;
}
