import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import "./index.css";
import { useSelector } from 'react-redux'

export default function Slider(props) {
    const storeValues = useSelector(state => state);
    const { pageDetails: { journeyType } } = storeValues || {};

    const sliderWidthGenerate = () => {
        let progress = 20
        if (props.progress)
            progress = props.progress
        if (props.progress > 100) progress = 100
        return progress + "%"
    }
    
    return (
        <div className="roadmap_slider_cont">
            <div className="roadmap_slider">
                <div className="bar" style={{ width: sliderWidthGenerate() }}>
                    {journeyType?.includes("Car") ? <DirectionsCarIcon /> : <TwoWheelerIcon />}
                </div>
            </div>
        </div>
    )
}
