import { Slider } from "@mui/material";
import React from "react";
import { changeValueToCurrency } from "../../../utils/utils";
import "./SliderComponent.css";

const SliderComponent = ({
  name,
  values,
  setValues,
  size = "small",
  max,
  min,
  rangeStartLabel,
  rangeEndLabel,
  label,
  onChange,
  step = 1
}) => {
  return (
    <div className="slider-component-root">
      <p className="slider-label">{label}</p>
      <div className="slider-cont relative">
        <Slider
          min={min}
          size={size}
          value={values?.[name] ? parseInt(values[name]) : 0}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          aria-label="Small"
          valueLabelDisplay="auto"
          max={max}
          style={{ margin: "0px 5px" }}
          step={step}
        />
        <div className="slidebar-range-info flex justify-between">
          <p>{rangeStartLabel ? changeValueToCurrency.format(rangeStartLabel) : 0}</p>
          <p>{rangeEndLabel ? changeValueToCurrency.format(rangeEndLabel) : 0}</p>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
