/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import './index.css';
import Header from '../../components/Header';
import { WELCOME_NAME, PHONE_HEADER_LABEL, MOBILE_NUMBER, READ_MORE, TERMS_CONDITION, NEXT_ACTION, ERROR_TEXT, OTP_GENERATION_FAILED, OTP_GENERATION_FAILED_TRY_AGAIN, MAXIMUM_GENERATED_OTP_ERROR, MAXIMUM_GENERATED_OTP_ERROR1, PHONE_NUMBER_HINT, PHONE_NUMBER_ERROR_TEXT, PHONE_NUMBER_ERROR_TITLE } from '../../utils/cms';
import '../../App.css';
import Footer from '../../components/Footer';
import { useSelector, useDispatch } from 'react-redux'
import { userBasicDetails } from '../../store/userDetails';
import { pageBasicDetails } from '../../store/pageDetails'
import Terms from "../Terms";
import MobileOTP from '../../components/MobileOTP/OTP';
import LoginServices from '../../services/login';
import ErrorModal from '../../components/ErrorModal';
import Loader from "../../components/Loader/Loader";
import PhoneNumberInput from '../../components/CustomInput/PhoneNumberInput/PhoneNumberInput';
import TermsAndConditionsWrapper from '../../components/TermsAndConditionsWrapper/TermsAndConditionsWrapper';

const PhoneNumber = () => {
  const dispatch = useDispatch();
  const storeValues = useSelector(state => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [mobile, setMobile] = useState({mobileNumber: userDetails?.mobileNumber});
  const [terms, openTermsModal] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(userDetails?.mobileNumber);
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [errors, setError] = useState([]);
  const [errorTitle, setErrorTitle] = useState('');
  
  const callBackFunc = (success, response) => {
    setDisableButton(true);
    setLoading(false);
    setResetTimer(false);
    if (success) {
      let removeSpaceNumber = mobile?.mobileNumber?.replace(/\s/g,'');
      dispatch(pageBasicDetails({generatedMobileOTPCount: ((removeSpaceNumber === userDetails?.mobileNumber ? (pageDetails?.generatedMobileOTPCount || 0) : 0)) + 1, invalidMobileOTPCount: 0}));
      if (response?.statusCode === '200') {
        setShowOTP(true);
        return;
      }
      if (response?.statusCode === '429') {
        setShowOTP(false);
        setOpenErrorModal(true);
        setErrorMessage(response?.respDesc);
        return;
      }
      if (response?.statusCode === '500') {
        setShowOTP(false);
        setOpenErrorModal(true);
        setErrorTitle(PHONE_NUMBER_ERROR_TITLE)
        setErrorMessage(PHONE_NUMBER_ERROR_TEXT);
        return;
      }
    } else {
      setShowOTP(false);
      setOpenErrorModal(true);
      if (response?.statusCode === "500") {
        setErrorMessage(response?.respDesc);
        return;
      }
      if (!response?.response?.data) {
        setErrorMessage(OTP_GENERATION_FAILED);
      }
    }
  }

  const handleClick = (showButtonLoader) => {
    setDisableButton(false);
    if (showButtonLoader) {
      setLoading(true);
    } else {
      setResetTimer(true)
    }
    let removeSpaceNumber = mobile?.mobileNumber?.replace(/\s/g,'')
    if (pageDetails?.generatedMobileOTPCount === 3 && removeSpaceNumber === userDetails?.mobileNumber) {
      setShowOTP(false);
      setOpenErrorModal(true);
      setErrorMessage(MAXIMUM_GENERATED_OTP_ERROR);
      return;
    }
    dispatch(userBasicDetails({mobileNumber:removeSpaceNumber}));
    LoginServices.generateOTP(callBackFunc);
  }


  useEffect(() => {
    if (mobile?.mobileNumber?.length == 11 && !errors?.mobileNumber) {
      setDisableButton(true)
    } else if (disableButton) {
      setDisableButton(false)
    }
  }, [mobile])

  return (
    <>
      <Loader isLoading={loading} />
      {terms === false && (
        <div className="page-container">
          <Header journeyTitle={pageDetails?.journeyType} title={WELCOME_NAME +', '+ (userDetails?.userName?.split(' ')?.[0]) + '!'} subtitle={PHONE_HEADER_LABEL} />
          <div className="top-round-card-header"></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              <PhoneNumberInput
                values={mobile}
                setValues={setMobile}
                errors={errors}
                setErrors={setError}
                name="mobileNumber"
                label={MOBILE_NUMBER}
                hintText={pageDetails?.preApprovedJourney ? PHONE_NUMBER_HINT : ''}
              />
              <div className="page-body-checkbox1">
                <p>{TERMS_CONDITION}
                  <label
                    className="page-body-check-box-click"
                    onClick={() => openTermsModal(true)}
                  >
                    {READ_MORE}
                  </label>
                </p>
              </div>            
            </div>
            <Footer callBackAction={() => {handleClick(true)}} disableButton={!disableButton} buttonText={NEXT_ACTION} apiTriggered={loading} />
          </div>
        </div>
      )}
      {terms === true && (
        <TermsAndConditionsWrapper>
          <div className="terms-conditions-container">
            <div className="top-round-card-header"></div>
            <div className="page-body-content">
              <div className="page-body-contents">
              <div className="">
                <Terms
                  closeTersmAction={() => openTermsModal(false)}
                  acceptTersmAction={() => {
                    openTermsModal(false);
                  }}
                  consent='Mobile'
                />
              </div>
              </div>
            </div>
          </div>
        </TermsAndConditionsWrapper>
      )}
      {showOTP && <MobileOTP onClose={() => {
        setShowOTP(false);
        setDisableButton(true);
        setLoading(false);
      }} resendOTP={() => handleClick()} onProceed={(val) => {}} resetTimer={resetTimer} />}
      {errorMessage && <ErrorModal errorTitle={errorTitle || ERROR_TEXT} errorContent={errorMessage} errorSubContent={errorTitle ? '' : (pageDetails?.generatedMobileOTPCount === 3 ? MAXIMUM_GENERATED_OTP_ERROR1 : OTP_GENERATION_FAILED_TRY_AGAIN)} clearCurrentSession={false} openErrorModal={openErrorModal} closeErrorModal={() => { 
        setErrorTitle('')
        setOpenErrorModal(false);
        setLoading(false);
      }} />}
    </>
  )
}

export default PhoneNumber ;