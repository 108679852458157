import pageDetails from './pageDetails'
import userDetails from './userDetails'
import toolsReducer from './features/toolsSlice'
import { combineReducers } from '@reduxjs/toolkit'

const appReducer = combineReducers(
    {
        pageDetails,
        userDetails,
        tools: toolsReducer
    }
)
const rootReducer = (state, action) => {
    if (action.type === 'clearResult') {
        window.sessionStorage.clear()
        state = {}
    }
    return appReducer(state, action)
}

export default rootReducer
