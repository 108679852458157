/*eslint-disable react-hooks/exhaustive-deps*/
import TextFieldInput from "../../../components/CustomInput/TextFieldInput";
import SelectInput from "../../../components/CustomInput/SelectInput";
import MasterServices from "../../../services/masters";
import { onlyNumbersAccept } from "../../../utils/utils";
import React, { useEffect, useState } from "react";
import {
  OTP_GENERATION_FAILED_TRY_AGAIN,
  DEDUPE_ERROR,
  PIN_CODE_LABEL,
  CITY_LABEL,
} from "../../../utils/cms";
export default function PincodeAndCity({
  isEnablePincode = false,
  pincodeName = "pin",
  cityName = "cityCode",
  prefillData,
  values,
  setValues,
  errors,
  options,
  afterInputChangeCallback,
  setLoading,
  setOptions,
  onCitySelect,
  errorOptions,
  setErrorOptions,
  afterPincodeChange,
  setMakeValues,
  setDealerOptions,
  setOrp,
  setDealerValue
}) {
  const [previousPincode, setpreviousPincode] = useState(null);

  useEffect(() => {
    if (prefillData?.pin?.length === 6) {
      setpreviousPincode(prefillData.pin);
      setLoading(true);
      MasterServices.getMasterList(
        callBackFunc,
        `CITY/${prefillData?.pin?.slice(0, 2)}`,
        "prefill"
      );
    }
  }, [prefillData]);

  const callBackFunc = (success, response, type) => {
    setLoading(false);
    if (success) {
      setOptions({
        ...options,
        [cityName]: response?.cacheValuesList,
      });
      if (type === "prefill") {
        let cityValue;
        if (prefillData?.cityCode) {
          cityValue = response.cacheValuesList?.find((option) => option.value === prefillData.cityName);
          if (!cityValue)
          cityValue = response.cacheValuesList?.find((option) =>
          (option?.code?.split("-")?.[1] || option.code) ===
            prefillData.cityCode || option.value === prefillData.cityCode
          );
          if (cityValue) setValues({ ...values, [cityName]: cityValue });
        }
      }
    } else {
      setErrorOptions({
        ...errorOptions,
        openErrorModal: true,
        errorContent: response?.respDesc || DEDUPE_ERROR,
        errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
      });
    }
  };

  const clearValuesOnPinChange = (values) => {
    setValues(values);
    setMakeValues({});
    setDealerOptions([]);
    setOrp({});
    setDealerValue({})
  };

  const onPincodeChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    value = onlyNumbersAccept(value);
    if (value?.length > 6) return;
    setValues({ ...values, [name]: value });
    if (value?.length === 6 && previousPincode?.slice(0, 2) !== value?.slice(0, 2)) {
      clearValuesOnPinChange({ [name]: value });
      setpreviousPincode(value);
      setLoading(true);
      MasterServices.getMasterList(
        callBackFunc,
        `CITY/${value?.slice(0, 2)}`
      );
    } else if (
      previousPincode?.slice(0, 2) !== value?.slice(0, 2)
    ) {
      clearValuesOnPinChange({ [name]: value });
    }
    afterPincodeChange && afterPincodeChange(value);
  };

  return (
    <>
      <TextFieldInput
        name={pincodeName}
        label={PIN_CODE_LABEL}
        values={values}
        setValues={setValues}
        onChange={onPincodeChange}
        errors={errors?.[pincodeName]}
        afterInputChangeCallback={afterInputChangeCallback}
        disabled={isEnablePincode}
        hintText='Purchase Location PIN Code'
      />
      <SelectInput
        name={cityName}
        label={CITY_LABEL}
        values={values}
        setValues={setValues}
        afterInputChangeCallback={afterInputChangeCallback || onCitySelect}
        options={options?.[cityName]}
        hint='Purchase Location City'
        twHint='Purchase Location City'
      />
    </>
  );
}
