/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react'
import "./index.css";
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { STEPS_LABEL, CONTINUE_ACTION, START_ACTION, TITLE_1, TITLE_2, TITLE_3, TITLE_4, TITLE_5, TITLE_6, JOURNEY_TYPE_3, DESCRIPTION_1, DESCRIPTION_2, DESCRIPTION_3, DESCRIPTION_4, DESCRIPTION_5, DESCRIPTION_6, SUCCESS_MSG, BASIC_DETAILS_HEADING, AGGREGATOR_ROADMAP_BASIC_DETAILS, AGGREGATOR_ASSETS_DETAILS_STEPPER_HEADING, AGGREGATOR_VEHICLE_VALUATION_PROCESS, ERROR_TEXT, RETRY_ACTION, SYSTEM_ERROR1, SYSTEM_ERROR2, JOURNEY_TYPE_4, STEPS_LABEL1, DESCRIPTION_7, TITLE_7 } from '../../utils/cms';
import Stepper from "@mui/material/Stepper";
import Step from '@mui/material/Step';
import Loader from "../../components/Loader/Loader";
import StepLabel from "@mui/material/StepLabel";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import initialoffer from "../../assets/roadmap-icons/initialoffer.png";
import initialofferBlur from "../../assets/roadmap-icons/initialoffer-blur.png";
import income from "../../assets/roadmap-icons/income.png";
import incomeBlur from "../../assets/roadmap-icons/income-blur.png";
import home from "../../assets/roadmap-icons/home.png";
import homeBlur from "../../assets/roadmap-icons/home-blur.png";
import car from "../../assets/roadmap-icons/car.png";
import stepAggregator from '../../assets/roadmap-icons/basic-details.png'
import carBlur from "../../assets/roadmap-icons/car-blur.png";
import twowheeler from "../../assets/roadmap-icons/twowheeler.png";
import twowheelerBlur from "../../assets/roadmap-icons/twowheeler-blur.png";
import finaloffer from "../../assets/roadmap-icons/finaloffer.png";
import finalofferBlur from "../../assets/roadmap-icons/finaloffer-blur.png";
import videokyc from "../../assets/roadmap-icons/videokyc.png";
import videokycBlur from "../../assets/roadmap-icons/videokyc-blur.png";
import step4Active from '../../assets/VerificationStepper/step-4-active.png';
import step4Inactive from '../../assets/VerificationStepper/step-4-inactive.png'
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useDispatch, useSelector } from 'react-redux'
import MasterServices from '../../services/masters';
import styled from "@emotion/styled";
import { pageBasicDetails } from '../../store/pageDetails';
import { getJourneyType, getVehicleType } from "../../utils/utils";
import { DEVIATION_URL, EXPOSURE_ETB_URL, STP_URL, VKYC_SYNC } from '../../config/config';
import ErrorModal from '../../components/ErrorModal';
import { Decryption } from '../../utils/payload';
import { updateJourneyStatus } from '../../utils/updateJouneyStatus';

const RoadMapPage = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const storeValues = useSelector(state => state);
    const { pageDetails: { journeyType, currentApplication, aggregatorJourney, preApprovedJourney, completedPages, encryptedPerfios }, userDetails: { mobileNumber } } = storeValues || {};
    const [activeStep, setActiveStep] = useState(0);
    const [searchParams] = useSearchParams();
    const [headerTitle, setHeaderTitle] = useState()
    const [isLoading, setIsLoading] = useState()
    const { step: queryStep } = useParams();
    const queryProgress = searchParams.get('progress');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [roadmapResponse, setRoadMapResponse] = useState({});
    const [retryCount, setRetryCount] = useState(0);
    const [isDeviationDone, setDeviationDone] = useState(false);
    const [isSTPDone, setSTPDone] = useState(false);
    const [isExposureDone, setExposureDone] = useState(false);
    const payloadRoadmap = {
        d2cId: currentApplication?.d2CApplicantID || ""
    }
    const payload = {
        D2CApplicantID: currentApplication?.d2CApplicantID || "",
        vehicleType: getVehicleType(journeyType),
        journeyType: getJourneyType(journeyType),
        leadId: currentApplication?.leadId || "",
    }
    const initialStepData = [
        {
            id: 1,
            label: (aggregatorJourney || preApprovedJourney) ? BASIC_DETAILS_HEADING : TITLE_1,
            image: (aggregatorJourney || preApprovedJourney) ? stepAggregator : initialoffer,
            imageBlur: initialofferBlur,
            description: (aggregatorJourney || preApprovedJourney) ? AGGREGATOR_ROADMAP_BASIC_DETAILS : DESCRIPTION_1,
            success: SUCCESS_MSG,
            key: "initialOfferCompletedOn",
        },
        {
            id: 2,
            label: (journeyType === JOURNEY_TYPE_3 || preApprovedJourney) ? TITLE_6 : TITLE_2,
            image: (journeyType === JOURNEY_TYPE_3 || preApprovedJourney) ? home : income,
            imageBlur: (journeyType === JOURNEY_TYPE_3 || preApprovedJourney) ? homeBlur : incomeBlur,
            description: (journeyType === JOURNEY_TYPE_3 || preApprovedJourney) ? DESCRIPTION_6 : DESCRIPTION_2,
            success: SUCCESS_MSG,
            key: "incomeVerificationCompletedOn",
        },
        {
            id: 3,
            label: (aggregatorJourney || journeyType === JOURNEY_TYPE_4) ? AGGREGATOR_ASSETS_DETAILS_STEPPER_HEADING : TITLE_3,
            image: (journeyType === JOURNEY_TYPE_3 ? twowheeler : car),
            imageBlur: (journeyType === JOURNEY_TYPE_3 ? twowheelerBlur : carBlur),
            image1: twowheeler,
            imageBlur1: twowheelerBlur,
            description: (aggregatorJourney || journeyType === JOURNEY_TYPE_4) ? AGGREGATOR_VEHICLE_VALUATION_PROCESS : DESCRIPTION_3,
            success: SUCCESS_MSG,
            key: "vehicleFinalizationCompletedOn",
        },
        {
            id: 4,
            label: TITLE_4,
            image: finaloffer,
            imageBlur: finalofferBlur,
            description: DESCRIPTION_4,
            success: SUCCESS_MSG,
            key: "finalOfferCompletedOn",
        },
        {
            id: 5,
            label: TITLE_7,
            image: step4Active,
            imageBlur: step4Inactive,
            description: DESCRIPTION_7,
            success: SUCCESS_MSG,
            key: "videoKYCCompletedOn",
        }
    ]
    const [stepsData, setStepsData] = useState(initialStepData);

    const handleClick = () => {
        if (activeStep === 0) {
            MasterServices.makeMoEngage(
                {
                    d2cId: currentApplication?.d2CApplicantID,
                    screenId: "6"
                }
            );            
            navigate("/basicDetails/0");
        } else if (activeStep === 1) {
            dispatch(pageBasicDetails({ initialOffer: {}, completedPages:{...completedPages, animationPage:false} }))
            navigate("/verification/0");
        } else if (activeStep === 2) {
            navigate("/dealerVehicle/0");
        } else if (activeStep === 4) {
            if (roadmapResponse?.vkycNeeded)  dispatch(pageBasicDetails({ currentApplication: {...currentApplication, vkyc: true} }))
            navigate("/finalOfferAccept");
        }
    };

    const StyledStepLabel = styled(StepLabel)({
        "& .MuiStepLabel-active": {
            color: "#1488CC",
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0)
        if (parseInt(queryStep) !== 0) {
            setIsLoading(true)
            setTimeout(() => {
                MasterServices.getRoadmapData(setRoadmapDate, payloadRoadmap);
            }, 1000)        
        }
    }, [queryStep])

    const cb = (success, res, type) => {
        if (success && res?.statusCode === '200') {
            if (type === 'deviation') {
                makeSTPAPI();
            }
            if (type === 'stp') {
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
            if (type === 'deviation') {
                setRetryCount(retryCount + 1);
            } else {
                setRetryCount(2);
            }
            setOpenErrorModal(true);
        }
    }

    const makeDeviationAPI = () => {
        if(isDeviationDone) return;
        setDeviationDone(true)
        setIsLoading(true)
        setTimeout(() => {
            MasterServices.makeFinalCheck(
                DEVIATION_URL,
                cb,
                payload,
                "deviation"
            );
        }, 2000);
    }

    const makeSTPAPI = () => {
        if(isSTPDone) return;
        setSTPDone(true)
        setIsLoading(true)
        setTimeout(() => {
            MasterServices.makeFinalCheck(
                STP_URL,
                cb,
                {...payload, isPACustomer: preApprovedJourney || false},
                "stp"
            );
        }, 2000);
    }

    const makeUpdateSync = () => {
        setIsLoading(true)
        MasterServices.makeFinalCheck(
            VKYC_SYNC,
            () => {},
            {
                d2cApplicantId: currentApplication?.d2CApplicantID || "",
                loanApplicationId: currentApplication?.loanApplicationId || "",
                pan: JSON.parse(Decryption(encryptedPerfios || "") || "{}") || '',
                mobileNumber: mobileNumber || "",
            },
            "updateSync"
        );
    }

    useEffect(() => {
        setActiveStep(parseInt(queryStep));
        let stepsCount = 5;
        if (!queryStep) {
            setHeaderTitle(STEPS_LABEL.replace('#step#', stepsCount))
        }
        if (parseInt(queryStep) >= stepsCount || parseInt(queryStep) < 0) {
            setHeaderTitle(SUCCESS_MSG)
        }
        if (parseInt(queryStep) < stepsCount && parseInt(queryStep) >= 0) {
            setHeaderTitle(STEPS_LABEL.replace('#step#', stepsCount - parseInt(queryStep)))
        }
        if (parseInt(queryStep) === 4) {
            setHeaderTitle(STEPS_LABEL1);
            if (!roadmapResponse?.vkycNeeded && roadmapResponse?.statusCode === '200') makeUpdateSync();
            if (currentApplication?.customerType === 'ETB') {
                if(isExposureDone) return;
                setExposureDone(true)
                MasterServices.makeFinalCheck(
                    EXPOSURE_ETB_URL,
                    (success, response, type) => {
                        //dispatch(pageBasicDetails({ isExposureDone: true}))
                    },
                    payload
                );
            }
            if (preApprovedJourney) {
                makeSTPAPI();
            } else {
                makeDeviationAPI();
            }
        }
    }, [queryStep, queryProgress, roadmapResponse])

    const setRoadmapDate = (success, response) => {
        if (parseInt(queryStep) !== 4) setIsLoading(false)
        if (success) {
            setRoadMapResponse(response);
            const updatedStepsData = initialStepData.map((step) => {
                if (step.key === "initialOfferCompletedOn" && response?.initialOfferCompletedOn) {
                    step.date = response?.initialOfferCompletedOn
                }
                else if (step.key === "incomeVerificationCompletedOn" && response?.incomeVerificationCompletedOn) {
                    step.date = response?.incomeVerificationCompletedOn
                }
                else if (step.key === "vehicleFinalizationCompletedOn" && response?.vehicleFinalizationCompletedOn) {
                    step.date = response?.vehicleFinalizationCompletedOn
                }
                else if (step.key === "finalOfferCompletedOn" && response?.finalOfferCompletedOn) {
                    step.date = response?.finalOfferCompletedOn
                }
                else if (step.key === "videoKYCCompletedOn" && response?.videoKYCCompletedOn) {
                    step.date = response?.videoKYCCompletedOn
                }
                if (step?.id === 5) {
                    if (response?.vkycNeeded) {
                        step.label = TITLE_5
                        step.description = DESCRIPTION_5
                        step.image = videokyc
                        step.imageBlur = videokycBlur
                    } else if (parseInt(queryStep) === 4) {
                        updateJourneyStatus(29, 'Final Offer Acceptance in Progress');
                    }
                }
                return step
            })
            setStepsData(updatedStepsData)
        }
    }

    const getDateString = (date) => {
        const splitDate = date.split('-')
        const monthNumber = parseInt(splitDate[1])
        const dateNumber = splitDate[2]
        if (monthNumber > 12) return
        const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return month[monthNumber - 1].toString() +" "+ dateNumber
    }

    const getTimeString = (resTime) => {
        let finalTime
        let splitTime = resTime.toString().split(':')
        const hour = parseInt(splitTime[0])
        if (hour >= 12) {
            finalTime = ((hour - 12) < 10 ? (hour - 12) === 0 ? '12' : '0' + (hour - 12).toString() : (hour - 12)) + ':' + splitTime[1] + " PM"
        }
        else {
            finalTime = (parseInt(splitTime[0]) === 0 ? '12' : splitTime[0]) + ':' + splitTime[1] + " AM"
        }
        return finalTime
    }

    const getFullDate = (resdate) => {
        let finalDate
        const splitDateObj = resdate.toString().split(' ')
        if (splitDateObj.length > 1) {
            const date = splitDateObj[0]
            const time = splitDateObj[1]
            finalDate = getDateString(date) + ", " + getTimeString(time)
        }
        else {
            const date = splitDateObj[0]
            finalDate = getDateString(date)
        }
        return finalDate
    }


    return (
        <div className="page-container roadmap">
            <Loader isLoading={isLoading} />
            <Header journeyTitle={journeyType} title={headerTitle} bottomSlider={parseInt(queryStep) === 0 ? 10 : parseInt(queryStep) * 20} />
            <div className="top-round-card-header" style={{ marginTop: '20px' }}></div>
            <div className="page-body-content">
                <div className="page-body-contents">
                    <div className="desktop_stepper">
                        <Stepper activeStep={activeStep ? activeStep : 0} alternativeLabel>
                            {stepsData.map((step) => (
                                <Step key={step.id}>
                                    <StepLabel>
                                        <img src={activeStep >= step.id ? step.image : (activeStep === step.id - 1 ? step.image : step.imageBlur)} alt="" height="60px" />
                                    </StepLabel>
                                    <div className={"stepper-label"+(parseInt(queryStep)+2 <= step.id ? ' disabled':'')}>{step.label}</div>
                                    <div className={"stepper-description"+(parseInt(queryStep)+2 <= step.id ? ' disabled':'')}>{step.description}</div>
                                    {queryStep >= step.id && step?.date ? <div className="stepper-completed-description"><i>Completed on {getFullDate(step?.date)}</i></div> : <></>}
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className="mobile_stepper">
                        <Stepper
                            activeStep={activeStep ? activeStep : 0}
                            orientation="vertical"
                        >
                            {stepsData.map((step) => (
                                <div key={step.id} className='stepper-block'>
                                    <StyledStepLabel
                                        icon={
                                            <div className={activeStep > step.id - 1 ? "circle-correct-tick" : ((activeStep === step.id || activeStep === step.id - 1) ? "circle_active" : "circle")}>
                                                <DoneOutlinedIcon
                                                    style={{ color: "white", width: '12px', height: '20px', marginTop: '-3px' }}
                                                />
                                                {step.id === 5 ? null : <div className={activeStep > step.id - 1 ? "completed-steps" : "step"}></div>}
                                            </div>
                                        }
                                    >
                                        <div className={`stepper-contents ${activeStep >= step.id ? "" : (activeStep === step.id - 1 ? "" : "incompleted-steps")}`}>
                                            <img className="stepper-img" src={activeStep >= step.id ? (journeyType === 'Two Wheeler Loan' && step.id === 3 ? step.image1 : step.image) : (activeStep === step.id - 1 ? (journeyType === 'Two Wheeler Loan' && step.id === 3 ? step.image1 : step.image) : (journeyType === 'Two Wheeler Loan' && step.id === 3 ? step.imageBlur1 : step.imageBlur))} alt="" />
                                            <div className='stepper-body'>
                                                <div className="stepper-label">{step.label}</div>
                                                <div className="stepper_description">{step.description}</div>
                                                {activeStep >= step.id && step?.date && <div className="stepper_completed_description"><i>Completed on {getFullDate(step?.date)}</i></div>}
                                            </div>
                                        </div>
                                    </StyledStepLabel>
                                </div>
                            ))}
                        </Stepper>
                    </div>
                </div>
                <Footer callBackAction={() => { handleClick() }} disableButton={false} buttonText={activeStep === 0 ? START_ACTION : activeStep === 5 ? SUCCESS_MSG : CONTINUE_ACTION} />
            </div>
            <ErrorModal
                errorTitle={ERROR_TEXT}
                errorContent={retryCount === 2 ? SYSTEM_ERROR2 : SYSTEM_ERROR1}
                openErrorModal={openErrorModal}
                closeErrorModal={() => {
                    setOpenErrorModal(false)
                    if (retryCount <= 1) {
                        makeDeviationAPI();
                    } else if (retryCount === 2) {
                        sessionStorage.clear();
                        window.location.href = '/';
                    }
                }} buttonText={(retryCount <= 1) ? RETRY_ACTION : null}
            />
        </div>
    );
}

export default RoadMapPage;
