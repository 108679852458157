export const myConfig = (productionConfig) => {
    let baseUrl = productionConfig?.PROD_BASE_URL;
    let baseFinalUrl = productionConfig?.PROD_FINALOFFER_BASE_URL
    if (window.location.origin?.includes('dev')) {
        baseUrl = productionConfig?.DEV_BASE_URL;
        baseFinalUrl = productionConfig?.DEV_FINALOFFER_BASE_URL
    } else if (window.location.origin?.includes('sit')) {
        baseUrl = productionConfig?.SIT_BASE_URL;
        baseFinalUrl = productionConfig?.SIT_FINALOFFER_BASE_URL
    } else if (window.location.origin?.includes('uat')) {
        baseUrl = productionConfig?.UAT_BASE_URL;
        baseFinalUrl = productionConfig?.UAT_FINALOFFER_BASE_URL
    } else if (window.location.origin?.includes('istio')) {
        baseUrl = productionConfig?.ISTIO_BASE_URL;
        baseFinalUrl = productionConfig?.ISTIO_FINALOFFER_BASE_URL
    } else if (window.location.origin?.includes('prdd2ccfd') || window.location.origin?.includes('induseasydrive')) {
        baseUrl = productionConfig?.PROD_BASE_URL;
        baseFinalUrl = productionConfig?.PROD_FINALOFFER_BASE_URL
    }
    return {BASE_URL:baseUrl, FINALOFFER_BASE_URL:baseFinalUrl}
}
