import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { WELCOME_HEADER, NAME_LABEL, AGREE_TEXT, AGREE_TERMS_TEXT, NEXT_ACTION } from '../../utils/cms'
import { useSelector, useDispatch } from 'react-redux'
import Terms from "../Terms";
import { userBasicDetails } from '../../store/userDetails';
import CheckboxInput from "../../components/CustomInput/CheckboxInput"
import FullNameInput from '../../components/CustomInput/FullNameInput/FullNameInput';
import TermsAndConditionsWrapper from '../../components/TermsAndConditionsWrapper/TermsAndConditionsWrapper';

const NamePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeValues = useSelector(state => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [terms, openTermsModal] = useState(false);
  const [accepted, isTermsAccepted] = useState(userDetails?.userName);
  const [name, setName] = useState(userDetails?.userName);
  const [errors, setError] = useState([])
  
  const disableButton = name && accepted;

  const handleClick = () => {
    dispatch(userBasicDetails({userName:name}))
    navigate('/mobile');
  };
  

  return (
    <>
      {terms === false && (
        <div className="page-container">
          <Header journeyTitle={pageDetails?.journeyType} title={WELCOME_HEADER} subtitle={NAME_LABEL} />
          <div className="top-round-card-header"></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              <FullNameInput
                label="Name"
                values={{name: name}}
                name="name"
                setFieldValue={(val) =>
                  setName(val)
                }
                setErrors={setError}
                errors={errors}
                maxLength={40}
                //hintText={NAME_FIELD_HINT_TEXT}
              />
              <div className="page-body-checkbox">
                <CheckboxInput
                  checked={accepted}
                  //setValues={() => isTermsAccepted(!accepted)}
                  name="terms_checkbox"
                  disabled={!accepted}
                  //showToastMessage={!accepted}
                  openTermsModal={() => !accepted && openTermsModal(true)}
                />
                <p>{AGREE_TEXT}
                  <label
                    className="page-body-check-box-click"
                    onClick={() => openTermsModal(true)}
                  >
                    {AGREE_TERMS_TEXT}
                  </label>
                </p>
              </div>            
            </div>
          <Footer callBackAction={() => {handleClick()}} disableButton={!disableButton} buttonText={NEXT_ACTION} />
          </div>
        </div>
      )}
      {terms === true && (
        <TermsAndConditionsWrapper>
          <div className="terms-conditions-container">
            <div className="top-round-card-header"></div>
            <div className="page-body-content">
              <div className="page-body-contents">
              <div className="">
                <Terms
                  closeTersmAction={() => openTermsModal(false)}
                  acceptTersmAction={() => {
                    openTermsModal(false)
                    isTermsAccepted(true)
                  }}
                  consent='Name'
                />
              </div>
              </div>
            </div>
          </div>
        </TermsAndConditionsWrapper>
      )}
    </> 
  );
}

export default NamePage;