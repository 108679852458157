/*eslint-disable react-hooks/exhaustive-deps*/
import {
	useEffect,
	useState,
} from "react";
import RadioInput from "../../components/CustomInput/RadioInput";
import {
	BASIC_DETAILS_STEP_HEADING,
	OFFICE_CONTENTS_ADDRESS,
	OFFICE_PERMANENT_ADDRESS,
} from "../../utils/cms";
import "./index.css";
import { addressOptions2 } from "./data";
import AddressForm from "../../components/AddressDetailsComponent/AddressForm";
import TextFieldInput from "../../components/CustomInput/TextFieldInput";
import { useSelector } from "react-redux";

const EmployeeOfficeDetails = ({
	values,
	setValues,
	setIsDisabled,
	setLoading,
	isDisabled,
	disabled
}) => {
	const storeValues = useSelector((state) => state);
	const {
	  pageDetails: { currentApplication, officeDetails, dropOffScreenRoute }
	} = storeValues || {};
	const [errors, setErrors] = useState({});
	const [globalOptions, setGlobalOptions] = useState();
	const [applyPrefill, setApplyPrefill] = useState(values?.pinCode);
	const [showAddressForm, setShowAddressForm] = useState();

	useEffect(() => {
		if (
			!errors?.addressLine1 &&
			!errors?.addressLine2 &&
			values.addressType === "different_address" &&
			values.businessName &&
			values.addressLine1?.length >= 10 &&
			values.addressLine2?.length >= 3 &&
			(values.cityName || values.cityName?.value) &&
			(values.districtName || values.districtName?.value) &&
			(values.stateName || values.stateName?.value) &&
			(values.pinCode?.length === 6 || values.pinCode?.toString().length === 6)
		) {
			setIsDisabled(false);
		} else if (
			(values.addressType === "current_address" ||
				values.addressType === "permanent_address") &&
			values.businessName
		) {
			setIsDisabled(false);
		} else {
			if (!isDisabled) {
				setIsDisabled(true);
			}
		}
		setLoading(false);
		if (values.pinCode?.length === 6 || values.pinCode?.toString().length === 6) {
			setApplyPrefill(true);
			setIsDisabled(false);
			setShowAddressForm(!officeDetails?.businessIsPermanentAddress && !officeDetails?.businessIsCurrentAddress)
		}
		if (values?.addressType) {
			setApplyPrefill(false);
			setShowAddressForm(values.addressType === "different_address" && !officeDetails?.businessIsPermanentAddress && !officeDetails?.businessIsCurrentAddress)
		}
	}, [values,errors]);

	return (
		<div className="employee-details self-employed">
			<div className="heading">
				{BASIC_DETAILS_STEP_HEADING.replace(
					"#category#",
					"Office "
				)}
				{<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
			</div>
			<TextFieldInput
				label="Business Name"
				fieldType="inputText"
				inputType="text"
				name="businessName"
				values={values}
				setValues={setValues}
				maxLength={80}
				disabled={disabled}
			/>
			<div className="address_check_cont">
				<div>
					<div className="address-heading">
						{OFFICE_CONTENTS_ADDRESS}
					</div>
					<RadioInput
						values={values}
						setValues={setValues}
						options={addressOptions2}
						name="AddressType"
						disabled={disabled}
						prefill={applyPrefill}
					/>
				</div>
			</div>
			{(values.addressType === "different_address" || disabled || showAddressForm) && (
				<div className="heading-toggle">
					<p>{OFFICE_PERMANENT_ADDRESS}</p>
					<AddressForm
						values={values}
						setValues={setValues}
						setLoading={setLoading}
						clearForm={false}
						globalOptions={globalOptions}
						setGlobalOptions={setGlobalOptions}
						errors={errors}
						setErrors ={setErrors}
						disabled={disabled}
					/>
				</div>
			)}
		</div>
	);
};

export default EmployeeOfficeDetails;
