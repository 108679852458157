import React from "react";
import './timer.css'
import { RESEND_OTP } from '../../utils/cms';

const Timer = ({ resendOTPAction, showResend, counterSec, counterMin, initial, startTime }) => {

  const handleTime = () => {
    resendOTPAction()
    startTime(initial);
  };

  const changeToDoubleDigit = (number) => {
    if (!number) return "00";
    return (number).toLocaleString('en-US', {minimumIntegerDigits: 2});
  }

  return (
    <div >
      {(counterMin === 0  && counterSec === 0) || showResend ? (
        <div>
          <span onClick={handleTime} className='otp-resend-button'>
            <u>{RESEND_OTP}</u>
          </span>
        </div>
      ) : (
        <div className='otp-timer'>
          {RESEND_OTP} in {changeToDoubleDigit(counterMin)}:{changeToDoubleDigit(counterSec)}
        </div>
      )}
    </div>
  );
}

export default Timer;