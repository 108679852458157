/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import {
  VEHICLE_VALUATION_HEADING,
  VEHICLE_VALUATION,
  VALUATION_COMPLETE,
  VEHICLE_VALUATION_TEXT_CONTENT,
  BE_DETAILS,
} from "../../utils/cms";
import DOMPurify from "dompurify";
import valuationError from "../../assets/DealerVehicle/valuationError.png";
import valuationSuccess from "../../assets/DealerVehicle/valuationSuccess.png";
import { phoneNumberFormat } from "../../utils/utils";
import { useSelector } from "react-redux";

function VehicleValuation({ responseData, setIsDisabled }) {
  const storeValues = useSelector((state) => state);
  const { pageDetails: { currentApplication } } = storeValues || {};
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  useEffect(() => {
    if (
      responseData.journeyStatus &&
      ((responseData.journeyStatus === "Journey Continues" && (responseData.valuationPrice !== 0 && responseData.valuationPrice !== '0' && responseData.valuationPrice !== null)) || responseData.journeyStatus === "Journey Stopped")
    ) {
      setIsDisabled(false);
    }
    localStorage.setItem('restrictDropOff', 'yes');
  }, [responseData]);

  return (
    <div className="assets_details">
      <div className="header-vehicle-valuation">
        {VEHICLE_VALUATION_HEADING}
        {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
      </div>
      {responseData.journeyStatus === "Journey Stopped" ? (
        <div className="vehicle-valuation-card">
          <div className="vehical-containt">
            <img src={valuationError} alt="" />
            <div className="text-valuation-error">
              {BE_DETAILS.replace(
                "#bename#",
                (responseData.beName || '')
              ).replace(
                "#bemobile#",
                phoneNumberFormat(responseData?.beContactNumber || '')
              )}
            </div>
          </div>
        </div>
      ) : (
      (responseData.journeyStatus === "Journey Continues" && (responseData.valuationPrice !== 0 && responseData.valuationPrice !== '0' && responseData.valuationPrice !== null)) ? (
        <div className="vehicle-valuation-card">
          <div className="vehical-containt">
            <img src={valuationSuccess} alt="" />
            <div className="text-valuation">{VALUATION_COMPLETE}</div>
          </div>
        </div>
      ) : (
        <div className="vehicle-valuation-text">
          <p dangerouslySetInnerHTML={sanitizedData(VEHICLE_VALUATION)} />
          <div>
            {VEHICLE_VALUATION_TEXT_CONTENT.replace(
              "#bename#",
              responseData?.beName
            ).replace(
              "#bemobile#",
              phoneNumberFormat(responseData?.beContactNumber)
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default VehicleValuation;
