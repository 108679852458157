import React from "react";
import "./PreApprovedOffer.css";
import { JOURNEY_TYPE_1, JOURNEY_TYPE_2, JOURNEY_TYPE_3, PRE_APPROVED_AVAL_THIS_OFFER, PRE_APPROVED_LINK_TEXT, PRE_APPROVED_OFFER_HEADER,  PRE_APPROVED_IRR,
  PRE_APPROVED_LOAN_AMOUNT,
  PRE_APPROVED_NEW_CAR_LOAN,
  PRE_APPROVED_TENURE,
  PRE_APPROVED_TWO_WHEELER_LOAN,
  PRE_APPROVED_USED_CAR_LOAN } from "../../utils/cms";
import PreApprovedCar from "../../assets/PreApproved/car.png";
import PreApprovedBike from "../../assets/PreApproved/bike.png";
import PreApprovedUsedCar from "../../assets/PreApproved/usedCar.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { changeValueToCurrency } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageBasicDetails } from '../../store/pageDetails'

function PreApprovedOfferPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeValues = useSelector((state) => state);
  const { pageDetails: { tilesConfig: { preapprovedMasterList: preApprovedData }}, userDetails } = storeValues || {};

  const handleClick = (data, flowType) => {
    if (data?.productType === 'PV New') dispatch(pageBasicDetails({journeyType: JOURNEY_TYPE_1}));
    if (data?.productType === 'TW New') dispatch(pageBasicDetails({journeyType: JOURNEY_TYPE_3}));
    if (data?.productType === 'PV Used') dispatch(pageBasicDetails({journeyType: JOURNEY_TYPE_2}));
    dispatch(pageBasicDetails({preApprovedJourney: flowType ? true : false, preapprovedMaster: data }));
    navigate('/login')
  };

  return (
    <div className="page-container">
      <div className="preApproved-offerPage-top-round-card-header"></div>
      <div className="preApproved-offerPage-page-body-content">
        <div className="page-body-contents">
          <div className="header-text">{PRE_APPROVED_OFFER_HEADER.replace('#name#', userDetails?.userName?.split(' ')?.[0] )}</div>
          {preApprovedData.map((data) => data.expiredFlag !== '2' && (
            <div className="pre-offer-page-container" key={data?.productType}>
              <div className="pre-offer-header">
                <img
                  src={
                    (data.productType === "PV New" && PreApprovedCar) ||
                    (data.productType === "PV Used" && PreApprovedUsedCar) ||
                    (data.productType === "TW New" && PreApprovedBike)
                  }
                  alt=""
                />
                <div className="pre-offer-header-text"><b>
                  {data.productType === "PV New"
                    ? PRE_APPROVED_NEW_CAR_LOAN
                    : data.productType === "PV Used"
                    ? PRE_APPROVED_USED_CAR_LOAN
                    : PRE_APPROVED_TWO_WHEELER_LOAN}
                </b></div>
              </div>

              <div className="pre-offer-content">
                <div className="pre-offer-loan-amount">
                  <div className="content-text">{PRE_APPROVED_LOAN_AMOUNT}</div>
                  <div className="content-text">{PRE_APPROVED_TENURE}</div>
                  <div className="content-text">{PRE_APPROVED_IRR}</div>
                </div>

                <div className="pre-offer-value">
                  <div className="content-value">
                    <CurrencyRupeeIcon sx={{ height: "17px" }} />
                    {changeValueToCurrency.format(data.preApprovedLimit)}
                  </div>
                  <div className="content-value">{data.maxTenure} Months</div>
                  <div className="content-value">{data.interestRate}%</div>
                </div>
              </div>
              <br />
              <button className="avail-offer-button" onClick={() => handleClick(data, 'Pre-approved')}><b>{PRE_APPROVED_AVAL_THIS_OFFER}</b></button>
              <div className="pre-approved link-text" onClick={() => handleClick(data, '')}>{PRE_APPROVED_LINK_TEXT}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PreApprovedOfferPage;
