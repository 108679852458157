import { PROCEED_ACTION } from "../../../utils/cms"
import { GST, SALARY_CREDITED_TO_BANK, ONLINE } from "./data"

export function RadioButton(props) {

    return (
        <div className="radio_btn_cont">
            <div className={"radio_btn" + (props.id === props.radioActive ? " active" : "")} onClick={() => props.onClick(props.id)}>
                <div></div>
            </div>
            <div className="label">
                <div className="main_label">{props.label}</div>
                <div className="sub_label">{props.subLabel}</div>
            </div>
        </div>
    )
}

export function DetailsCont(props) {

    return (
        <div className="option_box active">
            <div className="title">{props.title}</div>
            <div className="heading">{props.heading}</div>
            <div className="sub_heading">{props.subHeading}</div>
            {(props.radioActive === SALARY_CREDITED_TO_BANK && props.type === ONLINE) && <div className="recomend">(Recommended)</div>}
            <button className="proceed_btn"
                disabled={
                    props.radioType === GST ?
                        props.disableProceed : false}
                onClick={() => props.clickHandler(props.type)}>{PROCEED_ACTION}</button>
        </div>
    )
}