import React from "react";
import "./VericationTerms.css";
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  VERIFICATION_TERMS_HEADING,
  CONSENT_VERIFICATION_SUBHEADING1,
  CONSENT_VERIFICATION_SUBHEADING2,
  CONSENT_VERIFICATION_SUBHEADING3,
  CONSENT_VERIFICATION_SUBHEADING4,
  CONSENT_VERIFICATION_SUBHEADING5,
  CONSENT_VERIFICATION_SUBHEADING6,
  CONSENT_VERIFICATION_SUBHEADING7,
  CONSENT_VERIFICATION_SUBHEADING8,
  CONSENT_VERIFICATION_SUBHEADING9,
  CONSENT_VERIFICATION_SUBHEADING10,
  TERMS_CONDITIONS1

} from "../../../../utils/cms";

const VerificationTerms = ({closeTersmAction, acceptTersmAction}) => {

  return (
    <>
      <div className="income-consent-content">
        <h3 className="income-terms-heading">
          {VERIFICATION_TERMS_HEADING} <CloseIcon onClick={() => closeTersmAction()} />
        </h3>
        <div className="consent-body">
          <p>{CONSENT_VERIFICATION_SUBHEADING1}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING2}</p>
          <fieldset>
            <p className="filedset-text"><i>{CONSENT_VERIFICATION_SUBHEADING3}</i></p>
          </fieldset>
          <p className="terms-condition">{TERMS_CONDITIONS1} </p>
          <p>{CONSENT_VERIFICATION_SUBHEADING4}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING5}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING6}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING7}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING8}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING9}</p>
          <p>{CONSENT_VERIFICATION_SUBHEADING10}</p>
        </div>

      </div>
      <Button className="consent-action-button" variant="contained" color="secondary" onClick={() => acceptTersmAction()}><b>Accept</b></Button>
    </>
  );
};

export default VerificationTerms;