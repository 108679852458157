/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable eqeqeq*/
import React from "react";
import { PAN_ERROR_SUB_TEXT1 } from "../../../utils/cms";
import {
	checkValidPANFormat,
	removeInvaildCharFromPANField,
} from "../../../utils/utils";
import TextFieldInput from "../TextFieldInput";
import "./PanInput.css";

const PanInput = ({
	values,
	errors,
	setErrors,
	name,
	label,
	hintText,
	setFieldValue,
	disabled
}) => {
	const inputChangeHandler = (e) => {
		let value = e.target.value; 
		if (value.length > 10) {
			return
		}
		value = removeInvaildCharFromPANField(value);
		if (
			value?.length &&
			!/^[a-zA-Z]+$/.test(value.slice(0, 5)) &&
			value !== ""
		) {
			setErrors({ ...errors, [name]: [PAN_ERROR_SUB_TEXT1] });
		} else if (
			(value.length === 4 || value.length === 5) &&
			value[3]?.toUpperCase() !== "P" &&
			value[3]?.toUpperCase() !== "H"
		) {
			setErrors({ ...errors, [name]: [PAN_ERROR_SUB_TEXT1] });
		} else if (
			value?.length > 5 &&
			value?.length <= 9 &&
			!/^\d+$/.test(value.slice(5, 9))
		) {
			setErrors({ ...errors, [name]: [PAN_ERROR_SUB_TEXT1] });
		}
		else if (value.length === 10) {
			if (!checkValidPANFormat(value)) {
				setErrors({ ...errors, [name]: [PAN_ERROR_SUB_TEXT1] });
			} else {
				setErrors({ ...errors, [name]: null });
			}
		} else {
			if (errors[name]) {
				setErrors({ ...errors, [name]: null });
			}
		}

		setFieldValue(value);
	};

	const onBlurHandler = (e) => {
		let value = e.target.value;
		if (value.length < 10 && value?.length) {
			setErrors({ ...errors, [name]: [PAN_ERROR_SUB_TEXT1] });
		}
	};

	return (
		<TextFieldInput
			errors={errors?.[name]}
			name={name}
			values={values}
			label={label}
			onBlur={onBlurHandler}
			hintText={hintText}
			onChange={inputChangeHandler}
			className="pan-input"
			disabled={disabled}
		/>
	);
};

export default PanInput;
