/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
import MasterServices from "../services/masters";
import { screenData } from './screenData';
import { store } from '../store/index'
import { getJourneyType, getVehicleType } from "./utils";
import { Decryption } from "./payload";

const pathWithStepper = ["roadmap","basicdetails","verification","dealervehicle"]

function dropOffApiCallback(success, response, dropOffReason) {
    if (dropOffReason === "Session Idle Timeout") {
        sessionStorage.clear();
        return;
    }
    if(dropOffReason!=="Browser Close"){
        sessionStorage.clear();
        window.location.href = '/';
    }
    if (success) {
        console.log("Drop Off Api Success.")
    } else {
        console.log("Drop Off Api Failed.")
    }
}

export function dropOffApiCall(dropOffReason, strictDropOff) {
    localStorage.clear();
    const storeValues = store.getState();
    const { pageDetails: { currentApplication, journeyType, aggregatorJourney, preApprovedJourney, aggregatorHandshakeResp, encryptedPerfios, dropOffScreenRoute}, userDetails } = storeValues || {};
    let screenId = "", screenName = "", dropOffType = ""
    let route =  dropOffScreenRoute || window.location.pathname;
    const routeSplitArray = route.split('/')
    route = routeSplitArray[1].toLocaleLowerCase()
    if (!currentApplication?.d2CApplicantID || !currentApplication?.loanApplicationId || !userDetails?.email || !userDetails?.mobileNumber || route === "") {
        console.log("Missing Data")
        return
    }
    if (pathWithStepper.includes(route)) {
        route=route+"/"+routeSplitArray[2]
    }
    screenData.forEach((screen) => {
        if (screen.route.toLocaleLowerCase() === route.toLocaleLowerCase()) {
            screenId = screen.id
            screenName = screen.name
        }
    })
    if (!screenId) {
        return
    }
    console.log("Screen Id: ", screenId)
    console.log("Screen Name: ", screenName)
    console.log("Route: ", route)
    if (screenId <= 11) {
        dropOffType = "Initial offer drop"
    }
    else {
        dropOffType = "Final offer drop"
    }
    if(dropOffReason==="Move to assisted" || dropOffReason === "VKYC is Rejected") {
        dropOffType = "Move to assisted"
    }
    let payload = {
        d2cApplicantId: currentApplication?.d2CApplicantID,
        d2cUIDropOffType: dropOffReason,
        d2cScreenId: screenId,
        d2cScreenName: screenName,
        sfDropOffType: dropOffType,
        sfLoanApplicationId: currentApplication?.loanApplicationId,
        emailId: userDetails?.email,
        phoneNumber: userDetails?.mobileNumber,
        userName: userDetails?.userName || "",
        pan: JSON.parse(Decryption(encryptedPerfios || "") || "{}") || '',
        vehicleType: getVehicleType(journeyType),
        journeyType: getJourneyType(journeyType),
        leadSource: aggregatorHandshakeResp?.leadSource || 'IndusMobile',
        journey: (aggregatorJourney ? 'Aggregator' : (preApprovedJourney ? 'PreApproved' : 'IndusMobile')),
        }
    if (screenId === 99 || screenId === 100) payload.d2cScreenId = 15;
    MasterServices.userDropOff(dropOffApiCallback, payload, dropOffReason);
}
