import React from "react";
import { removeInvaildCharFromTextField } from "../../../utils/utils";
import TextFieldInput from "../TextFieldInput";

const FullNameInput = ({
  values,
  errors,
  setErrors,
  name,
  label,
  hintText,
  maxLength,
  setFieldValue,
  disabled
}) => {
  const inputChangeHandler = (e) => {
    let value = e.target.value;
    value = removeInvaildCharFromTextField(value);
    value = value?.replace(/\s\s|\t+/g, " ");
    if (value === ' ') return;
    value = value?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    if (maxLength < value?.length) {
      return;
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setFieldValue(value);
  };

  const onBlurHandler = (e) => {
    let value = e.target.value;
    value = removeInvaildCharFromTextField(value);
    if (value) {
      setFieldValue(value.trim());
    }
  };

  return (
    <TextFieldInput
      errors={errors?.[name]}
      name={name}
      values={values}
      label={label}
      onBlur={onBlurHandler}
      hintText={hintText}
      onChange={inputChangeHandler}
      disabled={disabled}
    />
  );
};

export default FullNameInput;
