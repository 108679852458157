import React from 'react'
import "./index.css";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Slider from "../../components/Slider";
import { useSelector } from 'react-redux'

const Header = (props) => {
  const storeValues = useSelector(state => state);
  const { pageDetails } = storeValues || {};
  const { journeyTitle, title, subtitle, phoneTitle, emailTitle, topSlider, bottomSlider } = props;
  return (
    <div className="top-header">
      {journeyTitle && <p className='journey-title'>{((pageDetails?.preApprovedJourney) ? 'Pre-approved ' : '') + journeyTitle}</p>}
      {topSlider && <Slider progress={topSlider} />}
      <p className={`welcome-title ${!bottomSlider ? "trancated-text" : "font24"}`} style={{ paddingTop: (!journeyTitle && !topSlider) ? '30px' : '0px'}}>{title}</p>
      {subtitle && <p className="welcome-sub-title">{subtitle}</p>}
      {bottomSlider && <div className='bottom-slider'><Slider progress={bottomSlider} /></div>}
      {/*Aadhaar page*/}
      {phoneTitle && <p className="welcome-phone-title"><LocalPhoneOutlinedIcon className="phone-icon-color" sx={{fontSize:15}}/><span>+91 {phoneTitle.match(/.{1,5}/g)?.join(' ')}</span></p>}
      {emailTitle && <p className="welcome-email-title"><EmailOutlinedIcon className="email-icon-color" sx={{fontSize:15}}/><span>{emailTitle}</span></p>}
    </div> 
  );
}

export default Header;
