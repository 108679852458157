import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./index.css";
import { CLOSE_ACTION } from '../../utils/cms';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorModal = (props) => {
  const { errorTitle, errorContent, errorSubContent, clearCurrentSession, openErrorModal, closeErrorModal, buttonText } = props;
  const closeCurrentSession = () => {
    if (clearCurrentSession) {
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      closeErrorModal(false);
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={openErrorModal}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="error"
        >
          <DialogTitle className="error-title">
            {errorTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="alert-dialog">
                {errorContent}
                {errorSubContent ? <><br/><br/>{errorSubContent}</> : null}
            </DialogContentText>
            <div className="error-actions">
              <Button className="error-submit-button" variant="contained" color="secondary" onClick={() => {closeCurrentSession()}}>{buttonText || CLOSE_ACTION}</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default ErrorModal;
