import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import React from "react";
import {useSelector } from "react-redux";


const RadioInput = ({
  values,
  name,
  value,
  disabled,
  options = [],
  setValues,
  className,
  prefill,
  inputChangeCallBack = ()=>{},
  ...props
}) => {
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: {
      officeDetails
    },
  } = storeValues || {};
//   const isTrue = (value) => {
//  if(disabled || prefill) {
//   if(value === "current_address") {
//     return officeDetails?.businessIsCurrentAddress;
//    }
//     if(value === "permanent_address") {
//       return officeDetails?.businessIsPermanentAddress;
//     }
//     if(!officeDetails?.businessIsPermanentAddress && !officeDetails?.businessIsCurrentAddress) {
//       return true
//     }
//    }
//   }
  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setValues({ ...values, [name]: value });
    inputChangeCallBack(name,value)
  };
  return (
    <div className="custom-input">
      <RadioGroup
        className={`radio-group ${className}`}
        name="addressType"
        value={value || values?.[name]}
        onChange={inputChangeHandler}
        {...props}
        disabled = {disabled}
      >
        {options?.map((each) => (
          <FormControlLabel
            key={each.value}
            id={each.value}
            value={each.value}
            control={
              <Radio
                className={"radio-item"}
                value={each.value}
                label={each.label}
                disabled = {disabled}
                checked = {each.value === values?.addressType}
              />
            }
            label={each.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioInput;
