/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.css";
import { useSelector, useDispatch } from 'react-redux';
import {  WELCOME_NAME,
  NEXT_ACTION,
  OTP_GENERATION_FAILED,
  ERROR_TEXT,
  OTP_GENERATION_FAILED_TRY_AGAIN, AADHAR_CONFIRMATION_TITLE, AADHAR_CONFIRMATION_CONTENT, AADHAR_CONFIRMATION_SUB_CONTENT, MAXIMUM_GENERATED_OTP_ERROR } from '../../utils/cms';
import { userBasicDetails } from '../../store/userDetails';
import { pageBasicDetails } from '../../store/pageDetails'
import LoginServices from '../../services/login';
import ErrorModal from '../../components/ErrorModal';
import MobileOTP from '../../components/MobileOTP/OTP';
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from '../../components/ConfirmationDialogBox'
import AadharInput from "./AadharInput";
import { Decryption, Encryption } from "../../utils/payload";
import Loader from "../../components/Loader/Loader";
import { removeCompletedScreen } from "../../store/features/toolsSlice";

const TestPage = ({
  title,
  subtitle,
  topSlider,
  activeStep,
  showStepper,
  CustomStepIcon,
  journeyType,
  showLoader
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValues = useSelector(state => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [values, setValues] = useState({})
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [clearCurrentSession, setClearCurrentSession] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);


  const generateMobileOTPCountFun = () => {
    let removeSpaceNumber = Decryption(userDetails?.profile || '')?.replaceAll('"', '');
    let count = 0;
    if (removeSpaceNumber === (values?.aadhar)) {
      count = pageDetails?.generatedMobileOTPCount || 0
    }
    return count
  }


  const callBackFunc = (success, response) => {
    setDisableButton(true);
    setLoading(false);
    setShowConfirmation(false)
    if (success) {
      dispatch(pageBasicDetails({ generatedMobileOTPCount: generateMobileOTPCountFun() + 1, invalidMobileOTPCount: 0 }));
      if (response?.statusCode === '429' || response?.statusCode === "500") {
        setShowOTP(false);
        setOpenErrorModal(true);
        setErrorMessage(response?.respDesc)
        return;
      }
      if (response?.statusCode === '200') {
        if(showStepper) {showLoader(false)}
        dispatch(pageBasicDetails({ aadharResponse: response }));
        setShowOTP(true);
        return;
      }
    } else {
      setShowOTP(false);
      setOpenErrorModal(true);
      setClearCurrentSession(false);
      if (response?.statusCode === "500") {
        setErrorMessage(response?.respDesc);
        return;
      }
      if (!response?.response?.data) {
        setErrorMessage(OTP_GENERATION_FAILED);
      }
    }
  }

  const handleClick = () => {
    setDisableButton(false);
    setLoading(true);
    if (pageDetails?.generatedMobileOTPCount === 3 && Decryption(userDetails?.profile || '')?.replaceAll('"', '') === values?.aadhar) {
      setShowOTP(false);
      setOpenErrorModal(true);
      setShowConfirmation(false);
      setErrorMessage(MAXIMUM_GENERATED_OTP_ERROR);
      return;
    }
    dispatch(userBasicDetails({ profile: Encryption(values?.aadhar) }));
    LoginServices.validateAadhar(callBackFunc);
    dispatch(removeCompletedScreen(15))
  }

  useEffect(() => {
    if (pageDetails?.aadhar?.statusCode === '200') {
      if(showStepper) return
      navigate('/roadmap/0');
    } else if (!userDetails?.jwt) {
      navigate('/')
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="page-container">
    <Loader isLoading={loading}/>
      <Header
        journeyTitle={!showStepper ? pageDetails?.journeyType : ''}
        title={title || WELCOME_NAME + ', ' + (userDetails?.userName?.split(' ')?.[0]) + '!'}
        phoneTitle={!showStepper ? userDetails?.mobileNumber : ''}
        topSlider={topSlider || null}
        emailTitle={!showStepper ? userDetails?.email : ''}
        subtitle={subtitle || null}
      />
      <div className="top-round-card-header"></div>
      <div className="page-body-content">
        <div className="page-body-contents">
          {showStepper && <div className="basic_details_stepper">
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
              </Step>
              {journeyType !== "Two Wheeler Loan" && (
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                </Step>
              )}
            </Stepper>
          </div>}
          <AadharInput
            values={values}
            setValues={(val) => {
              setValues(val);
              setDisableButton(val?.aadhar?.length === 14)
            }}
          />
        </div>
        <Footer
          callBackAction={() => {
            setShowConfirmation(true);
          }}
          disableButton={!disableButton}
          buttonText={NEXT_ACTION}
        />
      </div>
      {showOTP && <MobileOTP
        showStepper={showStepper}
        onClose={() => {
        setShowOTP(false);
        setDisableButton(true);
        setLoading(false);
      }} resendOTP={() => handleClick()} onProceed={(val) => { }} aadharModal={true} />}
      {errorMessage && <ErrorModal errorTitle={ERROR_TEXT} errorContent={errorMessage} errorSubContent={OTP_GENERATION_FAILED_TRY_AGAIN} clearCurrentSession={clearCurrentSession} openErrorModal={openErrorModal} closeErrorModal={() => { setOpenErrorModal(false); setLoading(false); setShowConfirmation(false); }} />}
      {showConfirmation && <ConfirmationDialog errorTitle={AADHAR_CONFIRMATION_TITLE} errorContent={AADHAR_CONFIRMATION_CONTENT} errorSubContent={AADHAR_CONFIRMATION_SUB_CONTENT} openErrorModal={showConfirmation} callBackAction={(val) => {
        if (val === 'continue') {
          handleClick();
        } else {
          setShowConfirmation(false);
        }
      }} apiTriggered={loading} primaryAction={true} secondaryAction={true} />}
    </div>
  );
};
export default TestPage;