import { v4 as uuidv4 } from 'uuid';
import { Decryption, Encryption } from '../utils/payload';

export default function setupAxios(axios, configUrls) {
    axios.interceptors.request.use(
        config => {
            if (config.url?.includes("finaloffer") || config.url?.includes("roadmap") || config.url?.includes("video")) {
                config.baseURL = configUrls.FINALOFFER_BASE_URL;
                //if (sessionStorage.getItem('showConsole')) alert('final url: ' + config.baseURL + config.url)
                localStorage.setItem('FINALOFFER_BASE_URL', configUrls.FINALOFFER_BASE_URL)
            }
            else {
                config.baseURL = configUrls.BASE_URL;
                //if (sessionStorage.getItem('showConsole')) alert('first url: ' + config.baseURL + config.url)
                localStorage.setItem('BASE_URL', configUrls.BASE_URL)
            }
            config.headers['UUID'] = uuidv4();
            config.headers['Request-Date'] = new Date();
            if (sessionStorage.getItem('jwt')) {
                config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('jwt')}`;
            }
            if (config.data) {
                let newPayload = config.data;
                let encryptedData = Encryption(newPayload);
                console.log('API Payload', config.url, config.data)
                config.data = { 'bffData': encryptedData }
            }
            return config;
        },
        httpsAgent => {
            httpsAgent['rejectUnauthorized'] = false;
        },
        err => Promise.reject(err)
    );
    axios.interceptors.response.use(function (res) {
        let decryptedReponse = '';
        if (res?.data?.bffData) {
            decryptedReponse = JSON.parse(Decryption(res?.data?.bffData));
        } else {
            decryptedReponse = res?.data?.d2cResponse;
        }
        console.log('API Success Resp', res, decryptedReponse)
        return decryptedReponse;
    }, function (error) {
        console.log('API Error Resp1', error)
        if (error?.message && !error?.response?.data) {
            return Promise.reject(error);
        }
        const decryptedReponse = JSON.parse(Decryption(error?.response?.data?.bffData));
        console.log('API Error Resp2', decryptedReponse)
        return Promise.reject(decryptedReponse);
    });
}
