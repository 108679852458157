/*eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import RadioInput from "../../components/CustomInput/RadioInput";
import {
  RESIDENCE_CONTENTS_ADDRESS,
  BASIC_DETAILS_STEP_HEADING,
  RESIDENCE_CURRENT_ADDRESS,
  RESIDENCE_PERMANENT_ADDRESS,
} from "../../utils/cms";
import "./index.css";
import { residenceAddressOptions } from "./data";
import AddressForm from "../../components/AddressDetailsComponent/AddressForm";
import {
  validatingValueHandler,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import PermanentDetailsTerms from "./PermanentDetailsTerms";

const ResidenceDetails = ({
  values,
  setValues,
  setIsDisabled,
  isDisabled,
  setLoading
}) => {
  const [errors,setErrors] = useState(null)
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: { residenceDetails, currentApplication }
  } = storeValues || {};

  useEffect(() => {
    setValues({})
  }, []);

  useEffect(() => {
    if (
      !errors?.addressLine1 &&
			!errors?.addressLine2 &&
      values.addressLine1?.length >= 10 &&
      values.addressLine2?.length >= 3 &&
      values.cityName &&
      values.districtName &&
      values.stateName &&
      values.pinCode?.length === 6
    ) {
      setIsDisabled(false);
    } else if (
      values.addressType === "bothAddress" ||
      values.addressType === "noneAddress"
    ) {
      setIsDisabled(false);
    } else {
      if (!isDisabled) {
        setIsDisabled(true);
      }
    }
  }, [values,errors]);

  const [showTerms,setShowTerms] = useState(false)
  const radioButtonClickHandler = (name,value) => {
    if (value === "permanentAddress") {
      setShowTerms(true)
    }
  }

  return (
    <>
      <div
          style={{ display: showTerms ? 'none' :"block" }}
          className="employee-details resident-address">
      <div className="heading">
        {BASIC_DETAILS_STEP_HEADING.replace("#category#", "Residence Address")}
        {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
      </div>
      <div className="address">
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.addressLine1
          )}
        </p>
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.addressLine2
          )}
        </p>
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.cityName
          )}
        </p>
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.districtName
          )}
        </p>
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.stateName
          )}
        </p>
        <p>
          {validatingValueHandler(
            residenceDetails?.address?.aadharAddress?.pinCode
          )}
        </p>
      </div>
      <div className="address_check_cont">
        <div>
          <div className="address-heading">{RESIDENCE_CONTENTS_ADDRESS}</div>
          <RadioInput
            values={values}
            setValues={setValues}
            options={residenceAddressOptions}
            name="addressType"
            inputChangeCallBack ={radioButtonClickHandler}
          />
        </div>
      </div>
      {(values?.addressType === "permanentAddress" ||
        values?.addressType === "currentAddress") && (
          <div className="heading-toggle">
            {values?.addressType === "permanentAddress" ? (
              <p>{RESIDENCE_CURRENT_ADDRESS}</p>
            ) : values?.addressType === "currentAddress" ? (
              <p className="heading-toggle">{RESIDENCE_PERMANENT_ADDRESS}</p>
            ) : (
              <></>
            )}
            <AddressForm
              values={values}
              setValues={setValues}
              setLoading={setLoading}
              errors={errors}
              setErrors ={setErrors}
            />
          </div>
        )}
      </div>
      {showTerms && <PermanentDetailsTerms setShowTerms={setShowTerms} />}
    </>
 
  );
};

export default ResidenceDetails;
