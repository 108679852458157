/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useLayoutEffect, useState } from "react"
import './App.css';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import bg1 from '../src/assets/bg1.png'
import JourneyPage from '../src/pages/JourneyPage'
import NamePage from '../src/pages/NamePage'
import MobilePage from '../src/pages/MobilePage'
import RoadmapPage from '../src/pages/RoadmapPage'
import AadharPage from '../src/pages/AadharPage'
import PanCardPage from '../src/pages/PanCardPage'
import BasicDetails from "../src/pages/BasicDetailsPage";
import NotFound from '../src/pages/NotFoundPage'
import SnackbarComponent from "./components/SnackbarComponent/SnackbarComponent";
import Animation from './pages/Animation'
import InitialOfferPage from "./pages/InitialOfferPage/InitialOfferPage";
import LoginPage from "./pages/LoginPage";
import VehicleLoanDashboard from './pages/VehicleLoanDashboard';
import MotorInsurance from './pages/MotorInsurance';
import Verification from './pages/VerificationPage';
import TopHeaderNav from "./components/TopHeaderNav/TopHeaderNav";
import { dropOffApiCall } from "./utils/dropOff";
import { useIdleTimer } from 'react-idle-timer'
import { Decryption, Encryption } from "./utils/payload";
import { userBasicDetails } from "./store/userDetails";
import { useDispatch, useSelector } from "react-redux";
import { pageBasicDetails } from "./store/pageDetails";
import { JOURNEY_TYPE_1, JOURNEY_TYPE_2, JOURNEY_TYPE_3, JOURNEY_TYPE_4, ALERT_TITLE, CLOSE_ACTION, SESSION_IDLE_TIMEOUT_MSG } from "./utils/cms";
import PerfiosRedirectPage from "./pages/PerfiosRedirectPage/PerfiosRedirectPage";
import PreApprovedOfferPage from "./pages/PreApprovedOffer/PreApprovedOffer";
import DealerVehicleDetails from "./pages/DealerVehicleDetails";
import TestLoginPage from "./pages/AggregatorTestLoginPage";
import VideoKyc from "./pages/VideoKyc";
import FinalOfferAcceptance from "./pages/FinalOfferAcceptance/FinalOfferAcceptance";
import { capitailizeWord, capitaizeSentence, currentScreenDetails, getJourneyType, getVehicleType, isProdEnvironment, isUatEnvironment, removeSpaces } from "./utils/utils";
import { addCompletedScreen } from "./store/features/toolsSlice";
import { screenData } from "./utils/screenData";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import RootComponent from "./components/RootComponent/RootComponent";
import ConfirmationModal from "./components/ConfirmationDialogBox";
import { updateJourneyStatus } from "./utils/updateJouneyStatus";

const App = () => {
  const [idleTime,setIdleTime] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValues = useSelector((state) => state);
  const { pageDetails, userDetails: { email, mobileNumber, userName } } = storeValues || {};
  const { completedScreens } = storeValues?.tools || {}
  const location = useLocation();
  const [onLoad, setOnload] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const screensForUpdateJouneyCall = [7, 14, 15, 21, 27, 99, 100];
  const screensForUpdateJouneyCallStatus = {7:'Basic Details in Progress', 14:'Address Verification in Progress', 15:'Address Verification in Progress', 21:'Vehicle Finalization in Progress', 27:'Video KYC in Progress', 99:'Address Verification in Progress', 100:'Address Verification in Progress'};

  function someFunc() {
    dropOffApiCall("Browser Close")
  }
  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      window.location.reload();
      event.preventDefault();
      for (var i = 0; i < 500000000; i++) { }
    });
    return () => {
      window.removeEventListener("beforeunload", someFunc)
    }
  }, [])

  function onUserIdle() {
    if (pageDetails?.currentApplication?.d2CApplicantID) {
      if (window.location.pathname !== '/dealerVehicle/1') dropOffApiCall("Session Idle Timeout");
      setOpenErrorModal(true);
    } else if (sessionStorage.getItem('jwt')) {
      setOpenErrorModal(true);
    }
  }

  useIdleTimer({
    timeout: idleTime || pageDetails?.config?.SESSION_IDLE_TIMEOUT || 5*60*1000,
    onIdle: onUserIdle
  })

  const dropoffFunction = (res) => {
    const search = window.location.search;
    if (search?.includes('?')) {
      if (search?.slice(1) === 'preApproved') {
        dispatch(pageBasicDetails({ preApprovedJourney: true }));
        navigate("/name");
      } else if (search?.includes('?q=')) {
        const decryptData = Decryption(search?.replace('?q=',''))?.split('&');
        let finalObj = {
          dropoffJourney: true
        }
        let userObj = {};
        decryptData?.forEach((each) => {
          const types = each?.split('=');
          if (((types[0] === 'agg' || types[0] === 'AGG') && types[1] === 'Y') || (types[0] === 'l' && res?.AGGREGATOR_TYPES?.includes(types[1]))) {
            finalObj.aggregatorJourney = true;
          }
          if (types[0] === 'PA' && types[1] === 'Y') {
            finalObj.preApprovedJourney = true;
          }
          if (types[0] === 'j' && types[1] === 'N') finalObj.journeyType = 'New';
          if (types[0] === 'j' && types[1] === 'U') finalObj.journeyType = 'Used';
          if (types[0] === 'j' && types[1] === 'R') finalObj.journeyType = 'Refinance';
          if (types[0] === 'v') finalObj.vehicleType = types[1];
          if (types[0] === 'l') finalObj.leadSource = types[1];
          if (types[0] === 'P') finalObj.encryptedPerfios = Encryption(types[1]);
          if (types[0] === 'M' && types[1]?.length === 10) {
            userObj.mobileNumber = types[1];
          }
          if (types[0] === 'name') userObj.userName = types[1];
          if (types[0] === 'emailId') userObj.email = types[1];
          if (types[0] === 'd2CApplicantID') finalObj.d2CApplicantID = types[1];
        });
        if (finalObj.journeyType === 'New') {
          if (finalObj.vehicleType === 'C') finalObj.journeyType = JOURNEY_TYPE_1;
          if (finalObj.vehicleType === 'H') finalObj.journeyType = JOURNEY_TYPE_3;
        }
        if (finalObj.journeyType === 'Used') {
          if (finalObj.vehicleType === 'C') finalObj.journeyType = JOURNEY_TYPE_2;
        }
        if (finalObj.journeyType === 'Refinance') {
          if (finalObj.vehicleType === 'C') finalObj.journeyType = JOURNEY_TYPE_4;
        }
        if (finalObj.aggregatorJourney) finalObj.aggregatorHandshakeResp = { leadSource: finalObj.leadSource }
        dispatch(pageBasicDetails(finalObj));
        dispatch(userBasicDetails(userObj));
        if (!finalObj.d2CApplicantID) navigate("/login");
      }
    }
  }

  const aggregatorHandshakeRedirect = (res) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const prefill = params.get("prefill");
    if (prefill) {
      let parsePrefill = JSON.parse(prefill);
      let bffData = parsePrefill.bffData;
      let decryptData = Decryption(bffData);
      decryptData = JSON.parse(decryptData);
      if (decryptData) {
        const aggregatorJourney = res?.AGGREGATOR_TYPES?.includes(decryptData?.leadSource);
        dispatch(pageBasicDetails({ journeyType: JOURNEY_TYPE_2, aggregatorJourney, encryptedPerfios: Encryption(decryptData?.PAN?.toUpperCase() || ""), aggregatorHandshakeResp: { ...decryptData }, isMobileApp: decryptData?.leadSource === 'IndusMobile' || decryptData?.channel === 'IndusMobile'  }));
        delete decryptData?.PAN;
        delete decryptData?.pan;
        let fullName = capitaizeSentence(decryptData?.name);
        if (!fullName) {
          fullName = [capitailizeWord(decryptData?.firstName), capitailizeWord(decryptData?.middleName), capitailizeWord(decryptData?.lastName)]?.join(" ")
          fullName = removeSpaces(fullName)
        }
        dispatch(userBasicDetails({ userName: fullName, email: decryptData?.emailId, ...decryptData }));
        if (aggregatorJourney) {
          navigate("/login");
        }
      }
    }
  }

  const preApprovedUIUrlMock = (res) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get("pan") && params.get("customerCode") && params.get("customerType") && params.get("firstName")) {
      dispatch(pageBasicDetails({ aggregatorHandshakeResp: { PAN: params.get("pan"), CFDCustomercode: params.get("customerCode") }, encryptedPerfios: Encryption(params.get("pan")) }));
      dispatch(userBasicDetails({ customerType: params.get("customerType"), userName: capitailizeWord(params.get("firstName")) +' '+ capitailizeWord(params.get("lastName")), email: params.get("emailId"), mobileNumber: params.get("mobile")  }));
    }
  };

  const UTMFlagChanges = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let utmFlags = {...pageDetails?.utmFlags}
    if (params.get("utm_source")) utmFlags.utmSource = params.get("utm_source");
    if (params.get("utm_medium")) utmFlags.utmMedium = params.get("utm_medium");
    if (params.get("utm_campaign")) utmFlags.utmCampaign = params.get("utm_campaign");
    if (params.get("utm_term")) utmFlags.utmTerm = params.get("utm_term");
    if (params.get("utm_content")) utmFlags.utmContent = params.get("utm_content");
    dispatch(pageBasicDetails({...pageDetails, utmFlags}));
  }

  useLayoutEffect(() => {
    const Axios = axios.create();
    Axios.get(window.location.origin + '/config/appConfig.json').then((res) => {
      console.log('config.json resp', res.data)
      dispatch(pageBasicDetails({ config: res?.data }));
      dropoffFunction(res.data);
      aggregatorHandshakeRedirect(res.data);
      preApprovedUIUrlMock();
    }).catch((err) => {
      console.log('config.json error', err)
    })
  }, [])

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get("showConsole")) {
      sessionStorage.setItem('showConsole', 'true');
    }
    if (params.get("pt")) {
      sessionStorage.setItem('pt', 'true');
    }
    UTMFlagChanges();
    dispatch(pageBasicDetails({ completedPages: { personalPage: false, professionPage: false, mmvPage: false, animationPage: false } }));
  }, []);


  useEffect(() => {
    if (isUatEnvironment || isProdEnvironment) {
      if (onLoad) {
        setOnload(false)
        if (location.pathname?.includes("perfios-verification") || location.pathname?.includes("aggregator-form") || location.pathname?.includes("login") || location.pathname === "/" || window.location?.search?.includes("?kyc_status=")) return
        let [screenId] = currentScreenDetails();
        if(screenId===0){
          dispatch(addCompletedScreen(screenId))
        }
        if (screenId === completedScreens[completedScreens?.length - 1]) return
        if (screenId !== 0) {
          dispatch({ type: "clearResult" })
          window.location.href = '/';
        }
      }
      else{
        let [screenId] = currentScreenDetails();
        if (location.pathname?.includes("perfios-verification")) return
        if (!completedScreens?.includes(screenId)) {
          screensForUpdateJouneyCall.includes(screenId) && updateJourneyStatus(screenId, screensForUpdateJouneyCallStatus[screenId])
          dispatch(addCompletedScreen(screenId))
        }
        else {
          if (location.pathname?.includes("name") || location.pathname === "/" || location.pathname?.includes("aggregator-form")) return
          const latestScreenObj = screenData.find(screen => (screen.id === completedScreens[completedScreens.length - 1]))
          if (latestScreenObj && latestScreenObj?.id !== screenId) {
            navigate(`/${latestScreenObj.route}`)
          }
        }
      }
    }
    let [screenId, screenName] = currentScreenDetails();
    if (screenId === 19) {
      //setIdleTime(pageDetails?.config?.PERFIOUS_SESSION_IDLE_TIMEOUT)
    }
    if (screenId === 28) {
      setIdleTime(pageDetails?.config?.VIDEO_KYC_SESSION_IDLE_TIMEOUT)
    }
    // let dropOffRoute = '';
    // if(pageDetails?.dropOffScreenRoute){
    //   dropOffRoute = (screenData.find(screen => (screen.route === pageDetails?.dropOffScreenRoute))).id
    // }
    let payloadDropoff = {
      d2cApplicantId: pageDetails?.currentApplication?.d2CApplicantID,
      d2cUIDropOffType: 'Browser Close',
      d2cScreenId:  screenId,
      d2cScreenName: screenName,
      sfDropOffType:screenId <= 11? "Initial offer drop":"Final offer drop",
      sfLoanApplicationId: pageDetails?.currentApplication?.loanApplicationId,
      emailId: email,
      phoneNumber: mobileNumber,
      userName: userName || "",
      pan: JSON.parse(Decryption(pageDetails?.encryptedPerfios || "") || "{}") || '',
      vehicleType: getVehicleType(pageDetails?.journeyType),
      journeyType: getJourneyType(pageDetails?.journeyType),
      leadSource: pageDetails?.aggregatorHandshakeResp?.leadSource || 'IndusMobile',
      journey: (pageDetails?.aggregatorJourney ? 'Aggregator' : (pageDetails?.preApprovedJourney ? 'PreApproved' : 'IndusMobile')),
  }
  if (screenId === 99 || screenId === 100) payloadDropoff.d2cScreenId = 15;
  localStorage.setItem('payloadDropoff', JSON.stringify({bffData: Encryption(payloadDropoff)}));
  localStorage.setItem('uuid', uuidv4());
  if (!pageDetails?.currentApplication?.d2CApplicantID || screenId === 28) {
    localStorage.setItem('restrictDropOff', 'yes');
  } else {
    localStorage.removeItem('restrictDropOff');
  }
  }, [location])

  
  return (
    <div className='app-body'>
      <SnackbarComponent isMobile={pageDetails?.isMobileApp} />
      {!pageDetails?.isMobileApp && <TopHeaderNav />}
      <div className='top-background'><img src={bg1} alt="Top-Header" /></div>
      <Routes>
        <Route path="/" element={<RootComponent><JourneyPage /></RootComponent>} />
        <Route path="/name" element={<RootComponent><NamePage /></RootComponent>} />
        <Route path="/mobile" element={<RootComponent><MobilePage /></RootComponent>} />
        <Route path="/aadhar" element={<RootComponent><AadharPage /></RootComponent>} />
        <Route path="/pan" element={<RootComponent><PanCardPage /></RootComponent>} />
        <Route path="/roadmap/:step" element={<RootComponent><RoadmapPage /></RootComponent>} />
        <Route path="/basicDetails/:step" element={<RootComponent><BasicDetails /></RootComponent>} />
        <Route path="/animation" element={<RootComponent><Animation /></RootComponent>} />
        <Route path="/initialoffer" element={<RootComponent><InitialOfferPage /></RootComponent>} />
        <Route path="/vehicleLoanDashboard" element={<RootComponent><VehicleLoanDashboard /></RootComponent>} />
        <Route path="/login" element={<RootComponent><LoginPage /></RootComponent>} />
        <Route path="/verification/:step" element={<RootComponent><Verification /></RootComponent>} />
        <Route path="/perfios-verification" element={<RootComponent><PerfiosRedirectPage /></RootComponent>} />
        <Route path="/preApprovedOffer" element={<PreApprovedOfferPage />} />
        <Route path="/dealerVehicle/:step" element={<RootComponent><DealerVehicleDetails /></RootComponent>} />
        <Route path="/aggregator-form" element={<RootComponent><TestLoginPage /></RootComponent>} />
        <Route path="/finaloffer" element={<RootComponent><InitialOfferPage isFinalOffer /></RootComponent>} />
        <Route path="/finalAnimation" element={<RootComponent><Animation isFinalOffer /></RootComponent>} />
        <Route path="/motorInsurance" element={<RootComponent><MotorInsurance /></RootComponent>} />
        <Route path="/vkyclanding" element={<RootComponent><VideoKyc /></RootComponent>} />
        <Route path="/finalOfferAccept" element={<RootComponent><FinalOfferAcceptance /></RootComponent>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ConfirmationModal
        errorTitle={ALERT_TITLE}
        errorContent={SESSION_IDLE_TIMEOUT_MSG}
        openErrorModal={openErrorModal}
        primaryAction={true}
        primaryBtnText={CLOSE_ACTION}
        callBackAction={() => {
          sessionStorage.clear();
          window.location.href = '/';
        }}
        className='top-z-index'
      />
    </div>
  )
}

export default App;
