/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CANCEL_ACTION, PROCEED_ACTION } from '../../utils/cms';
import Loader from "../Loader/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationModal = ({ 
  errorTitle, 
  errorContent, 
  errorSubContent, 
  openErrorModal, 
  callBackAction, 
  apiTriggered, 
  primaryAction, 
  secondaryBtnText, 
  primaryBtnText, 
  secondaryAction, 
  showItalicContent, 
  hideLoader,
  className
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hideLoader && loading) setLoading(false);
  }, [hideLoader]);

  return (
    <>
      <div>
        <Dialog
          open={openErrorModal}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className={className}
        >
          <Loader isLoading={loading} />
          <DialogTitle className="error-title">
            {errorTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {errorContent}<br /><br /><span className={`${showItalicContent ? 'italic-text' : ''}`}>{errorSubContent}</span>
            </DialogContentText>
            <div className="otp-verification-actions">
              {secondaryAction && <Button className="otp-verification-cancel-button" variant="outlined" color="secondary" onClick={() => { callBackAction('close') }}>{secondaryBtnText || CANCEL_ACTION}</Button>}
              {primaryAction && <Button className="otp-verification-submit-button" variant="contained" color="secondary" onClick={() => {
                callBackAction('continue');
                if(!hideLoader) setLoading(true);
              }} disabled={apiTriggered}>{primaryBtnText || PROCEED_ACTION}</Button>}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default ConfirmationModal;
