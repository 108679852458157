/*eslint-disable no-mixed-operators*/
import {VkycStart,VkycProgrss,UnableTOCompleteVkyc,VkycRejected,VkycCompleted, VIDEO_KYC_INPROGRESS, VIDEO_KYC_UNABLE_COMPLETE, VIDEO_KYC_REJECTED, VIDEO_KYC_COMPLETED,} from "./data";
import "./VideoKycComponent.css";
import {
	START_VKYC,
	VKYC_IN_PROGRESS,
	COMPLETE_VKYC,
	UNABLE_TO_COMPLETE_VKYC,
	VKYC_REJECTED,
	VIDEO_KYC_HEADER,
} from "../../utils/cms";

const VideoKycBody = ({ status, beDetails }) => {
	if (status === VIDEO_KYC_INPROGRESS ) {
		return <VkycProgrss />;
	} else if (status === VIDEO_KYC_UNABLE_COMPLETE ) {
		return <UnableTOCompleteVkyc />;
	} else if (status === VIDEO_KYC_REJECTED ) {
		return <VkycRejected beDetails={beDetails} />;
	} else if (status === VIDEO_KYC_COMPLETED) {
		return <VkycCompleted />;
	}
	else
		return <VkycStart />;
};
let statusToColor = {
	[VIDEO_KYC_COMPLETED]: "#419b56",
	[VIDEO_KYC_INPROGRESS]: "#d99221",
	[VIDEO_KYC_UNABLE_COMPLETE]: "#c44143",
	[VIDEO_KYC_REJECTED] : "#c44143"
}
export default function VideoKycComponent({ status, beDetails }) {
	return (
		<div className="videoKyc">
			<>
				<div className="vkyc-body-header">{VIDEO_KYC_HEADER}</div>
			</>
			<div className="video-kyc-container">
				<div className="kyc-header-container"
					style={{backgroundColor: statusToColor[status]||"#43403f"}}
				>
					<div className="kyc-header">
						{
							!status && START_VKYC ||
							status === VIDEO_KYC_INPROGRESS && VKYC_IN_PROGRESS ||
							status === VIDEO_KYC_UNABLE_COMPLETE && UNABLE_TO_COMPLETE_VKYC ||
							status === VIDEO_KYC_REJECTED && VKYC_REJECTED ||
							status === VIDEO_KYC_COMPLETED && COMPLETE_VKYC
						}
					</div>
				</div>
				<div className="kyc-body-container">
					<VideoKycBody status={status} beDetails={beDetails} />
				</div>
			</div>
	
		</div>
	);
}
