import React from 'react'
import './index.css'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { INSURANCE_COVER_POPUP, LOAN_AND_HEALTH_INSURANCE, LOAN_AND_HEALTH_INSURANCE1, LOAN_AND_HEALTH_INSURANCE2, LOAN_AND_HEALTH_INSURANCE3, LOAN_AND_HEALTH_INSURANCE4, LOAN_AND_HEALTH_INSURANCE5, REVISE_EMI, LOAN_AND_HEALTH_INSURANCE7, LOAN_AND_HEALTH_INSURANCE8, LOAN_AND_HEALTH_INSURANCE6, TWO_WHEELER_INSURANCE_CONTENT2, TWO_WHEELER_INSURANCE_CONTENT1, TWO_WHEELER_INSURANCE_CONTENT4, TWO_WHEELER_INSURANCE_CONTENT3, TWO_WHEELER_INSURANCE_CONTENT7, TWO_WHEELER_INSURANCE_CONTENT6, TWO_WHEELER_INSURANCE_CONTENT5, INSURANCE_COVER_POPUP1, LOAN_AND_HEALTH_INSURANCE9} from '../../utils/cms'
import { changeValueToCurrency } from '../../utils/utils'

export const stringValueToNumber = (data) => {
  if (data)
    return parseInt((data + "")?.split(",")?.join(""))
  else
    return 0
}

export const MotoroInsuranceData = () => {
  return (
    <div className='motor-insurance-data'>
      <div>{INSURANCE_COVER_POPUP}</div><br/>
      <div className='two-wheeler-content1'>{INSURANCE_COVER_POPUP1}</div>
    </div>
  )
}

export const twoWheelerInsuranceData = () => {
  return (
    <div>
      <div className='two-wheeler-content'><b>{TWO_WHEELER_INSURANCE_CONTENT1}</b><br/><div>{TWO_WHEELER_INSURANCE_CONTENT2}</div></div><br/>
      <div className='two-wheeler-content'><b>Note:</b><br/><div>{TWO_WHEELER_INSURANCE_CONTENT3}</div></div><br/>
      <div className='two-wheeler-content'><b>Hospital Daily Cash*</b><br/><div>{TWO_WHEELER_INSURANCE_CONTENT4}</div></div><br/>
      <div className='two-wheeler-content'><b>Group Personal Accident**</b><br/><div>{TWO_WHEELER_INSURANCE_CONTENT5}</div></div><br/>
      <div className='two-wheeler-content1'>{TWO_WHEELER_INSURANCE_CONTENT6}<br/>{TWO_WHEELER_INSURANCE_CONTENT7}</div>
    </div>
  )
}

export const LoanAndHeathInsurance = () => {
    return (
      <div className='loan-heath-insurance-data-container'>
        <div className='two-wheeler-content'><b>{LOAN_AND_HEALTH_INSURANCE9}</b><br/></div>
        <div>{LOAN_AND_HEALTH_INSURANCE}</div><br/>
        <div>{LOAN_AND_HEALTH_INSURANCE1}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE2}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE3}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE4}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE5}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE6}</div>
        <div>{LOAN_AND_HEALTH_INSURANCE7}</div><br/>
        <div className='two-wheeler-content1'>{LOAN_AND_HEALTH_INSURANCE8}</div>
      </div>
    )
  }
 
export const RevisedEMI=(emi)=>{
  return(
    <>
    <div className="revised-emi">
      {REVISE_EMI} 
      <CurrencyRupeeIcon fontSize="16px"/>
      {changeValueToCurrency.format(emi)}
      </div>
    </>
   
  )
}

export const initialModalOption={
  errorTitle: "",
  errorContent: "",
  errorSubContent: "",
  openErrorModal: false,
  secondaryAction: null,
  primaryAction: true,
  secondaryBtnText: "",
  primaryBtnText: "",
  callBackAction: null,
  hideLoader:false
  } 