import axios from "axios";
import React from "react";
import { useSelector } from 'react-redux';
import { myConfig } from "../../config/rootConfig";
import { currentScreenDetails, isProdEnvironment, isUatEnvironment } from '../../utils/utils';
import setupAxios from "../../services/setAxios";

export default function RootComponent(props) {

  const storeValues = useSelector((state) => state);
  const { completedScreens } = storeValues?.tools || {}
  const [screenId] = currentScreenDetails()
  const activeScreenId = completedScreens[completedScreens.length - 1]

  if (!storeValues?.pageDetails?.config) {
    return <></>
  } else if (!axios?.interceptors?.request?.handlers?.length) {
    //axios.defaults.baseURL = myConfig(storeValues?.pageDetails?.config).BASE_URL;
    setupAxios(axios, myConfig(storeValues?.pageDetails?.config))
  }
  if (screenId === activeScreenId || screenId === 0 || screenId === 1 || screenId === 19 || screenId === 28 || screenId === -1 || screenId === -2 || screenId === -3) {
    return props.children
  }
  return (isUatEnvironment || isProdEnvironment) ? <></> : props.children
}
