const CITYJSON = {cacheValuesList: [
    {
        "code": "ABOHAR333",
        "value": "ABOHAR LOCAL"
    },
    {
        "code": "ADILABAD99",
        "value": "ADILABAD"
    },
    {
        "code": "ADITYAPUR1050",
        "value": "ADITYAPUR"
    },
    {
        "code": "AGAR1222",
        "value": "AGAR"
    },
    {
        "code": "AGARTALA266",
        "value": "AGARTALA"
    },
    {
        "code": "AGRA221",
        "value": "AGRA"
    },
    {
        "code": "AHMEDABAD128",
        "value": "AHMEDABAD"
    },
    {
        "code": "AHMEDNAGAR14",
        "value": "AHMEDNAGAR"
    },
    {
        "code": "AHWA134",
        "value": "AHWA"
    },
    {
        "code": "AIZAWL404",
        "value": "AIZAWL"
    },
    {
        "code": "AJMER147",
        "value": "AJMER"
    },
    {
        "code": "AKBARPUR159",
        "value": "AKBARPUR"
    },
    {
        "code": "AKOLA15",
        "value": "AKOLA"
    },
    {
        "code": "ALAPPUZHA410",
        "value": "ALAPPUZHA"
    },
    {
        "code": "ALIBAG361",
        "value": "ALIBAG"
    },
    {
        "code": "ALIGARH267",
        "value": "ALIGARH"
    },
    {
        "code": "ALIPORE1087",
        "value": "ALIPORE"
    },
    {
        "code": "ALIPURDUAR841",
        "value": "ALIPURDUAR"
    },
    {
        "code": "ALIRAJPUR1361",
        "value": "ALIRAJPUR"
    },
    {
        "code": "ALMORA649",
        "value": "ALMORA"
    },
    {
        "code": "ALONG1199",
        "value": "ALONG"
    },
    {
        "code": "ALWAR148",
        "value": "ALWAR"
    },
    {
        "code": "AMBALA1277",
        "value": "AMBALA"
    },
    {
        "code": "AMBASSA1412",
        "value": "AMBASSA"
    },
    {
        "code": "AMBIKAPUR634",
        "value": "AMBIKAPUR"
    },
    {
        "code": "AMINGAON1200",
        "value": "AMINGAON"
    },
    {
        "code": "AMPATI201",
        "value": "AMPATI"
    },
    {
        "code": "AMRAVATI16",
        "value": "AMRAVATI"
    },
    {
        "code": "AMRELI129",
        "value": "AMRELI"
    },
    {
        "code": "AMRITSAR231",
        "value": "AMRITSAR"
    },
    {
        "code": "AMROHA983",
        "value": "AMROHA"
    },
    {
        "code": "ANAND363",
        "value": "ANAND"
    },
    {
        "code": "ANANTAPUR100",
        "value": "ANANTAPUR"
    },
    {
        "code": "ANANTNAG1044",
        "value": "ANANTNAG"
    },
    {
        "code": "ANGUL331",
        "value": "ANGUL"
    },
    {
        "code": "ANINI1367",
        "value": "ANINI"
    },
    {
        "code": "ANUPPUR447",
        "value": "ANUPPUR"
    },
    {
        "code": "ARARIA1554",
        "value": "ARARIA"
    },
    {
        "code": "ARIYALUR1230",
        "value": "ARIYALUR"
    },
    {
        "code": "ARRAH635",
        "value": "ARRAH"
    },
    {
        "code": "ARWAL1362",
        "value": "ARWAL"
    },
    {
        "code": "ASANSOL214",
        "value": "ASANSOL"
    },
    {
        "code": "ASHOKNAGAR826",
        "value": "ASHOKNAGAR"
    },
    {
        "code": "ASIFABAD105",
        "value": "ASIFABAD"
    },
    {
        "code": "AURAIYA593",
        "value": "AURAIYA"
    },
    {
        "code": "AURANGABAD17",
        "value": "AURANGABAD"
    },
    {
        "code": "AURANGABAD660",
        "value": "AURANGABAD"
    },
    {
        "code": "AVADI1319",
        "value": "AVADI"
    },
    {
        "code": "AYODHYA267",
        "value": "AYODHYA"
    },
    {
        "code": "AZAMGARH389",
        "value": "AZAMGARH"
    },
    {
        "code": "BADLAPUR1281",
        "value": "BADLAPUR"
    },
    {
        "code": "BAGALKOT409",
        "value": "BAGALKOT"
    },
    {
        "code": "BAGESHWAR1235",
        "value": "BAGESHWAR"
    },
    {
        "code": "BAGHMARA917",
        "value": "BAGHMARA"
    },
    {
        "code": "BAGHPAT1085",
        "value": "BAGHPAT"
    },
    {
        "code": "BAHARAMPUR1065",
        "value": "BAHARAMPUR"
    },
    {
        "code": "BAHRAICH1249",
        "value": "BAHRAICH"
    },
    {
        "code": "BAIKUNTHPUR1132",
        "value": "BAIKUNTHPUR"
    },
    {
        "code": "BALAGHAT62",
        "value": "BALAGHAT"
    },
    {
        "code": "BALANGIR944",
        "value": "BALANGIR"
    },
    {
        "code": "BALASORE396",
        "value": "BALASORE"
    },
    {
        "code": "BALLARI45",
        "value": "BALLARI"
    },
    {
        "code": "BALLIA432",
        "value": "BALLIA"
    },
    {
        "code": "BALOD1088",
        "value": "BALOD"
    },
    {
        "code": "BALODA BAZAR1524",
        "value": "BALODA BAZAR"
    },
    {
        "code": "BALRAMPUR1631",
        "value": "BALRAMPUR"
    },
    {
        "code": "BALRAMPUR790",
        "value": "BALRAMPUR"
    },
    {
        "code": "BALURGHAT677",
        "value": "BALURGHAT"
    },
    {
        "code": "BANDA1208",
        "value": "BANDA"
    },
    {
        "code": "BANDIPORE1364",
        "value": "BANDIPORE"
    },
    {
        "code": "BANDRA (EAST)1",
        "value": "BANDRA (EAST)"
    },
    {
        "code": "BANKA1385",
        "value": "BANKA"
    },
    {
        "code": "BANKURA674",
        "value": "BANKURA"
    },
    {
        "code": "BANSWARA149",
        "value": "BANSWARA"
    },
    {
        "code": "BARABANKI1211",
        "value": "BARABANKI"
    },
    {
        "code": "BARAMULLA819",
        "value": "BARAMULLA"
    },
    {
        "code": "BARAN1021",
        "value": "BARAN"
    },
    {
        "code": "BARASAT1257",
        "value": "BARASAT"
    },
    {
        "code": "BARDHAMAN463",
        "value": "BARDHAMAN"
    },
    {
        "code": "BAREILLY223",
        "value": "BAREILLY"
    },
    {
        "code": "BARGARH242",
        "value": "BARGARH"
    },
    {
        "code": "BARIPADA952",
        "value": "BARIPADA"
    },
    {
        "code": "BARMER150",
        "value": "BARMER"
    },
    {
        "code": "BARNALA623",
        "value": "BARNALA"
    },
    {
        "code": "BARPETA903",
        "value": "BARPETA"
    },
    {
        "code": "BARWANI1397",
        "value": "BARWANI"
    },
    {
        "code": "BASAR1544",
        "value": "BASAR"
    },
    {
        "code": "BASTI436",
        "value": "BASTI"
    },
    {
        "code": "BATALA382",
        "value": "BATALA"
    },
    {
        "code": "BATHINDA232",
        "value": "BATHINDA"
    },
    {
        "code": "BEED18",
        "value": "BEED"
    },
    {
        "code": "BEGUSARAI467",
        "value": "BEGUSARAI"
    },
    {
        "code": "BELGAUM44",
        "value": "BELGAUM"
    },
    {
        "code": "BELONIA1414",
        "value": "BELONIA"
    },
    {
        "code": "BEMETARA1089",
        "value": "BEMETARA"
    },
    {
        "code": "BENGALURU2",
        "value": "BENGALURU"
    },
    {
        "code": "BERHAMPUR332",
        "value": "BERHAMPUR"
    },
    {
        "code": "BETTIAH1042",
        "value": "BETTIAH"
    },
    {
        "code": "BETUL63",
        "value": "BETUL"
    },
    {
        "code": "BHABUA1400",
        "value": "BHABUA"
    },
    {
        "code": "BHADRAK680",
        "value": "BHADRAK"
    },
    {
        "code": "BHAGALPUR302",
        "value": "BHAGALPUR"
    },
    {
        "code": "BHANDARA19",
        "value": "BHANDARA"
    },
    {
        "code": "BHARATPUR151",
        "value": "BHARATPUR"
    },
    {
        "code": "BHARUCH131",
        "value": "BHARUCH"
    },
    {
        "code": "BHAVNAGAR132",
        "value": "BHAVNAGAR"
    },
    {
        "code": "BHAWANIPATNA618",
        "value": "BHAWANIPATNA"
    },
    {
        "code": "BHILAI269",
        "value": "BHILAI"
    },
    {
        "code": "BHILWARA152",
        "value": "BHILWARA"
    },
    {
        "code": "BHIND64",
        "value": "BHIND"
    },
    {
        "code": "BHIWANDI1495",
        "value": "BHIWANDI"
    },
    {
        "code": "BHIWANI308",
        "value": "BHIWANI"
    },
    {
        "code": "BHONGIR105",
        "value": "BHONGIR"
    },
    {
        "code": "BHOPAL4",
        "value": "BHOPAL"
    },
    {
        "code": "BHUBANESWAR243",
        "value": "BHUBANESWAR"
    },
    {
        "code": "BHUJ133",
        "value": "BHUJ"
    },
    {
        "code": "BHUPALPALLY105",
        "value": "BHUPALPALLY"
    },
    {
        "code": "BIDAR46",
        "value": "BIDAR"
    },
    {
        "code": "BIHAR SHARIF1478",
        "value": "BIHAR SHARIF"
    },
    {
        "code": "BIJAPUR1427",
        "value": "BIJAPUR"
    },
    {
        "code": "BIJNOR437",
        "value": "BIJNOR"
    },
    {
        "code": "BIKANER153",
        "value": "BIKANER"
    },
    {
        "code": "BILASPUR1219",
        "value": "BILASPUR"
    },
    {
        "code": "BILASPUR206",
        "value": "BILASPUR"
    },
    {
        "code": "BIRGAON125",
        "value": "BIRGAON"
    },
    {
        "code": "BISHNUPUR1432",
        "value": "BISHNUPUR"
    },
    {
        "code": "BISHRAMGANJ1594",
        "value": "BISHRAMGANJ"
    },
    {
        "code": "BISWANATH CHARIALI1479",
        "value": "BISWANATH CHARIALI"
    },
    {
        "code": "BOKARO320",
        "value": "BOKARO"
    },
    {
        "code": "BOMDILA1374",
        "value": "BOMDILA"
    },
    {
        "code": "BONGAIGAON570",
        "value": "BONGAIGAON"
    },
    {
        "code": "BOTAD1165",
        "value": "BOTAD"
    },
    {
        "code": "BOUDH955",
        "value": "BOUDH"
    },
    {
        "code": "BUDAUN1458",
        "value": "BUDAUN"
    },
    {
        "code": "BUDGAM1394",
        "value": "BUDGAM"
    },
    {
        "code": "BULANDSHAHR1458",
        "value": "BULANDSHAHR"
    },
    {
        "code": "BULDHANA20",
        "value": "BULDHANA"
    },
    {
        "code": "BUNDI154",
        "value": "BUNDI"
    },
    {
        "code": "BURHANPUR1308",
        "value": "BURHANPUR"
    },
    {
        "code": "BUXAR1387",
        "value": "BUXAR"
    },
    {
        "code": "CAR NICOBAR196",
        "value": "CAR NICOBAR"
    },
    {
        "code": "CHAIBASA1050",
        "value": "CHAIBASA"
    },
    {
        "code": "CHAMARAJANAGARA721",
        "value": "CHAMARAJANAGARA"
    },
    {
        "code": "CHAMBA571",
        "value": "CHAMBA"
    },
    {
        "code": "CHAMPAWAT1376",
        "value": "CHAMPAWAT"
    },
    {
        "code": "CHAMPHAI401",
        "value": "CHAMPHAI"
    },
    {
        "code": "CHANDAULI789",
        "value": "CHANDAULI"
    },
    {
        "code": "CHANDEL918",
        "value": "CHANDEL"
    },
    {
        "code": "CHANDIGARH244",
        "value": "CHANDIGARH"
    },
    {
        "code": "CHANDRAPUR21",
        "value": "CHANDRAPUR"
    },
    {
        "code": "CHANGLANG1366",
        "value": "CHANGLANG"
    },
    {
        "code": "CHARKHI DADRI704",
        "value": "CHARKHI DADRI"
    },
    {
        "code": "CHATRA869",
        "value": "CHATRA"
    },
    {
        "code": "CHENGALPATTU755",
        "value": "CHENGALPATTU"
    },
    {
        "code": "CHENNAI176",
        "value": "CHENNAI"
    },
    {
        "code": "CHHAPRA475",
        "value": "CHHAPRA"
    },
    {
        "code": "CHHATARPUR66",
        "value": "CHHATARPUR"
    },
    {
        "code": "CHHATRAPUR418",
        "value": "CHHATRAPUR"
    },
    {
        "code": "CHHINDWARA67",
        "value": "CHHINDWARA"
    },
    {
        "code": "CHHOTA UDAIPUR1146",
        "value": "CHHOTA UDAIPUR"
    },
    {
        "code": "CHIKKABALLAPUR1221",
        "value": "CHIKKABALLAPUR"
    },
    {
        "code": "CHIKKAMAGALURU48",
        "value": "CHIKKAMAGALURU"
    },
    {
        "code": "CHINSURAH405",
        "value": "CHINSURAH"
    },
    {
        "code": "CHIRMIRI1430",
        "value": "CHIRMIRI"
    },
    {
        "code": "CHITRADURGA49",
        "value": "CHITRADURGA"
    },
    {
        "code": "CHITTOOR101",
        "value": "CHITTOOR"
    },
    {
        "code": "CHITTORGARH155",
        "value": "CHITTORGARH"
    },
    {
        "code": "CHURACHANDPUR919",
        "value": "CHURACHANDPUR"
    },
    {
        "code": "CHURU156",
        "value": "CHURU"
    },
    {
        "code": "COIMBATORE177",
        "value": "COIMBATORE"
    },
    {
        "code": "CONNAUGHT PLACE10",
        "value": "CONNAUGHT PLACE"
    },
    {
        "code": "COOCH BEHAR461",
        "value": "COOCH BEHAR"
    },
    {
        "code": "CUDDALORE360",
        "value": "CUDDALORE"
    },
    {
        "code": "CUTTACK245",
        "value": "CUTTACK"
    },
    {
        "code": "DAHOD468",
        "value": "DAHOD"
    },
    {
        "code": "DALTONGANJ610",
        "value": "DALTONGANJ"
    },
    {
        "code": "DAMAN948",
        "value": "DAMAN"
    },
    {
        "code": "DAMOH68",
        "value": "DAMOH"
    },
    {
        "code": "DANTEWADA1428",
        "value": "DANTEWADA"
    },
    {
        "code": "DAPORIJIO1373",
        "value": "DAPORIJIO"
    },
    {
        "code": "DARBHANGA452",
        "value": "DARBHANGA"
    },
    {
        "code": "DARJEELING402",
        "value": "DARJEELING"
    },
    {
        "code": "DARYAGANJ10",
        "value": "DARYAGANJ"
    },
    {
        "code": "DATIA69",
        "value": "DATIA"
    },
    {
        "code": "DAUSA666",
        "value": "DAUSA"
    },
    {
        "code": "DAVANAGERE390",
        "value": "DAVANAGERE"
    },
    {
        "code": "DEBAGARH258",
        "value": "DEBAGARH"
    },
    {
        "code": "DEFENCE COLONY10",
        "value": "DEFENCE COLONY"
    },
    {
        "code": "DEHRADUN262",
        "value": "DEHRADUN"
    },
    {
        "code": "DEOGHAR584",
        "value": "DEOGHAR"
    },
    {
        "code": "DEORIA431",
        "value": "DEORIA"
    },
    {
        "code": "DEWAS70",
        "value": "DEWAS"
    },
    {
        "code": "DHAMTARI598",
        "value": "DHAMTARI"
    },
    {
        "code": "DHANBAD282",
        "value": "DHANBAD"
    },
    {
        "code": "DHAR71",
        "value": "DHAR"
    },
    {
        "code": "DHARAMSHALA603",
        "value": "DHARAMSHALA"
    },
    {
        "code": "DHARMANAGAR1477",
        "value": "DHARMANAGAR"
    },
    {
        "code": "DHARMAPURI180",
        "value": "DHARMAPURI"
    },
    {
        "code": "DHARWAD51",
        "value": "DHARWAD"
    },
    {
        "code": "DHEMAJI907",
        "value": "DHEMAJI"
    },
    {
        "code": "DHENKANAL681",
        "value": "DHENKANAL"
    },
    {
        "code": "DHOLPUR157",
        "value": "DHOLPUR"
    },
    {
        "code": "DHUBRI908",
        "value": "DHUBRI"
    },
    {
        "code": "DHULE22",
        "value": "DHULE"
    },
    {
        "code": "DIBRUGARH238",
        "value": "DIBRUGARH"
    },
    {
        "code": "DIDIHAT456",
        "value": "DIDIHAT"
    },
    {
        "code": "DIMAPUR330",
        "value": "DIMAPUR"
    },
    {
        "code": "DINDIGUL181",
        "value": "DINDIGUL"
    },
    {
        "code": "DINDORI1122",
        "value": "DINDORI"
    },
    {
        "code": "DINDORI1141",
        "value": "DINDORI"
    },
    {
        "code": "DIPHU922",
        "value": "DIPHU"
    },
    {
        "code": "DIU1389",
        "value": "DIU"
    },
    {
        "code": "DODA759",
        "value": "DODA"
    },
    {
        "code": "DUMKA398",
        "value": "DUMKA"
    },
    {
        "code": "DUNGARPUR158",
        "value": "DUNGARPUR"
    },
    {
        "code": "DURG122",
        "value": "DURG"
    },
    {
        "code": "DURGAPUR397",
        "value": "DURGAPUR"
    },
    {
        "code": "ELURU515",
        "value": "ELURU"
    },
    {
        "code": "ENGLISH BAZAR198",
        "value": "ENGLISH BAZAR"
    },
    {
        "code": "ERODE340",
        "value": "ERODE"
    },
    {
        "code": "ETAH945",
        "value": "ETAH"
    },
    {
        "code": "ETAWAH435",
        "value": "ETAWAH"
    },
    {
        "code": "FARIDABAD273",
        "value": "FARIDABAD"
    },
    {
        "code": "FARIDKOT575",
        "value": "FARIDKOT"
    },
    {
        "code": "FATEHABAD592",
        "value": "FATEHABAD"
    },
    {
        "code": "FATEHGARH SAHIB1227",
        "value": "FATEHGARH SAHIB"
    },
    {
        "code": "FATEHGARH1102",
        "value": "FATEHGARH"
    },
    {
        "code": "FATEHPUR1091",
        "value": "FATEHPUR"
    },
    {
        "code": "FAZILKA1228",
        "value": "FAZILKA"
    },
    {
        "code": "FIROZABAD469",
        "value": "FIROZABAD"
    },
    {
        "code": "FIROZPUR279",
        "value": "FIROZPUR"
    },
    {
        "code": "GADAG-BETAGERI470",
        "value": "GADAG-BETAGERI"
    },
    {
        "code": "GADCHIROLI722",
        "value": "GADCHIROLI"
    },
    {
        "code": "GADWAL538",
        "value": "GADWAL"
    },
    {
        "code": "GANDERBAL260",
        "value": "GANDERBAL"
    },
    {
        "code": "GANDHINAGAR135",
        "value": "GANDHINAGAR"
    },
    {
        "code": "GANGTOK339",
        "value": "GANGTOK"
    },
    {
        "code": "GARAMUR1583",
        "value": "GARAMUR"
    },
    {
        "code": "GARHWA1391",
        "value": "GARHWA"
    },
    {
        "code": "GARIABAND1429",
        "value": "GARIABAND"
    },
    {
        "code": "GAURELLA1128",
        "value": "GAURELLA"
    },
    {
        "code": "GAURIGANJ1483",
        "value": "GAURIGANJ"
    },
    {
        "code": "GAYA535",
        "value": "GAYA"
    },
    {
        "code": "GHAZIABAD225",
        "value": "GHAZIABAD"
    },
    {
        "code": "GHAZIPUR788",
        "value": "GHAZIPUR"
    },
    {
        "code": "GIRIDIH123",
        "value": "GIRIDIH"
    },
    {
        "code": "GOALPARA923",
        "value": "GOALPARA"
    },
    {
        "code": "GODDA1022",
        "value": "GODDA"
    },
    {
        "code": "GODHRA408",
        "value": "GODHRA"
    },
    {
        "code": "GOLAGHAT299",
        "value": "GOLAGHAT"
    },
    {
        "code": "GONDA783",
        "value": "GONDA"
    },
    {
        "code": "GONDIA23",
        "value": "GONDIA"
    },
    {
        "code": "GOPALGANJ24",
        "value": "GOPALGANJ"
    },
    {
        "code": "GOPESHWAR1375",
        "value": "GOPESHWAR"
    },
    {
        "code": "GORAKHPUR261",
        "value": "GORAKHPUR"
    },
    {
        "code": "GUMLA1104",
        "value": "GUMLA"
    },
    {
        "code": "GUNA72",
        "value": "GUNA"
    },
    {
        "code": "GUNTUR104",
        "value": "GUNTUR"
    },
    {
        "code": "GURDASPUR425",
        "value": "GURDASPUR"
    },
    {
        "code": "GURUGRAM246",
        "value": "GURUGRAM"
    },
    {
        "code": "GUWAHATI226",
        "value": "GUWAHATI"
    },
    {
        "code": "GWALIOR73",
        "value": "GWALIOR"
    },
    {
        "code": "GYALSHING1411",
        "value": "GYALSHING"
    },
    {
        "code": "GYANPUR785",
        "value": "GYANPUR"
    },
    {
        "code": "HAFLONG1449",
        "value": "HAFLONG"
    },
    {
        "code": "HAILAKANDI924",
        "value": "HAILAKANDI"
    },
    {
        "code": "HAJIPUR1572",
        "value": "HAJIPUR"
    },
    {
        "code": "HALDWANI353",
        "value": "HALDWANI"
    },
    {
        "code": "HAMIRPUR1573",
        "value": "HAMIRPUR"
    },
    {
        "code": "HAMIRPUR315",
        "value": "HAMIRPUR"
    },
    {
        "code": "HAMREN922",
        "value": "HAMREN"
    },
    {
        "code": "HANAMKONDA105",
        "value": "HANAMKONDA"
    },
    {
        "code": "HANUMANGARH202",
        "value": "HANUMANGARH"
    },
    {
        "code": "HAPUR606",
        "value": "HAPUR"
    },
    {
        "code": "HARDA1000",
        "value": "HARDA"
    },
    {
        "code": "HARDOI604",
        "value": "HARDOI"
    },
    {
        "code": "HARIDWAR415",
        "value": "HARIDWAR"
    },
    {
        "code": "HASSAN53",
        "value": "HASSAN"
    },
    {
        "code": "HATHRAS614",
        "value": "HATHRAS"
    },
    {
        "code": "HATSINGIMARI908",
        "value": "HATSINGIMARI"
    },
    {
        "code": "HAVERI714",
        "value": "HAVERI"
    },
    {
        "code": "HAWAI1367",
        "value": "HAWAI"
    },
    {
        "code": "HAZARIBAG319",
        "value": "HAZARIBAG"
    },
    {
        "code": "HIMATNAGAR285",
        "value": "HIMATNAGAR"
    },
    {
        "code": "HINGOLI25",
        "value": "HINGOLI"
    },
    {
        "code": "HISAR309",
        "value": "HISAR"
    },
    {
        "code": "HNAHTHIAL404",
        "value": "HNAHTHIAL"
    },
    {
        "code": "HOJAI926",
        "value": "HOJAI"
    },
    {
        "code": "HOSHANGABAD74",
        "value": "HOSHANGABAD"
    },
    {
        "code": "HOSHIARPUR334",
        "value": "HOSHIARPUR"
    },
    {
        "code": "HOSPETE255",
        "value": "HOSPETE"
    },
    {
        "code": "HOSUR534",
        "value": "HOSUR"
    },
    {
        "code": "HOWRAH421",
        "value": "HOWRAH"
    },
    {
        "code": "HYDERABAD105",
        "value": "HYDERABAD"
    },
    {
        "code": "IGATPURI1469",
        "value": "IGATPURI"
    },
    {
        "code": "INDORE5",
        "value": "INDORE"
    },
    {
        "code": "JABALPUR7",
        "value": "JABALPUR"
    },
    {
        "code": "JAGATSINGHPUR959",
        "value": "JAGATSINGHPUR"
    },
    {
        "code": "JAGDALPUR218",
        "value": "JAGDALPUR"
    },
    {
        "code": "JAGTIAL544",
        "value": "JAGTIAL"
    },
    {
        "code": "JAIPUR160",
        "value": "JAIPUR"
    },
    {
        "code": "JAISALMER161",
        "value": "JAISALMER"
    },
    {
        "code": "JALANDHAR233",
        "value": "JALANDHAR"
    },
    {
        "code": "JALGAON26",
        "value": "JALGAON"
    },
    {
        "code": "JALNA27",
        "value": "JALNA"
    },
    {
        "code": "JALORE162",
        "value": "JALORE"
    },
    {
        "code": "JALPAIGURI840",
        "value": "JALPAIGURI"
    },
    {
        "code": "JAMIN1452",
        "value": "JAMIN"
    },
    {
        "code": "JAMMU259",
        "value": "JAMMU"
    },
    {
        "code": "JAMNAGAR136",
        "value": "JAMNAGAR"
    },
    {
        "code": "JAMSHEDPUR211",
        "value": "JAMSHEDPUR"
    },
    {
        "code": "JAMTARA879",
        "value": "JAMTARA"
    },
    {
        "code": "JAMUI1024",
        "value": "JAMUI"
    },
    {
        "code": "JANGAON696",
        "value": "JANGAON"
    },
    {
        "code": "JASHPUR NAGAR699",
        "value": "JASHPUR NAGAR"
    },
    {
        "code": "JAUNPUR626",
        "value": "JAUNPUR"
    },
    {
        "code": "JEHANABAD1399",
        "value": "JEHANABAD"
    },
    {
        "code": "JHABUA75",
        "value": "JHABUA"
    },
    {
        "code": "JHAJJAR707",
        "value": "JHAJJAR"
    },
    {
        "code": "JHALAWAR163",
        "value": "JHALAWAR"
    },
    {
        "code": "JHANSI348",
        "value": "JHANSI"
    },
    {
        "code": "JHARGRAM840",
        "value": "JHARGRAM"
    },
    {
        "code": "JHARSUGUDA419",
        "value": "JHARSUGUDA"
    },
    {
        "code": "JHUNJHUNU164",
        "value": "JHUNJHUNU"
    },
    {
        "code": "JIND705",
        "value": "JIND"
    },
    {
        "code": "JIRIBAM1223",
        "value": "JIRIBAM"
    },
    {
        "code": "JODHPUR165",
        "value": "JODHPUR"
    },
    {
        "code": "JORHAT272",
        "value": "JORHAT"
    },
    {
        "code": "JOWAI915",
        "value": "JOWAI"
    },
    {
        "code": "JUNAGADH137",
        "value": "JUNAGADH"
    },
    {
        "code": "KADAPA691",
        "value": "KADAPA"
    },
    {
        "code": "KAILASHAHAR266",
        "value": "KAILASHAHAR"
    },
    {
        "code": "KAITHAL310",
        "value": "KAITHAL"
    },
    {
        "code": "KAJALGAON1486",
        "value": "KAJALGAON"
    },
    {
        "code": "KAKCHING1223",
        "value": "KAKCHING"
    },
    {
        "code": "KAKINADA252",
        "value": "KAKINADA"
    },
    {
        "code": "KAKKANAD200",
        "value": "KAKKANAD"
    },
    {
        "code": "KALABURAGI52",
        "value": "KALABURAGI"
    },
    {
        "code": "KALIMPONG632",
        "value": "KALIMPONG"
    },
    {
        "code": "KALLAKURICHI342",
        "value": "KALLAKURICHI"
    },
    {
        "code": "KALPETTA620",
        "value": "KALPETTA"
    },
    {
        "code": "KALYAN DOMBIVLI40",
        "value": "KALYAN DOMBIVLI"
    },
    {
        "code": "KAMAREDDY105",
        "value": "KAMAREDDY"
    },
    {
        "code": "KAMJONG1223",
        "value": "KAMJONG"
    },
    {
        "code": "KANCHIPURAM755",
        "value": "KANCHIPURAM"
    },
    {
        "code": "KANGPOKPI1223",
        "value": "KANGPOKPI"
    },
    {
        "code": "KANJHAWALA10",
        "value": "KANJHAWALA"
    },
    {
        "code": "KANKER1026",
        "value": "KANKER"
    },
    {
        "code": "KANNAUJ782",
        "value": "KANNAUJ"
    },
    {
        "code": "KANNUR326",
        "value": "KANNUR"
    },
    {
        "code": "KANPUR237",
        "value": "KANPUR"
    },
    {
        "code": "KAPURTHALA472",
        "value": "KAPURTHALA"
    },
    {
        "code": "KARAIKAL427",
        "value": "KARAIKAL"
    },
    {
        "code": "KARAULI1379",
        "value": "KARAULI"
    },
    {
        "code": "KARGIL1040",
        "value": "KARGIL"
    },
    {
        "code": "KARIMGANJ679",
        "value": "KARIMGANJ"
    },
    {
        "code": "KARIMNAGAR106",
        "value": "KARIMNAGAR"
    },
    {
        "code": "KARJAT1492",
        "value": "KARJAT"
    },
    {
        "code": "KARNAL253",
        "value": "KARNAL"
    },
    {
        "code": "KARUR386",
        "value": "KARUR"
    },
    {
        "code": "KARWAR1502",
        "value": "KARWAR"
    },
    {
        "code": "KARWI1212",
        "value": "KARWI"
    },
    {
        "code": "KASARAGOD563",
        "value": "KASARAGOD"
    },
    {
        "code": "KASGANJ627",
        "value": "KASGANJ"
    },
    {
        "code": "KASHIPUR561",
        "value": "KASHIPUR"
    },
    {
        "code": "KATHUA763",
        "value": "KATHUA"
    },
    {
        "code": "KATIHAR1027",
        "value": "KATIHAR"
    },
    {
        "code": "KATNI446",
        "value": "KATNI"
    },
    {
        "code": "KAVARATTI1675",
        "value": "KAVARATTI"
    },
    {
        "code": "KAWARDHA1090",
        "value": "KAWARDHA"
    },
    {
        "code": "KENDRAPARA967",
        "value": "KENDRAPARA"
    },
    {
        "code": "KENDUJHAR243",
        "value": "KENDUJHAR"
    },
    {
        "code": "KEYLONG568",
        "value": "KEYLONG"
    },
    {
        "code": "KHAGARIA1401",
        "value": "KHAGARIA"
    },
    {
        "code": "KHALILABAD1251",
        "value": "KHALILABAD"
    },
    {
        "code": "KHAMBHALIA1494",
        "value": "KHAMBHALIA"
    },
    {
        "code": "KHAMMAM107",
        "value": "KHAMMAM"
    },
    {
        "code": "KHANDWA76",
        "value": "KHANDWA"
    },
    {
        "code": "KHARGONE77",
        "value": "KHARGONE"
    },
    {
        "code": "KHAWZAWL404",
        "value": "KHAWZAWL"
    },
    {
        "code": "KHLIEHRIAT201",
        "value": "KHLIEHRIAT"
    },
    {
        "code": "KHONSA1371",
        "value": "KHONSA"
    },
    {
        "code": "KHORDHA968",
        "value": "KHORDHA"
    },
    {
        "code": "KHOWAI1593",
        "value": "KHOWAI"
    },
    {
        "code": "KHUNTI884",
        "value": "KHUNTI"
    },
    {
        "code": "KIPHIRE1441",
        "value": "KIPHIRE"
    },
    {
        "code": "KISHANGANJ1575",
        "value": "KISHANGANJ"
    },
    {
        "code": "KISHTWAR760",
        "value": "KISHTWAR"
    },
    {
        "code": "KOCHI9",
        "value": "KOCHI"
    },
    {
        "code": "KODERMA885",
        "value": "KODERMA"
    },
    {
        "code": "KOHIMA910",
        "value": "KOHIMA"
    },
    {
        "code": "KOKRAJHAR1203",
        "value": "KOKRAJHAR"
    },
    {
        "code": "KOLAR55",
        "value": "KOLAR"
    },
    {
        "code": "KOLASIB1225",
        "value": "KOLASIB"
    },
    {
        "code": "KOLHAPUR28",
        "value": "KOLHAPUR"
    },
    {
        "code": "KOLKATA198",
        "value": "KOLKATA"
    },
    {
        "code": "KOLLAM399",
        "value": "KOLLAM"
    },
    {
        "code": "KOLORIANG1452",
        "value": "KOLORIANG"
    },
    {
        "code": "KONDAGAON1541",
        "value": "KONDAGAON"
    },
    {
        "code": "KOPPALA710",
        "value": "KOPPALA"
    },
    {
        "code": "KORAPUT849",
        "value": "KORAPUT"
    },
    {
        "code": "KORBA306",
        "value": "KORBA"
    },
    {
        "code": "KOTA166",
        "value": "KOTA"
    },
    {
        "code": "KOTDWAR479",
        "value": "KOTDWAR"
    },
    {
        "code": "KOTHAGUDEM105",
        "value": "KOTHAGUDEM"
    },
    {
        "code": "KOTTAYAM212",
        "value": "KOTTAYAM"
    },
    {
        "code": "KOZHIKODE271",
        "value": "KOZHIKODE"
    },
    {
        "code": "KRISHNAGIRI750",
        "value": "KRISHNAGIRI"
    },
    {
        "code": "KRISHNANAGAR600",
        "value": "KRISHNANAGAR"
    },
    {
        "code": "KULGAM1220",
        "value": "KULGAM"
    },
    {
        "code": "KULLU608",
        "value": "KULLU"
    },
    {
        "code": "KUMBAKONAM1528",
        "value": "KUMBAKONAM"
    },
    {
        "code": "KUPWARA1395",
        "value": "KUPWARA"
    },
    {
        "code": "KURNOOL11",
        "value": "KURNOOL"
    },
    {
        "code": "KURUKSHETRA288",
        "value": "KURUKSHETRA"
    },
    {
        "code": "LAKHIMPUR669",
        "value": "LAKHIMPUR"
    },
    {
        "code": "LAKHISARAI1402",
        "value": "LAKHISARAI"
    },
    {
        "code": "LALITPUR1421",
        "value": "LALITPUR"
    },
    {
        "code": "LAMPHELPAT1223",
        "value": "LAMPHELPAT"
    },
    {
        "code": "LATEHAR887",
        "value": "LATEHAR"
    },
    {
        "code": "LATUR29",
        "value": "LATUR"
    },
    {
        "code": "LAWNGTLAI1224",
        "value": "LAWNGTLAI"
    },
    {
        "code": "LEH899",
        "value": "LEH"
    },
    {
        "code": "LEMMI1368",
        "value": "LEMMI"
    },
    {
        "code": "LIKABALI1373",
        "value": "LIKABALI"
    },
    {
        "code": "LOHARDAGA891",
        "value": "LOHARDAGA"
    },
    {
        "code": "LONGDING1371",
        "value": "LONGDING"
    },
    {
        "code": "LONGLENG1442",
        "value": "LONGLENG"
    },
    {
        "code": "LUCKNOW220",
        "value": "LUCKNOW"
    },
    {
        "code": "LUDHIANA234",
        "value": "LUDHIANA"
    },
    {
        "code": "LUNAVADA1127",
        "value": "LUNAVADA"
    },
    {
        "code": "LUNGLEI404",
        "value": "LUNGLEI"
    },
    {
        "code": "MACHIILIPATNAM108",
        "value": "MACHIILIPATNAM"
    },
    {
        "code": "MADHEPURA1214",
        "value": "MADHEPURA"
    },
    {
        "code": "MADHUBANI1403",
        "value": "MADHUBANI"
    },
    {
        "code": "MADIKERI854",
        "value": "MADIKERI"
    },
    {
        "code": "MADURAI184",
        "value": "MADURAI"
    },
    {
        "code": "MAHABUBABAD695",
        "value": "MAHABUBABAD"
    },
    {
        "code": "MAHAD1601",
        "value": "MAHAD"
    },
    {
        "code": "MAHARAJGANJ1420",
        "value": "MAHARAJGANJ"
    },
    {
        "code": "MAHASAMUND1136",
        "value": "MAHASAMUND"
    },
    {
        "code": "MAHBUBNAGAR109",
        "value": "MAHBUBNAGAR"
    },
    {
        "code": "MAHE587",
        "value": "MAHE"
    },
    {
        "code": "MAHOBA1420",
        "value": "MAHOBA"
    },
    {
        "code": "MAINPURI946",
        "value": "MAINPURI"
    },
    {
        "code": "MALAPPURAM569",
        "value": "MALAPPURAM"
    },
    {
        "code": "MALEGAON1019",
        "value": "MALEGAON"
    },
    {
        "code": "MALERKOTLA268",
        "value": "MALERKOTLA"
    },
    {
        "code": "MALKANGIRI1226",
        "value": "MALKANGIRI"
    },
    {
        "code": "MAMIT404",
        "value": "MAMIT"
    },
    {
        "code": "MANCHERIAL105",
        "value": "MANCHERIAL"
    },
    {
        "code": "MANDI290",
        "value": "MANDI"
    },
    {
        "code": "MANDLA79",
        "value": "MANDLA"
    },
    {
        "code": "MANDSAUR78",
        "value": "MANDSAUR"
    },
    {
        "code": "MANDYA56",
        "value": "MANDYA"
    },
    {
        "code": "MANENDRAGARH698",
        "value": "MANENDRAGARH"
    },
    {
        "code": "MANESAR1054",
        "value": "MANESAR"
    },
    {
        "code": "MANGALAGIRI 104",
        "value": "MANGALAGIRI"
    },
    {
        "code": "MANGALDOI906",
        "value": "MANGALDOI"
    },
    {
        "code": "MANGALURU208",
        "value": "MANGALURU"
    },
    {
        "code": "MANGAN1409",
        "value": "MANGAN"
    },
    {
        "code": "MANJHANPUR1426",
        "value": "MANJHANPUR"
    },
    {
        "code": "MANSA744",
        "value": "MANSA"
    },
    {
        "code": "MARGAO1109",
        "value": "MARGAO"
    },
    {
        "code": "MATHURA349",
        "value": "MATHURA"
    },
    {
        "code": "MAU786",
        "value": "MAU"
    },
    {
        "code": "MAWKYRWAT201",
        "value": "MAWKYRWAT"
    },
    {
        "code": "MAYABUNDER196",
        "value": "MAYABUNDER"
    },
    {
        "code": "MAYILADUTHURAI1580",
        "value": "MAYILADUTHURAI"
    },
    {
        "code": "MEDAK110",
        "value": "MEDAK"
    },
    {
        "code": "MEDININAGAR894",
        "value": "MEDININAGAR"
    },
    {
        "code": "MEERUT277",
        "value": "MEERUT"
    },
    {
        "code": "MEHSANA139",
        "value": "MEHSANA"
    },
    {
        "code": "MIDNAPORE463",
        "value": "MIDNAPORE"
    },
    {
        "code": "MIRAJ441",
        "value": "MIRAJ"
    },
    {
        "code": "MIRZAPUR430",
        "value": "MIRZAPUR"
    },
    {
        "code": "MODASA1467",
        "value": "MODASA"
    },
    {
        "code": "MOGA336",
        "value": "MOGA"
    },
    {
        "code": "MOHALI235",
        "value": "MOHALI"
    },
    {
        "code": "MOHLA126",
        "value": "MOHLA"
    },
    {
        "code": "MOKOKCHUNG911",
        "value": "MOKOKCHUNG"
    },
    {
        "code": "MON1443",
        "value": "MON"
    },
    {
        "code": "MORADABAD350",
        "value": "MORADABAD"
    },
    {
        "code": "MORBI589",
        "value": "MORBI"
    },
    {
        "code": "MORENA80",
        "value": "MORENA"
    },
    {
        "code": "MORIGAON933",
        "value": "MORIGAON"
    },
    {
        "code": "MOTIHARI636",
        "value": "MOTIHARI"
    },
    {
        "code": "MULUGU105",
        "value": "MULUGU"
    },
    {
        "code": "MUMBAI1",
        "value": "MUMBAI"
    },
    {
        "code": "MUNGELI1125",
        "value": "MUNGELI"
    },
    {
        "code": "MUNGER1206",
        "value": "MUNGER"
    },
    {
        "code": "MUSHALPUR902",
        "value": "MUSHALPUR"
    },
    {
        "code": "MUZAFFARNAGAR351",
        "value": "MUZAFFARNAGAR"
    },
    {
        "code": "MUZAFFARPUR305",
        "value": "MUZAFFARPUR"
    },
    {
        "code": "MYSURU57",
        "value": "MYSURU"
    },
    {
        "code": "NABARANGAPUR972",
        "value": "NABARANGAPUR"
    },
    {
        "code": "NADIAD478",
        "value": "NADIAD"
    },
    {
        "code": "NAGAON615",
        "value": "NAGAON"
    },
    {
        "code": "NAGAPATTINAM1229",
        "value": "NAGAPATTINAM"
    },
    {
        "code": "NAGARKURNOOL105",
        "value": "NAGARKURNOOL"
    },
    {
        "code": "NAGAUR167",
        "value": "NAGAUR"
    },
    {
        "code": "NAGERCOIL342",
        "value": "NAGERCOIL"
    },
    {
        "code": "NAGPUR3",
        "value": "NAGPUR"
    },
    {
        "code": "NAHAN1300",
        "value": "NAHAN"
    },
    {
        "code": "NAILA JANJGIR1130",
        "value": "NAILA JANJGIR"
    },
    {
        "code": "NAINITAL644",
        "value": "NAINITAL"
    },
    {
        "code": "NALBARI934",
        "value": "NALBARI"
    },
    {
        "code": "NALGONDA111",
        "value": "NALGONDA"
    },
    {
        "code": "NAMAKKAL343",
        "value": "NAMAKKAL"
    },
    {
        "code": "NAMCHI1410",
        "value": "NAMCHI"
    },
    {
        "code": "NAMSAI1366",
        "value": "NAMSAI"
    },
    {
        "code": "NANDED30",
        "value": "NANDED"
    },
    {
        "code": "NANDURBAR602",
        "value": "NANDURBAR"
    },
    {
        "code": "NARAYANPET105",
        "value": "NARAYANPET"
    },
    {
        "code": "NARAYANPUR1431",
        "value": "NARAYANPUR"
    },
    {
        "code": "NARNAUL455",
        "value": "NARNAUL"
    },
    {
        "code": "NARSINGHPUR81",
        "value": "NARSINGHPUR"
    },
    {
        "code": "NASHIK31",
        "value": "NASHIK"
    },
    {
        "code": "NAUGARH1417",
        "value": "NAUGARH"
    },
    {
        "code": "NAVSARI366",
        "value": "NAVSARI"
    },
    {
        "code": "NAWADA637",
        "value": "NAWADA"
    },
    {
        "code": "NAWANSHAHR338",
        "value": "NAWANSHAHR"
    },
    {
        "code": "NAYAGARH973",
        "value": "NAYAGARH"
    },
    {
        "code": "NEEMUCH774",
        "value": "NEEMUCH"
    },
    {
        "code": "NELLORE112",
        "value": "NELLORE"
    },
    {
        "code": "NEW TEHRI380",
        "value": "NEW TEHRI"
    },
    {
        "code": "NIRMAL541",
        "value": "NIRMAL"
    },
    {
        "code": "NIWARI95",
        "value": "NIWARI"
    },
    {
        "code": "NIZAMABAD113",
        "value": "NIZAMABAD"
    },
    {
        "code": "NOIDA224",
        "value": "NOIDA"
    },
    {
        "code": "NOKLAK330",
        "value": "NOKLAK"
    },
    {
        "code": "NONEY (LONGMAIL)1223",
        "value": "NONEY (LONGMAIL)"
    },
    {
        "code": "NONGPOH916",
        "value": "NONGPOH"
    },
    {
        "code": "NONGSTOIN1440",
        "value": "NONGSTOIN"
    },
    {
        "code": "NORTH LAKHIMPUR640",
        "value": "NORTH LAKHIMPUR"
    },
    {
        "code": "NUAPADA1380",
        "value": "NUAPADA"
    },
    {
        "code": "NUH1651",
        "value": "NUH"
    },
    {
        "code": "ONGOLE505",
        "value": "ONGOLE"
    },
    {
        "code": "OOTY387",
        "value": "OOTY"
    },
    {
        "code": "ORAI1574",
        "value": "ORAI"
    },
    {
        "code": "OROS39",
        "value": "OROS"
    },
    {
        "code": "OSMANABAD32",
        "value": "OSMANABAD"
    },
    {
        "code": "PADRAUNA858",
        "value": "PADRAUNA"
    },
    {
        "code": "PAINAVU392",
        "value": "PAINAVU"
    },
    {
        "code": "PAKUR1023",
        "value": "PAKUR"
    },
    {
        "code": "PAKYONG339",
        "value": "PAKYONG"
    },
    {
        "code": "PALAKKAD375",
        "value": "PALAKKAD"
    },
    {
        "code": "PALAMPUR443",
        "value": "PALAMPUR"
    },
    {
        "code": "PALANPUR367",
        "value": "PALANPUR"
    },
    {
        "code": "PALGHAR1261",
        "value": "PALGHAR"
    },
    {
        "code": "PALI168",
        "value": "PALI"
    },
    {
        "code": "PALWAL898",
        "value": "PALWAL"
    },
    {
        "code": "PANAJI228",
        "value": "PANAJI"
    },
    {
        "code": "PANCHKULA291",
        "value": "PANCHKULA"
    },
    {
        "code": "PANGIN1544",
        "value": "PANGIN"
    },
    {
        "code": "PANIKOILI243",
        "value": "PANIKOILI"
    },
    {
        "code": "PANIPAT249",
        "value": "PANIPAT"
    },
    {
        "code": "PANNA82",
        "value": "PANNA"
    },
    {
        "code": "PANVEL8",
        "value": "PANVEL"
    },
    {
        "code": "PARALAKHEMUNDI332",
        "value": "PARALAKHEMUNDI"
    },
    {
        "code": "PARBHANI33",
        "value": "PARBHANI"
    },
    {
        "code": "PASIGHAT1544",
        "value": "PASIGHAT"
    },
    {
        "code": "PATAN368",
        "value": "PATAN"
    },
    {
        "code": "PATHANAMTHITTA327",
        "value": "PATHANAMTHITTA"
    },
    {
        "code": "PATHANKOT281",
        "value": "PATHANKOT"
    },
    {
        "code": "PATHSALA903",
        "value": "PATHSALA"
    },
    {
        "code": "PATIALA236",
        "value": "PATIALA"
    },
    {
        "code": "PATNA210",
        "value": "PATNA"
    },
    {
        "code": "PAURI1232",
        "value": "PAURI"
    },
    {
        "code": "PEDDAPALLI1598",
        "value": "PEDDAPALLI"
    },
    {
        "code": "PEN737",
        "value": "PEN"
    },
    {
        "code": "PERAMBALUR771",
        "value": "PERAMBALUR"
    },
    {
        "code": "PEREN1444",
        "value": "PEREN"
    },
    {
        "code": "PHAGWARA583",
        "value": "PHAGWARA"
    },
    {
        "code": "PHEK1445",
        "value": "PHEK"
    },
    {
        "code": "PHERZAWL1223",
        "value": "PHERZAWL"
    },
    {
        "code": "PHULBANI965",
        "value": "PHULBANI"
    },
    {
        "code": "PILIBHIT1419",
        "value": "PILIBHIT"
    },
    {
        "code": "PITHORAGARH1234",
        "value": "PITHORAGARH"
    },
    {
        "code": "POONCH758",
        "value": "POONCH"
    },
    {
        "code": "PORBANDAR294",
        "value": "PORBANDAR"
    },
    {
        "code": "POROMPAT1223",
        "value": "POROMPAT"
    },
    {
        "code": "PORT BLAIR296",
        "value": "PORT BLAIR"
    },
    {
        "code": "PRATAPGARH433",
        "value": "PRATAPGARH"
    },
    {
        "code": "PRATAPGARH749",
        "value": "PRATAPGARH"
    },
    {
        "code": "PRAYAGRAJ1419",
        "value": "PRAYAGRAJ"
    },
    {
        "code": "PREET VIHAR10",
        "value": "PREET VIHAR"
    },
    {
        "code": "PUDUCHERRY358",
        "value": "PUDUCHERRY"
    },
    {
        "code": "PUDUKKOTTAI189",
        "value": "PUDUKKOTTAI"
    },
    {
        "code": "PULWAMA1051",
        "value": "PULWAMA"
    },
    {
        "code": "PUNE12",
        "value": "PUNE"
    },
    {
        "code": "PURI552",
        "value": "PURI"
    },
    {
        "code": "PURNIA483",
        "value": "PURNIA"
    },
    {
        "code": "PURULIA1087",
        "value": "PURULIA"
    },
    {
        "code": "RAE BARELI566",
        "value": "RAE BARELI"
    },
    {
        "code": "RAGA1197",
        "value": "RAGA"
    },
    {
        "code": "RAICHUR58",
        "value": "RAICHUR"
    },
    {
        "code": "RAIGANJ839",
        "value": "RAIGANJ"
    },
    {
        "code": "RAIGARH124",
        "value": "RAIGARH"
    },
    {
        "code": "RAIPUR125",
        "value": "RAIPUR"
    },
    {
        "code": "RAISEN84",
        "value": "RAISEN"
    },
    {
        "code": "RAJAHMUNDRY103",
        "value": "RAJAHMUNDRY"
    },
    {
        "code": "RAJGARH83",
        "value": "RAJGARH"
    },
    {
        "code": "RAJKOT141",
        "value": "RAJKOT"
    },
    {
        "code": "RAJNANDGAON126",
        "value": "RAJNANDGAON"
    },
    {
        "code": "RAJOURI GARDEN10",
        "value": "RAJOURI GARDEN"
    },
    {
        "code": "RAJOURI757",
        "value": "RAJOURI"
    },
    {
        "code": "RAJPIPLA1217",
        "value": "RAJPIPLA"
    },
    {
        "code": "RAJSAMAND617",
        "value": "RAJSAMAND"
    },
    {
        "code": "RAMAGUNDAM1514",
        "value": "RAMAGUNDAM"
    },
    {
        "code": "RAMANAGARA1349",
        "value": "RAMANAGARA"
    },
    {
        "code": "RAMANATHAPURAM428",
        "value": "RAMANATHAPURAM"
    },
    {
        "code": "RAMBAN761",
        "value": "RAMBAN"
    },
    {
        "code": "RAMGARH484",
        "value": "RAMGARH"
    },
    {
        "code": "RAMPUR566",
        "value": "RAMPUR"
    },
    {
        "code": "RANCHI280",
        "value": "RANCHI"
    },
    {
        "code": "RANIKHET1234",
        "value": "RANIKHET"
    },
    {
        "code": "RANIPETTAI304",
        "value": "RANIPETTAI"
    },
    {
        "code": "RATLAM85",
        "value": "RATLAM"
    },
    {
        "code": "RATNAGIRI35",
        "value": "RATNAGIRI"
    },
    {
        "code": "RAYAGADA978",
        "value": "RAYAGADA"
    },
    {
        "code": "REASI1396",
        "value": "REASI"
    },
    {
        "code": "RECKONG PEO1298",
        "value": "RECKONG PEO"
    },
    {
        "code": "RESUBELPARA201",
        "value": "RESUBELPARA"
    },
    {
        "code": "REWA86",
        "value": "REWA"
    },
    {
        "code": "REWARI311",
        "value": "REWARI"
    },
    {
        "code": "RISALI122",
        "value": "RISALI"
    },
    {
        "code": "RISHIKESH456",
        "value": "RISHIKESH"
    },
    {
        "code": "ROBERTSGANJ1529",
        "value": "ROBERTSGANJ"
    },
    {
        "code": "ROHTAK264",
        "value": "ROHTAK"
    },
    {
        "code": "ROING1370",
        "value": "ROING"
    },
    {
        "code": "ROORKEE263",
        "value": "ROORKEE"
    },
    {
        "code": "ROURKELA265",
        "value": "ROURKELA"
    },
    {
        "code": "RUDRAPRAYAG1233",
        "value": "RUDRAPRAYAG"
    },
    {
        "code": "RUDRAPUR354",
        "value": "RUDRAPUR"
    },
    {
        "code": "RUPNAGAR639",
        "value": "RUPNAGAR"
    },
    {
        "code": "SADAR BAZAAR10",
        "value": "SADAR BAZAAR"
    },
    {
        "code": "SAGAR87",
        "value": "SAGAR"
    },
    {
        "code": "SAHARANPUR485",
        "value": "SAHARANPUR"
    },
    {
        "code": "SAHARSA1204",
        "value": "SAHARSA"
    },
    {
        "code": "SAHEBGANJ1392",
        "value": "SAHEBGANJ"
    },
    {
        "code": "SAHIBZADA AJIT SINGH NAGAR235",
        "value": "SAHIBZADA AJIT SINGH NAGAR"
    },
    {
        "code": "SAIHA1416",
        "value": "SAIHA"
    },
    {
        "code": "SAITUAL404",
        "value": "SAITUAL"
    },
    {
        "code": "SAKET10",
        "value": "SAKET"
    },
    {
        "code": "SAKTI1130",
        "value": "SAKTI"
    },
    {
        "code": "SALEM191",
        "value": "SALEM"
    },
    {
        "code": "SAMASTIPUR1058",
        "value": "SAMASTIPUR"
    },
    {
        "code": "SAMBA762",
        "value": "SAMBA"
    },
    {
        "code": "SAMBALPUR258",
        "value": "SAMBALPUR"
    },
    {
        "code": "SAMBHAL985",
        "value": "SAMBHAL"
    },
    {
        "code": "SANGAREDDY105",
        "value": "SANGAREDDY"
    },
    {
        "code": "SANGLI36",
        "value": "SANGLI"
    },
    {
        "code": "SANGRUR268",
        "value": "SANGRUR"
    },
    {
        "code": "SARANGARH124",
        "value": "SARANGARH"
    },
    {
        "code": "SASARAM996",
        "value": "SASARAM"
    },
    {
        "code": "SATARA37",
        "value": "SATARA"
    },
    {
        "code": "SATNA88",
        "value": "SATNA"
    },
    {
        "code": "SAWAI MADHOPUR172",
        "value": "SAWAI MADHOPUR"
    },
    {
        "code": "SEELAMPUR10",
        "value": "SEELAMPUR"
    },
    {
        "code": "SEHORE89",
        "value": "SEHORE"
    },
    {
        "code": "SENAPATI1433",
        "value": "SENAPATI"
    },
    {
        "code": "SEONI90",
        "value": "SEONI"
    },
    {
        "code": "SEPPA1368",
        "value": "SEPPA"
    },
    {
        "code": "SERAIKELA1393",
        "value": "SERAIKELA"
    },
    {
        "code": "SERCHHIP912",
        "value": "SERCHHIP"
    },
    {
        "code": "SHAHAPUR727",
        "value": "SHAHAPUR"
    },
    {
        "code": "SHAHDARA10",
        "value": "SHAHDARA"
    },
    {
        "code": "SHAHDOL91",
        "value": "SHAHDOL"
    },
    {
        "code": "SHAHJAHANPUR352",
        "value": "SHAHJAHANPUR"
    },
    {
        "code": "SHAJAPUR92",
        "value": "SHAJAPUR"
    },
    {
        "code": "SHAMIRPET105",
        "value": "SHAMIRPET"
    },
    {
        "code": "SHAMLI1213",
        "value": "SHAMLI"
    },
    {
        "code": "SHEIKHPURA1405",
        "value": "SHEIKHPURA"
    },
    {
        "code": "SHEOHAR1406",
        "value": "SHEOHAR"
    },
    {
        "code": "SHEOPUR1398",
        "value": "SHEOPUR"
    },
    {
        "code": "SHILLONG201",
        "value": "SHILLONG"
    },
    {
        "code": "SHIMLA316",
        "value": "SHIMLA"
    },
    {
        "code": "SHIVAMOGGA59",
        "value": "SHIVAMOGGA"
    },
    {
        "code": "SHIVPURI93",
        "value": "SHIVPURI"
    },
    {
        "code": "SHOPIAN1144",
        "value": "SHOPIAN"
    },
    {
        "code": "SHRAVASTI1213",
        "value": "SHRAVASTI"
    },
    {
        "code": "SIBSAGAR362",
        "value": "SIBSAGAR"
    },
    {
        "code": "SIDDIPET1175",
        "value": "SIDDIPET"
    },
    {
        "code": "SIDHI94",
        "value": "SIDHI"
    },
    {
        "code": "SIKAR169",
        "value": "SIKAR"
    },
    {
        "code": "SILCHAR240",
        "value": "SILCHAR"
    },
    {
        "code": "SILIGURI219",
        "value": "SILIGURI"
    },
    {
        "code": "SILVASSA1489",
        "value": "SILVASSA"
    },
    {
        "code": "SIMDEGA897",
        "value": "SIMDEGA"
    },
    {
        "code": "SIRCILLA105",
        "value": "SIRCILLA"
    },
    {
        "code": "SIROHI170",
        "value": "SIROHI"
    },
    {
        "code": "SIRSA312",
        "value": "SIRSA"
    },
    {
        "code": "SITAMARHI1205",
        "value": "SITAMARHI"
    },
    {
        "code": "SITAPUR949",
        "value": "SITAPUR"
    },
    {
        "code": "SIVAGANGAI800",
        "value": "SIVAGANGAI"
    },
    {
        "code": "SIVAKASI346",
        "value": "SIVAKASI"
    },
    {
        "code": "SIWAN638",
        "value": "SIWAN"
    },
    {
        "code": "SOLAN549",
        "value": "SOLAN"
    },
    {
        "code": "SOLAPUR38",
        "value": "SOLAPUR"
    },
    {
        "code": "SONARI939",
        "value": "SONARI"
    },
    {
        "code": "SONIPAT808",
        "value": "SONIPAT"
    },
    {
        "code": "SORENG339",
        "value": "SORENG"
    },
    {
        "code": "SRI GANGANAGAR202",
        "value": "SRI GANGANAGAR"
    },
    {
        "code": "SRI MUKTSAR SAHIB575",
        "value": "SRI MUKTSAR SAHIB"
    },
    {
        "code": "SRIKAKULAM116",
        "value": "SRIKAKULAM"
    },
    {
        "code": "SRINAGAR260",
        "value": "SRINAGAR"
    },
    {
        "code": "SUBARNAPUR258",
        "value": "SUBARNAPUR"
    },
    {
        "code": "SUKMA1542",
        "value": "SUKMA"
    },
    {
        "code": "SULTANPUR656",
        "value": "SULTANPUR"
    },
    {
        "code": "SUNDERGARH416",
        "value": "SUNDERGARH"
    },
    {
        "code": "SUPAUL1028",
        "value": "SUPAUL"
    },
    {
        "code": "SURAJPUR1129",
        "value": "SURAJPUR"
    },
    {
        "code": "SURAT143",
        "value": "SURAT"
    },
    {
        "code": "SURENDRANAGAR144",
        "value": "SURENDRANAGAR"
    },
    {
        "code": "SURI673",
        "value": "SURI"
    },
    {
        "code": "SURYAPET105",
        "value": "SURYAPET"
    },
    {
        "code": "TADEPALLI104",
        "value": "TADEPALLI"
    },
    {
        "code": "TAMBARAM755",
        "value": "TAMBARAM"
    },
    {
        "code": "TAMENGLONG1434",
        "value": "TAMENGLONG"
    },
    {
        "code": "TAMLUK675",
        "value": "TAMLUK"
    },
    {
        "code": "TARN TARAN SAHIB764",
        "value": "TARN TARAN SAHIB"
    },
    {
        "code": "TATO1199",
        "value": "TATO"
    },
    {
        "code": "TAWANG901",
        "value": "TAWANG"
    },
    {
        "code": "TENGNOUPAL1223",
        "value": "TENGNOUPAL"
    },
    {
        "code": "TENKASI833",
        "value": "TENKASI"
    },
    {
        "code": "TEZPUR300",
        "value": "TEZPUR"
    },
    {
        "code": "TEZU900",
        "value": "TEZU"
    },
    {
        "code": "THANE40",
        "value": "THANE"
    },
    {
        "code": "THANJAVUR193",
        "value": "THANJAVUR"
    },
    {
        "code": "THENI621",
        "value": "THENI"
    },
    {
        "code": "THIRUVANANTHAPURAM835",
        "value": "THIRUVANANTHAPURAM"
    },
    {
        "code": "THOOTHUKUDI1535",
        "value": "THOOTHUKUDI"
    },
    {
        "code": "THOUBAL1223",
        "value": "THOUBAL"
    },
    {
        "code": "THRISSUR328",
        "value": "THRISSUR"
    },
    {
        "code": "TIKAMGARH95",
        "value": "TIKAMGARH"
    },
    {
        "code": "TINSUKIA227",
        "value": "TINSUKIA"
    },
    {
        "code": "TIRUCHIRAPPALLI194",
        "value": "TIRUCHIRAPPALLI"
    },
    {
        "code": "TIRUNELVELI195",
        "value": "TIRUNELVELI"
    },
    {
        "code": "TIRUPATI297",
        "value": "TIRUPATI"
    },
    {
        "code": "TIRUPATTUR304",
        "value": "TIRUPATTUR"
    },
    {
        "code": "TIRUPPUR347",
        "value": "TIRUPPUR"
    },
    {
        "code": "TIRUVALLUR1319",
        "value": "TIRUVALLUR"
    },
    {
        "code": "TIRUVANNAMALAI580",
        "value": "TIRUVANNAMALAI"
    },
    {
        "code": "TIRUVARUR193",
        "value": "TIRUVARUR"
    },
    {
        "code": "TONK173",
        "value": "TONK"
    },
    {
        "code": "TRIPURA266",
        "value": "TRIPURA"
    },
    {
        "code": "TUENSANG1446",
        "value": "TUENSANG"
    },
    {
        "code": "TUMAKURU60",
        "value": "TUMAKURU"
    },
    {
        "code": "TURA407",
        "value": "TURA"
    },
    {
        "code": "UDAIPUR1476",
        "value": "UDAIPUR"
    },
    {
        "code": "UDAIPUR174",
        "value": "UDAIPUR"
    },
    {
        "code": "UDALGURI941",
        "value": "UDALGURI"
    },
    {
        "code": "UDHAMPUR318",
        "value": "UDHAMPUR"
    },
    {
        "code": "UDUPI322",
        "value": "UDUPI"
    },
    {
        "code": "UJJAIN96",
        "value": "UJJAIN"
    },
    {
        "code": "UKHRUL1435",
        "value": "UKHRUL"
    },
    {
        "code": "ULHASNAGAR1267",
        "value": "ULHASNAGAR"
    },
    {
        "code": "UMARIA1001",
        "value": "UMARIA"
    },
    {
        "code": "UNA403",
        "value": "UNA"
    },
    {
        "code": "UNNAO787",
        "value": "UNNAO"
    },
    {
        "code": "UTTARKASHI1236",
        "value": "UTTARKASHI"
    },
    {
        "code": "VADODARA145",
        "value": "VADODARA"
    },
    {
        "code": "VALSAD146",
        "value": "VALSAD"
    },
    {
        "code": "VARANASI250",
        "value": "VARANASI"
    },
    {
        "code": "VASAI-VIRAR395",
        "value": "VASAI-VIRAR"
    },
    {
        "code": "VASANT VIHAR10",
        "value": "VASANT VIHAR"
    },
    {
        "code": "VELLORE304",
        "value": "VELLORE"
    },
    {
        "code": "VERAVAL1577",
        "value": "VERAVAL"
    },
    {
        "code": "VIDISHA97",
        "value": "VIDISHA"
    },
    {
        "code": "VIJAYAPURA47",
        "value": "VIJAYAPURA"
    },
    {
        "code": "VIJAYAWADA108",
        "value": "VIJAYAWADA"
    },
    {
        "code": "VIKARABAD1174",
        "value": "VIKARABAD"
    },
    {
        "code": "VILUPPURAM360",
        "value": "VILUPPURAM"
    },
    {
        "code": "VIRUDHUNAGAR423",
        "value": "VIRUDHUNAGAR"
    },
    {
        "code": "VISAKHAPATNAM117",
        "value": "VISAKHAPATNAM"
    },
    {
        "code": "VIZIANAGARAM1331",
        "value": "VIZIANAGARAM"
    },
    {
        "code": "VYARA1218",
        "value": "VYARA"
    },
    {
        "code": "WAIDHAN1600",
        "value": "WAIDHAN"
    },
    {
        "code": "WANAPARTHY694",
        "value": "WANAPARTHY"
    },
    {
        "code": "WARANGAL119",
        "value": "WARANGAL"
    },
    {
        "code": "WARDHA41",
        "value": "WARDHA"
    },
    {
        "code": "WASHIM42",
        "value": "WASHIM"
    },
    {
        "code": "WILLIAMNAGAR1436",
        "value": "WILLIAMNAGAR"
    },
    {
        "code": "WOKHA1447",
        "value": "WOKHA"
    },
    {
        "code": "YADGIR713",
        "value": "YADGIR"
    },
    {
        "code": "YAMUNANAGAR314",
        "value": "YAMUNANAGAR"
    },
    {
        "code": "YAMUNOTRI1236",
        "value": "YAMUNOTRI"
    },
    {
        "code": "YANAM448",
        "value": "YANAM"
    },
    {
        "code": "YAVATMAL413",
        "value": "YAVATMAL"
    },
    {
        "code": "YINGKIONG1372",
        "value": "YINGKIONG"
    },
    {
        "code": "YUPIA481",
        "value": "YUPIA"
    },
    {
        "code": "ZIRO1197",
        "value": "ZIRO"
    },
    {
        "code": "ZUNHEBOTO330",
        "value": "ZUNHEBOTO"
    },
    {
        "code": "PONDICHERRY358",
        "value": "PONDICHERRY"
    },
    {
        "code": "BANGALORE2",
        "value": "BANGALORE"
    },
    {
        "code": "KANCHEEPURAM755",
        "value": "KANCHEEPURAM"
    }
]}

export default CITYJSON