import "./index.css";
import Button from "@mui/material/Button";
import bg2 from "../../assets/bg2.png";
import { currentScreenDetails } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import {
  addCompletedScreen,
  updateCompletedScreens,
} from "../../store/features/toolsSlice";
import { screenData } from "../../utils/screenData";
import { useNavigate } from "react-router-dom";
import {pageBasicDetails} from "../../store/pageDetails";

const Footer = (props) => {
  const dispatch = useDispatch();
  const storeValues = useSelector((state) => state);
  const {pageDetails : {dropOffScreenRoute}, tools : {completedScreens}} = storeValues;
  const {
    buttonText,
    disableButton,
    callBackAction,
    removeBtn,
    prevNextBtnActive,
  } = props;
  const navigate = useNavigate();
  let [screenId, ,route] = currentScreenDetails();
  const handleClick = () => {
    if (dropOffScreenRoute === route) {
      dispatch(pageBasicDetails({dropOffScreenRoute : ''}))
    }
    callBackAction();
  };

  const getClass = () => {
    let className = ''
    if (screenId === 1 || screenId === 2 || screenId === 3 || screenId === 5 || screenId === 14) className = 'footer-button';
    return className;
  }

  return (
    <div className={`${getClass()} bottom-section`}>
      {
        <div className="page-body-contents">
          {prevNextBtnActive?.prev && (
            <Button className="prev-next-button"
              onClick={() => {
                if(screenId === 16) {
                  let indexes = [15, 16, 99, 100];
                  let screens = completedScreens.filter((value, index) => !indexes.includes(completedScreens[index]));
                  dispatch(updateCompletedScreens(screens));
                  dispatch(pageBasicDetails({reKYC : false}))
                } else {
                  const updatedScreeens = completedScreens.slice(0, -1);
                  dispatch(updateCompletedScreens(updatedScreeens));
                }
                if (dropOffScreenRoute === '' || dropOffScreenRoute === undefined) {
                  dispatch(pageBasicDetails({dropOffScreenRoute : route}))
                }
                const latestScreenObj = screenData.find(
                  (screen) => screen.id === screenId - 1,
                );
                if (latestScreenObj && latestScreenObj?.id !== screenId) {
                  navigate(`/${latestScreenObj.route}`);
                }
              }}
            >
              {" "}
              Back{" "}
            </Button>
          )}

          {prevNextBtnActive?.next && (
            <Button className="prev-next-button"
              onClick={() => {
                let [screenId] = currentScreenDetails();
                dispatch(addCompletedScreen(screenId));
                if (screenId === 99 || screenId === 100) {
                  navigate("/verification/1");
                } else {
                  const latestScreenObj = screenData.find(
                    (screen) => screen.id === screenId + 1,
                  );
                  navigate(`/${latestScreenObj.route}`);
                }
              }}
            >
              {" "}
              Next{" "}
            </Button>
          )}
          {!removeBtn && (
            <Button
              style={{ fontSize: "20px", height: "40px" }}
              className="action-button"
              variant="contained"
              color="secondary"
              disabled={disableButton}
              onClick={() => handleClick()}
            >
              <b>{buttonText}</b>
            </Button>
          )}
        </div>
      }
      <img className="bottom-image" src={bg2} alt="Bottom-Footer" />
    </div>
  );
};

export default Footer;

