import React from "react";
import "./index.css";
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  TERMS_HEADING,
  TERMS_TYPE_1,
  TERMS_TYPE_2,
  TERMS_TYPE_3,
  TERMS_TYPE_4,
  TERMS_TYPE_5,
  TERMS_TYPE_6,
  TERMS_TYPE_7,
  TERMS_TYPE_8,
  TERMS_TYPE_9,
  CONSENT_BODY,
  CONSENT_HEADING,
  AUTHORISATION_HEADING,
  AUTHORISATION_BODY,
  TERMS_ACCEPT,
  TERMS_CONDITIONS1,
  TERMS_CONDITIONS2,
  TERMS_CONDITIONS3,
  AUTHORISATION_NOTE,
  CONSENT_BODY_3,
  CONSENT_BODY_4,
  CONSENT_BODY_5,
  CONSENT_BODY_6,
  CONSENT_BODY_7,
  CONSENT_BODY_8,
  CONSENT_BODY_9,
  CONSENT_BODY_10,
  CONSENT_BODY_11,
  CONSENT_BODY_12,
  CONSENT_BODY_13,
  CONSENT_BODY_14,
  CONSENT_BODY_15,
  CONSENT_BODY_4_SUB_CONTENT,
  CONSENT_BODY_5_SUB_CONTENT,
  CONSENT_BODY_6_SUB_CONTENT,
  CONSENT_BODY_7_SUB_CONTENT,
  CONSENT_BODY_8_SUB_CONTENT,
  FINAL_OFFER_ACCEPT_TERMS
} from "../../utils/cms";
import DOMPurify from 'dompurify'

const Terms = (props) => {
  const { closeTersmAction, acceptTersmAction, consent } = props

  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
  })
   
   return (
     <>
      <div className="name-consent-content">
        {consent === 'Name' && 
          <>
            <h3 className="terms-heading">
              {TERMS_HEADING} <CloseIcon onClick={() => closeTersmAction()} />
            </h3>
            <div className="terms-content-wrapper">
              <div className="name-consent-title">
                <a href="#scroll-one">{TERMS_TYPE_1}</a>
                <a href="#scroll-two">{TERMS_TYPE_2}</a>
                <a href="#scroll-three">{TERMS_TYPE_3}</a>
                <a href="#scroll-four">{TERMS_TYPE_4}</a>
                <a href="#scroll-five">{TERMS_TYPE_5}</a>
                <a href="#scroll-six">{TERMS_TYPE_6}</a>
                <a href="#scroll-seven">{TERMS_TYPE_7}</a>
                <a href="#scroll-eight">{TERMS_TYPE_8}</a>
                <a href="#scroll-nine">{TERMS_TYPE_9}</a>
              </div>
              <p className="terms-accept">{TERMS_ACCEPT}&nbsp;
                <label className="terms-conditions">{TERMS_CONDITIONS1}</label>, <label className="terms-conditions">{TERMS_CONDITIONS2}</label>, <label className="terms-conditions">{TERMS_CONDITIONS3}</label>
              </p>
              <div className="consent-body">
                <h3 id="scroll-one">{CONSENT_HEADING}</h3>
                <p>{CONSENT_BODY}</p>

                <h3 id="scroll-two">{AUTHORISATION_HEADING}</h3>
                <p>{AUTHORISATION_BODY}</p>
                <fieldset>
                  <p><i>{AUTHORISATION_NOTE}</i></p>
                </fieldset>

                <h3 id="scroll-three">{TERMS_TYPE_3}</h3>
                <p>{CONSENT_BODY_3}</p>

                <h3 id="scroll-four">{TERMS_TYPE_4}</h3>
                <p dangerouslySetInnerHTML={sanitizedData(CONSENT_BODY_4)} />
                <fieldset>
                  <p><i>{CONSENT_BODY_4_SUB_CONTENT}</i></p>
                </fieldset>

                <h3 id="scroll-five">{TERMS_TYPE_5}</h3>
                <p dangerouslySetInnerHTML={sanitizedData(CONSENT_BODY_5)} />
                <fieldset>
                  <p><i>{CONSENT_BODY_5_SUB_CONTENT}</i></p>
                </fieldset>

                <h3 id="scroll-six">{TERMS_TYPE_6}</h3>
                <p dangerouslySetInnerHTML={sanitizedData(CONSENT_BODY_6)} />
                <fieldset>
                  <p><i>{CONSENT_BODY_6_SUB_CONTENT}</i></p>
                </fieldset>

                <h3 id="scroll-seven">{TERMS_TYPE_7}</h3>
                <p dangerouslySetInnerHTML={sanitizedData(CONSENT_BODY_7)} />
                <fieldset>
                  <p><i>{CONSENT_BODY_7_SUB_CONTENT}</i></p>
                </fieldset>

                <h3 id="scroll-eight">{TERMS_TYPE_8}</h3>
                <p dangerouslySetInnerHTML={sanitizedData(CONSENT_BODY_8)} />
                <fieldset>
                  <p><i>{CONSENT_BODY_8_SUB_CONTENT}</i></p>
                </fieldset>

                <h3 id="scroll-nine">{TERMS_TYPE_9}</h3>
                <ul>
                  <li>{CONSENT_BODY_9}</li>
                  <li>{CONSENT_BODY_10}</li>
                  <li>{CONSENT_BODY_11}</li>
                  <li>{CONSENT_BODY_12}</li>
                  <li>{CONSENT_BODY_13}</li>
                  <li>{CONSENT_BODY_14}</li>
                  <li>{CONSENT_BODY_15}</li>
                </ul>
              </div>
            </div>
          </>
        }
        {consent === 'Mobile' && 
          <div>
            <h3 className="terms-heading">
              {TERMS_TYPE_2} <CloseIcon onClick={() => closeTersmAction()} />
            </h3>
            <div className="terms-content-wrapper">
              <div className="name-consent-title">
                  <p>{AUTHORISATION_BODY}</p>
                  <fieldset>
                    <p><i>{AUTHORISATION_NOTE}</i></p>
                  </fieldset>              
              </div>
            </div>
          </div>
        }
        {consent === 'Final-Accept' && 
          <div>
            <h3 className="terms-heading">
              {TERMS_HEADING} <CloseIcon onClick={() => closeTersmAction()} />
            </h3>
            <div className="terms-content-wrapper">
              <div className="name-consent-title">
                <div dangerouslySetInnerHTML={sanitizedData(FINAL_OFFER_ACCEPT_TERMS)} />              
              </div>
            </div>
          </div>
        }
      </div>
      <Button style={{backgroundColor: "#870016"}} className="consent-action-button" variant="contained" color="secondary" onClick={() => acceptTersmAction()}><b>Accept</b></Button>
    </>
  );
};

export default Terms;