const STATE = {cacheValuesList: [
    {
        "State_Code": "22",
        "State_Name": "CHHATTISGARH",
        "DISTRICTS": [
            {
                "District_Code": "434",
                "District_Name": "BALOD"
            },
            {
                "District_Code": "435",
                "District_Name": "BALODA BAZAR BHATAPARA"
            },
            {
                "District_Code": "436",
                "District_Name": "BALRAMPUR"
            },
            {
                "District_Code": "437",
                "District_Name": "BASTAR"
            },
            {
                "District_Code": "438",
                "District_Name": "BEMETARA"
            },
            {
                "District_Code": "439",
                "District_Name": "BIJAPUR"
            },
            {
                "District_Code": "440",
                "District_Name": "BILASPUR"
            },
            {
                "District_Code": "441",
                "District_Name": "DANTEWADA"
            },
            {
                "District_Code": "442",
                "District_Name": "DHAMTARI"
            },
            {
                "District_Code": "443",
                "District_Name": "DURG"
            },
            {
                "District_Code": "444",
                "District_Name": "GARIABAND"
            },
            {
                "District_Code": "445",
                "District_Name": "JANJGIR - CHAMPA"
            },
            {
                "District_Code": "446",
                "District_Name": "JASHPUR"
            },
            {
                "District_Code": "447",
                "District_Name": "KANKER"
            },
            {
                "District_Code": "448",
                "District_Name": "KONDAGAON"
            },
            {
                "District_Code": "449",
                "District_Name": "KORBA"
            },
            {
                "District_Code": "450",
                "District_Name": "KORIYA"
            },
            {
                "District_Code": "451",
                "District_Name": "MAHASAMUND"
            },
            {
                "District_Code": "452",
                "District_Name": "MUNGELI"
            },
            {
                "District_Code": "453",
                "District_Name": "NARAYANPUR"
            },
            {
                "District_Code": "454",
                "District_Name": "RAIGARH"
            },
            {
                "District_Code": "455",
                "District_Name": "RAIPUR"
            },
            {
                "District_Code": "456",
                "District_Name": "RAJNANDGAON"
            },
            {
                "District_Code": "457",
                "District_Name": "SUKMA"
            },
            {
                "District_Code": "458",
                "District_Name": "SURAJPUR"
            },
            {
                "District_Code": "459",
                "District_Name": "SURGUJA"
            },
            {
                "District_Code": "634",
                "District_Name": "KABIRDHAM"
            },
            {
                "District_Code": "752",
                "District_Name": "MANENDRAGARH"
            },
            {
                "District_Code": "753",
                "District_Name": "SAKTI"
            }
        ]
    },
    {
        "State_Code": "01",
        "State_Name": "JAMMU KASHMIR",
        "DISTRICTS": [
            {
                "District_Code": "1",
                "District_Name": "ANANTNAG"
            },
            {
                "District_Code": "2",
                "District_Name": "BANDIPORE"
            },
            {
                "District_Code": "3",
                "District_Name": "BARAMULLA"
            },
            {
                "District_Code": "4",
                "District_Name": "BUDGAM"
            },
            {
                "District_Code": "5",
                "District_Name": "SRINAGAR"
            },
            {
                "District_Code": "6",
                "District_Name": "UDHAMPUR"
            },
            {
                "District_Code": "34",
                "District_Name": "DODA"
            },
            {
                "District_Code": "35",
                "District_Name": "GANDERBAL"
            },
            {
                "District_Code": "36",
                "District_Name": "JAMMU"
            },
            {
                "District_Code": "37",
                "District_Name": "KATHUA"
            },
            {
                "District_Code": "38",
                "District_Name": "KISHTWAR"
            },
            {
                "District_Code": "39",
                "District_Name": "KULGAM"
            },
            {
                "District_Code": "40",
                "District_Name": "KUPWARA"
            },
            {
                "District_Code": "41",
                "District_Name": "POONCH"
            },
            {
                "District_Code": "42",
                "District_Name": "PULWAMA"
            },
            {
                "District_Code": "43",
                "District_Name": "RAJOURI"
            },
            {
                "District_Code": "44",
                "District_Name": "RAMBAN"
            },
            {
                "District_Code": "45",
                "District_Name": "REASI"
            },
            {
                "District_Code": "46",
                "District_Name": "SAMBA"
            },
            {
                "District_Code": "47",
                "District_Name": "SHOPIAN"
            }
        ]
    },
    {
        "State_Code": "23",
        "State_Name": "MADHYA PRADESH",
        "DISTRICTS": [
            {
                "District_Code": "460",
                "District_Name": "AGAR MALWA"
            },
            {
                "District_Code": "461",
                "District_Name": "ALIRAJPUR"
            },
            {
                "District_Code": "462",
                "District_Name": "ANUPPUR"
            },
            {
                "District_Code": "463",
                "District_Name": "ASHOKNAGAR"
            },
            {
                "District_Code": "464",
                "District_Name": "BALAGHAT"
            },
            {
                "District_Code": "465",
                "District_Name": "BARWANI"
            },
            {
                "District_Code": "466",
                "District_Name": "BETUL"
            },
            {
                "District_Code": "467",
                "District_Name": "BHIND"
            },
            {
                "District_Code": "468",
                "District_Name": "BHOPAL"
            },
            {
                "District_Code": "469",
                "District_Name": "BURHANPUR"
            },
            {
                "District_Code": "470",
                "District_Name": "CHHATARPUR"
            },
            {
                "District_Code": "471",
                "District_Name": "CHHINDWARA"
            },
            {
                "District_Code": "472",
                "District_Name": "DAMOH"
            },
            {
                "District_Code": "473",
                "District_Name": "DATIA"
            },
            {
                "District_Code": "474",
                "District_Name": "DEWAS"
            },
            {
                "District_Code": "475",
                "District_Name": "DHAR"
            },
            {
                "District_Code": "476",
                "District_Name": "DINDORI"
            },
            {
                "District_Code": "477",
                "District_Name": "EAST NIMAR/KHANDWA"
            },
            {
                "District_Code": "478",
                "District_Name": "GUNA"
            },
            {
                "District_Code": "479",
                "District_Name": "GWALIOR"
            },
            {
                "District_Code": "480",
                "District_Name": "HARDA"
            },
            {
                "District_Code": "481",
                "District_Name": "HOSHANGABAD"
            },
            {
                "District_Code": "482",
                "District_Name": "INDORE"
            },
            {
                "District_Code": "483",
                "District_Name": "JABALPUR"
            },
            {
                "District_Code": "484",
                "District_Name": "JHABUA"
            },
            {
                "District_Code": "485",
                "District_Name": "KATNI"
            },
            {
                "District_Code": "486",
                "District_Name": "MANDLA"
            },
            {
                "District_Code": "487",
                "District_Name": "MANDSAUR"
            },
            {
                "District_Code": "488",
                "District_Name": "MORENA"
            },
            {
                "District_Code": "489",
                "District_Name": "NARSINGHPUR"
            },
            {
                "District_Code": "490",
                "District_Name": "NEEMUCH"
            },
            {
                "District_Code": "491",
                "District_Name": "NIWARI"
            },
            {
                "District_Code": "492",
                "District_Name": "PANNA"
            },
            {
                "District_Code": "493",
                "District_Name": "RAISEN"
            },
            {
                "District_Code": "494",
                "District_Name": "RAJGARH"
            },
            {
                "District_Code": "495",
                "District_Name": "RATLAM"
            },
            {
                "District_Code": "496",
                "District_Name": "REWA"
            },
            {
                "District_Code": "497",
                "District_Name": "SAGAR"
            },
            {
                "District_Code": "498",
                "District_Name": "SATNA"
            },
            {
                "District_Code": "499",
                "District_Name": "SEHORE"
            },
            {
                "District_Code": "500",
                "District_Name": "SEONI"
            },
            {
                "District_Code": "501",
                "District_Name": "SHAHDOL"
            },
            {
                "District_Code": "502",
                "District_Name": "SHAJAPUR"
            },
            {
                "District_Code": "503",
                "District_Name": "SHEOPUR"
            },
            {
                "District_Code": "504",
                "District_Name": "SHIVPURI"
            },
            {
                "District_Code": "505",
                "District_Name": "SIDHI"
            },
            {
                "District_Code": "506",
                "District_Name": "SINGRAULI"
            },
            {
                "District_Code": "507",
                "District_Name": "TIKAMGARH"
            },
            {
                "District_Code": "508",
                "District_Name": "UJJAIN"
            },
            {
                "District_Code": "509",
                "District_Name": "UMARIA"
            },
            {
                "District_Code": "510",
                "District_Name": "VIDISHA"
            },
            {
                "District_Code": "511",
                "District_Name": "WEST NIMAR/KHARGONE"
            },
            {
                "District_Code": "756",
                "District_Name": "CHACHAURA"
            },
            {
                "District_Code": "757",
                "District_Name": "MAIHAR"
            },
            {
                "District_Code": "758",
                "District_Name": "NAGDA"
            }
        ]
    },
    {
        "State_Code": "02",
        "State_Name": "HIMACHAL PRADESH",
        "DISTRICTS": [
            {
                "District_Code": "7",
                "District_Name": "BILASPUR"
            },
            {
                "District_Code": "8",
                "District_Name": "CHAMBA"
            },
            {
                "District_Code": "9",
                "District_Name": "HAMIRPUR"
            },
            {
                "District_Code": "10",
                "District_Name": "KANGRA"
            },
            {
                "District_Code": "11",
                "District_Name": "KINNAUR"
            },
            {
                "District_Code": "12",
                "District_Name": "KULLU"
            },
            {
                "District_Code": "13",
                "District_Name": "LAHAUL & SPITI"
            },
            {
                "District_Code": "14",
                "District_Name": "MANDI"
            },
            {
                "District_Code": "15",
                "District_Name": "SHIMLA"
            },
            {
                "District_Code": "16",
                "District_Name": "SIRMAUR"
            },
            {
                "District_Code": "17",
                "District_Name": "SOLAN"
            },
            {
                "District_Code": "18",
                "District_Name": "UNA"
            }
        ]
    },
    {
        "State_Code": "24",
        "State_Name": "GUJARAT",
        "DISTRICTS": [
            {
                "District_Code": "512",
                "District_Name": "AHMEDABAD"
            },
            {
                "District_Code": "513",
                "District_Name": "AMRELI"
            },
            {
                "District_Code": "514",
                "District_Name": "ANAND"
            },
            {
                "District_Code": "515",
                "District_Name": "ARAVALLI"
            },
            {
                "District_Code": "516",
                "District_Name": "BANASKANTHA"
            },
            {
                "District_Code": "517",
                "District_Name": "BHARUCH"
            },
            {
                "District_Code": "518",
                "District_Name": "BHAVNAGAR"
            },
            {
                "District_Code": "519",
                "District_Name": "BOTAD"
            },
            {
                "District_Code": "520",
                "District_Name": "CHHOTA UDAIPUR"
            },
            {
                "District_Code": "521",
                "District_Name": "DAHOD"
            },
            {
                "District_Code": "522",
                "District_Name": "DANG"
            },
            {
                "District_Code": "523",
                "District_Name": "DEVBHUMI DWARKA"
            },
            {
                "District_Code": "524",
                "District_Name": "GANDHINAGAR"
            },
            {
                "District_Code": "525",
                "District_Name": "GIR SOMNATH"
            },
            {
                "District_Code": "526",
                "District_Name": "JAMNAGAR"
            },
            {
                "District_Code": "527",
                "District_Name": "JUNAGADH"
            },
            {
                "District_Code": "528",
                "District_Name": "KHEDA"
            },
            {
                "District_Code": "529",
                "District_Name": "KUTCH"
            },
            {
                "District_Code": "530",
                "District_Name": "MAHISAGAR"
            },
            {
                "District_Code": "531",
                "District_Name": "MEHSANA"
            },
            {
                "District_Code": "532",
                "District_Name": "MORBI"
            },
            {
                "District_Code": "533",
                "District_Name": "NARMADA"
            },
            {
                "District_Code": "534",
                "District_Name": "NAVSARI"
            },
            {
                "District_Code": "535",
                "District_Name": "PANCHMAHAL"
            },
            {
                "District_Code": "536",
                "District_Name": "PATAN"
            },
            {
                "District_Code": "537",
                "District_Name": "PORBANDAR"
            },
            {
                "District_Code": "538",
                "District_Name": "RAJKOT"
            },
            {
                "District_Code": "539",
                "District_Name": "SABARKANTHA"
            },
            {
                "District_Code": "540",
                "District_Name": "SURAT"
            },
            {
                "District_Code": "541",
                "District_Name": "SURENDRANAGAR"
            },
            {
                "District_Code": "542",
                "District_Name": "TAPI"
            },
            {
                "District_Code": "543",
                "District_Name": "VADODARA"
            },
            {
                "District_Code": "544",
                "District_Name": "VALSAD"
            }
        ]
    },
    {
        "State_Code": "03",
        "State_Name": "PUNJAB",
        "DISTRICTS": [
            {
                "District_Code": "19",
                "District_Name": "AMRITSAR"
            },
            {
                "District_Code": "20",
                "District_Name": "BARNALA"
            },
            {
                "District_Code": "21",
                "District_Name": "BATHINDA"
            },
            {
                "District_Code": "22",
                "District_Name": "FARIDKOT"
            },
            {
                "District_Code": "23",
                "District_Name": "FATEHGARH SAHIB"
            },
            {
                "District_Code": "24",
                "District_Name": "FAZILKA"
            },
            {
                "District_Code": "25",
                "District_Name": "FIROZPUR"
            },
            {
                "District_Code": "26",
                "District_Name": "GURDASPUR"
            },
            {
                "District_Code": "27",
                "District_Name": "HOSHIARPUR"
            },
            {
                "District_Code": "28",
                "District_Name": "JALANDHAR"
            },
            {
                "District_Code": "29",
                "District_Name": "KAPURTHALA"
            },
            {
                "District_Code": "30",
                "District_Name": "LUDHIANA"
            },
            {
                "District_Code": "31",
                "District_Name": "MANSA"
            },
            {
                "District_Code": "32",
                "District_Name": "MOGA"
            },
            {
                "District_Code": "33",
                "District_Name": "PATHANKOT"
            },
            {
                "District_Code": "48",
                "District_Name": "PATIALA"
            },
            {
                "District_Code": "49",
                "District_Name": "RUPNAGAR"
            },
            {
                "District_Code": "50",
                "District_Name": "SANGRUR"
            },
            {
                "District_Code": "51",
                "District_Name": "SRI MUKTSAR SAHIB"
            },
            {
                "District_Code": "52",
                "District_Name": "TARN TARAN"
            },
            {
                "District_Code": "768",
                "District_Name": "MOHALI"
            },
            {
                "District_Code": "769",
                "District_Name": "MUKTSAR"
            },
            {
                "District_Code": "770",
                "District_Name": "SHAHEED BHAGAT SINGH NAGAR"
            }
        ]
    },
    {
        "State_Code": "25",
        "State_Name": "DAMAN DIU",
        "DISTRICTS": [
            {
                "District_Code": "545",
                "District_Name": "DAMAN"
            },
            {
                "District_Code": "546",
                "District_Name": "DIU"
            }
        ]
    },
    {
        "State_Code": "04",
        "State_Name": "CHANDIGARH",
        "DISTRICTS": [
            {
                "District_Code": "53",
                "District_Name": "CHANDIGARH"
            }
        ]
    },
    {
        "State_Code": "26",
        "State_Name": "DADRA NAGAR HAVELI",
        "DISTRICTS": [
            {
                "District_Code": "547",
                "District_Name": "DADRA & NAGAR HAVELI"
            }
        ]
    },
    {
        "State_Code": "05",
        "State_Name": "UTTARANCHAL",
        "DISTRICTS": [
            {
                "District_Code": "54",
                "District_Name": "ALMORA"
            },
            {
                "District_Code": "55",
                "District_Name": "BAGESHWAR"
            },
            {
                "District_Code": "56",
                "District_Name": "CHAMOLI"
            },
            {
                "District_Code": "57",
                "District_Name": "CHAMPAWAT"
            },
            {
                "District_Code": "58",
                "District_Name": "DEHRADUN"
            },
            {
                "District_Code": "59",
                "District_Name": "HARIDWAR"
            },
            {
                "District_Code": "60",
                "District_Name": "NAINITAL"
            },
            {
                "District_Code": "61",
                "District_Name": "PAURI GARHWAL"
            },
            {
                "District_Code": "62",
                "District_Name": "PITHORAGARH"
            },
            {
                "District_Code": "63",
                "District_Name": "RUDRAPRAYAG"
            },
            {
                "District_Code": "64",
                "District_Name": "TEHRI GARHWAL"
            },
            {
                "District_Code": "65",
                "District_Name": "UDHAM SINGH NAGAR"
            },
            {
                "District_Code": "66",
                "District_Name": "UTTARKASHI"
            }
        ]
    },
    {
        "State_Code": "27",
        "State_Name": "MAHARASHTRA",
        "DISTRICTS": [
            {
                "District_Code": "548",
                "District_Name": "AHMEDNAGAR"
            },
            {
                "District_Code": "549",
                "District_Name": "AKOLA"
            },
            {
                "District_Code": "550",
                "District_Name": "AMRAVATI"
            },
            {
                "District_Code": "551",
                "District_Name": "AURANGABAD"
            },
            {
                "District_Code": "552",
                "District_Name": "BEED"
            },
            {
                "District_Code": "553",
                "District_Name": "BHANDARA"
            },
            {
                "District_Code": "554",
                "District_Name": "BULDHANA"
            },
            {
                "District_Code": "555",
                "District_Name": "CHANDRAPUR"
            },
            {
                "District_Code": "556",
                "District_Name": "DHULE"
            },
            {
                "District_Code": "557",
                "District_Name": "GADCHIROLI"
            },
            {
                "District_Code": "558",
                "District_Name": "GONDIA"
            },
            {
                "District_Code": "559",
                "District_Name": "HINGOLI"
            },
            {
                "District_Code": "560",
                "District_Name": "JALGAON"
            },
            {
                "District_Code": "561",
                "District_Name": "JALNA"
            },
            {
                "District_Code": "562",
                "District_Name": "KOLHAPUR"
            },
            {
                "District_Code": "563",
                "District_Name": "LATUR"
            },
            {
                "District_Code": "564",
                "District_Name": "MUMBAI CITY"
            },
            {
                "District_Code": "565",
                "District_Name": "MUMBAI SUBURBAN"
            },
            {
                "District_Code": "566",
                "District_Name": "NAGPUR"
            },
            {
                "District_Code": "567",
                "District_Name": "NANDED"
            },
            {
                "District_Code": "568",
                "District_Name": "NANDURBAR"
            },
            {
                "District_Code": "569",
                "District_Name": "NASHIK"
            },
            {
                "District_Code": "570",
                "District_Name": "OSMANABAD"
            },
            {
                "District_Code": "571",
                "District_Name": "PALGHAR"
            },
            {
                "District_Code": "572",
                "District_Name": "PARBHANI"
            },
            {
                "District_Code": "573",
                "District_Name": "PUNE"
            },
            {
                "District_Code": "574",
                "District_Name": "RAIGAD"
            },
            {
                "District_Code": "575",
                "District_Name": "RATNAGIRI"
            },
            {
                "District_Code": "576",
                "District_Name": "SANGLI"
            },
            {
                "District_Code": "577",
                "District_Name": "SATARA"
            },
            {
                "District_Code": "578",
                "District_Name": "SINDHUDURG"
            },
            {
                "District_Code": "579",
                "District_Name": "SOLAPUR"
            },
            {
                "District_Code": "580",
                "District_Name": "THANE"
            },
            {
                "District_Code": "581",
                "District_Name": "WARDHA"
            },
            {
                "District_Code": "582",
                "District_Name": "WASHIM"
            },
            {
                "District_Code": "583",
                "District_Name": "YAVATMAL"
            }
        ]
    },
    {
        "State_Code": "06",
        "State_Name": "HARYANA",
        "DISTRICTS": [
            {
                "District_Code": "67",
                "District_Name": "AMBALA"
            },
            {
                "District_Code": "68",
                "District_Name": "BHIWANI"
            },
            {
                "District_Code": "69",
                "District_Name": "CHARKHI DADRI"
            },
            {
                "District_Code": "70",
                "District_Name": "FARIDABAD"
            },
            {
                "District_Code": "71",
                "District_Name": "FATEHABAD"
            },
            {
                "District_Code": "72",
                "District_Name": "GURGAON"
            },
            {
                "District_Code": "73",
                "District_Name": "GURUGRAM (GURGAON)"
            },
            {
                "District_Code": "74",
                "District_Name": "HISAR"
            },
            {
                "District_Code": "75",
                "District_Name": "JHAJJAR"
            },
            {
                "District_Code": "76",
                "District_Name": "JIND"
            },
            {
                "District_Code": "77",
                "District_Name": "KAITHAL"
            },
            {
                "District_Code": "78",
                "District_Name": "KARNAL"
            },
            {
                "District_Code": "79",
                "District_Name": "KURUKSHETRA"
            },
            {
                "District_Code": "80",
                "District_Name": "MAHENDRAGARH"
            },
            {
                "District_Code": "81",
                "District_Name": "NUH"
            },
            {
                "District_Code": "82",
                "District_Name": "PALWAL"
            },
            {
                "District_Code": "83",
                "District_Name": "PANCHKULA"
            },
            {
                "District_Code": "84",
                "District_Name": "PANIPAT"
            },
            {
                "District_Code": "85",
                "District_Name": "REWARI"
            },
            {
                "District_Code": "86",
                "District_Name": "ROHTAK"
            },
            {
                "District_Code": "87",
                "District_Name": "SIRSA"
            },
            {
                "District_Code": "88",
                "District_Name": "SONIPAT"
            },
            {
                "District_Code": "89",
                "District_Name": "YAMUNANAGAR"
            },
            {
                "District_Code": "767",
                "District_Name": "MEWAT"
            }
        ]
    },
    {
        "State_Code": "28",
        "State_Name": "ANDHRA PRADESH",
        "DISTRICTS": [
            {
                "District_Code": "584",
                "District_Name": "ANANTAPUR"
            },
            {
                "District_Code": "585",
                "District_Name": "CHITTOOR"
            },
            {
                "District_Code": "586",
                "District_Name": "EAST GODAVARI"
            },
            {
                "District_Code": "587",
                "District_Name": "GUNTUR"
            },
            {
                "District_Code": "588",
                "District_Name": "KRISHNA"
            },
            {
                "District_Code": "589",
                "District_Name": "KURNOOL"
            },
            {
                "District_Code": "590",
                "District_Name": "PRAKASAM"
            },
            {
                "District_Code": "591",
                "District_Name": "SRI POTTI SRIRAMULU NELLORE"
            },
            {
                "District_Code": "592",
                "District_Name": "SRIKAKULAM"
            },
            {
                "District_Code": "593",
                "District_Name": "VISAKHAPATNAM"
            },
            {
                "District_Code": "594",
                "District_Name": "VIZIANAGARAM"
            },
            {
                "District_Code": "595",
                "District_Name": "WEST GODAVARI"
            },
            {
                "District_Code": "596",
                "District_Name": "YSR KADAPA"
            }
        ]
    },
    {
        "State_Code": "07",
        "State_Name": "DELHI",
        "DISTRICTS": [
            {
                "District_Code": "90",
                "District_Name": "CENTRAL DELHI"
            },
            {
                "District_Code": "91",
                "District_Name": "EAST DELHI"
            },
            {
                "District_Code": "92",
                "District_Name": "NEW DELHI"
            },
            {
                "District_Code": "93",
                "District_Name": "NORTH DELHI"
            },
            {
                "District_Code": "94",
                "District_Name": "NORTH EAST DELHI"
            },
            {
                "District_Code": "95",
                "District_Name": "NORTH WEST DELHI"
            },
            {
                "District_Code": "96",
                "District_Name": "SHAHDARA"
            },
            {
                "District_Code": "97",
                "District_Name": "SOUTH DELHI"
            },
            {
                "District_Code": "98",
                "District_Name": "SOUTH EAST DELHI"
            },
            {
                "District_Code": "99",
                "District_Name": "SOUTH WEST DELHI"
            },
            {
                "District_Code": "100",
                "District_Name": "WEST DELHI"
            }
        ]
    },
    {
        "State_Code": "29",
        "State_Name": "KARNATAKA",
        "DISTRICTS": [
            {
                "District_Code": "597",
                "District_Name": "BAGALKOT"
            },
            {
                "District_Code": "598",
                "District_Name": "BALLARI"
            },
            {
                "District_Code": "599",
                "District_Name": "BELGAUM"
            },
            {
                "District_Code": "600",
                "District_Name": "BENGALURU RURAL"
            },
            {
                "District_Code": "601",
                "District_Name": "BENGALURU URBAN"
            },
            {
                "District_Code": "602",
                "District_Name": "BIDAR"
            },
            {
                "District_Code": "603",
                "District_Name": "CHAMARAJANAGARA"
            },
            {
                "District_Code": "604",
                "District_Name": "CHIKKABALLAPUR"
            },
            {
                "District_Code": "605",
                "District_Name": "CHIKKAMAGALURU"
            },
            {
                "District_Code": "606",
                "District_Name": "CHITRADURGA"
            },
            {
                "District_Code": "607",
                "District_Name": "DAKSHINA KANNADA"
            },
            {
                "District_Code": "608",
                "District_Name": "DAVANAGERE"
            },
            {
                "District_Code": "609",
                "District_Name": "DHARWAD"
            },
            {
                "District_Code": "610",
                "District_Name": "GADAG"
            },
            {
                "District_Code": "611",
                "District_Name": "HASSAN"
            },
            {
                "District_Code": "612",
                "District_Name": "HAVERI"
            },
            {
                "District_Code": "613",
                "District_Name": "KODAGU"
            },
            {
                "District_Code": "614",
                "District_Name": "KOLAR"
            },
            {
                "District_Code": "615",
                "District_Name": "KOPPALA"
            },
            {
                "District_Code": "616",
                "District_Name": "MANDYA"
            },
            {
                "District_Code": "617",
                "District_Name": "MYSURU"
            },
            {
                "District_Code": "618",
                "District_Name": "RAICHUR"
            },
            {
                "District_Code": "619",
                "District_Name": "RAMANAGARA"
            },
            {
                "District_Code": "620",
                "District_Name": "SHIVAMOGGA"
            },
            {
                "District_Code": "621",
                "District_Name": "TUMAKURU"
            },
            {
                "District_Code": "622",
                "District_Name": "UDUPI"
            },
            {
                "District_Code": "623",
                "District_Name": "UTTARA KANNADA"
            },
            {
                "District_Code": "624",
                "District_Name": "VIJAYAPURA"
            },
            {
                "District_Code": "625",
                "District_Name": "YADGIR"
            },
            {
                "District_Code": "755",
                "District_Name": "GULBARGA"
            }
        ]
    },
    {
        "State_Code": "08",
        "State_Name": "RAJASTHAN",
        "DISTRICTS": [
            {
                "District_Code": "101",
                "District_Name": "AJMER"
            },
            {
                "District_Code": "102",
                "District_Name": "ALWAR"
            },
            {
                "District_Code": "103",
                "District_Name": "BANSWARA"
            },
            {
                "District_Code": "104",
                "District_Name": "BARAN"
            },
            {
                "District_Code": "105",
                "District_Name": "BARMER"
            },
            {
                "District_Code": "106",
                "District_Name": "BHARATPUR"
            },
            {
                "District_Code": "107",
                "District_Name": "BHILWARA"
            },
            {
                "District_Code": "108",
                "District_Name": "BIKANER"
            },
            {
                "District_Code": "109",
                "District_Name": "BUNDI"
            },
            {
                "District_Code": "110",
                "District_Name": "CHITTORGARH"
            },
            {
                "District_Code": "111",
                "District_Name": "CHURU"
            },
            {
                "District_Code": "112",
                "District_Name": "DAUSA"
            },
            {
                "District_Code": "113",
                "District_Name": "DHOLPUR"
            },
            {
                "District_Code": "114",
                "District_Name": "DUNGARPUR"
            },
            {
                "District_Code": "115",
                "District_Name": "HANUMANGARH"
            },
            {
                "District_Code": "116",
                "District_Name": "JAIPUR"
            },
            {
                "District_Code": "117",
                "District_Name": "JAISALMER"
            },
            {
                "District_Code": "118",
                "District_Name": "JALORE"
            },
            {
                "District_Code": "119",
                "District_Name": "JHALAWAR"
            },
            {
                "District_Code": "120",
                "District_Name": "JHUNJHUNU"
            },
            {
                "District_Code": "121",
                "District_Name": "JODHPUR"
            },
            {
                "District_Code": "122",
                "District_Name": "KARAULI"
            },
            {
                "District_Code": "123",
                "District_Name": "KOTA"
            },
            {
                "District_Code": "124",
                "District_Name": "NAGAUR"
            },
            {
                "District_Code": "125",
                "District_Name": "PALI"
            },
            {
                "District_Code": "126",
                "District_Name": "PRATAPGARH"
            },
            {
                "District_Code": "127",
                "District_Name": "RAJSAMAND"
            },
            {
                "District_Code": "128",
                "District_Name": "SAWAI MADHOPUR"
            },
            {
                "District_Code": "129",
                "District_Name": "SIKAR"
            },
            {
                "District_Code": "130",
                "District_Name": "SIROHI"
            },
            {
                "District_Code": "131",
                "District_Name": "SRI GANGANAGAR"
            },
            {
                "District_Code": "132",
                "District_Name": "TONK"
            },
            {
                "District_Code": "133",
                "District_Name": "UDAIPUR"
            }
        ]
    },
    {
        "State_Code": "09",
        "State_Name": "UTTAR PRADESH",
        "DISTRICTS": [
            {
                "District_Code": "134",
                "District_Name": "AGRA"
            },
            {
                "District_Code": "135",
                "District_Name": "ALIGARH"
            },
            {
                "District_Code": "136",
                "District_Name": "AMBEDKAR NAGAR"
            },
            {
                "District_Code": "137",
                "District_Name": "AMETHI"
            },
            {
                "District_Code": "138",
                "District_Name": "AMROHA"
            },
            {
                "District_Code": "139",
                "District_Name": "AURAIYA"
            },
            {
                "District_Code": "140",
                "District_Name": "AYODHYA (FAIZABAD)"
            },
            {
                "District_Code": "141",
                "District_Name": "AZAMGARH"
            },
            {
                "District_Code": "142",
                "District_Name": "BAGPAT"
            },
            {
                "District_Code": "143",
                "District_Name": "BAHRAICH"
            },
            {
                "District_Code": "144",
                "District_Name": "BALLIA"
            },
            {
                "District_Code": "145",
                "District_Name": "BALRAMPUR"
            },
            {
                "District_Code": "146",
                "District_Name": "BANDA"
            },
            {
                "District_Code": "147",
                "District_Name": "BARABANKI"
            },
            {
                "District_Code": "148",
                "District_Name": "BAREILLY"
            },
            {
                "District_Code": "149",
                "District_Name": "BASTI"
            },
            {
                "District_Code": "150",
                "District_Name": "BHADOHI"
            },
            {
                "District_Code": "151",
                "District_Name": "BIJNOR"
            },
            {
                "District_Code": "152",
                "District_Name": "BUDAUN"
            },
            {
                "District_Code": "153",
                "District_Name": "BULANDSHAHR"
            },
            {
                "District_Code": "154",
                "District_Name": "CHANDAULI"
            },
            {
                "District_Code": "155",
                "District_Name": "CHITRAKOOT"
            },
            {
                "District_Code": "156",
                "District_Name": "DEORIA"
            },
            {
                "District_Code": "157",
                "District_Name": "ETAH"
            },
            {
                "District_Code": "158",
                "District_Name": "ETAWAH"
            },
            {
                "District_Code": "159",
                "District_Name": "FARRUKHABAD"
            },
            {
                "District_Code": "160",
                "District_Name": "FATEHPUR"
            },
            {
                "District_Code": "161",
                "District_Name": "FIROZABAD"
            },
            {
                "District_Code": "162",
                "District_Name": "GAUTAM BUDDHA NAGAR"
            },
            {
                "District_Code": "163",
                "District_Name": "GHAZIABAD"
            },
            {
                "District_Code": "164",
                "District_Name": "GHAZIPUR"
            },
            {
                "District_Code": "165",
                "District_Name": "GONDA"
            },
            {
                "District_Code": "166",
                "District_Name": "GORAKHPUR"
            },
            {
                "District_Code": "167",
                "District_Name": "HAMIRPUR"
            },
            {
                "District_Code": "168",
                "District_Name": "HAPUR"
            },
            {
                "District_Code": "169",
                "District_Name": "HARDOI"
            },
            {
                "District_Code": "170",
                "District_Name": "HATHRAS"
            },
            {
                "District_Code": "171",
                "District_Name": "JALAUN"
            },
            {
                "District_Code": "172",
                "District_Name": "JAUNPUR"
            },
            {
                "District_Code": "173",
                "District_Name": "JHANSI"
            },
            {
                "District_Code": "174",
                "District_Name": "KANNAUJ"
            },
            {
                "District_Code": "175",
                "District_Name": "KANPUR DEHAT"
            },
            {
                "District_Code": "176",
                "District_Name": "KANPUR NAGAR"
            },
            {
                "District_Code": "177",
                "District_Name": "KAUSHAMBI"
            },
            {
                "District_Code": "178",
                "District_Name": "KUSHINAGAR"
            },
            {
                "District_Code": "179",
                "District_Name": "LAKHIMPUR KHERI"
            },
            {
                "District_Code": "180",
                "District_Name": "LALITPUR"
            },
            {
                "District_Code": "181",
                "District_Name": "LUCKNOW"
            },
            {
                "District_Code": "182",
                "District_Name": "MAHARAJGANJ"
            },
            {
                "District_Code": "183",
                "District_Name": "MAHOBA"
            },
            {
                "District_Code": "184",
                "District_Name": "MAINPURI"
            },
            {
                "District_Code": "185",
                "District_Name": "MATHURA"
            },
            {
                "District_Code": "186",
                "District_Name": "MAU"
            },
            {
                "District_Code": "187",
                "District_Name": "MEERUT"
            },
            {
                "District_Code": "188",
                "District_Name": "MIRZAPUR"
            },
            {
                "District_Code": "189",
                "District_Name": "MORADABAD"
            },
            {
                "District_Code": "190",
                "District_Name": "MUZAFFARNAGAR"
            },
            {
                "District_Code": "191",
                "District_Name": "PILIBHIT"
            },
            {
                "District_Code": "192",
                "District_Name": "PRATAPGARH"
            },
            {
                "District_Code": "193",
                "District_Name": "RAE BARELI"
            },
            {
                "District_Code": "194",
                "District_Name": "RAMPUR"
            },
            {
                "District_Code": "195",
                "District_Name": "SAHARANPUR"
            },
            {
                "District_Code": "196",
                "District_Name": "SANT KABIR NAGAR"
            },
            {
                "District_Code": "197",
                "District_Name": "SHAHJAHANPUR"
            },
            {
                "District_Code": "198",
                "District_Name": "SHRAVASTI"
            },
            {
                "District_Code": "199",
                "District_Name": "SIDDHARTHNAGAR"
            },
            {
                "District_Code": "200",
                "District_Name": "SITAPUR"
            },
            {
                "District_Code": "201",
                "District_Name": "SONBHADRA"
            },
            {
                "District_Code": "202",
                "District_Name": "SULTANPUR"
            },
            {
                "District_Code": "203",
                "District_Name": "UNNAO"
            },
            {
                "District_Code": "204",
                "District_Name": "VARANASI"
            },
            {
                "District_Code": "647",
                "District_Name": "PRAYAGRAJ"
            },
            {
                "District_Code": "771",
                "District_Name": "ALLAHABAD"
            }
        ]
    },
    {
        "State_Code": "30",
        "State_Name": "GOA",
        "DISTRICTS": [
            {
                "District_Code": "626",
                "District_Name": "NORTH GOA"
            },
            {
                "District_Code": "627",
                "District_Name": "SOUTH GOA"
            }
        ]
    },
    {
        "State_Code": "31",
        "State_Name": "LAKSHADWEEP",
        "DISTRICTS": [
            {
                "District_Code": "628",
                "District_Name": "ANJAW"
            },
            {
                "District_Code": "629",
                "District_Name": "ARWAL"
            },
            {
                "District_Code": "630",
                "District_Name": "Bajali"
            },
            {
                "District_Code": "631",
                "District_Name": "DIDIHAT"
            },
            {
                "District_Code": "632",
                "District_Name": "GAURELLA-PENDRA-MARWAHI"
            },
            {
                "District_Code": "633",
                "District_Name": "HANAMKONDA"
            },
            {
                "District_Code": "635",
                "District_Name": "KALABURAGI"
            },
            {
                "District_Code": "636",
                "District_Name": "KASGANJ"
            },
            {
                "District_Code": "637",
                "District_Name": "KOTDWAR"
            },
            {
                "District_Code": "638",
                "District_Name": "LAKSHADWEEP"
            },
            {
                "District_Code": "639",
                "District_Name": "LONGLENG"
            },
            {
                "District_Code": "640",
                "District_Name": "MAHABUBABAD"
            },
            {
                "District_Code": "641",
                "District_Name": "MALERKOTLA"
            },
            {
                "District_Code": "642",
                "District_Name": "MANENDRAGARH-CHIRMIRI-BHARATPUR"
            },
            {
                "District_Code": "643",
                "District_Name": "MOHLA MANPUR"
            },
            {
                "District_Code": "644",
                "District_Name": "NOKLAK"
            },
            {
                "District_Code": "645",
                "District_Name": "PAKYONG"
            },
            {
                "District_Code": "646",
                "District_Name": "PEREN"
            },
            {
                "District_Code": "648",
                "District_Name": "RANIKHET"
            },
            {
                "District_Code": "649",
                "District_Name": "SAHIBZADA AJIT SINGH NAGAR"
            },
            {
                "District_Code": "650",
                "District_Name": "SAMBHAL"
            },
            {
                "District_Code": "651",
                "District_Name": "SARANGARH-BILAIGARH"
            },
            {
                "District_Code": "652",
                "District_Name": "SERAIKELA"
            },
            {
                "District_Code": "653",
                "District_Name": "SHAHID BHAGAT SINGH NAGAR"
            },
            {
                "District_Code": "654",
                "District_Name": "SHAKTI"
            },
            {
                "District_Code": "655",
                "District_Name": "SHAMLI"
            },
            {
                "District_Code": "656",
                "District_Name": "SORENG"
            },
            {
                "District_Code": "657",
                "District_Name": "UPPER DIBANG VALLEY"
            },
            {
                "District_Code": "658",
                "District_Name": "VIJAYANAGARA"
            },
            {
                "District_Code": "659",
                "District_Name": "YAMUNOTRI"
            }
        ]
    },
    {
        "State_Code": "10",
        "State_Name": "BIHAR",
        "DISTRICTS": [
            {
                "District_Code": "205",
                "District_Name": "ARARIA"
            },
            {
                "District_Code": "206",
                "District_Name": "AURANGABAD"
            },
            {
                "District_Code": "207",
                "District_Name": "BANKA"
            },
            {
                "District_Code": "208",
                "District_Name": "BEGUSARAI"
            },
            {
                "District_Code": "209",
                "District_Name": "BHAGALPUR"
            },
            {
                "District_Code": "210",
                "District_Name": "BHOJPUR"
            },
            {
                "District_Code": "211",
                "District_Name": "BUXAR"
            },
            {
                "District_Code": "212",
                "District_Name": "DARBHANGA"
            },
            {
                "District_Code": "213",
                "District_Name": "EAST CHAMPARAN"
            },
            {
                "District_Code": "214",
                "District_Name": "GAYA"
            },
            {
                "District_Code": "215",
                "District_Name": "GOPALGANJ"
            },
            {
                "District_Code": "216",
                "District_Name": "JAMUI"
            },
            {
                "District_Code": "217",
                "District_Name": "JEHANABAD"
            },
            {
                "District_Code": "218",
                "District_Name": "KAIMUR"
            },
            {
                "District_Code": "219",
                "District_Name": "KATIHAR"
            },
            {
                "District_Code": "220",
                "District_Name": "KHAGARIA"
            },
            {
                "District_Code": "221",
                "District_Name": "KISHANGANJ"
            },
            {
                "District_Code": "222",
                "District_Name": "LAKHISARAI"
            },
            {
                "District_Code": "223",
                "District_Name": "MADHEPURA"
            },
            {
                "District_Code": "224",
                "District_Name": "MADHUBANI"
            },
            {
                "District_Code": "225",
                "District_Name": "MUNGER"
            },
            {
                "District_Code": "226",
                "District_Name": "MUZAFFARPUR"
            },
            {
                "District_Code": "227",
                "District_Name": "NALANDA"
            },
            {
                "District_Code": "228",
                "District_Name": "NAWADA"
            },
            {
                "District_Code": "229",
                "District_Name": "PATNA"
            },
            {
                "District_Code": "230",
                "District_Name": "PURNIA"
            },
            {
                "District_Code": "231",
                "District_Name": "ROHTAS"
            },
            {
                "District_Code": "232",
                "District_Name": "SAHARSA"
            },
            {
                "District_Code": "233",
                "District_Name": "SAMASTIPUR"
            },
            {
                "District_Code": "234",
                "District_Name": "SARAN"
            },
            {
                "District_Code": "235",
                "District_Name": "SHEIKHPURA"
            },
            {
                "District_Code": "236",
                "District_Name": "SHEOHAR"
            },
            {
                "District_Code": "237",
                "District_Name": "SITAMARHI"
            },
            {
                "District_Code": "238",
                "District_Name": "SIWAN"
            },
            {
                "District_Code": "239",
                "District_Name": "SUPAUL"
            },
            {
                "District_Code": "240",
                "District_Name": "VAISHALI"
            },
            {
                "District_Code": "241",
                "District_Name": "WEST CHAMPARAN"
            }
        ]
    },
    {
        "State_Code": "32",
        "State_Name": "KERALA",
        "DISTRICTS": [
            {
                "District_Code": "660",
                "District_Name": "ALAPPUZHA"
            },
            {
                "District_Code": "661",
                "District_Name": "ERNAKULAM"
            },
            {
                "District_Code": "662",
                "District_Name": "IDUKKI"
            },
            {
                "District_Code": "663",
                "District_Name": "KANNUR"
            },
            {
                "District_Code": "664",
                "District_Name": "KASARAGOD"
            },
            {
                "District_Code": "665",
                "District_Name": "KOLLAM"
            },
            {
                "District_Code": "666",
                "District_Name": "KOTTAYAM"
            },
            {
                "District_Code": "667",
                "District_Name": "KOZHIKODE"
            },
            {
                "District_Code": "668",
                "District_Name": "MALAPPURAM"
            },
            {
                "District_Code": "669",
                "District_Name": "PALAKKAD"
            },
            {
                "District_Code": "670",
                "District_Name": "PATHANAMTHITTA"
            },
            {
                "District_Code": "671",
                "District_Name": "THIRUVANANTHAPURAM"
            },
            {
                "District_Code": "672",
                "District_Name": "THRISSUR"
            },
            {
                "District_Code": "673",
                "District_Name": "WAYANAD"
            }
        ]
    },
    {
        "State_Code": "11",
        "State_Name": "SIKKIM",
        "DISTRICTS": [
            {
                "District_Code": "242",
                "District_Name": "EAST SIKKIM"
            },
            {
                "District_Code": "243",
                "District_Name": "NORTH SIKKIM"
            },
            {
                "District_Code": "244",
                "District_Name": "SOUTH SIKKIM"
            },
            {
                "District_Code": "245",
                "District_Name": "WEST SIKKIM"
            }
        ]
    },
    {
        "State_Code": "33",
        "State_Name": "TAMIL NADU",
        "DISTRICTS": [
            {
                "District_Code": "674",
                "District_Name": "ARIYALUR"
            },
            {
                "District_Code": "675",
                "District_Name": "CHENGALPATTU"
            },
            {
                "District_Code": "676",
                "District_Name": "CHENNAI"
            },
            {
                "District_Code": "677",
                "District_Name": "COIMBATORE"
            },
            {
                "District_Code": "678",
                "District_Name": "CUDDALORE"
            },
            {
                "District_Code": "679",
                "District_Name": "DHARMAPURI"
            },
            {
                "District_Code": "680",
                "District_Name": "DINDIGUL"
            },
            {
                "District_Code": "681",
                "District_Name": "ERODE"
            },
            {
                "District_Code": "682",
                "District_Name": "KALLAKURICHI"
            },
            {
                "District_Code": "683",
                "District_Name": "KANCHIPURAM"
            },
            {
                "District_Code": "684",
                "District_Name": "KANYAKUMARI"
            },
            {
                "District_Code": "685",
                "District_Name": "KARUR"
            },
            {
                "District_Code": "686",
                "District_Name": "KRISHNAGIRI"
            },
            {
                "District_Code": "687",
                "District_Name": "MADURAI"
            },
            {
                "District_Code": "688",
                "District_Name": "MAYILADUTHURAI"
            },
            {
                "District_Code": "689",
                "District_Name": "NAGAPATTINAM"
            },
            {
                "District_Code": "690",
                "District_Name": "NAMAKKAL"
            },
            {
                "District_Code": "691",
                "District_Name": "NILGIRIS"
            },
            {
                "District_Code": "692",
                "District_Name": "PERAMBALUR"
            },
            {
                "District_Code": "693",
                "District_Name": "PUDUKKOTTAI"
            },
            {
                "District_Code": "694",
                "District_Name": "RAMANATHAPURAM"
            },
            {
                "District_Code": "695",
                "District_Name": "RANIPET"
            },
            {
                "District_Code": "696",
                "District_Name": "SALEM"
            },
            {
                "District_Code": "697",
                "District_Name": "SIVAGANGA"
            },
            {
                "District_Code": "698",
                "District_Name": "TENKASI"
            },
            {
                "District_Code": "699",
                "District_Name": "THANJAVUR"
            },
            {
                "District_Code": "700",
                "District_Name": "THENI"
            },
            {
                "District_Code": "701",
                "District_Name": "THOOTHUKUDI"
            },
            {
                "District_Code": "702",
                "District_Name": "TIRUCHIRAPPALLI"
            },
            {
                "District_Code": "703",
                "District_Name": "TIRUNELVELI"
            },
            {
                "District_Code": "704",
                "District_Name": "TIRUPATTUR"
            },
            {
                "District_Code": "705",
                "District_Name": "TIRUPPUR"
            },
            {
                "District_Code": "706",
                "District_Name": "TIRUVALLUR"
            },
            {
                "District_Code": "707",
                "District_Name": "TIRUVANNAMALAI"
            },
            {
                "District_Code": "708",
                "District_Name": "TIRUVARUR"
            },
            {
                "District_Code": "709",
                "District_Name": "VELLORE"
            },
            {
                "District_Code": "710",
                "District_Name": "VILUPPURAM"
            },
            {
                "District_Code": "711",
                "District_Name": "VIRUDHUNAGAR"
            }
        ]
    },
    {
        "State_Code": "12",
        "State_Name": "ARUNACHAL PRADESH",
        "DISTRICTS": [
            {
                "District_Code": "246",
                "District_Name": "CHANGLANG"
            },
            {
                "District_Code": "247",
                "District_Name": "EAST KAMENG"
            },
            {
                "District_Code": "248",
                "District_Name": "EAST SIANG"
            },
            {
                "District_Code": "249",
                "District_Name": "KAMLE"
            },
            {
                "District_Code": "250",
                "District_Name": "KRA DAADI"
            },
            {
                "District_Code": "251",
                "District_Name": "KURUNG KUMEY"
            },
            {
                "District_Code": "252",
                "District_Name": "LEPA RADA"
            },
            {
                "District_Code": "253",
                "District_Name": "LOHIT"
            },
            {
                "District_Code": "254",
                "District_Name": "LONGDING"
            },
            {
                "District_Code": "255",
                "District_Name": "LOWER DIBANG VALLEY"
            },
            {
                "District_Code": "256",
                "District_Name": "LOWER SIANG"
            },
            {
                "District_Code": "257",
                "District_Name": "LOWER SUBANSIRI"
            },
            {
                "District_Code": "258",
                "District_Name": "NAMSAI"
            },
            {
                "District_Code": "259",
                "District_Name": "PAKKE KESSANG"
            },
            {
                "District_Code": "260",
                "District_Name": "PAPUM PARE"
            },
            {
                "District_Code": "261",
                "District_Name": "SHI YOMI"
            },
            {
                "District_Code": "262",
                "District_Name": "SIANG"
            },
            {
                "District_Code": "263",
                "District_Name": "TAWANG"
            },
            {
                "District_Code": "264",
                "District_Name": "TIRAP"
            },
            {
                "District_Code": "265",
                "District_Name": "UPPER SIANG"
            },
            {
                "District_Code": "266",
                "District_Name": "UPPER SUBANSIRI"
            },
            {
                "District_Code": "267",
                "District_Name": "WEST KAMENG"
            },
            {
                "District_Code": "268",
                "District_Name": "WEST SIANG"
            }
        ]
    },
    {
        "State_Code": "34",
        "State_Name": "PONDICHERRY",
        "DISTRICTS": [
            {
                "District_Code": "712",
                "District_Name": "KARAIKAL"
            },
            {
                "District_Code": "713",
                "District_Name": "MAHE"
            },
            {
                "District_Code": "714",
                "District_Name": "PUDUCHERRY"
            },
            {
                "District_Code": "715",
                "District_Name": "YANAM"
            }
        ]
    },
    {
        "State_Code": "13",
        "State_Name": "NAGALAND",
        "DISTRICTS": [
            {
                "District_Code": "269",
                "District_Name": "DIMAPUR"
            },
            {
                "District_Code": "270",
                "District_Name": "KIPHIRE"
            },
            {
                "District_Code": "271",
                "District_Name": "KOHIMA"
            },
            {
                "District_Code": "272",
                "District_Name": "MOKOKCHUNG"
            },
            {
                "District_Code": "273",
                "District_Name": "MON"
            },
            {
                "District_Code": "274",
                "District_Name": "PHEK"
            },
            {
                "District_Code": "275",
                "District_Name": "TUENSANG"
            },
            {
                "District_Code": "276",
                "District_Name": "WOKHA"
            },
            {
                "District_Code": "277",
                "District_Name": "ZUNHEBOTO"
            },
            {
                "District_Code": "760",
                "District_Name": "CHUMUKEDIMA"
            },
            {
                "District_Code": "761",
                "District_Name": "NIULAND"
            },
            {
                "District_Code": "762",
                "District_Name": "TSEMINYU"
            }
        ]
    },
    {
        "State_Code": "35",
        "State_Name": "ANDAMAN NICOBAR ISLANDS",
        "DISTRICTS": [
            {
                "District_Code": "716",
                "District_Name": "NICOBAR"
            },
            {
                "District_Code": "717",
                "District_Name": "NORTH  & MIDDLE ANDAMAN"
            },
            {
                "District_Code": "718",
                "District_Name": "SOUTH ANDAMAN"
            }
        ]
    },
    {
        "State_Code": "14",
        "State_Name": "MANIPUR",
        "DISTRICTS": [
            {
                "District_Code": "278",
                "District_Name": "BISHNUPUR"
            },
            {
                "District_Code": "279",
                "District_Name": "CHANDEL"
            },
            {
                "District_Code": "280",
                "District_Name": "CHURACHANDPUR"
            },
            {
                "District_Code": "281",
                "District_Name": "IMPHAL EAST"
            },
            {
                "District_Code": "282",
                "District_Name": "IMPHAL WEST"
            },
            {
                "District_Code": "283",
                "District_Name": "JIRIBAM"
            },
            {
                "District_Code": "284",
                "District_Name": "KAKCHING"
            },
            {
                "District_Code": "285",
                "District_Name": "KAMJONG"
            },
            {
                "District_Code": "286",
                "District_Name": "KANGPOKPI"
            },
            {
                "District_Code": "287",
                "District_Name": "NONEY"
            },
            {
                "District_Code": "288",
                "District_Name": "PHERZAWL"
            },
            {
                "District_Code": "289",
                "District_Name": "SENAPATI"
            },
            {
                "District_Code": "290",
                "District_Name": "TAMENGLONG"
            },
            {
                "District_Code": "291",
                "District_Name": "TENGNOUPAL"
            },
            {
                "District_Code": "292",
                "District_Name": "THOUBAL"
            },
            {
                "District_Code": "293",
                "District_Name": "UKHRUL"
            }
        ]
    },
    {
        "State_Code": "36",
        "State_Name": "TELANGANA",
        "DISTRICTS": [
            {
                "District_Code": "719",
                "District_Name": "ADILABAD"
            },
            {
                "District_Code": "720",
                "District_Name": "BHADRADRI KOTHAGUDEM"
            },
            {
                "District_Code": "721",
                "District_Name": "HYDERABAD"
            },
            {
                "District_Code": "722",
                "District_Name": "JAGTIAL"
            },
            {
                "District_Code": "723",
                "District_Name": "JANGAON"
            },
            {
                "District_Code": "724",
                "District_Name": "JAYASHANKAR BHUPALPALLY"
            },
            {
                "District_Code": "725",
                "District_Name": "JOGULAMBA GADWAL"
            },
            {
                "District_Code": "726",
                "District_Name": "KAMAREDDY"
            },
            {
                "District_Code": "727",
                "District_Name": "KARIMNAGAR"
            },
            {
                "District_Code": "728",
                "District_Name": "KHAMMAM"
            },
            {
                "District_Code": "729",
                "District_Name": "KUMURAM BHEEM ASIFABAD"
            },
            {
                "District_Code": "730",
                "District_Name": "MAHBUBNAGAR"
            },
            {
                "District_Code": "731",
                "District_Name": "MANCHERIAL"
            },
            {
                "District_Code": "732",
                "District_Name": "MEDAK"
            },
            {
                "District_Code": "733",
                "District_Name": "MEDCHAL MALKAJGIRI"
            },
            {
                "District_Code": "734",
                "District_Name": "MULUGU"
            },
            {
                "District_Code": "735",
                "District_Name": "NAGARKURNOOL"
            },
            {
                "District_Code": "736",
                "District_Name": "NALGONDA"
            },
            {
                "District_Code": "737",
                "District_Name": "NARAYANPET"
            },
            {
                "District_Code": "738",
                "District_Name": "NIRMAL"
            },
            {
                "District_Code": "739",
                "District_Name": "NIZAMABAD"
            },
            {
                "District_Code": "740",
                "District_Name": "PEDDAPALLI"
            },
            {
                "District_Code": "741",
                "District_Name": "RAJANNA SIRCILLA"
            },
            {
                "District_Code": "742",
                "District_Name": "RANGA REDDY"
            },
            {
                "District_Code": "743",
                "District_Name": "SANGAREDDY"
            },
            {
                "District_Code": "744",
                "District_Name": "SIDDIPET"
            },
            {
                "District_Code": "745",
                "District_Name": "SURYAPET"
            },
            {
                "District_Code": "746",
                "District_Name": "VIKARABAD"
            },
            {
                "District_Code": "747",
                "District_Name": "WANAPARTHY"
            },
            {
                "District_Code": "748",
                "District_Name": "WARANGAL"
            },
            {
                "District_Code": "749",
                "District_Name": "YADADRI BHUVANAGIRI"
            }
        ]
    },
    {
        "State_Code": "15",
        "State_Name": "MIZORAM",
        "DISTRICTS": [
            {
                "District_Code": "294",
                "District_Name": "AIZAWL"
            },
            {
                "District_Code": "295",
                "District_Name": "CHAMPHAI"
            },
            {
                "District_Code": "296",
                "District_Name": "HNAHTHIAL"
            },
            {
                "District_Code": "297",
                "District_Name": "KHAWZAWL"
            },
            {
                "District_Code": "298",
                "District_Name": "KOLASIB"
            },
            {
                "District_Code": "299",
                "District_Name": "LAWNGTLAI"
            },
            {
                "District_Code": "300",
                "District_Name": "LUNGLEI"
            },
            {
                "District_Code": "301",
                "District_Name": "MAMIT"
            },
            {
                "District_Code": "302",
                "District_Name": "SAIHA"
            },
            {
                "District_Code": "303",
                "District_Name": "SAITUAL"
            },
            {
                "District_Code": "304",
                "District_Name": "SERCHHIP"
            }
        ]
    },
    {
        "State_Code": "16",
        "State_Name": "TRIPURA",
        "DISTRICTS": [
            {
                "District_Code": "305",
                "District_Name": "DHALAI"
            },
            {
                "District_Code": "306",
                "District_Name": "GOMATI"
            },
            {
                "District_Code": "307",
                "District_Name": "KHOWAI"
            },
            {
                "District_Code": "308",
                "District_Name": "NORTH TRIPURA"
            },
            {
                "District_Code": "309",
                "District_Name": "SEPAHIJALA"
            },
            {
                "District_Code": "310",
                "District_Name": "SOUTH TRIPURA"
            },
            {
                "District_Code": "311",
                "District_Name": "UNOKOTI"
            },
            {
                "District_Code": "312",
                "District_Name": "WEST TRIPURA"
            },
            {
                "District_Code": "766",
                "District_Name": "UNAKOTI"
            }
        ]
    },
    {
        "State_Code": "38",
        "State_Name": "LADAKH",
        "DISTRICTS": [
            {
                "District_Code": "750",
                "District_Name": "KARGIL"
            },
            {
                "District_Code": "751",
                "District_Name": "LEH"
            }
        ]
    },
    {
        "State_Code": "17",
        "State_Name": "MEGHALAYA",
        "DISTRICTS": [
            {
                "District_Code": "313",
                "District_Name": "EAST GARO HILLS"
            },
            {
                "District_Code": "314",
                "District_Name": "EAST JAINTIA HILLS"
            },
            {
                "District_Code": "315",
                "District_Name": "EAST KHASI HILLS"
            },
            {
                "District_Code": "316",
                "District_Name": "NORTH GARO HILLS"
            },
            {
                "District_Code": "317",
                "District_Name": "RI BHOI"
            },
            {
                "District_Code": "318",
                "District_Name": "SOUTH GARO HILLS"
            },
            {
                "District_Code": "319",
                "District_Name": "SOUTH WEST GARO HILLS"
            },
            {
                "District_Code": "320",
                "District_Name": "SOUTH WEST KHASI HILLS"
            },
            {
                "District_Code": "321",
                "District_Name": "WEST GARO HILLS"
            },
            {
                "District_Code": "322",
                "District_Name": "WEST JAINTIA HILLS"
            },
            {
                "District_Code": "323",
                "District_Name": "WEST KHASI HILLS"
            },
            {
                "District_Code": "759",
                "District_Name": "MAIRANG"
            }
        ]
    },
    {
        "State_Code": "18",
        "State_Name": "ASSAM",
        "DISTRICTS": [
            {
                "District_Code": "324",
                "District_Name": "BAKSA"
            },
            {
                "District_Code": "325",
                "District_Name": "BARPETA"
            },
            {
                "District_Code": "326",
                "District_Name": "BISWANATH"
            },
            {
                "District_Code": "327",
                "District_Name": "BONGAIGAON"
            },
            {
                "District_Code": "328",
                "District_Name": "CACHAR"
            },
            {
                "District_Code": "329",
                "District_Name": "CHARAIDEO"
            },
            {
                "District_Code": "330",
                "District_Name": "CHIRANG"
            },
            {
                "District_Code": "331",
                "District_Name": "DARRANG"
            },
            {
                "District_Code": "332",
                "District_Name": "DHEMAJI"
            },
            {
                "District_Code": "333",
                "District_Name": "DHUBRI"
            },
            {
                "District_Code": "334",
                "District_Name": "DIBRUGARH"
            },
            {
                "District_Code": "335",
                "District_Name": "DIMA HASAO"
            },
            {
                "District_Code": "336",
                "District_Name": "GOALPARA"
            },
            {
                "District_Code": "337",
                "District_Name": "GOLAGHAT"
            },
            {
                "District_Code": "338",
                "District_Name": "HAILAKANDI"
            },
            {
                "District_Code": "339",
                "District_Name": "HOJAI"
            },
            {
                "District_Code": "340",
                "District_Name": "JORHAT"
            },
            {
                "District_Code": "341",
                "District_Name": "KAMRUP"
            },
            {
                "District_Code": "342",
                "District_Name": "KAMRUP METROPOLITAN"
            },
            {
                "District_Code": "343",
                "District_Name": "KARBI ANGLONG"
            },
            {
                "District_Code": "344",
                "District_Name": "KARIMGANJ"
            },
            {
                "District_Code": "345",
                "District_Name": "KOKRAJHAR"
            },
            {
                "District_Code": "346",
                "District_Name": "LAKHIMPUR"
            },
            {
                "District_Code": "347",
                "District_Name": "MAJULI"
            },
            {
                "District_Code": "348",
                "District_Name": "MORIGAON"
            },
            {
                "District_Code": "349",
                "District_Name": "NAGAON"
            },
            {
                "District_Code": "350",
                "District_Name": "NALBARI"
            },
            {
                "District_Code": "351",
                "District_Name": "SIVASAGAR"
            },
            {
                "District_Code": "352",
                "District_Name": "SONITPUR"
            },
            {
                "District_Code": "353",
                "District_Name": "SOUTH SALMARA MANKACHAR"
            },
            {
                "District_Code": "354",
                "District_Name": "TINSUKIA"
            },
            {
                "District_Code": "355",
                "District_Name": "UDALGURI"
            },
            {
                "District_Code": "356",
                "District_Name": "WEST KARBI ANGLONG"
            }
        ]
    },
    {
        "State_Code": "19",
        "State_Name": "WEST BENGAL",
        "DISTRICTS": [
            {
                "District_Code": "357",
                "District_Name": "ALIPURDUAR"
            },
            {
                "District_Code": "358",
                "District_Name": "BANKURA"
            },
            {
                "District_Code": "359",
                "District_Name": "BIRBHUM"
            },
            {
                "District_Code": "360",
                "District_Name": "COOCH BEHAR"
            },
            {
                "District_Code": "361",
                "District_Name": "DAKSHIN DINAJPUR"
            },
            {
                "District_Code": "362",
                "District_Name": "DARJEELING"
            },
            {
                "District_Code": "363",
                "District_Name": "HOOGHLY"
            },
            {
                "District_Code": "364",
                "District_Name": "HOWRAH"
            },
            {
                "District_Code": "365",
                "District_Name": "JALPAIGURI"
            },
            {
                "District_Code": "366",
                "District_Name": "JHARGRAM"
            },
            {
                "District_Code": "367",
                "District_Name": "KALIMPONG"
            },
            {
                "District_Code": "368",
                "District_Name": "KOLKATA"
            },
            {
                "District_Code": "369",
                "District_Name": "MALDAH"
            },
            {
                "District_Code": "370",
                "District_Name": "MURSHIDABAD"
            },
            {
                "District_Code": "371",
                "District_Name": "NADIA"
            },
            {
                "District_Code": "372",
                "District_Name": "NORTH 24 PARGANAS"
            },
            {
                "District_Code": "373",
                "District_Name": "PASCHIM BARDHAMAN"
            },
            {
                "District_Code": "374",
                "District_Name": "PASCHIM MEDINIPUR"
            },
            {
                "District_Code": "375",
                "District_Name": "PURBA BARDHAMAN"
            },
            {
                "District_Code": "376",
                "District_Name": "PURBA MEDINIPUR"
            },
            {
                "District_Code": "377",
                "District_Name": "PURULIA"
            },
            {
                "District_Code": "378",
                "District_Name": "SOUTH 24 PARGANAS"
            },
            {
                "District_Code": "379",
                "District_Name": "UTTAR DINAJPUR"
            }
        ]
    },
    {
        "State_Code": "20",
        "State_Name": "JHARKHAND",
        "DISTRICTS": [
            {
                "District_Code": "380",
                "District_Name": "BOKARO"
            },
            {
                "District_Code": "381",
                "District_Name": "CHATRA"
            },
            {
                "District_Code": "382",
                "District_Name": "DEOGHAR"
            },
            {
                "District_Code": "383",
                "District_Name": "DHANBAD"
            },
            {
                "District_Code": "384",
                "District_Name": "DUMKA"
            },
            {
                "District_Code": "385",
                "District_Name": "EAST SINGHBHUM"
            },
            {
                "District_Code": "386",
                "District_Name": "GARHWA"
            },
            {
                "District_Code": "387",
                "District_Name": "GIRIDIH"
            },
            {
                "District_Code": "388",
                "District_Name": "GODDA"
            },
            {
                "District_Code": "389",
                "District_Name": "GUMLA"
            },
            {
                "District_Code": "390",
                "District_Name": "HAZARIBAG"
            },
            {
                "District_Code": "391",
                "District_Name": "JAMTARA"
            },
            {
                "District_Code": "392",
                "District_Name": "KHUNTI"
            },
            {
                "District_Code": "393",
                "District_Name": "KODERMA"
            },
            {
                "District_Code": "394",
                "District_Name": "LATEHAR"
            },
            {
                "District_Code": "395",
                "District_Name": "LOHARDAGA"
            },
            {
                "District_Code": "396",
                "District_Name": "PAKUR"
            },
            {
                "District_Code": "397",
                "District_Name": "PALAMU"
            },
            {
                "District_Code": "398",
                "District_Name": "RAMGARH"
            },
            {
                "District_Code": "399",
                "District_Name": "RANCHI"
            },
            {
                "District_Code": "400",
                "District_Name": "SAHIBGANJ"
            },
            {
                "District_Code": "401",
                "District_Name": "SERAIKELA-KHARSAWAN"
            },
            {
                "District_Code": "402",
                "District_Name": "SIMDEGA"
            },
            {
                "District_Code": "403",
                "District_Name": "WEST SINGHBHUM"
            }
        ]
    },
    {
        "State_Code": "21",
        "State_Name": "ORISSA",
        "DISTRICTS": [
            {
                "District_Code": "404",
                "District_Name": "ANGUL"
            },
            {
                "District_Code": "405",
                "District_Name": "BALANGIR"
            },
            {
                "District_Code": "406",
                "District_Name": "BALASORE"
            },
            {
                "District_Code": "407",
                "District_Name": "BARGARH (BARAGARH)"
            },
            {
                "District_Code": "408",
                "District_Name": "BHADRAK"
            },
            {
                "District_Code": "409",
                "District_Name": "BOUDH (BAUDA)"
            },
            {
                "District_Code": "410",
                "District_Name": "CUTTACK"
            },
            {
                "District_Code": "411",
                "District_Name": "DEBAGARH (DEOGARH)"
            },
            {
                "District_Code": "412",
                "District_Name": "DHENKANAL"
            },
            {
                "District_Code": "413",
                "District_Name": "GAJAPATI"
            },
            {
                "District_Code": "414",
                "District_Name": "GANJAM"
            },
            {
                "District_Code": "415",
                "District_Name": "JAGATSINGHPUR"
            },
            {
                "District_Code": "416",
                "District_Name": "JAJPUR"
            },
            {
                "District_Code": "417",
                "District_Name": "JHARSUGUDA"
            },
            {
                "District_Code": "418",
                "District_Name": "KALAHANDI"
            },
            {
                "District_Code": "419",
                "District_Name": "KANDHAMAL"
            },
            {
                "District_Code": "420",
                "District_Name": "KENDRAPARA"
            },
            {
                "District_Code": "421",
                "District_Name": "KENDUJHAR"
            },
            {
                "District_Code": "422",
                "District_Name": "KHORDHA"
            },
            {
                "District_Code": "423",
                "District_Name": "KORAPUT"
            },
            {
                "District_Code": "424",
                "District_Name": "MALKANGIRI"
            },
            {
                "District_Code": "425",
                "District_Name": "MAYURBHANJ"
            },
            {
                "District_Code": "426",
                "District_Name": "NABARANGAPUR"
            },
            {
                "District_Code": "427",
                "District_Name": "NAYAGARH"
            },
            {
                "District_Code": "428",
                "District_Name": "NUAPADA"
            },
            {
                "District_Code": "429",
                "District_Name": "PURI"
            },
            {
                "District_Code": "430",
                "District_Name": "RAYAGADA"
            },
            {
                "District_Code": "431",
                "District_Name": "SAMBALPUR"
            },
            {
                "District_Code": "432",
                "District_Name": "SUBARNAPUR (SONEPUR)"
            },
            {
                "District_Code": "433",
                "District_Name": "SUNDARGARH"
            }
        ]
    }
]}

export default STATE