import React from "react";
import { checkValidEMAILFormat } from "../../../utils/utils";
import TextFieldInput from "../TextFieldInput";

const EmailInput = ({
	values,
	errors,
	setErrors,
	name,
	label,
	hintText,
	setFieldValue,
	disabled
}) => {
	const inputChangeHandler = (e) => {
		let value = e.target.value;
    	if(value?.includes(' ')) return;
    	setFieldValue(value);
		if (checkValidEMAILFormat(value) && errors?.[name]) {
			setErrors({ ...errors, [name]: null });
		}
	};

	const onBlurHandler = (e) => {
		let value = e.target.value;
		if (!checkValidEMAILFormat(value) && value) {
			setErrors({
				...errors,
				[name]: ["Please enter a valid Email ID"],
			});
		}
		else{
			setErrors({
				...errors,
				[name]: null,
			});
		}
	};

	return (
		<TextFieldInput
			errors={errors?.[name]}
			name={name}
			values={values}
			label={label}
			onBlur={onBlurHandler}
			hintText={hintText}
			onChange={inputChangeHandler}
			disabled={disabled}
		/>
	);
};

export default EmailInput;
