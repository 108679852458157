/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import SelectInput from "../../../components/CustomInput/SelectInput";
import MasterServices from "../../../services/masters";
import {
	OTP_GENERATION_FAILED_TRY_AGAIN,
	DEDUPE_ERROR,
	MAKE_LABEL,
	MODEL_LABEL,
	VARIANT_LABEL,
	JOURNEY_TYPE_3,
	JOURNEY_TYPE_1,
} from "../../../utils/cms";
import {
	structuringDataHandler,
	groupingFunction,
	groupsToOptionsGenerate,
} from "../../BasicDetailsPage/data";

export default function MakeModelVariant({
	isEnableMake = true,
	makeName = "makeCode",
	modelName = "modelCode",
	variantName = "variantCode",
	prefillData,
	values,
	setValues,
	errors,
	options,
	setLoading,
	setOptions,
	onVariantSelect,
	afterInputChangeCallback,
	errorOptions,
	setErrorOptions,
	groups,
	setGroups,
	journeyType,
	setDealerLoading,
	setOrp,
	setDealerValue,
	setDealerOptions,
}) {
	const MAKE = "makeCode";
	const MODEL = "modelCode";
	const VARIANT = "variantCode";
	const mmvType = `${journeyType === JOURNEY_TYPE_3 ? "TW" : "PV"}`;

	const MmvApiHandlerCallback = (success, response, type) => {
		setLoading(false);
		if (success) {
			let [key, makeOptions, groupOfMakes] = structuringDataHandler(
				response[0]?.records,
				type,
				journeyType === JOURNEY_TYPE_1
			);
			setGroups({ ...groups, [key]: groupOfMakes });
			setOptions({ ...options, [key]: makeOptions });
		} else {
			setErrorOptions({
				...errorOptions,
				openErrorModal: true,
				errorContent: response?.respDesc || DEDUPE_ERROR,
				errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
			});
		}
	};

	useEffect(() => {
		if (isEnableMake || isEnableMake == "") {
			setValues({
				...values,
				[MAKE]: { code: "", value: "" },
				[MODEL]: { code: "", value: "" },
				[VARIANT]: { code: "", value: "" },
			});
			if (!options?.[MAKE]) {
				setLoading(true);
				MasterServices.getMasterList(
					MmvApiHandlerCallback,
					mmvType,
					mmvType
				);
			} else {
				setOptions({
					...options,
					[MODEL]: [],
					[VARIANT]: [],
				});
			}
		}
	}, [isEnableMake]);

	const fetchVehicleDetailsOptions = async () => {
		let optionRoutes = [mmvType];
		try {
			const res = await Promise.all(
				optionRoutes?.map((each) =>
					MasterServices.getMasterListReturn(each)
				)
			);
			return res;
		} catch {
		}
	};

	async function PrefillDataOptions() {
		let optionsUpdate = {};
		let groupsUpdate = {};
		let options = await fetchVehicleDetailsOptions();
		options?.forEach((each, i) => {
			if (i == 0 && each?.[0]?.records) {
				let [key, makeOptions, groupOfMakes] =
					structuringDataHandler(each[0]?.records, mmvType, journeyType === JOURNEY_TYPE_1);
				groupsUpdate[key] = groupOfMakes;
				optionsUpdate[key] = makeOptions;

				if (prefillData?.makeCode) {
					let selectedMake = groupOfMakes[prefillData?.makeCode];
					let groupOfModels = groupingFunction(
						selectedMake,
						MODEL
					);
					groupsUpdate[MODEL] = groupOfModels;
					let [key, groupOptions] = groupsToOptionsGenerate(
						groupOfModels,
						MODEL
					);
					optionsUpdate[key] = groupOptions;
				}

				if (prefillData?.[MAKE] && prefillData?.[MODEL]) {
					let selectedModel =
						groupsUpdate?.modelCode?.[prefillData?.modelCode];
					let groupOfVariants = groupingFunction(
						selectedModel,
						VARIANT
					);
					groupsUpdate[VARIANT] = groupOfVariants;
					let [key, groupOptions] = groupsToOptionsGenerate(
						groupOfVariants,
						VARIANT
					);
					optionsUpdate[key] = groupOptions;
				}
			}
			setOptions(optionsUpdate);
			setGroups(groupsUpdate);
		});

		let update = {};

		let makeOption = optionsUpdate.makeCode?.find(
			(each) => each.code == prefillData?.makeCode
		);
		update.makeCode = makeOption;

		let modelOption = optionsUpdate.modelCode?.find(
			(each) => each.code == prefillData?.modelCode
		);
		update.modelCode = modelOption;

		let variantOption = optionsUpdate.variantCode?.find(
			(each) => each.code == prefillData?.variantCode
		);
		update.variantCode = variantOption;
		if (!variantOption) {
			// setErrorOptions({
			// 	...errorOptions,
			// 	openErrorModal: true,
			// 	errorContent: DEDUPE_ERROR,
			// 	errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
			// });
			setDealerLoading(false);
		}
		setValues({ ...values, ...update });
		setLoading(false);
	}

	useEffect(() => {
		if (
			prefillData.makeCode &&
			prefillData.modelCode &&
			prefillData.variantCode
		) {
			setLoading(true);
			PrefillDataOptions();
		}
	}, [prefillData]);

	const onMakeSelect = (updatedValues, name, value) => {
		setValues({
			...updatedValues,
			[MAKE]: value,
			[MODEL]: null,
			[VARIANT]: null,
		});
		setOrp({});
		setDealerValue({});
		setDealerOptions([]);
		if (value?.code) {
			let selectedMake = groups?.makeCode?.[value?.code];
			let groupOfModels = groupingFunction(selectedMake, MODEL);
			setGroups({ ...groups, modelCode: groupOfModels });
			let [key, modelOptions] = groupsToOptionsGenerate(
				groupOfModels,
				MODEL
			);
			setOptions({ ...options, [key]: modelOptions, [VARIANT]: [] });
			return;
		}
		setOptions({ ...options, [MODEL]: [], [VARIANT]: [] });
	};

	const onModelSelect = (updatedValues, name, value) => {
		setValues({
			...updatedValues,
			[MODEL]: value,
			[VARIANT]: null,
		});
		setOrp({});
		setDealerValue({});
		setDealerOptions([]);
		if (value?.code) {
			let selectedModel = groups?.modelCode?.[value?.code];
			let groupOfVariants = groupingFunction(selectedModel, VARIANT);
			setGroups({ ...groups, variantCode: groupOfVariants });
			let [key, variantOptions] = groupsToOptionsGenerate(
				groupOfVariants,
				VARIANT
			);
			setOptions({ ...options, [key]: variantOptions });
			return;
		}
		setOptions({ ...options, [VARIANT]: [] });
	};

	return (
		<>
			<SelectInput
				name={makeName}
				label={MAKE_LABEL}
				values={values}
				setValues={setValues}
				errors={errors?.[makeName]}
				afterInputChangeCallback={
					afterInputChangeCallback || onMakeSelect
				}
				options={options?.[MAKE]}
				twHint="Eg: TVS"
				hint="Eg: Hyundai"
			/>
			<SelectInput
				name={modelName}
				label={MODEL_LABEL}
				values={values}
				setValues={setValues}
				errors={errors?.[modelName]}
				afterInputChangeCallback={
					afterInputChangeCallback || onModelSelect
				}
				options={options?.[MODEL]}
				twHint="Eg: Apache"
				hint="Eg: Grand i10"
			/>
			<SelectInput
				name={variantName}
				label={VARIANT_LABEL}
				values={values}
				setValues={setValues}
				errors={errors?.[variantName]}
				afterInputChangeCallback={
					afterInputChangeCallback || onVariantSelect
				}
				options={options?.[VARIANT]}
				twHint="Eg: RTR 160"
				hint="Eg: Nios"
			/>
		</>
	);
}
