/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CheckIcon from "@mui/icons-material/Check";
import {
  VERIFICATION_DETAILS_HEADING,
  SAVE_CONTINUE_ACTION,
  VERIFICATION_DETAILS_SUBHEADING_1,
  INFORMATION_TEXT,
  ADDRESS_CONFIRMATION_SUBTITLE,
  ADDRESS_CONFIRMATION_TITLE,
  ADDRESS_BE_DETAILS,
  OKAY_ACTION,
  CLOSE_ACTION,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  DEDUPE_ERROR,
  GO_BACK_ACTION,
  QUIT_ACTION,
  ERROR_TEXT,
  VERIFICATION_DETAILS_SUBHEADING_2,
  JOURNEY_TYPE_3,
} from "../../utils/cms";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import SalariedOfficeDetails from "./SalariedOfficeDetails";
import { useEffect, useState } from "react";
import ResidenceDetails from "./ResidenceDetails";
import DemographicDetails from "./DemographicDetails";
import SelfEmployedOfficeDetails from "./SelfEmployedOfficeDetails";
import VerifyIncome from "./VerifyIncome";
import ETBResidenceDetails from "./ETBResidenceDetails";
import ConfirmationModal from "../../components/ConfirmationDialogBox/index";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import handleApiRequest from "./handlerFunctions";
import step1Active from "../../assets/VerificationStepper/step-1-active.png";
import step2Active from "../../assets/VerificationStepper/step-2-active.png";
import step3Active from "../../assets/VerificationStepper/step-3-active.png";
import step4Active from "../../assets/VerificationStepper/step-4-active.png";
import step2Inactive from "../../assets/VerificationStepper/step-2-inactive.png";
import step3Inactive from "../../assets/VerificationStepper/step-3-inactive.png";
import step4Inactive from "../../assets/VerificationStepper/step-4-inactive.png";
import {
  getJourneyType,
  getVehicleType,
  phoneNumberFormat,
} from "../../utils/utils";
import { dropOffApiCall } from "../../utils/dropOff";
import MasterServices from "../../services/masters";
import AadharPage from "../AadharPage";
import { pageBasicDetails } from "../../store/pageDetails";
import { currentScreenDetails } from "../../utils/utils";

function VerificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step } = useParams();
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: {
      journeyType,
      residenceDetails,
      officeDetails,
      currentApplication,
      reKYC,
      preApprovedJourney,
      preapprovedMaster,
      dropOffScreenRoute
    },
    pageDetails
  } = storeValues || {};
  const [values, setValues] = useState({});
  const [responseData, setResponseData] = useState();
  const [modalOptions, setModalOptions] = useState({
    errorTitle: "",
    errorContent: "",
    errorSubContent: "",
    openErrorModal: false,
    secondaryAction: null,
    primaryAction: null,
    secondaryBtnText: "",
    primaryBtnText: "",
    callBackAction: null,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [prefill, setPrefill] = useState();
  const [prevNextBtnActive, setPrevNextBtnActive] = useState({
    next: false,
    prev: false,
  });
  function modalFunction(val) {
    setLoading(false);
    if (val === "continue") {
      setModalOptions({});
    } else if (val === "close") {
      setModalOptions({
        errorTitle: INFORMATION_TEXT,
        errorContent: ADDRESS_BE_DETAILS.replace(
          "#bename#",
          currentApplication?.beName ||
            preapprovedMaster?.assignedBeEmpName ||
            residenceDetails?.beName,
        ).replace(
          "#bemobile#",
          phoneNumberFormat(
            currentApplication?.beContactNumber ||
              preapprovedMaster?.assignedBeContactDtls ||
              residenceDetails?.beContactNumber,
          ),
        ),
        errorSubContent: "",
        openErrorModal: true,
        secondaryAction: false,
        primaryAction: true,
        secondaryBtnText: "",
        primaryBtnText: OKAY_ACTION,
        callBackAction: (val) => {
          if (val === "continue") {
            dropOffApiCall("Move to assisted");
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = "/";
            }, 0);
          }
        },
      });
    }
  }

  function callbackFunction(success, response, type) {
    setLoading(false);
    if (success) {
      if (response?.statusCode !== "200") {
        setModalOptions({
          errorTitle: ERROR_TEXT,
          errorContent: DEDUPE_ERROR,
          errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
          openErrorModal: true,
          secondaryAction: false,
          primaryAction: true,
          secondaryBtnText: "",
          primaryBtnText: CLOSE_ACTION,
          callBackAction: (val) => {
            setModalOptions({});
          },
        });
        return;
      }
      setValues({});
      if (type === "ETBResidenceAddress") {
        navigate("/verification/1");
      } else if (type === "ResidenceAddress") {
        if (values?.addressType === "noneAddress") {
        } else {
          navigate("/verification/1");
        }
      } else if (type === "SalOfficeAddress" || type === "officeAddress") {
        navigate("/verification/2");
      } else if (step == 2) {
        if (
          journeyType === JOURNEY_TYPE_3 ||
          preApprovedJourney ||
          sessionStorage.getItem("pt")
        ) {
          navigate("/roadmap/2");
        } else {
          navigate("/verification/3");
        }
      }
    } else {
      setModalOptions({
        errorTitle: ERROR_TEXT,
        errorContent: DEDUPE_ERROR,
        errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
        openErrorModal: true,
        secondaryAction: false,
        primaryAction: true,
        secondaryBtnText: "",
        primaryBtnText: CLOSE_ACTION,
        callBackAction: (val) => {
          setModalOptions({});
        },
      });
    }
  }
  const handleRedirect = () => {
    setLoading(true);

    if (step == "etbAddress") {
      let finalValue =
        values.currentAddresType && values.permanentAddresType ? {} : values;
      handleApiRequest(
        step,
        "ETBResidenceAddress",
        finalValue,
        responseData,
        callbackFunction,
      );
      return;
    }
    if (step == "ntbAddress" && values.addressType === "noneAddress") {
      setModalOptions({
        errorTitle: INFORMATION_TEXT,
        errorContent: ADDRESS_CONFIRMATION_TITLE,
        errorSubContent: ADDRESS_CONFIRMATION_SUBTITLE,
        openErrorModal: true,
        secondaryAction: true,
        primaryAction: true,
        secondaryBtnText: QUIT_ACTION,
        primaryBtnText: GO_BACK_ACTION,
        showItalicContent: true,
        type: "bold",
        callBackAction: modalFunction,
      });
    }
    if (
      step == "ntbAddress" &&
      (values.addressType === "bothAddress" ||
        ((values.addressType === "currentAddress" ||
          values.addressType === "permanentAddress") &&
          values.addressLine1 &&
          values.addressLine2 &&
          values.pinCode &&
          values.cityName &&
          values.districtName &&
          values.stateName))
    ) {
      handleApiRequest(
        step,
        "ResidenceAddress",
        values,
        responseData,
        callbackFunction,
      );
    }
    if (step == 1) {
      handleApiRequest(
        step,
        officeDetails?.occupationCode === "SAL"
          ? "SalOfficeAddress"
          : "officeAddress",
        values,
        officeDetails,
        callbackFunction,
      );
    }
    if (step == 2) {
      handleApiRequest(
        step,
        "DemographicDetails",
        values,
        responseData,
        callbackFunction,
      );
    }
  };

  const CustomStepIcon = (props) => {
    const stepIcons = {
      1: props.completed ? (
        <CheckIcon />
      ) : (
        <img
          src={props.active ? step1Active : step2Inactive}
          alt="Journey-Logo"
          className="journey-logo"
        />
      ),
      2: props.completed ? (
        <CheckIcon />
      ) : (
        <img
          src={props.active ? step2Active : step2Inactive}
          alt="Journey-Logo"
          className="journey-logo"
        />
      ),
      3: props.completed ? (
        <CheckIcon />
      ) : (
        <img
          src={props.active ? step3Active : step3Inactive}
          alt="Journey-Logo"
          className="journey-logo"
        />
      ),
      4: props.completed ? (
        <CheckIcon />
      ) : (
        <img
          src={props.active ? step4Active : step4Inactive}
          alt="Journey-Logo"
          className="journey-logo"
        />
      ),
    };
    return <div className="stepper_svg">{stepIcons[String(props.icon)]}</div>;
  };

  const pageLoadCallbackFunction = (success, response, type) => {
    setLoading(false);
    if (success === true) {
      if (type === "getaddressinfo") {
        setResponseData(response);
        if (step == 0) {
          dispatch(pageBasicDetails({ residenceDetails: response }));
          if(dropOffScreenRoute) {
            navigate("/verification/etbAddress");
            return;
          }
          if (response?.rekyc && !response?.addressFreeze) {
            navigate("/verification/ntbAddress");
          } else if (
            response?.address?.permanentAddress?.pinCode &&
            response?.address?.currentAddress?.pinCode
          ) {
            navigate("/verification/etbAddress");
          } else {
            navigate("/verification/ntbAddress");
          }
        } else if (step == 1) {
          if (
            (response?.occupationCode === "SAL" || response?.occupationCode === "SENP" 
            || response?.occupationCode === "SEP") &&
            response?.address?.officeAddress?.pinCode &&
            (response?.address?.officeAddress?.cityCode ||
              response?.address?.officeAddress?.cityName) &&
            (response?.address?.officeAddress?.stateCode ||
              response?.address?.officeAddress?.stateName) &&
            (response?.address?.officeAddress?.districtCode ||
              response?.address?.officeAddress?.districtName)
          ) {
            setLoading(true);
            let addressType = '';
            if (response?.address?.officeAddress.pinCode?.length === 6 || response?.address?.officeAddress.pinCode?.toString().length === 6) {
              if(response?.businessIsCurrentAddress) {
                addressType = "current_address";
              } else if(response?.businessIsPermanentAddress) {
                addressType = "permanent_address";
              } else if(!response?.businessIsPermanentAddress && !response?.businessIsCurrentAddress) {
                addressType = "different_address";
              }              
            }
            setValues({
              employerName:
                response?.address?.officeAddress?.employerBusinessName,
              addressLine1: response?.address?.officeAddress?.addressLine1,
              addressLine2: response?.address?.officeAddress?.addressLine2,
              pinCode: response?.address?.officeAddress?.pinCode,
              businessName: response?.address?.officeAddress?.employerBusinessName,
              cityName: { code:  response?.address?.officeAddress?.cityCode, value: response?.address?.officeAddress?.cityName },
              districtName : { code:  response?.address?.officeAddress?.districtCode, value: response?.address?.officeAddress?.districtName },
              stateName : { code:  response?.address?.officeAddress?.stateCode, value: response?.address?.officeAddress?.stateName },
              addressType: addressType
            });
            setPrefill({
              cityCode:
                response?.address?.officeAddress?.cityCode ||
                response?.address?.officeAddress?.cityName,
              stateCode:
                response?.address?.officeAddress?.stateCode ||
                response?.address?.officeAddress?.stateName,
              districtCode:
                response?.address?.officeAddress?.districtCode ||
                response?.address?.officeAddress?.districtName,
              stateName: response?.address?.officeAddress?.stateName,
              districtName: response?.address?.officeAddress?.districtName,
            });
          }
          dispatch(pageBasicDetails({ officeDetails: response }));
        }
      }
    } else {
      if (step == 0 && type === "getaddressinfo") {
        getAddressInfo();
        return;
      }
      setModalOptions({
        errorTitle: ERROR_TEXT,
        errorContent: DEDUPE_ERROR,
        errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
        openErrorModal: true,
        primaryAction: true,
        primaryBtnText: CLOSE_ACTION,
        callBackAction: () => {
          if (step == 0 && type === "getaddressinfo") getAddressInfo();
          setModalOptions({});
        },
      });
    }
  };

  const getAddressInfo = () => {
    setLoading(true);
    const payload = {
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      vehicleType: getVehicleType(journeyType),
      journeyType: getJourneyType(journeyType),
      screenID: step == 0 ? "15" : "16",
      ...(step == 0 ? { reKYC: reKYC ? true : false } : {}),
    };
    setTimeout(() => {
      MasterServices.getVerificationDetails(
        pageLoadCallbackFunction,
        "getaddressinfo",
        "getaddressinfo",
        payload,
      );
    }, 1000);
  };

  useEffect(() => {
    if (step == 0 || step == 1) {
      getAddressInfo();
    }
    const [,,route] = currentScreenDetails();
    if (journeyType == JOURNEY_TYPE_3) {
       console.log(route);
      switch (route) {
        case "verification/0":
        case "verification/etbAddress":
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{
              prev: false,
              next: dropOffScreenRoute ? true : false
            },
          }));
          break;
        case "verification/1":
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{
              prev: true,
              next: dropOffScreenRoute ? dropOffScreenRoute !== route : false
            },
          }));
          break;
        case "verification/2":
          console.log('verifi 2')
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{ prev: true, next: false },
          }));
          break;
        default:
          break;
      }
    }
  }, [step]);

  if (step === "aadharVerify") {
    return (
      <>
        <Loader isLoading={loading} />
        <AadharPage
          journeyTitle={false}
          title={VERIFICATION_DETAILS_HEADING}
          subtitle={VERIFICATION_DETAILS_SUBHEADING_1}
          topSlider={40}
          activeStep={0}
          showStepper={true}
          CustomStepIcon={CustomStepIcon}
          journeyType={journeyType}
          showLoader={setLoading}
        />
      </>
    );
  } else {
    return (
      <div className="page-container verification-page">
        <Loader isLoading={loading} />
        <Header
          journeyTitle=""
          title={VERIFICATION_DETAILS_HEADING}
          subtitle={
            journeyType === JOURNEY_TYPE_3 || preApprovedJourney
              ? VERIFICATION_DETAILS_SUBHEADING_2
              : VERIFICATION_DETAILS_SUBHEADING_1
          }
          topSlider={40}
        />
        <div className="top-round-card-header"></div>
        <div className="page-body-content">
          <div className="page-body-contents">
            <div className="basic_details_stepper">
              <Stepper
                activeStep={
                  step
                    ? step === "etbAddress" || step === "ntbAddress"
                      ? 0
                      : parseInt(step)
                    : 0
                }
                alternativeLabel
              >
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                </Step>
                {journeyType !== JOURNEY_TYPE_3 && !preApprovedJourney && (
                  <Step>
                    <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                  </Step>
                )}
              </Stepper>
            </div>
            {step == "etbAddress" ? (
              <ETBResidenceDetails
                values={values}
                setValues={setValues}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                setLoading={setLoading}
                modalOptions={modalOptions}
                setModalOptions={setModalOptions}
                responseData={responseData}
                setResponseData={setResponseData}
                disable = {dropOffScreenRoute ? true : false}
              />
            ) : step == "ntbAddress" ? (
              <ResidenceDetails
                values={values}
                setValues={setValues}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                setLoading={setLoading}
                modalOptions={modalOptions}
                setModalOptions={setModalOptions}
                responseData={responseData}
                setResponseData={setResponseData}
                disabled = {prevNextBtnActive.next}
              />
            ) : (
              <></>
            )}
            {step == 1 &&
              (officeDetails?.occupationCode === "SAL" ? (
                <SalariedOfficeDetails
                  values={values}
                  setValues={setValues}
                  setIsDisabled={setIsDisabled}
                  isDisabled={isDisabled}
                  setLoading={setLoading}
                  modalOptions={modalOptions}
                  setModalOptions={setModalOptions}
                  prefill={prefill}
                  disabled = {prevNextBtnActive.next}
                />
              ) : (officeDetails?.occupationCode === "SEP" || officeDetails?.occupationCode === "SENP") ? (
                <SelfEmployedOfficeDetails
                  values={values}
                  setValues={setValues}
                  setIsDisabled={setIsDisabled}
                  isDisabled={isDisabled}
                  setLoading={setLoading}
                  modalOptions={modalOptions}
                  setModalOptions={setModalOptions}
                  disabled = {prevNextBtnActive.next}
                />
              ) : (
                <></>
              ))}
            {step == 2 && (
              <DemographicDetails
                values={values}
                setValues={setValues}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                setLoading={setLoading}
                modalOptions={modalOptions}
                setModalOptions={setModalOptions}
              />
            )}
            {journeyType !== JOURNEY_TYPE_3 && step == 3 && (
              <VerifyIncome
                values={values}
                setValues={setValues}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                setLoading={setLoading}
                modalOptions={modalOptions}
                setModalOptions={setModalOptions}
              />
            )}
          </div>
          <Footer
            prevNextBtnActive={prevNextBtnActive}
            callBackAction={handleRedirect}
            disableButton={isDisabled}
            buttonText={SAVE_CONTINUE_ACTION}
            removeBtn={step == 3 || prevNextBtnActive.next}
          />
        </div>
        <ConfirmationModal
          errorTitle={modalOptions.errorTitle}
          errorContent={modalOptions.errorContent}
          errorSubContent={modalOptions.errorSubContent}
          openErrorModal={modalOptions.openErrorModal}
          secondaryAction={modalOptions.secondaryAction}
          primaryAction={modalOptions.primaryAction}
          secondaryBtnText={modalOptions.secondaryBtnText}
          primaryBtnText={modalOptions.primaryBtnText}
          callBackAction={modalOptions.callBackAction}
          showItalicContent={modalOptions.showItalicContent}
          hideLoader={true}
          className={modalOptions.type ? "bold" : ""}
        />
      </div>
    );
  }
}

export default VerificationPage;
