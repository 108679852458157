import {
  SHOWROOM_PRICE_LABEL,
  DEALER_DISCOUNT_LABEL,
  RTO_CHARGES_LABEL,
  ESTIMATED_ORP_LABEL,
  DEALER_QUOTED_ORP_LABEL,
} from "../../utils/cms";
export const dealerVehicleDetailsInputs = [
  {
    label: SHOWROOM_PRICE_LABEL,
    inputType: "text",
    name: "showroomPrice",
    disabled: true,
    formatter: "currency",
  },
  {
    label: DEALER_DISCOUNT_LABEL,
    name: "dealerDiscount",
    inputType: "text",
    disabled: true,
    formatter: "currency",
  },
  {
    label: RTO_CHARGES_LABEL,
    inputType: "text",
    name: "rtoCharges",
    disabled: true,
    formatter: "currency",
  },
  {
    label: ESTIMATED_ORP_LABEL,
    inputType: "text",
    name: "estimatedOnRoadPrice",
    disabled: true,
    formatter: "currency",
    hintText: 'Estimated on-road price (ORP) includes charges such as FASTag, Road safety tax & other related expenses.',
  },
  {
    label: DEALER_QUOTED_ORP_LABEL,
    name: "dealerOnRoadPrice",
    inputType: "text",
    disabled: true,
    formatter: "currency",
  },
];
