import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    loading: false,
    snackbar: null,
    completedScreens: []
}

const updateCompletedScreensAction = (state, action) => { 
    state.completedScreens = action.payload
} 
const removeCompletedScreenAction = (state, action) => { 
    if (state.completedScreens?.includes(action.payload)) {
    let filter = state?.completedScreens?.filter(each => (each !== action.payload && each !== ""));
    state.completedScreens = filter 
} }

const openSnackbarAction = (state, action) => {
    state.snackbar = action.payload;
}
const addCompletedScreenAction = (state, action) => {
    if (!state.completedScreens?.includes(action.payload))
        state.completedScreens?.push(action.payload)
}
const clearCompletedScreenAction = (state, action) => {
    state.completedScreens = []
}


const toolsSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        openSnackbar: openSnackbarAction,
        addCompletedScreen: addCompletedScreenAction,
        clearCompletedScreen: clearCompletedScreenAction,
        removeCompletedScreen: removeCompletedScreenAction,
        updateCompletedScreens : updateCompletedScreensAction
    }
})

export const openToastMessage = (payload) => {
    return (dispatch, getState) => {
        dispatch(toolsSlice.actions.openSnackbar(payload))
        setTimeout(() => {
            dispatch(toolsSlice.actions.openSnackbar(null))
        }, 5000);
    }
}

export const generateToastMessage = (success, response) => {
    return (dispatch, getState) => {
        let payload = {
            severity: 'error',
            message: response.respDesc
        }
        if (success) payload.severity = "success"
        dispatch(openToastMessage(payload))
    }
}

export const { addCompletedScreen , clearCompletedScreen, removeCompletedScreen, updateCompletedScreens } = toolsSlice.actions;

export default toolsSlice.reducer