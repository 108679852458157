import React,{ useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { PAN_CARD_NUMBER, EMAIL_ID_NUMBER, PAN_CARD_NUMBER_TEXT, EMAIL_ID_NUMBER_TEXT, WELCOME_NAME, NEXT_ACTION, PAN_ERROR_TEXT, PAN_ERROR_SUB_TEXT, EMAIL_ERROR_TEXT, EMAIL_ERROR_SUB_TEXT, ERROR_TEXT, RESEND_OTP_ERROR_TEXT, DEDUPE_ERROR, OTP_GENERATION_FAILED_TRY_AGAIN, RETRY_ACTION, CONTACT_BRANCH_ERROR, PAN_ERROR_TEXT1 } from '../../utils/cms';
import { checkValidPANFormat, checkValidEMAILFormat, sortByDescending } from '../../utils/utils'
import { userBasicDetails } from '../../store/userDetails';
import ErrorModal from '../../components/ErrorModal';
import Loader from "../../components/Loader/Loader";
import PanInput from '../../components/CustomInput/PanInput/PanInput';
import EmailInput from '../../components/CustomInput/EmailInput/EmailInput';
import LoginServices from '../../services/login';
import { pageBasicDetails } from '../../store/pageDetails';
import { Encryption } from '../../utils/payload';

const PanCard= () => {
  const storeValues = useSelector(state => state);
  const { userDetails, pageDetails } = storeValues || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pan, setPan] = useState();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errors, setError] = useState([]);
  const [clearCurrentSession, setClearCurrentSession] = useState(false);
  const [retryDedupe, setRetryDedupe] = useState(false);
  
  const callBackFunc = (success, response) => {
    if (success) {
        setLoading(false);
        setDisableButton(true);
        if (response?.emailStatus?.status !== 'valid' && response?.panStatus == null && response?.dashBoardInfoList == null) {
          setOpenErrorModal(true);
          setErrorMessage(EMAIL_ERROR_TEXT);
          setErrorContent(EMAIL_ERROR_SUB_TEXT)
          return;
        }
        if (response?.panStatus?.status !== 'E' && response?.dashBoardInfoList == null) {
          setOpenErrorModal(true);
          setErrorMessage(PAN_ERROR_TEXT);
          setErrorContent(PAN_ERROR_SUB_TEXT)
          return;
        }
        if (response?.panStatus?.aadhaarSeedingStatus === 'R' && response?.dashBoardInfoList == null) {
          setOpenErrorModal(true);
          setErrorMessage(PAN_ERROR_TEXT1);
          setErrorContent(' ');
          setClearCurrentSession(true)
          return;
        }        
        if (response?.emailStatus?.status !== 'valid' && response?.dashBoardInfoList == null) {
          setOpenErrorModal(true);
          setErrorMessage(EMAIL_ERROR_TEXT);
          setErrorContent(EMAIL_ERROR_SUB_TEXT)
          return;
        }
        if (response?.dedupeStatus?.journeyStop) {
          setOpenErrorModal(true);
          setErrorMessage(CONTACT_BRANCH_ERROR);
          setErrorContent('');
          setClearCurrentSession(true)
          return;
        }
        if (response?.journeyStop) {
          setRetryDedupe(true);
          setOpenErrorModal(true);
          setErrorMessage(DEDUPE_ERROR);
          setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
          return;
        }
        let currentApplication = {
          d2CApplicantID: response?.d2CApplicantID,
          sfapplicantId: response?.sfapplicantId,
          loanApplicationId: response?.loanApplicationId,
          leadId: response?.leadId + '_B' + response?.sfapplicantId,
          documentId: response?.documentId,
          customerType: (response?.dedupeStatus?.isETB) ? "ETB" : ((response?.dedupeStatus?.isNTB) ? "NTB" : ""),
          beContactNumber: response?.beContactNumber || "",
          referenceNumber: response?.referenceNumber
        }
        if (response?.dashBoardInfoList?.length) {
          let sorterdDashboardInfo = sortByDescending(response?.dashBoardInfoList)
          response.dashBoardInfoList = sorterdDashboardInfo
        }
        dispatch(pageBasicDetails({dedupeResponse:response, currentApplication}));
        if (response?.dashBoardInfoList != null && response?.dashBoardInfoList?.length > 0 && !pageDetails?.startNewApplication) {
          navigate('/vehicleLoanDashboard');
        } else if (response?.dedupeStatus?.isNTB) {
          if (response?.dedupeStatus?.reKYCNeeded) {
            navigate('/aadhar');
          } else {
            navigate('/roadmap/0');
          }
        } else if (response?.dedupeStatus?.isETB) {
          if (response?.dashBoardInfoList?.length > 0) {
            navigate('/vehicleLoanDashboard');
          } else if (response?.reKYCNeeded) {
            navigate('/aadhar');
          } else {
            navigate('/roadmap/0');
          }
        }
    } else {
      setOpenErrorModal(true);
      setRetryDedupe(true);
      if (response?.statusCode === "500") {
        setErrorMessage(response?.respDesc);
        return;
      }
      if (!response?.response?.data) {
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(RESEND_OTP_ERROR_TEXT);
      }
    }      
  }

  const handleClick = () => {
    setRetryDedupe(false);
    setDisableButton(false);
    setLoading(true);
    dispatch(userBasicDetails({email: email}));
    dispatch(pageBasicDetails({ encryptedPerfios: Encryption(pan?.toUpperCase()) }));
    LoginServices.dedupeVerification(callBackFunc);
  }

  useEffect(() => {
    if(checkValidPANFormat(pan) && checkValidEMAILFormat(email)) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [pan, email])

  useEffect(() => {
    if (!userDetails?.jwt) {
      navigate('/')
    } else if (pageDetails?.dedupeResponse?.dashBoardInfoList != null && pageDetails?.dedupeResponse?.dashBoardInfoList?.length > 0 && !pageDetails?.startNewApplication) {
      navigate('/vehicleLoanDashboard');
    } else if (pageDetails?.dedupeResponse?.d2CApplicantID && pageDetails?.dedupeResponse?.dedupeStatus?.isNTB) {
      if (pageDetails?.dedupeResponse?.dedupeStatus?.reKYCNeeded) {
        navigate('/aadhar');
      } else {
        navigate('/roadmap/0');
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="page-container">
      <Loader isLoading={loading} interval={5000} newProgress={retryDedupe} />
      <Header
        journeyTitle={pageDetails?.journeyType}
        title={WELCOME_NAME +', '+ (userDetails?.userName?.split(' ')?.[0]) + '!'}
        phoneTitle={userDetails?.mobileNumber}
      />
      <div className="top-round-card-header"></div>
      <div className="page-body-content">
        <div className="page-body-contents">
            <PanInput
              label={PAN_CARD_NUMBER}
              values={{pan: pan}}
              name="pan"
              setFieldValue={(val) => setPan(val)}
              setErrors={setError}
              errors={errors}
              hintText={PAN_CARD_NUMBER_TEXT}
            />
            <EmailInput
              label={EMAIL_ID_NUMBER}
              values={{email: email}}
              name="email"
              setFieldValue={(val) => setEmail(val)}
              setErrors={setError}
              errors={errors}
              hintText={EMAIL_ID_NUMBER_TEXT}
            />
        </div>
        <Footer disableButton={!disableButton} buttonText={NEXT_ACTION} callBackAction={() => {handleClick()}} apiTriggered={loading} />
      </div>
      {errorMessage && <ErrorModal errorTitle={ERROR_TEXT} errorContent={errorMessage} errorSubContent={errorContent} openErrorModal={openErrorModal} closeErrorModal={() => {
        setOpenErrorModal(false);
        setLoading(false);
        setDisableButton(true);
        if (retryDedupe) {
          setClearCurrentSession(true);
          if(!clearCurrentSession) handleClick();
        }
      }} clearCurrentSession={clearCurrentSession} buttonText={!clearCurrentSession ? RETRY_ACTION : ''} />}
    </div>
  )
}

export default PanCard;