/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable eqeqeq*/
import { useEffect, useState, } from "react";
import { RadioButton, DetailsCont } from "./childComponents";
import TextFieldInput from "../../../components/CustomInput/TextFieldInput";
import { useDispatch, useSelector } from "react-redux";
import { AVERAGE_BANK_BALANCE, generateCurrentMonthYear, generateLast6MonthYear, ONLINE, PERFIOUSTYPE, PERFIOUS_URL_FETCH_ROUTE, salaryAccountContent, SALARY_CREDITED_TO_BANK, selfEmployedAccountContent } from "./data";
import AssistanceCard from '../../../components/AssistanceCard/AssistanceCard'
import "./index.css";
import masterServices from '../../../services/masters'
import { pageBasicDetails } from "../../../store/pageDetails";
import Loader from "../../../components/Loader/Loader";
import VerificationTerms from "./verificationTerms/VerificationTerms";
import { checkValidGstNumer, getJourneyType, getVehicleType, removeInvaildCharFromPANField } from "../../../utils/utils";
import ErrorModal from "../../../components/ErrorModal";
import { ERROR_TEXT, RETRY_ACTION, SYSTEM_ERROR1 } from "../../../utils/cms";
import { nipApiHandler } from "../../PerfiosRedirectPage/nipApiHandler";
import { useNavigate } from "react-router-dom";
import { Decryption } from "../../../utils/payload";

const VerifyIncome = () => {
  const [showTerms, setShowTerms] = useState(false)
  const [showSection, setShowSection] = useState([]);
  const [gstNumber, setGstNumber] = useState("");
  const [disableGstProceed, setDisableGstProceed] = useState(true)
  const [radioActive, setRadioActive] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState()
  const [retryCount, setRetryCount] = useState(0);
  const [retryType, setRetryType] = useState(null)
  const [openErrorModal, setOpenErrorModal] = useState(false);


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: { residenceDetails, initialoffer, currentApplication, perfiosOnload, encryptedPerfios, perfios }, userDetails
  } = storeValues || {};


  useEffect(() => {
    if (perfiosOnload?.occupationCode === 'SAL') {
      setShowSection(salaryAccountContent);
    } else {
      setShowSection(selfEmployedAccountContent);
    }
  }, [perfiosOnload]);

  const perfiosOnloadCb = (success, data) => {
    setIsLoading(false)
    if (success) {
      setRetryCount(0)
      setRetryType(null)
      dispatch(pageBasicDetails({ perfiosOnload: data }));
    }
    else {
      setOpenErrorModal(true)
      setRetryType("onload")
    }
  }

  let perfiosOnloadFetch = () => {
    setIsLoading(true)
    const payload = {
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      vehicleType: currentApplication?.vehicleType || (storeValues?.pageDetails?.journeyType === "Two Wheeler Loan" ? "H" : "C"),
      journeyType: currentApplication?.journeyType || getJourneyType(storeValues?.pageDetails?.journeyType),
      screenID: "18"
    }
    masterServices.getVerificationDetails(perfiosOnloadCb, 'getincomeinfo', '', payload)
  }

  useEffect(() => {
    perfiosOnloadFetch()
  }, [])




  const handleRadioClick = (radioId) => {
    setRadioActive(radioId)
  };

  const cb = (success, data) => {
    setIsLoading(false)
    if (success && data.status != 400) {
      let expires = data?.expires?.split(" ")
      expires?.pop();
      expires = expires?.join(" ")
      const expireDate = new Date(expires)
      const currentDate = new Date();
      if (expireDate > currentDate || !expires) {
        if (!data.url) {
          setOpenErrorModal(true)
        }
        data.radioActive = radioActive;
        data.gstNumber = gstNumber;
        data.type = type;
        data.leadId = currentApplication?.leadId || residenceDetails?.leadId || ""
        dispatch(pageBasicDetails({ [PERFIOUSTYPE]: data }));
        localStorage.setItem('restrictDropOff', 'yes');
        setTimeout(() => { window.open(data.url, "_self") }, 300)
      } else {
        setOpenErrorModal(true)
      }
    }
    else {
      setOpenErrorModal(true)
    }
  }

  const perfiousClickHandler = (type) => {
    setShowTerms(true);
    setType(type)
  }

  const perfiousApiHandler = () => {
    setRetryCount(state => state + 1)
    setShowTerms(false);
    setIsLoading(true)
    let payload = {
      leadId: currentApplication?.leadId || residenceDetails?.leadId || "",
      emailId: userDetails?.email || "",
      MobileNumber: userDetails?.mobileNumber || "",
      employmentType: perfiosOnload?.occupationCode,
      employerName: perfiosOnload?.employerName,
      loanDuration: perfiosOnload?.tenure || initialoffer?.tenure || "",
      loanAmount: perfiosOnload?.amount || initialoffer?.amount || "",
      dob: perfiosOnload?.dob || "",
      pan: JSON.parse(Decryption(encryptedPerfios || "") || "{}"),
      yearMonthFrom: generateLast6MonthYear(),
      yearMonthTo: generateCurrentMonthYear(),
      returnUrl: `${window.location.origin}/perfios-verification`,
      verificationType: radioActive,
      D2CApplicantID: currentApplication?.d2CApplicantID || residenceDetails?.D2CApplicantID || "",
      gstNumber: gstNumber || '',
      type: `${radioActive?.toUpperCase()}${type}`,
      loanType: "Vehicle",
      acceptancePolicy: "atLeastOneTransactionInRange",
      destination: type,
      productType: "AutoLoan",
      unit: "CFD",
      subUnit: "AutoLoan",
      isSMSRequired: "No",
      makerId: "0",
      source: "IndusMobile",
    }
    if (radioActive === AVERAGE_BANK_BALANCE || radioActive == SALARY_CREDITED_TO_BANK) {
      payload.destination = type === ONLINE ? "netbankingFetch" : "statement"
    }
    masterServices.getVerificationDetails(cb, `${radioActive}/${PERFIOUS_URL_FETCH_ROUTE}`, PERFIOUSTYPE, payload)
  }


  const gstChangeHandler = (e) => {
    let value = e.target.value;
    value = value?.toUpperCase()
    value = removeInvaildCharFromPANField(value)
    if (value.length > 15) return
    if (checkValidGstNumer(value))
      setDisableGstProceed(false)
    else
      setDisableGstProceed(true)
    setGstNumber(value)
  }

  const nipActionHandler = (nip) => {
    setIsLoading(true)
    let payload = {
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      vehicleType: currentApplication?.vehicleType || getVehicleType(storeValues?.pageDetails?.journeyType),
      journeyType: currentApplication?.journeyType || getJourneyType(storeValues?.pageDetails?.journeyType),
      screenID: "18",
      nip: nip, // true / false
      flagValue: perfios?.radioActive?.toUpperCase() || radioActive?.toUpperCase() || '',
      gstNumber: perfios?.gstNumber?.toUpperCase() || gstNumber?.toUpperCase() || '',
      yearMonthFrom: generateLast6MonthYear(),
      yearMonthTo: generateCurrentMonthYear(),
      makerId: "0",
      leadId: currentApplication?.leadId || '',
      clientTransactionId: perfios?.clientTransactionId || '',
      perfiosTransactionId: perfios?.perfiosTransactionId || '',
      type: `${perfios?.radioActive?.toUpperCase() || radioActive?.toUpperCase()}${perfios?.type || type}` || '',
    };
    nipApiHandler(payload, navigate)
  };

  return (
    <div className={`verifyIncome `}>
      {openErrorModal && <ErrorModal
        errorTitle={ERROR_TEXT}
        errorContent={SYSTEM_ERROR1}
        openErrorModal={openErrorModal}
        closeErrorModal={() => {
          setOpenErrorModal(false)
          if (retryCount <= 1) {
            if (retryType == 'onload') {
              perfiosOnloadFetch()
            }
            else {
              perfiousApiHandler()
            }
          }
          else {
            nipActionHandler(true)
          }
        }}
        buttonText={RETRY_ACTION} />}
      <Loader isLoading={isLoading} />
      <div className={`${showTerms ? ' open-terms' : " close-terms"}`}>
        <div className="heading">Please E-Verify your Income
          {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
        </div>
        {showSection.map((section, i) => (
          <div className="section" key={i}>
            <RadioButton
              label={section.radioBtn.label}
              subLabel={section.radioBtn.subLabel}
              id={section.radioBtn.type}
              onClick={handleRadioClick}
              radioActive={radioActive}
            />
            <div className={"details_section" + (section.radioBtn.type === radioActive ? " active" : "")} >
              <div className={"input_field" + (section?.inputField ? "" : " disable")}>
                {section?.inputField && (
                  <TextFieldInput
                    label={section?.inputField.label}
                    name="gstNumber"
                    values={{ gstNumber }}
                    onChange={gstChangeHandler}
                  />
                )}
              </div>
              {section.detailsContainer.map((detail, i) => (
                <DetailsCont
                  title={detail.title}
                  heading={detail.heading}
                  subHeading={detail.subHeading}
                  key={i}
                  type={detail.type}
                  radioType={section.radioBtn.type}
                  radioActive={radioActive}
                  clickHandler={perfiousClickHandler}
                  disableProceed={disableGstProceed}
                />
              ))}
            </div>
          </div>
        ))}
        <AssistanceCard beDetails={{ beName: perfiosOnload?.executiveName || '', beContactNumber: perfiosOnload?.executiveContact || '' }} />
      </div>
      {showTerms && <div className={"income-terms-conditions-container show-terms"}>
        <div className="top-round-card-header"></div>
        <div className="page-body-content">
          <div className="page-body-contents">
            <VerificationTerms
              closeTersmAction={() => setShowTerms(false)}
              acceptTersmAction={perfiousApiHandler}
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default VerifyIncome;
