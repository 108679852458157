/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from "react";
import "./OtpInput.css";

const OtpInput = ({
  onChange = () => {},
  OTPLength = 6,
  autoWebOtp,
  verifyOTP,
}) => {
  const [otp, setOtp] = useState({
    otp: [],
    changed: {
      index: null,
      value: null,
    },
  });

  useEffect(() => {
    if (otp?.changed?.value === "") {
      if (otp?.changed?.index >= otp?.otp?.length - 1)
        document.getElementById(`otp-${otp?.changed?.index - 1}`)?.focus();
    } else if (otp?.changed?.value !== null)
      document.getElementById(`otp-${otp?.changed?.index + 1}`)?.focus();
  }, [otp.changed]);

  useEffect(() => {
    if (autoWebOtp) {
      setOtp({
        otp: autoWebOtp?.split(""),
        changed: {
          index: null,
          value: 0,
        },
      });
      onChange(autoWebOtp?.slice(0,6));
      setTimeout(() => {
        document.getElementById(`otp-5`)?.focus();
      }, 200);
    }
  }, [autoWebOtp]);

  const otpEnterHandler = (e, index) => {
    let i = index;
    let value = e.target.value;
    let update = otp?.otp;
    
    value = value ? value.replace(/\D/gi, "") : "";
    if (value?.length > 1) {
      value = value[value.length - 1];
    }
    if (update?.length > i) {
      if (value === "" && update?.length - 1 == index) {
        update.splice(i, 1);
      } else {
        update[i] = value;
      }
    } else if (update.length < OTPLength) {
      if (i > update?.length) {
        i = update?.length;
      }
      update.push(value);
    }

    setOtp({
      otp: [...update],
      changed: {
        index: i,
        value: value,
      },
    });
    onChange(update?.join(""));
  };

  let otpInputGenerate = (i) => {
    if (otp?.otp?.length - 1 < i) {
      return "";
    } else return otp?.otp[i];
  };
  const moveCursorAtEnd = (id) => {
    let otpBox = document.getElementById(id);
    const end = otpBox.value.length;
    otpBox.setSelectionRange(end, end);
    otpBox.focus();
  };

  return (
    <div className={`otp-root-cont `}>
      {Array(OTPLength)
        .fill(0)
        .map((_, i) => (
          <form
            autoComplete="off"
            style={{ display: "inline-block" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              onKeyDown={(e) => {
                let key = e.keyCode || e.charCode;
                if (key === 8 && otp?.otp?.length <= i && i != 0) {
                  setTimeout(() => {
                    document
                      .getElementById(`otp-${otp?.otp?.length - 1}`)
                      ?.focus();
                  }, 100);
                }
                if (i === 5 && e?.key === "Enter" && e?.target?.value) {
                  verifyOTP && verifyOTP();
                }
              }}
              key={`otp-${i}`}
              value={otpInputGenerate(i)}
              onChange={(e) => otpEnterHandler(e, i)}
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              id={`otp-${i}`}
              className="otp-box"
              autoFocus={i === 0 ? true : false}
              onClick={() => moveCursorAtEnd(`otp-${i}`)}
              disabled={otp?.length < i}
              autoComplete="off"
            />
          </form>
        ))}
    </div>
  );
};

export default OtpInput;
