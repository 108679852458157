import { Decryption, Encryption } from "../utils/payload";
import { isProdEnvironment, isUatEnvironment } from "../utils/utils";

export const loadState = () => {
  try {
    const serializedState = (isUatEnvironment || isProdEnvironment) ? Decryption(sessionStorage.getItem('state')) : sessionStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse((isUatEnvironment || isProdEnvironment) ? JSON.parse(serializedState) : serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = (isUatEnvironment || isProdEnvironment) ? Encryption(JSON.stringify(state)) : JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};