import React from "react";
import TextFieldInput from "../../../components/CustomInput/TextFieldInput";
import { LOAN_AMOUNT_SLIDER_LABEL } from "../../../utils/cms";
import SliderComponent from "../SliderComponent/SliderComponent";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { cursorPositionForCurrencyInput, getCurrencyByText, onlyNumbersAccept } from "../../../utils/utils";

const LoanAmountInput = ({
  values,
  setValues,
  interval = 1,
  setIsValuesChanged,
  errors,
  setErrors,
  min,
  max,
}) => {
  const currencyChangeHandler = (e) => {
    let value = e.target.value;
    let caret = e.target.selectionStart;
    let name = e.target.name;

    let numberData = parseInt(onlyNumbersAccept(value));
    if (numberData > parseInt(max)) {
      return;
    }
    if (errors?.loanAmount_text_field) {
      setErrors({ ...errors, loanAmount_text_field: null });
    }
    if (numberData % interval === 0) {
      setIsValuesChanged(true);
      let loanAmount_text_field = getCurrencyByText(`${numberData}`);
      let loanAmount = numberData / interval;
      e.target.value = loanAmount_text_field;
      cursorPositionForCurrencyInput(e, values?.[name], caret);
      setValues({ ...values, loanAmount_text_field, loanAmount });
    } else {
      let loanAmount_text_field = getCurrencyByText(`${numberData}`);
      let loanAmount = numberData / interval;
      e.target.value = loanAmount_text_field;
      cursorPositionForCurrencyInput(e, values?.[name], caret);
      setValues({ ...values, loanAmount_text_field, loanAmount });
    }
   
  };

  const onBlurCurrencyHandler = (e) => {
    let value = e.target.value;
    let numberData = parseInt(onlyNumbersAccept(value));
    if (parseInt(numberData) < parseInt(min) || !numberData) {
      let minAmountFormat = getCurrencyByText(`${min}`)
      setErrors({
        ...errors,
        loanAmount_text_field: [
          `Loan amount should be minimum of ${minAmountFormat}`,
        ],
      });
    }
  };

  const sliderOnchange = (val) => {
    let loanAmount_text_field = null;
    if (val) {
      loanAmount_text_field = getCurrencyByText(parseInt(val) + "");
    }
    if (errors?.loanAmount_text_field) {
      setErrors({ ...errors, loanAmount_text_field: null });
    }
    setIsValuesChanged(true);
    setValues({
      ...values,
      loanAmount: val,
      loanAmount_text_field: loanAmount_text_field ? loanAmount_text_field : 0,
    });
  };

  return (
    <div className="loan-slider-cont">
      <SliderComponent
        label={LOAN_AMOUNT_SLIDER_LABEL}
        max={max}
        min={min}
        rangeEndLabel={max}
        rangeStartLabel={min}
        values={values}
        step={1}
        setValues={setValues}
        name={"loanAmount"}
        errors={errors}
        setErrors={setErrors}
        onChange={sliderOnchange}
      />
      <div className="slider-relative-inp">
        <TextFieldInput
          errors={errors?.loanAmount_text_field}
          startAdornment={<CurrencyRupeeIcon />}
          formatter="currency"
          values={values}
          setValues={setValues}
          name="loanAmount_text_field"
          onChange={currencyChangeHandler}
          onBlur={onBlurCurrencyHandler}
        />
      </div>
    </div>
  );
};

export default LoanAmountInput;
