/*eslint-disable react-hooks/exhaustive-deps*/
import React,{useEffect,useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./index.css";
import SelectInput from "../../components/CustomInput/SelectInput";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import {
  INSURANCE_HEADING1,
  JOURNEY_TYPE_1,
  JOURNEY_TYPE_3,
  TWOWHEELER_FUND_TEXT,
  TWOWHEELER_LABEL,
} from "../../utils/cms";
import { changeValueToCurrency } from "../../utils/utils";
function InsuranceSection({
  fundedText,
  name,
  label,
  heading,
  subText,
  values,
  setValues,
  setSwitchValue,
  switchValue,
  handleInformation,
  options,
  disbleDropDown
}) {
  const [disabled, setDisabled] = useState(!values?.[name]?.code);
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: { journeyType },
  } = storeValues || {};
  const onChangeHandler = (e, value) => {
    setValues({ ...values, [name]: value });
    if (disabled) {
      setSwitchValue({
        ...switchValue,
        [name]: true,
      });
    } else {
      setDisabled(!disabled);
      setSwitchValue({
        ...switchValue,
        [name]: true,
      });
    }
  };
  useEffect(() => {
    if (values?.[name]?.code === "NA" || values?.[name]?.fundDisabled) {
      setDisabled(true);
    } else if (values?.[name]?.code) {
      setDisabled(false);
    }
  }, [values]);
  return (
    <Card className="insurance card">
      <CardContent>
        <div className="insurance-contant">
          <div className="insurance header-content">
            <div className="insurance-header">
              {journeyType === JOURNEY_TYPE_3 ? INSURANCE_HEADING1 : heading}
            </div>
            <InfoOutlinedIcon
              className="infoOutlinedIcon"
              onClick={() => handleInformation(name)}
            />
          </div>
          {journeyType !== JOURNEY_TYPE_3 && journeyType !== JOURNEY_TYPE_1 && (
            <div className="subtext">{subText}</div>
          )}
        </div>
        {disbleDropDown && options?.[0] !== undefined && options?.[0]?.grossPremium !== '0' && name === 'motorPremium' && (<p style={{textAlign:'left'}}><b>{options?.[0]?.insurer} - ₹ {changeValueToCurrency.format(options?.[0]?.grossPremium)}</b></p>)}
        {!disbleDropDown && <div className="select input-field">
          <SelectInput
            options={options}
            label={journeyType === JOURNEY_TYPE_3 ? TWOWHEELER_LABEL : label}
            setValues={setValues}
            values={values}
            name={name}
            onChange={onChangeHandler}
          />
          {values?.[name]?.grossPremium && values?.[name]?.grossPremium !== '0' && <p className="plan-premium">Premium - ₹ {changeValueToCurrency.format(values?.[name]?.grossPremium)}</p>}
        </div>}
        <div className="insurance bottom-content">
          <div
            className={"insurence-bottom-text" + (disabled ? " disabled" : "")}
          >
            <label>
              {journeyType === JOURNEY_TYPE_3
                ? TWOWHEELER_FUND_TEXT
                : fundedText}
            </label>
          </div>
          <div>
            <div
              className={`custom-switch  ${switchValue?.[name] ? "enable-on" : "enable-off"
                } ${disabled && " disable"}`}
              onClick={() => {
                !disabled &&
                  setSwitchValue({
                    ...switchValue,
                    [name]: !switchValue?.[name],
                  });
              }}
            >
              <div className="circle-slide"></div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default InsuranceSection;
