import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    userDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
     };
    },
  },
});

export default user.reducer

const { userDetails } = user.actions
export const userBasicDetails = (param) => async dispatch => {
  try {
    dispatch(userDetails(param));
  } catch (e) {
    return console.error(e.message);
  }
}
