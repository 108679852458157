import React from "react";
import "./TopHeaderNav.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png'

const TopHeaderNav = () => {
  const { pathname } = useLocation();
  let isToShowBack = pathname === "/name" || pathname === "/mobile";
  const navigate = useNavigate();

  return (
    <div className="desktop-header top-header-nav">
      {isToShowBack && (
        <div onClick={() => navigate(-1)} className="go-back">
          <ArrowBackIcon sx={{ width: "25px", height: "25px" }} />
        </div>
      )}
      {/* <span className={` ${isToShowBack ? "neg-left-margin" : ""}`}>
        <i>IndusInd Bank</i>
      </span> */}
      <div className="header-container">
        <img className="app-logo" src={logo} alt="Indusind-Logo" />
      </div>
    </div>
  );
};

export default TopHeaderNav;
