import { VIDEO_KYC_BODY_TEXT,
	VIDEO_KYC_BODY_TEXT1,
	VIDEO_KYC_BODY_TEXT2,
	VIDEO_KYC_BODY_TEXT3,
	VIDEO_KYC_BODY_TEXT4,
	VKYC_IN_PROGRESS_BODYTEXT,
	UNABLE_TO_COMPLETE_VKYC_BODYTEXT1,
	UNABLE_TO_COMPLETE_VKYC_BODYTEXT2,
	VKYC_REJECTED_BODYTEXT1,
	VKYC_REJECTED_BODYTEXT2,
	VKYC_IS_COMPLETED_BODYTEXT1,
	VKYC_IS_COMPLETED_BODYTEXT2,
} from "../../utils/cms";
import { phoneNumberFormat } from "../../utils/utils";
import { useSelector } from "react-redux";

export const VkycStart = () => {
	return (
		<>
			<div className="video-kyc-body-text">{VIDEO_KYC_BODY_TEXT}</div>
			<ol>
				<li>{VIDEO_KYC_BODY_TEXT1}</li>
				<li>{VIDEO_KYC_BODY_TEXT2}</li>
				<li>{VIDEO_KYC_BODY_TEXT3}</li>
				<li>{VIDEO_KYC_BODY_TEXT4}</li>
			</ol>
		</>
	);
};
export const VkycProgrss = () => {
	return (
			<div>{VKYC_IN_PROGRESS_BODYTEXT}</div>
	);
};
export const UnableTOCompleteVkyc = () => {
	return (
		<>
			<div className="video-kyc-body-text">
				{UNABLE_TO_COMPLETE_VKYC_BODYTEXT1}
			</div>
			<div>{UNABLE_TO_COMPLETE_VKYC_BODYTEXT2}</div>
		</>
	);
};

export const VkycRejected = ({ beDetails }) => {
	return (
		<>
			<div className="video-kyc-body-text">
				{VKYC_REJECTED_BODYTEXT1}
			</div>
			<div>
				{VKYC_REJECTED_BODYTEXT2.replace(
					"#bename#",
					beDetails?.beName
				).replace(
					"#bemobile#",
					phoneNumberFormat(beDetails?.contactNumber)
				)}
			</div>
		</>
	);
};
export const VkycCompleted = () => {
	const storeValues = useSelector((state) => state);
	const {userDetails: { userName }, pageDetails: { videoKycName }} = storeValues || {};

	return (
		<>
			<div className="video-kyc-body-text">
				{VKYC_IS_COMPLETED_BODYTEXT1}
				{videoKycName || userName}!
			</div>
			<div>{VKYC_IS_COMPLETED_BODYTEXT2}</div>
		</>
	);
};




export const VIDEO_KYC_INPROGRESS  = "inprogress"
export const VIDEO_KYC_UNABLE_COMPLETE  = "unablecomplete"
export const VIDEO_KYC_COMPLETED  = "completed"
export const VIDEO_KYC_REJECTED  = "rejected"

export const kycStatusToReducedStatus = (status) => {
	let reducedStatus = null;
	if (
	  status === "Open" ||
	  status === "AgentAssigned" ||
	  status === "Successful" ||
	  status === "AuditorReady" ||
	  status === "AuditorAssigned"
	) {
	  reducedStatus = VIDEO_KYC_INPROGRESS
	} else if (status === "Unable" || status === "Reopen") {
	  reducedStatus = VIDEO_KYC_UNABLE_COMPLETE
	} else if (status === "Rejected" || status === "NotApproved") {
	  reducedStatus = VIDEO_KYC_REJECTED
	} else if (status === "Approved") {
	  reducedStatus = VIDEO_KYC_COMPLETED
	}
	return reducedStatus
  };


  export const kycStatusToReducedStatusCustomer = (status) => {
	let reducedStatus = null;
	if (
	  status === "AgentAssigned" ||
	  status === "Successful" ||
	  status === "AuditorReady" ||
	  status === "AuditorAssigned"
	) {
	  reducedStatus = VIDEO_KYC_INPROGRESS
	} else if (status === "Unable" || status === "Reopen" || status === "Open") {
	  reducedStatus = VIDEO_KYC_UNABLE_COMPLETE
	} else if (status === "Rejected" || status === "NotApproved") {
	  reducedStatus = VIDEO_KYC_REJECTED
	} else if (status === "Approved") {
	  reducedStatus = VIDEO_KYC_COMPLETED
	}
	return reducedStatus
  };