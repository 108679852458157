import React from "react";
import {
	CURRENT_CHECKBOX_LABEL,
	PERMANENT_CHECKBOX_LABEL,
} from "../../../utils/cms";
import CheckboxInput from "../../../components/CustomInput/CheckboxInput";
import { validatingValueHandler } from "../../../utils/utils";

function AddressCard({
	HeadingAddress,
	values,
	setValues,
	addressData,
	afterInputChangeCallback,
	addressFreezed,
	disable
}) {
	return (
		<div className="address-contant">
			<div className="address-header"> {HeadingAddress} </div>
			<div className="address-contents">
				<div className="address-sec">
					<p>{validatingValueHandler(addressData?.addressLine1)}</p>
					<p>{validatingValueHandler(addressData?.addressLine2)}</p>
					<p>{validatingValueHandler(addressData?.cityName)}</p>
					<p>{validatingValueHandler(addressData?.districtName)}</p>
					<p>{validatingValueHandler(addressData?.stateName)}</p>
					<p>{validatingValueHandler(addressData?.pinCode)}</p>
				</div>
				<CheckboxInput
					name={
						HeadingAddress === "Current Address"
							? "currentAddresType"
							: "permanentAddresType"
					}
					label={
						HeadingAddress === "Current Address"
							? CURRENT_CHECKBOX_LABEL
							: PERMANENT_CHECKBOX_LABEL
					}
					values={values}
					setValues={setValues}
					disabled={
						addressFreezed || disable ? true : false
					}
					afterInputChangeCallback={afterInputChangeCallback}
				/>
			</div>
		</div>
	);
}

export default AddressCard;
