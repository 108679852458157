/*eslint-disable react-hooks/exhaustive-deps*/
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { useParams, useNavigate } from "react-router-dom";
import {
	VEHICLE_FINALIZATION_HEADING,
	SAVE_CONTINUE_ACTION,
	VEHICLE_FINALIZATION_SUBHEADING,
	VEHICLE_ASSET_DETAILS_HEADING,
	CONTINUE_ACTION,
	CLOSE_ACTION,
	JOURNEY_TYPE_2,
	VEHICLE_FINALIZATION_SUBHEADING_2,
	JOURNEY_TYPE_4,
	ERROR_TEXT,
	DEDUPE_ERROR,
	OTP_GENERATION_FAILED_TRY_AGAIN,
} from "../../utils/cms";
import "./index.css";
import DealerVehicle from "./DealerVehicle";
import VehicleValuation from "./VehicleValuation";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getJourneyType, getVehicleType } from "../../utils/utils";
import carActive from "../../assets/DealerVehicle/carActive.png";
import carInactive from "../../assets/DealerVehicle/carInactive.png";
import valuationActive from "../../assets/DealerVehicle/valuationActive.png";
import valuationInactive from "../../assets/DealerVehicle/valuationInactive.png";
import MasterServices from "../../services/masters";
import Loader from "../../components/Loader/Loader";
import ErrorModal from "../../components/ErrorModal";
import { pageBasicDetails } from "../../store/pageDetails";
import { dropOffApiCall } from "../../utils/dropOff";

function DealerVehicleDetails() {
	const [values, setValues] = useState({});
	const { step } = useParams();
	const [isDisabled, setIsDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [responseData, setResponseData] = useState({});
	const [errorOptions, setErrorOptions] = useState({
		openErrorModal: false,
		errorTitle: ERROR_TEXT,
		errorSubContent: "",
		errorContent: "",
		clearCurrentSession: false,
	});
	const storeValues = useSelector((state) => state);
	const {
		pageDetails: { journeyType, currentApplication, preApprovedJourney, finalVehicleDetails, aggregatorJourney },
	} = storeValues || {};
	const navigate = useNavigate();

	const payload = {
		D2CApplicantID: currentApplication?.d2CApplicantID || "",
		vehicleType: getVehicleType(journeyType),
		journeyType: getJourneyType(journeyType),
		screenID: step === "0" ? "20" : "21",
	};

	useEffect(() => {
		if (step === "0") {
			setValues({
				showroomPrice: "0",
				rtoCharges: "0",
				estimatedOnRoadPrice: '0',
				dealerDiscount: '0',
				dealerOnRoadPrice: '0'
			})
		} else if (step === "1") {
			setLoading(true);
			setValues({});
			setIsDisabled(true);
			MasterServices.getVerificationDetails(
				callBackFunc,
				"valuationstatus",
				"valuationstatus",
				payload
			);
		}
	}, [step]);

	const CustomStepIcon = (props) => {
		const stepIcons = {
			1: props.completed ?
				<CheckIcon />
				: <img
					src={props.active ? carActive : carInactive}
					alt="Journey-Logo"
					className="journey-logo"
				/>,
			2: props.completed ?
				<CheckIcon />
				: <img
					src={props.active ? valuationActive : valuationInactive}
					alt="Journey-Logo"
					className="journey-logo"
				/>,
		};
		return (
			<div className="stepper_svg">
				{stepIcons[String(props.icon)]}
			</div>
		);
	};

	const inrToNumber = (number) => {
		if (!number) return ''
		return number?.replaceAll(',', '')
	}

	const handleRedirect = () => {
		if (step === "0") {
			setLoading(true);
			const saveAndContinue = {
				d2cApplicantID: payload?.D2CApplicantID,
				productType: getVehicleType(journeyType),
				makeCode: values?.makeCode?.code,
				makeValue: values?.makeCode?.value,
				modelCode: values?.modelCode?.code,
				modelValue: values?.modelCode?.value,
				variantCode: values?.variantCode?.code,
				variantValue: values?.variantCode?.value,
				manufacturerCode: values.variantCode?.manufacturerCode || "",
				subCategory: values.variantCode?.subCategoryCode || "",
				dealerName: values?.dealerName?.value || '',
				vehicleRegNum: values?.vehicleRegistrationNumber?.toUpperCase() || '',
				benCode: values?.dealerName?.benCode || '',
				vehiclePinCode: values?.pin,
				basicPrice: inrToNumber(values?.showroomPrice) || '',
				rtoRoadTax: inrToNumber(values?.rtoCharges) || '',
				onRoadPrice: inrToNumber(values?.orp) || '',
				dealerQuotedRoadPrice: ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) ? "0" : inrToNumber(values?.dealerOnRoadPrice)) || '',
				otherCharges: inrToNumber(values?.otherCharges) || "",
				dealerDiscount: inrToNumber(values?.dealerDiscount) || '',
				onRoadPriceCarwale: inrToNumber(values?.estimatedOnRoadPrice) || "",
				gstAmount: inrToNumber(values?.gstAmount) || '',
				carWaleResponse: values?.carWaleResponse || '',
				beId: finalVehicleDetails?.beID || '',
				beContactNumber: finalVehicleDetails?.beContactNumber || '',
				beSfdcId: finalVehicleDetails?.beSFDCID || '',
				beName: finalVehicleDetails?.beName || '',
				dealerCode: values?.dealerName?.code || '',
				dealerType: values?.dealerName?.dealerType || '',
				dealerCategorization: values?.dealerName?.dealerCategorization || '',
				cityCode: values?.cityCode?.code?.split('-')[1] || '',
				cityValue: values?.cityCode?.value || '',
				isPreApproved: preApprovedJourney || false,
				sfLoanApplicationId: currentApplication?.loanApplicationId || "",
				sfLeadNumber: currentApplication?.leadId || "",
				isAggregator: aggregatorJourney || false
			};
			dispatch(pageBasicDetails({ manufactureCode: values?.variantCode?.manufacturerCode || '', subCategory: values?.variantCode?.subCategoryCode, stateCode: ((values.cityCode?.code)?.includes('-') ? (values.cityCode?.code)?.split('-')[0] : values.cityCode?.code) || "" }));
			MasterServices.getVerificationDetails(
				callBackFunc,
				"saveassetdetails",
				SAVE_CONTINUE_ACTION,
				saveAndContinue
			);
		}
		if (
			step === "1" &&
			responseData.journeyStatus === "Journey Stopped"
		) {
			dropOffApiCall("Move to assisted");
		}
		if (
			step === "1" &&
			responseData.journeyStatus === "Journey Continues"
		) {
			navigate("/finalAnimation");
		}
	};
	const callBackFunc = (success, response, type) => {
		setLoading(false);
		if (success) {
			if (type === "valuationstatus") {
				setResponseData(response);
				dispatch(pageBasicDetails({ vehicleDetails: response }));
				return;
			}
			if (response?.statusCode !== '200') {
				setErrorOptions({
					...errorOptions,
					openErrorModal: true,
					errorContent: DEDUPE_ERROR,
					errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
				});
				return;
			}
			if (type === SAVE_CONTINUE_ACTION && response?.statusCode === '200') {
				if (
					(step === "0" &&
						journeyType !== JOURNEY_TYPE_2 &&
						journeyType !== JOURNEY_TYPE_4) ||
					(step === "1" &&
						(journeyType === JOURNEY_TYPE_2 ||
							journeyType === JOURNEY_TYPE_4))
				) {
					navigate("/finalAnimation");
				} else if (
					step === "0" &&
					(journeyType === JOURNEY_TYPE_2 ||
						journeyType === JOURNEY_TYPE_4)
				) {
					navigate("/dealerVehicle/1");
				}
			}
		} else {
			setErrorOptions({
				...errorOptions,
				openErrorModal: true,
				errorContent: DEDUPE_ERROR || response?.respDesc,
				errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
			});
		}
	};

	const getTitle = () => {
		let title = VEHICLE_FINALIZATION_HEADING;
		if (journeyType === JOURNEY_TYPE_4 || aggregatorJourney) {
			title = VEHICLE_ASSET_DETAILS_HEADING
		} else if (journeyType === JOURNEY_TYPE_2 || preApprovedJourney) {
			title = VEHICLE_FINALIZATION_SUBHEADING
		}
		return title;
	}

	const getSubTitle = () => {
		let title = VEHICLE_FINALIZATION_SUBHEADING;
		if (journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4 || aggregatorJourney || preApprovedJourney) {
			title = VEHICLE_FINALIZATION_SUBHEADING_2
		}
		return title;
	}

	return (
		<>
			<div className="page-container">
				<Loader isLoading={loading} />
				<Header
					title={getTitle()}
					subtitle={getSubTitle()}
					topSlider={60}
				/>
				<div className="top-round-card-header"></div>
				<div className="page-body-content">
					<div className="page-body-contents">
						{(journeyType === JOURNEY_TYPE_2 ||
							journeyType === JOURNEY_TYPE_4) && (
								<div className="basic_details_stepper">
									<Stepper
										activeStep={
											step ? parseInt(step) : 0
										}
										alternativeLabel
									>
										<Step>
											<StepLabel
												StepIconComponent={
													CustomStepIcon
												}
											></StepLabel>
										</Step>
										<Step>
											<StepLabel
												StepIconComponent={
													CustomStepIcon
												}
											></StepLabel>
										</Step>
									</Stepper>
								</div>
							)}
						{step === "0" && (
							<div className="user_details">
								<DealerVehicle
									globalValues={values}
									setGlobalValues={setValues}
									setIsDisabled={setIsDisabled}
									isDisabled={isDisabled}
									errorOptions={errorOptions}
									setErrorOptions={setErrorOptions}
								/>
							</div>
						)}

						{step === "1" &&
							(journeyType === JOURNEY_TYPE_2 ||
								journeyType === JOURNEY_TYPE_4) && (
								<VehicleValuation
									setLoading={setLoading}
									responseData={responseData}
									setIsDisabled={setIsDisabled}
								/>
							)}
					</div>
					<Footer
						callBackAction={handleRedirect}
						disableButton={isDisabled}
						buttonText={
							(step === "0" && SAVE_CONTINUE_ACTION) ||
							(step === "1" &&
								(responseData.journeyStatus ===
									"Journey Stopped"
									? CLOSE_ACTION
									: CONTINUE_ACTION))
						}
					/>
				</div>
			</div>
			<ErrorModal
				openErrorModal={errorOptions?.openErrorModal}
				errorTitle={errorOptions?.errorTitle}
				errorContent={errorOptions?.errorContent}
				errorSubContent={errorOptions?.errorSubContent}
				closeErrorModal={() => {
					setErrorOptions({
						...errorOptions,
						openErrorModal: "",
						errorSubContent: "",
						errorContent: "",
					});
				}}
				clearCurrentSession={errorOptions?.clearCurrentSession}
			/>
		</>
	);
}
export default DealerVehicleDetails;
