/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  VIDEO_KYC_HEADER,
  START_ACTION,
  VIDEO_KYC_VERIFICATION,
  VIDEO_KYC_MODEL_TEXT,
  RE_INITIATE_KYC,
  CLOSE_ACTION,
  CONTINUE_ACTION,
  ERROR_TEXT,
  DEDUPE_ERROR,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  SYSTEM_ERROR1,
  OKAY_ACTION,
  RETRY_ACTION,
  SYSTEM_ERROR2,
} from "../../utils/cms";
import ConfirmationModal from "../../components/ConfirmationDialogBox";
import { useNavigate } from "react-router-dom";
import AssistanceCard from "../../components/AssistanceCard/AssistanceCard";
import MasterServices from "../../services/masters";
import { getJourneyType } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../components/ErrorModal";
import VideoKycComponent from "./VideoKycComponent";
import Loader from "../../components/Loader/Loader";
import { pageBasicDetails } from "../../store/pageDetails";
import { dropOffApiCall } from "../../utils/dropOff";
import {
  kycStatusToReducedStatus,
  VIDEO_KYC_COMPLETED,
  VIDEO_KYC_INPROGRESS,
  VIDEO_KYC_REJECTED,
  VIDEO_KYC_UNABLE_COMPLETE,
} from "./data";
import { removeCompletedScreen, updateCompletedScreens } from "../../store/features/toolsSlice";
import LoginServices from "../../services/login";
import { updateJourneyStatus } from '../../utils/updateJouneyStatus';

const VideoKycPage = () => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [buttonText, setbuttonText] = useState({
    text: "",
    removeButton: false,
  });
  const [errorOptions, setErrorOptions] = useState({
    openErrorModal: false,
    errorTitle: ERROR_TEXT,
    errorSubContent: "",
    errorContent: "",
    clearCurrentSession: false,
  });
  const navigate = useNavigate();
  const storeValues = useSelector((state) => state);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const {
    pageDetails: { currentApplication, videoKyc, perfiosOnload, completedPages }, tools: { completedScreens }
  } = storeValues || {};
  let status = params?.get("kyc_status");

  const beDetailsCallBack = (success, data) => {
    setLoading(false);
    if (success) {
      dispatch(pageBasicDetails({ perfiosOnload: data }));
    } else {
      setErrorOptions({
        ...errorOptions,
        openErrorModal: true,
        errorContent: DEDUPE_ERROR || data?.respDesc,
        errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
        type: "getincomeinfo",
      });
    }
  };

  let fetchBeDetails = () => {
    setLoading(true);
    const payload = {
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      vehicleType:
        currentApplication?.vehicleType ||
        (storeValues?.pageDetails?.journeyType === "Two Wheeler Loan"
          ? "H"
          : "C"),
      journeyType:
        currentApplication?.journeyType ||
        getJourneyType(storeValues?.pageDetails?.journeyType),
      screenID: "25",
    };
    MasterServices.getVerificationDetails(
      beDetailsCallBack,
      "getincomeinfo",
      "",
      payload
    );
  };

	const dedupeCall = () => {
		setLoading(true);
		LoginServices.dedupeVerification(callBackFunction);
	};

	const callBackFunction = (success, response) => {
		setLoading(false);
		if (success) {
			dispatch(
				pageBasicDetails({
					dedupeResponse: response,
					currentApplication: {},
					finalOffer: {},
					initialOffer: {},
					completedPages: { ...completedPages, animationPage: false }
				})
			);
			if (response?.dashBoardInfoList != null && response?.dashBoardInfoList?.length > 0) {
				let removeAfterDashboardScreens = completedScreens?.filter(each => each < 4)
				dispatch(updateCompletedScreens(removeAfterDashboardScreens))
				navigate('/vehicleLoanDashboard');
			}
		} else {
			if (response?.statusCode === "500" || !response?.response?.data) {
        setRetryCount(1);
        setErrorOptions({
          openErrorModal: true,
          errorTitle: ERROR_TEXT,
          errorContent: SYSTEM_ERROR1,
          clearCurrentSession: false,
        });        
			}
		}
	};  

  const handleClick = () => {
    if (!kycStatusToReducedStatus(status)) {
      setOpen(true);
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_REJECTED) {
      dropOffApiCall("VKYC is Rejected");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.href = '/';
      }, 150)
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_UNABLE_COMPLETE) {
      dispatch(removeCompletedScreen(28))
      navigate("/vkyclanding");
    }
    if (kycStatusToReducedStatus(status) === VIDEO_KYC_COMPLETED) {
      dedupeCall();
    }
  };

  useEffect(() => {
    if (!kycStatusToReducedStatus(status)) {
      setbuttonText({ ...buttonText, text: START_ACTION, removeButton: false });
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_INPROGRESS) {
      setbuttonText({ ...buttonText, removeButton: true });
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_UNABLE_COMPLETE) {
      setbuttonText({
        ...buttonText,
        text: RE_INITIATE_KYC,
        removeButton: false,
      });
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_REJECTED) {
      setbuttonText({ ...buttonText, text: CLOSE_ACTION, removeButton: false });
    } else if (kycStatusToReducedStatus(status) === VIDEO_KYC_COMPLETED) {
      setbuttonText({
        ...buttonText,
        text: CONTINUE_ACTION,
        removeButton: false,
      });
    }
  }, [status]);

  const callBackAction = (action) => {
    if (action === "continue") {
      generateVideoKycUrl();
    } else if (action === "close") {
      setOpen(false);
    }
  };

  const videoKycUrlCb = (success, data) => {
    setLoading(false);
    if (success && data && data?.waitPageUrl) {
      dispatch(pageBasicDetails({ videoKyc: data }));
      setOpen(false);
      setbuttonText({ ...buttonText, removeButton: true });
      localStorage.setItem("restrictDropOff", "yes");
      updateJourneyStatus(28, 'Video KYC in Progress', data?.trackingId);
      setTimeout(() => {
        window.open(data.waitPageUrl, "_self");
      }, 300);
    } else {
      setErrorOptions({
        openErrorModal: true,
        errorTitle: ERROR_TEXT,
        errorContent: retryCount ? SYSTEM_ERROR2 : SYSTEM_ERROR1,
        clearCurrentSession: retryCount >= 1 ? true : false,
        type: "videokyc",
      });
      setRetryCount((state) => state + 1);
      setOpen(false);
    }
  };

  const generateVideoKycUrl = () => {
    setLoading(true);
    let payload = {
      d2CApplicantID: currentApplication?.d2CApplicantID || "",
      redirectUrl: `${window.location.origin}/vkyclanding`,
      isLoggedInSession: true
    };
    MasterServices.generateVideoKycLink(videoKycUrlCb, payload);
  };

  const videoKycTrackingCb = (success, data) => {
    setLoading(false);
    if (success && data) {
      if (data?.sfSyncStatusCode === "512") {
        setRetryCount(3);
        setErrorOptions({
          openErrorModal: true,
          errorTitle: ERROR_TEXT,
          errorContent: SYSTEM_ERROR2,
          clearCurrentSession: true,
          type: "tracking",
        });
        return;
      }
      dispatch(pageBasicDetails({ videoKycName: data?.displayName }));
      if (data?.videoKYCStatus === 'Open') {
        navigate('/vkyclanding');
        return;
      } else {
        navigate(`/vkyclanding?kyc_status=${data?.videoKYCStatus}`);
      }
    } else {
      setRetryCount((state) => state + 1);
      setErrorOptions({
        openErrorModal: true,
        errorTitle: ERROR_TEXT,
        errorContent: SYSTEM_ERROR2,
        clearCurrentSession: true,
        type: "tracking",
      });
    }
  };

  const FetchVideoKycTracking = () => {
    setLoading(true);
    let payload = {
      videoKYCTrackingId: videoKyc?.trackingId || currentApplication?.vkycTrackingId || "",
      d2CApplicantID: currentApplication?.d2CApplicantID || "",
      sfapplicantId: currentApplication?.loanApplicationId || "",
    };
    MasterServices.videoKycTracking(videoKycTrackingCb, payload);
  };

  useEffect(() => {
    if ((status === 'null') || (status && (status === 'Open' || status === 'AuditorAssigned' || status === 'AuditorReady' || status === 'Successful'))) {
      setRetryCount(0);
      FetchVideoKycTracking();
      setTimeout(() => {
        FetchVideoKycTracking();
      }, 1000 * 60 * 60);
    }
    if (!perfiosOnload?.executiveContact) {
      fetchBeDetails();
    }
  }, []);

  return (
    <>
      <div className="page-container">
        <Loader isLoading={loading} />
        <Header
          topSlider={100}
          title={VIDEO_KYC_HEADER}
          subtitle={VIDEO_KYC_VERIFICATION}
        />
        <div className="top-round-card-header"></div>
        <div className="page-body-content">
          <div className="page-body-contents">
            <VideoKycComponent
              status={kycStatusToReducedStatus(status)}
              beDetails={{
                  beName: perfiosOnload?.executiveName || "",
                  contactNumber: perfiosOnload?.executiveContact || "",
                }}
            />
            <div className="assitent-Card">
              <AssistanceCard
                beDetails={{
                  beName: perfiosOnload?.executiveName || "",
                  beContactNumber: perfiosOnload?.executiveContact || "",
                }}
              />
            </div>
            {isOpen && (
              <ConfirmationModal
                errorTitle={"Disclaimer"}
                openErrorModal={isOpen}
                secondaryAction
                primaryAction
                errorContent={VIDEO_KYC_MODEL_TEXT}
                callBackAction={callBackAction}
              />
            )}
          </div>
          <Footer
            callBackAction={() => {
              handleClick();
            }}
            disableButton={false}
            buttonText={buttonText.text}
            removeBtn={buttonText?.removeButton}
          />
        </div>
      </div>
      <ErrorModal
        openErrorModal={errorOptions?.openErrorModal}
        errorTitle={errorOptions?.errorTitle}
        errorContent={errorOptions?.errorContent}
        errorSubContent={errorOptions?.errorSubContent}
        closeErrorModal={() => {
          if (retryCount <= 1 && errorOptions.type === "videokyc") {
            generateVideoKycUrl();
          } else if (retryCount <= 1 && errorOptions.type === "tracking") {
            FetchVideoKycTracking();
          } else if (errorOptions.type === "getincomeinfo") {
            fetchBeDetails();
          }
          setErrorOptions({
            ...errorOptions,
            openErrorModal: "",
            errorSubContent: "",
            errorContent: "",
            clearCurrentSession: true,
          });
          if (errorOptions?.clearCurrentSession) {
            dropOffApiCall("Move to assisted", true);
          }
        }}
        buttonText={retryCount <= 1 ? RETRY_ACTION : OKAY_ACTION}
        clearCurrentSession={errorOptions?.clearCurrentSession}
      />
    </>
  );
};

export default VideoKycPage;
