import React from "react";
import message_icon from "../../assets/assistantCard-messageIcon.png";
import {
	VIDEO_KYC_BOTOOM_MESSAGE
} from "../../utils/cms";
import { phoneNumberFormat } from "../../utils/utils";
import "./AssistanceCard.css";

function AssistanceCard({beDetails}) {
	return (
		<div className="assistance-card-container">
			<div className="assistance-card">
				<img
					className="message-icon"
					src={message_icon}
					alt=""
                    width={"24px"}
				/>
				<div className="message-text">
					<div>{VIDEO_KYC_BOTOOM_MESSAGE.replace('#bename#', beDetails?.beName).replace('#bemobile#', phoneNumberFormat(beDetails?.beContactNumber))}</div>
				</div>
			</div>
		</div>
	);
}

export default AssistanceCard;
