/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { MOBILE_NUMBER_VALIDATION } from "../../../utils/cms";
import { removeInvaildCharFromMobileField } from "../../../utils/utils";
import TextFieldInput from "../TextFieldInput";
import InputMask from "react-input-mask";
import "./PhoneNumberInput.css";

const PhoneAdornment = () => {
	return <div className="adornment">+91</div>;
};
const PhoneNumberInput = ({
	values,
	setValues,
	errors,
	setErrors,
	name,
	label,
	hintText,
	disabled,
}) => {
	const [showAdornment, setShowAdornment] = useState(null);

	const inputChangeHandler = (e) => {
		let value = e.target.value;
		value = removeInvaildCharFromMobileField(value);
		if (value.length > 10) return;
		if (value.length === 11) {
			setErrors({ ...errors, [name]: null });
		}
		if (value.length && ["0", "1", "2", "3", "4", "5"].includes(value?.[0])) {
			setErrors({ ...errors, [name]: [MOBILE_NUMBER_VALIDATION] });
		} else {
			setErrors({ ...errors, [name]: null });
		}
		if (value.length > 5) {
			value = value?.match(/.{1,5}/g)?.join(" ");
			setValues({ ...values, [name]: value });
		} else {
			setValues({ ...values, [name]: value });
		}
	};

	const onBlurHandler = (e) => {
		setShowAdornment(false);
		let value = e.target.value;
		if (value.length < 11 && value?.length)
			setErrors({ ...errors, [name]: [MOBILE_NUMBER_VALIDATION] });
		if (value.length === 11 && !["0", "1", "2", "3", "4", "5"].includes(value?.[0]))
			setErrors({ ...errors, [name]: null });
	};

	const onFocusHandler = (e) => {
		setShowAdornment(true);
	};

	useEffect(() => {
		if (values?.[name]?.length > 6 && !/\s/g.test(values?.[name])) {
			const value = values?.[name]?.match(/.{1,5}/g)?.join(" ");
			setValues({ ...values, [name]: value });
		}
	}, [values?.[name]]);

	return (
		<InputMask
			mask="99999 99999"
			onChange={inputChangeHandler}
			maskPlaceholder={null}
			onBlur={onBlurHandler}
			onFocus={onFocusHandler}
			value={values?.[name] || ''}
			disabled={disabled}
		>
			<TextFieldInput
				errors={errors?.[name]}
				values={values}
				name={name}
				label={label}
				onChange={inputChangeHandler}
				hintText={hintText}
				startAdornment={
					values[name] || showAdornment ? (
						<PhoneAdornment />
					) : null
				}
				disabled={disabled}
				formatter='number'
			/>
		</InputMask>
	);
};

export default PhoneNumberInput;
