import React, { useEffect, useState } from 'react'
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
    VEHICLE_LOAN_DASHBOARD_TITLE,
    VEHICLE_LOAN_DASHBOARD_HEADING_2,
    VEHICLE_LOAN_DASHBOARD_HEADING_3,
    CONTINUE_APPLICATION_ACTION,
    START_NEW_APPLICATION_ACTION,
    PAN_ERROR_TEXT,
    PAN_ERROR_SUB_TEXT,
    EMAIL_ERROR_TEXT,
    EMAIL_ERROR_SUB_TEXT,
    DEDUPE_ERROR,
    RESEND_OTP_ERROR_TEXT,
    OTP_GENERATION_FAILED_TRY_AGAIN,
    ERROR_TEXT,
    CONTACT_BRANCH_ERROR,
    MOVE_TO_OFFLINE_TEXT1,
    VIEW_DETAILS
} from "../../utils/cms";
import "./index.css";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { screenData } from '../../utils/screenData'
import { pageBasicDetails } from '../../store/pageDetails';
import LoginServices from '../../services/login';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../components/Loader/Loader';
import { changeValueToCurrency } from '../../utils/utils';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function FinalOffer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeValues = useSelector(state => state);
    const { pageDetails: { journeyType, preApprovedJourney, dedupeResponse, aggregatorJourney } } = storeValues || {};
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorContent, setErrorContent] = useState('');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [anySanctionedApplication, setAnySanctionedApplication] = useState(false);
    const getDate = (date) => {
        if (date === null || date === 'null' || date === '' || date === undefined) return;
        let newDate = date?.split(" ")?.[0]?.split("-");
        if (newDate?.[1]) {
            let dateCase = 'th';
            if (newDate[2] === '01' || newDate[2] === '21') dateCase = "st";
            if (newDate[2] === '02' || newDate[2] === '22') dateCase = "nd";
            if (newDate[2] === '03' || newDate[2] === '23') dateCase = "rd";
            const month = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            return month[newDate[1] - 1] + " " + newDate[2] + dateCase + ", " + newDate[0];
        }
        return date;        
    }

    const handleClick = (val) => {
        const newVal = {...val, leadId: val?.leadId?.includes('_B') ? val?.leadId : val?.leadId + '_B' + val?.sfapplicantId, finalOfferAccepted: (val?.leadStatus === 'Application Submitted' || val?.leadStatus === 'Sanctioned' || val?.leadStatus === 'Disbursed'), referenceNumber: dedupeResponse?.referenceNumber || ''};
        dispatch(pageBasicDetails({currentApplication:newVal}));
        const routeDetails = screenData?.find(x => x.id === parseInt(val?.screenId))
        if (val?.customerType === 'NTB' && parseInt(val?.screenId) <= 5) {
          if ((parseInt(val?.screenId) === 0 && !val?.rekyc) || (parseInt(val?.screenId) <= 5 && val?.rekyc)) {
            navigate('/roadmap/0');
          } else {
            navigate('/aadhar');
          }
        } else if (val?.customerType === 'ETB' && parseInt(val?.screenId) <= 5) {
          navigate('/roadmap/0');
        } else if (parseInt(val?.screenId) === 10 || parseInt(val?.screenId) === 11) {
            navigate('/animation');
        } else if (parseInt(val?.screenId) === 15) {
            navigate('/verification/0');
        } else if (parseInt(val?.screenId) === 23 || parseInt(val?.screenId) === 24) {
            navigate('/finalAnimation');
        } else if (parseInt(val?.screenId) === 0 && aggregatorJourney) {
            navigate('/roadmap/0');
        } else if (parseInt(val?.screenId) === 28) {
          navigate(`/vkyclanding?kyc_status=${val?.vkycTrackingStatus}`);
          return;
        } else {
            navigate('/'+routeDetails?.route);
        }
    }

    const callBackFunc = (success, response) => {
        if (success) {
            setLoading(false);
            if (response?.panStatus?.status !== 'E' && response?.dashBoardInfoList == null) {
              setOpenErrorModal(true);
              setErrorMessage(PAN_ERROR_TEXT);
              setErrorContent(PAN_ERROR_SUB_TEXT)
              return;
            }
            if (response?.emailStatus?.status !== 'valid' && response?.dashBoardInfoList == null) {
              setOpenErrorModal(true);
              setErrorMessage(EMAIL_ERROR_TEXT);
              setErrorContent(EMAIL_ERROR_SUB_TEXT)
              return;
            }
            if (response?.dedupeStatus?.journeyStop) {
              setOpenErrorModal(true);
              setErrorMessage(CONTACT_BRANCH_ERROR);
              setErrorContent('');
              return;
            }
            if (response?.journeyStop) {
              setOpenErrorModal(true);
              setErrorMessage(DEDUPE_ERROR);
              setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
              return;
            }
            let currentApplication = {
              d2CApplicantID: response?.d2CApplicantID,
              sfapplicantId: response?.sfapplicantId,
              loanApplicationId: response?.loanApplicationId,
              leadId: response?.leadId + '_B' + response?.sfapplicantId,
              documentId: response?.documentId,
              customerType: (response?.dedupeStatus?.isETB) ? "ETB" : ((response?.dedupeStatus?.isNTB) ? "NTB" : ""),
              beContactNumber: response?.beContactNumber || "",
              referenceNumber: response?.referenceNumber
            }
            dispatch(pageBasicDetails({dedupeResponse:response, currentApplication}));
            if (response?.dedupeStatus?.isNTB) {
              if (response?.dedupeStatus?.reKYCNeeded) {
                navigate('/aadhar');
              } else {
                navigate('/roadmap/0');
              }
            } else if (response?.dedupeStatus?.isETB) {
              if (response?.reKYCNeeded) {
                navigate('/aadhar');
              } else {
                navigate('/roadmap/0');
              }
            }
        } else {
          setOpenErrorModal(true);
          if (response?.statusCode === "500") {
            setErrorMessage(response?.respDesc);
            return;
          }
          if (!response?.response?.data) {
            setErrorMessage(DEDUPE_ERROR);
            setErrorContent(RESEND_OTP_ERROR_TEXT);
          }
        }      
    }
    useEffect(() => {
        if (preApprovedJourney) {
         const anySanctionedApplication = dedupeResponse?.dashBoardInfoList?.some(application =>  application.leadStatus === 'Sanctioned' || application.leadStatus === 'Disbursed');
         setAnySanctionedApplication(anySanctionedApplication);
        }
    }, [])

    const startNew = () => {
        dispatch(pageBasicDetails({startNewApplication:true}));
        setLoading(true);
        LoginServices.dedupeVerification(callBackFunc);
    }

    return (
        <div className="page-container vehicle_dashboard">
            <Loader isLoading={loading} interval={5000} />
            <Header
                journeyTitle={journeyType}
                title={VEHICLE_LOAN_DASHBOARD_TITLE}
            />
            <div className="top-round-card-header" style={{ marginTop: "40px" }}>
                <Button variant="contained" onClick={() => startNew()} disabled={aggregatorJourney || anySanctionedApplication}>+ {START_NEW_APPLICATION_ACTION}</Button>
            </div>
            <div className="page-body-content">
                {dedupeResponse?.dashBoardInfoList?.map((val) => (
                    <div className="bottom-shadow" key={val?.leadId}>
                        <div className='page-body-contents'>
                            <div className="section-2">
                                <div className="heading">{journeyType} Lead ID</div>
                                <div className="data"><b>{val?.leadId?.split('_B')[0]}</b></div>
                                {val?.leadStatus ?
                                    <div className={`${(val?.leadStatus === 'Application Submitted' || val?.leadStatus === 'Sanctioned' || val?.leadStatus === 'Disbursed') ? "completed" : (val?.leadStatus?.includes('Rejected') ? "rejected" : "progress")}`}><b>{val?.leadStatus === 'Moved to Offline channel' ? (MOVE_TO_OFFLINE_TEXT1.replace('#bename#', val?.beName).replace('#bemobile#', (val?.beContactNumber))) : val?.leadStatus}</b></div>: <>NA<br/></>
                                }<br/>
                                {val?.loanAmount && val?.loanAmount !== 'null' && <div className="loan-amount">
                                  <CurrencyRupeeIcon style={{
                                        marginBottom: '-2px'
                                  }} />
                                  {changeValueToCurrency.format(
                                    val?.loanAmount ||
                                      "0"
                                  )}
                                </div>}
                                <div className="heading">{VEHICLE_LOAN_DASHBOARD_HEADING_2}</div>
                                <div className="data"><b>{val?.vehicleInfo ? val?.vehicleInfo : '-'}</b></div><br/>
                                <div className="heading">{getDate(val?.updatedTime) ? VEHICLE_LOAN_DASHBOARD_HEADING_3 : null}</div>
                                <div className="data"><b>{getDate(val?.updatedTime)}</b></div>
                            </div>
                            <div className="section-3">
                                <Button variant="outlined" onClick={() => handleClick(val)} disabled={val?.leadStatus === 'Moved to Offline channel' || (val?.leadStatus !== 'Sanctioned' && anySanctionedApplication)}>{(val?.leadStatus === 'Application Submitted' || val?.leadStatus === 'Sanctioned' || val?.leadStatus === 'Disbursed') ? VIEW_DETAILS : CONTINUE_APPLICATION_ACTION}</Button>
                            </div>
                        </div>
                    </div>
                ))}
                <Footer
                    removeBtn={true}
                />
            </div>
            {errorMessage && <ErrorModal errorTitle={ERROR_TEXT} errorContent={errorMessage} errorSubContent={errorContent} openErrorModal={openErrorModal} closeErrorModal={() => {
            setOpenErrorModal(false);
            setLoading(false);
            }} />}
        </div>
    )
}
