import axios from "axios"
import { GET_MASTER_DATA, INITIAL_OFFER_DATA, USER_DROP_OFF, VERIFICATION_DATA, SAVE_INITIAL_OFFER, HOME_PAGE_TILES_CONFIG, AGGREGATOR_HANDSHAKE_URL, ROADMAP_URL, SAVE_FINAL_OFFER, GET_FINAL_OFFER_ACCEPTANCE, SAVE_FINAL_OFFER_ACCEPTANCE, GET_VIDEO_KYC_URL, VIDEO_KYC_TRACKING, UPDATE_JOURNEY_STATUS, MO_ENGAGE_URL } from '../config/config';
import { store } from '../store/index'


const api = {
  getHomeTilesConfig: function (cb) {
    const storeValues = store.getState();
    const { pageDetails: { aggregatorHandshakeResp } } = storeValues || {};
    const payload = {
      customerCode: aggregatorHandshakeResp?.CFDCustomercode || '',
      pan: aggregatorHandshakeResp?.PAN || ''
    }
    axios.post(HOME_PAGE_TILES_CONFIG, payload).then((response) => {
      cb(true,response)
    }).catch((err) => {
      cb(false,err)
    })
  },
  getMasterList: async function (cb, val, type) {
    const payload = {}
    await axios.get(GET_MASTER_DATA + val, payload).then((response) => {
      cb(true, response, type);
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  getMasterListReturn: async function ( val, type) {
    const payload = {}
    return await axios.get(GET_MASTER_DATA + val, payload).then((response) => {
      return response
    }).catch((err) => {
      return err
    })
  },
  initialOfferPage: async function (cb, route, type, payload) {
    await axios.post(INITIAL_OFFER_DATA + route, payload).then((response) => {
      cb(true, response, type);
      return response
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  initialOffer: async function (cb, route, payload) {
    await axios.post(route, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  userDropOff: function (cb, payload, dropOffReason) {
    axios.post(USER_DROP_OFF, payload).then((response) => {
      cb(true, response, dropOffReason);
      return response
    }).catch((err) => {
      cb(false, err, dropOffReason);
    })
  },
  getVerificationDetails: async function (cb, route, type, payload) {
    await axios.post(VERIFICATION_DATA + route , payload).then((response) => {
      cb(true, response, type);
      return response
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  saveInitialOffer: async function (cb, payload, isFinalOffer) {
    await axios.post(isFinalOffer ? SAVE_FINAL_OFFER : SAVE_INITIAL_OFFER, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  aggregatorForm: async function (cb, payload) {
    await axios.post(AGGREGATOR_HANDSHAKE_URL, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  getRoadmapData: async function (cb, payload) {
    await axios.post(ROADMAP_URL, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  makeFinalCheck: async function (url, cb, payload, type) {
    await axios.post(url, payload).then((response) => {
      cb(true, response, type);
      return response
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  getFinalOfferAcceptData: async function (cb, type, payload) {
    await axios.post(GET_FINAL_OFFER_ACCEPTANCE, payload).then((response) => {
      cb(true, response, type);
      return response
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  saveFinalOfferAccept: async function (cb, type, payload) {
    await axios.post(SAVE_FINAL_OFFER_ACCEPTANCE, payload).then((response) => {
      cb(true, response, type);
      return response
    }).catch((err) => {
      cb(false, err, type);
    })
  },
  generateVideoKycLink: async function (cb, payload) {
    await axios.post(GET_VIDEO_KYC_URL, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  videoKycTracking: async function (cb, payload) {
    await axios.post(VIDEO_KYC_TRACKING, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  updateJourneyStatus: async function (cb, payload) {
    await axios.post(UPDATE_JOURNEY_STATUS, payload).then((response) => {
      cb(true, response);
      return response
    }).catch((err) => {
      cb(false, err);
    })
  },
  makeMoEngage: async function (payload) {
    await axios.post(MO_ENGAGE_URL, payload).then((response) => {
      return response
    }).catch((err) => {
    })
  },   
}

export default api;