/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable eqeqeq*/
import React , { useEffect ,useState } from "react";
import {
  BE_DETAILS,
  ERROR_TEXT,
  OKAY_ACTION,
  PERFIOS_WAITING_CARD_TITLE,
  PERFIOS_WAITING_DESCRIPTION,
  RETRY_ACTION,
  SYSTEM_ERROR1,
  SYSTEM_ERROR2,
} from "../../utils/cms";
import MessageHintCard from "../JourneyPage/MessageHintCard";
import noteImage from "../../assets/noteImage.jpg";
import "./PerfiosRedirectPage.css";
import masterServices from "../../services/masters.js";
import { useDispatch, useSelector } from "react-redux";
import perfiousBg from "../../assets/animation1.png";
import {
  getJourneyType,
  getVehicleType,
  phoneNumberFormat,
} from "../../utils/utils";
import ErrorModal from "../../components/ErrorModal";
import { nipApiHandler } from "./nipApiHandler";
import { useNavigate } from "react-router-dom";
import { Decryption } from "../../utils/payload";
import {
  AVERAGE_BANK_BALANCE,
  generateCurrentMonthYear,
  generateLast6MonthYear,
  GST,
  ITR,
  SALARY_CREDITED_TO_BANK,
} from "../VerificationPage/VerifyIncome/data";
import { dropOffApiCall } from "../../utils/dropOff";
import { pageBasicDetails } from "../../store/pageDetails";

const PerfiousRedirectPage = () => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(null);
  const [retriveReportError, setRetriveReportError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [timesTriggeredStatus, setTimesTriggeredStatus] = useState(0);

  const navigate = useNavigate();
  const storeValues = useSelector((state) => state);
  const { perfios, encryptedPerfios, perfiosOnload, currentApplication } =
    useSelector((store) => store.pageDetails);

  const nipActionHandler = (nip) => {
    let payload = {
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      vehicleType:
        currentApplication?.vehicleType ||
        getVehicleType(storeValues?.pageDetails?.journeyType),
      journeyType:
        currentApplication?.journeyType ||
        getJourneyType(storeValues?.pageDetails?.journeyType),
      screenID: "18",
      nip: nip, 
      flagValue: perfios?.radioActive?.toUpperCase() || '', 
      gstNumber: perfios?.gstNumber?.toUpperCase() || '',
      yearMonthFrom: generateLast6MonthYear(),
      yearMonthTo: generateCurrentMonthYear(),
      makerId: "0",
      leadId: currentApplication?.leadId,
      clientTransactionId: perfios?.clientTransactionId || '',
      perfiosTransactionId: perfios?.perfiosTransactionId || '',
      type: `${perfios?.radioActive?.toUpperCase()}${perfios?.type}`,
    };
    if (nip) dispatch(pageBasicDetails({ perfios: {...perfios, totalAvgBal: "0" }}));
    nipApiHandler(payload, navigate);
  };

  const stopTriggeringReportInterval = () => {
    setTimesTriggeredStatus(7);
  };

  const panCheckHandler = (pan) => {
    if (!pan) {
      nipActionHandler(false);
    }
    if (pan === JSON.parse(Decryption(encryptedPerfios)) || pan === "ABCDE1234Z" || pan === "ABDCE1234F") {
      nipActionHandler(false);
    } else {
      setErrorMessage(
        BE_DETAILS.replace("#bename#", perfiosOnload?.executiveName).replace(
          "#bemobile#",
          phoneNumberFormat(perfiosOnload?.executiveContact)
        )
      );
    }
  };

  const ItrandScbPanCheck = (data) => {
    let pan = data?.customerInfo?.pan;
    panCheckHandler(pan);
  }

  const retriveReportOfUser = (data) => {
    let cb = (success, data) => {
      if (success && data) {
        if (data?.sfStatusCode === "512") {
          setErrorMessage(SYSTEM_ERROR2)
          return
        }
        if (perfios?.radioActive === ITR) {
         
          ItrandScbPanCheck(data)
        } else if (perfios?.radioActive === GST) {
          if (Array.isArray(data?.customGstrTotalRevenueList)) {
            nipActionHandler(false);
          } else {
            nipActionHandler(true);
          }
        } else if (perfios?.radioActive === SALARY_CREDITED_TO_BANK) {
          ItrandScbPanCheck(data)
        } else if (perfios?.radioActive === AVERAGE_BANK_BALANCE) {
          dispatch(pageBasicDetails({ perfios: {...perfios, totalAvgBal: (perfiosOnload?.occupationCode === 'SAL' ? data?.summaryInfo?.AvgBal_Of_Range_Period : data?.SEP_Avg_Salary?.summaryInfo?.AvgBal_Of_Range_Period) || "0" }}));
          let pan = data?.customerInfo?.pan || data?.SEP_Avg_Salary?.customerInfo?.pan ;
          panCheckHandler(pan);
        }
      } else {
        setRetryCount((state) => state + 1);
        setRetriveReportError(true);
      }
    };

    let payload = {
      type: `${perfios?.radioActive?.toUpperCase()}${perfios?.type}`,
      PerfiosTransactionId: data?.perfiosTransactionId,
      Maker_Id: "0",
      Lead_Id: currentApplication?.leadId,
      reportFormat: "json",
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      yearMonthFrom: generateLast6MonthYear(),
      yearMonthTo: generateCurrentMonthYear(),
      gstNumber: perfios?.gstNumber || "",
    };
    if (data?.clientTransactionId) {
      payload.clientTransactionId = data?.clientTransactionId;
    }

    masterServices.getVerificationDetails(
      cb,
      `${perfios?.radioActive}/retrievereports`,
      "",
      payload
    );
  };

  const transactionStatusCb = (success, data) => {
    if (success) {
      dispatch(pageBasicDetails({ perfios: {...perfios, perfiosTransactionId: data.perfiosTransactionId}}));
      if (data.status === "COMPLETED" || data.Status === "COMPLETED") {
        stopTriggeringReportInterval();
        retriveReportOfUser(data);
        return;
      } else if (data.error_message === "E_NO_ERROR") {
        setTimesTriggeredStatus(timesTriggeredStatus + 1);
      } else if (data.status === "FAILED" || data.status === "USERACT") {
        stopTriggeringReportInterval();
        nipActionHandler(true);
      } else {
        setTimesTriggeredStatus(timesTriggeredStatus + 1);
      }
    } else {
      nipActionHandler(true);
    }
  };

  const getTransactionStatus = () => {
    if (!currentApplication?.d2CApplicantID) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
      return;
    }
    let payload = {
      ClientTransactionId: perfios?.clientTransactionId,
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
      type: `${perfios?.radioActive?.toUpperCase()}${perfios?.type}`,
    };
    masterServices.getVerificationDetails(
      transactionStatusCb,
      `${perfios?.radioActive}/gettransactionstatus`,
      "",
      payload
    );
  };

  useEffect(() => {
    if (timesTriggeredStatus < 6) {
      if (timesTriggeredStatus === 0) {
        getTransactionStatus();
      } else {
        setTimeout(() => {
          getTransactionStatus();
        }, 10000);
      }
    } else if (timesTriggeredStatus === 6) {
      nipActionHandler(true);
    }
  }, [timesTriggeredStatus]);

  return (
    <div className="perfious-redirect-page">
      {(!!errorMessage || !!retriveReportError) && (
        <ErrorModal
          errorTitle={ERROR_TEXT}
          errorContent={retriveReportError ? SYSTEM_ERROR1 : errorMessage}
          openErrorModal={!!errorMessage || !!retriveReportError}
          closeErrorModal={() => {
            if (retriveReportError) {
              setRetriveReportError(false);
              if (retryCount <= 1) {
                retriveReportOfUser();
              } else {
                nipActionHandler(true);
              }
            } else {
              setErrorMessage(null);
              dropOffApiCall("Move to assisted",true)
            }
          }}
          buttonText={errorMessage ? OKAY_ACTION : RETRY_ACTION}
        />
      )}

      <div className="message-hint-wrap">
        <img src={perfiousBg} alt="" className="perfious-bg bg-1" />
        <img src={perfiousBg} alt="" className="perfious-bg bg-2" />
        <MessageHintCard
          title={PERFIOS_WAITING_CARD_TITLE}
          image={noteImage}
          description={PERFIOS_WAITING_DESCRIPTION}
        />
      </div>
    </div>
  );
};

export default PerfiousRedirectPage;
