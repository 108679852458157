import React from "react";
import CheckboxInput from "../CustomInput/CheckboxInput";
import RadioInput from "../CustomInput/RadioInput";
import SelectInput from "../CustomInput/SelectInput";
import TextFieldInput from "../CustomInput/TextFieldInput";

const FormGenerate = ({
  formInputs,
  values,
  setValues,
  onChangeHandler,
  errors,
  options,
  afterInputChangeCallback,
  disabled,
  onBlurHanlder = () => {},
}) => {
  return (
    <>
      {formInputs.map((each) => {
        switch (each.inputType) {
          case "text":
            return (
              <TextFieldInput
                key={each.name}
                values={values}
                setValues={setValues}
                onChange={onChangeHandler}
                errors={errors?.[each.name]}
                afterInputChangeCallback = {afterInputChangeCallback}
                onBlur={onBlurHanlder}
                {...each}
                disabled = {each.disabled || disabled ? true : false}
              />
            );
          case "select":
            return (
              <SelectInput
                key={each.name}
                values={values}
                setValues={setValues}
                onChange={onChangeHandler}
                errors={errors?.[each.name]}
                afterInputChangeCallback = {afterInputChangeCallback}
                {...each}
                disabled = {disabled}
                options={options?.[each.name]}
              />
            );
          case "checkbox":
            return (
              <CheckboxInput
                key={each.name}
                values={values}
                setValues={setValues}
                onChange={onChangeHandler}
                errors={errors?.[each.name]}
                afterInputChangeCallback = {afterInputChangeCallback}
                {...each}
                disabled = {disabled}
              />
            );
          case "radio":
            return (
              <RadioInput
                key={each.name}
                values={values}
                setValues={setValues}
                onChange={onChangeHandler}
                errors={errors?.[each.name]}
                afterInputChangeCallback = {afterInputChangeCallback}
                {...each}
                disabled = {disabled}
              />
            );
          default:
            return (
              <TextFieldInput
                key={each.name}
                values={values}
                setValues={setValues}
                onChange={onChangeHandler}
                errors={errors?.[each.name]}
                afterInputChangeCallback = {afterInputChangeCallback}
                {...each}
              />
            );
        }
      })}
    </>
  );
};

export default FormGenerate;
