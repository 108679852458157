/*eslint-disable array-callback-return*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, {	useState,
	useEffect,
} from "react";
import SelectInput from "../CustomInput/SelectInput";
import TextFieldInput from "../CustomInput/TextFieldInput";
import MasterServices from "../../services/masters";
import {
	onlyNumbersAccept,
	removeInvaildCharFromAddress,
} from "../../utils/utils";
import {
	DISTRICT_LABEL,
	STATE_LABEL,
	ADDRESS_LINE_1_LABEL,
	ADDRESS_LINE_2_LABEL,
	PIN_CODE_LABEL,
	CITY_LABEL,
} from "../../utils/cms";
import "./AddressForm.css";
import { useDispatch } from "react-redux";
import { pageBasicDetails } from "../../store/pageDetails";
import CITYJSON from "../../data/city"
import STATEJSON from "../../data/state"

const AddressForm = ({
	setLoading,
	values,
	setValues,
	prefill,
	globalOptions,
	setGlobalOptions,
	clearForm=true,
	errors,
	disabled,
	setErrors
}) => {
	const [options, setOptions] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (values?.addressType && clearForm) {
			setValues({
				...values,
				addressLine1: "",
				addressLine2: "",
				cityName: "",
				stateName: "",
				districtName: "",
				pinCode: "",
			});
			setErrors();
		}
	}, [values?.addressType]);

	useEffect(() => {
		if (globalOptions) {
			setOptions({ ...globalOptions });
		}
	}, []);

	useEffect(() => {
		if(values?.stateName === '') {
			setValues({
				...values,
				districtName: null,
			})
		}
	},[values?.stateName])

	useEffect(() => {
		if (setGlobalOptions) {
			setGlobalOptions({ ...options });
		}
	}, [options]);

	const fetchCityDistrictOptions = async () => {
        const optionRoutes = ["CITY", "state/district"];
        let optionList;
        try {
            const res = await Promise.all(
                optionRoutes?.map((each) =>
                    MasterServices.getMasterListReturn(each)
                )
            );
			if (res[0].cacheValuesList?.length > 0) {
            	optionList = res;
			} else {
				optionList = [CITYJSON, STATEJSON];
			}
        } catch {
			
        }
        let optionsUpdate = {};
        optionList?.forEach((each, i) => {
            if (i === 0 && each?.cacheValuesList) {
                optionsUpdate.cityName = each?.cacheValuesList;
            }
            if (i === 1 && each?.cacheValuesList) {
                let stateNameList = [],
                    stateValue;
                each?.cacheValuesList.forEach((each) => {
                    stateValue = {
                        code: each.State_Code,
                        value: each.State_Name,
                    };
                    stateNameList.push(stateValue);
                });
                optionsUpdate.stateMasterData = each?.cacheValuesList;
                optionsUpdate.stateName = stateNameList;
				optionsUpdate.districtName = stateNameList;
            }
        });
        return optionsUpdate
    }

	const onLoadPrefillData = async () => {
		let optionsUpdate = await fetchCityDistrictOptions()
		let districtNameList = [],
			districtValue;
		let districtOptions = optionsUpdate.stateMasterData.find(
			(state) => (
				state.State_Code?.toUpperCase() === prefill.stateCode?.split(" ")?.join("")?.toUpperCase()
				|| state.State_Name?.split(" ")?.join("")?.toUpperCase() === prefill.stateCode?.split(" ")?.join("")?.toUpperCase() || state.State_Name?.split(" ")?.join("")?.toUpperCase() === prefill.stateName?.split(" ")?.join("")?.toUpperCase()
			)
		);
		districtOptions?.DISTRICTS?.map((each) => {
			districtValue = {
				code: each.District_Code,
				value: each.District_Name,
			};
			districtNameList.push(districtValue);
		});
		optionsUpdate.districtName = districtNameList;
		let cityValue = optionsUpdate.cityName?.find(
			(each) =>( 
				each.code === prefill.cityCode ||
				each?.code?.split('-')?.[1] === prefill.cityCode ||
				each.value === prefill.cityCode
			)
		);
		let stateValue = optionsUpdate.stateName?.find(
			(each) =>( each.code?.toUpperCase()?.split(" ")?.join("") === prefill.stateCode?.toUpperCase()?.split(" ")?.join("") || each.value?.toUpperCase()?.split(" ")?.join("") === prefill.stateCode?.toUpperCase()?.split(" ")?.join("") || each.value?.toUpperCase()?.split(" ")?.join("") === prefill.stateName?.toUpperCase()?.split(" ")?.join(""))
		);
		let districtValue1 = optionsUpdate.districtName?.find(
			(each) =>( each.code?.toUpperCase()?.split(" ")?.join("") === prefill.districtCode?.toUpperCase()?.split(" ")?.join("") || each.value?.toUpperCase()?.split(" ")?.join("") === prefill.districtCode?.toUpperCase()?.split(" ")?.join("") || each.value?.toUpperCase()?.split(" ")?.join("") === prefill.districtName?.toUpperCase()?.split(" ")?.join(""))
		);
		setOptions({ ...options, ...optionsUpdate });
		setValues({
			...values,
			cityName: cityValue,
			stateName: stateValue,
			districtName: districtValue1,
		});
		setLoading(false);
	};
	
	const onPageLoadFetchMasterData = async () => {
		let optionsUpdate = await fetchCityDistrictOptions();
		setOptions(optionsUpdate);
	};

	useEffect(() => {
		if (
			prefill?.cityCode &&
			prefill?.districtCode &&
			prefill?.stateCode
		) {
			onLoadPrefillData();
		} else if (
			!globalOptions?.cityName ||
			!globalOptions?.stateName
			) {
			onPageLoadFetchMasterData();
		}
	}, [prefill]);

	const onChangeHandler = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		const maxLength = 40;
		if (name.includes("addressLine") && value.length > maxLength) {
			value = removeInvaildCharFromAddress(value);
			setValues({ ...values, [name]: value.substring(0, maxLength) });
		} else if (name.includes("addressLine")) {
			value = value?.replace(/\s\s|\t+/g, " ");
    		if (value === ' ') return;			
			value = removeInvaildCharFromAddress(value);
			setValues({ ...values, [name]: value });
			setErrors({
				...errors,
				[name]: null
			})
		} else if (name === "pinCode") {
			if (value.length > 6) return;
			value = onlyNumbersAccept(value);
			setValues({ ...values, [name]: value });
		}
	};

	const onBlurHandler = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		if (name === "addressLine1" && value.length < 10) {
			setErrors({
				...errors,
				addressLine1: ["Please enter the complete address"]
			})
		}
		if (name === "addressLine2" && value.length < 3) {
			setErrors({
				...errors,
				addressLine2: ["Please enter the complete address"]
			})
		}
	};
	const callbackFunction = (success, response, type) => {
		setLoading(false);
		if (success) {
			if (type === "cityName") {
				dispatch(
					pageBasicDetails({ city: response.cacheValuesList })
				);
			}
			if (type === "stateMasterData") {
				let stateNameList = [],
					stateValue;
				response.cacheValuesList.map((each) => {
					stateValue = {
						code: each.State_Code,
						value: each.State_Name,
					};
					stateNameList.push(stateValue);
				});
				setOptions({
					...options,
					[type]: response.cacheValuesList,
					stateName: stateNameList,
				});
				return;
			}
			setOptions({
				...options,
				[type]: response.cacheValuesList,
			});
		} 
	};

	const onCitySelect = (updatedValues, name, value) => {
		if(!options?.stateName){
			setLoading(true)
			MasterServices.getMasterList(
				callbackFunction,
				`state/district`,
				"stateMasterData"
			);
		}
	};

	const onStateSelect = (updatedValues, name, value) => {
		let districtNameList = [],
			districtValue;
		let districtOptions = options?.stateMasterData?.find(
			(state) => state.State_Code === value?.code
		);
		districtOptions?.DISTRICTS?.map((each) => {
			districtValue = {
				code: each.District_Code,
				value: each.District_Name,
			};
			districtNameList.push(districtValue);
		});
		setValues({
			...updatedValues,
			districtName: null,
		})
		setOptions({
			...options,
			districtName: districtNameList,
		});
	};

	return (
		<div className="address-form">
				<TextFieldInput
					label={ADDRESS_LINE_1_LABEL}
					values={values}
					setValues={setValues}
					name="addressLine1"
					onChange={onChangeHandler}
					onBlur={onBlurHandler}
					errors={errors?.addressLine1}
					disabled={disabled}
				/>
				<TextFieldInput
					label={ADDRESS_LINE_2_LABEL}
					values={values}
					setValues={setValues}
					name="addressLine2"
					onChange={onChangeHandler}
					onBlur={onBlurHandler}
					errors={errors?.addressLine2}
					disabled={disabled}
				/>
				<SelectInput
					label={CITY_LABEL}
					name="cityName"
					options={options?.cityName}
					setOptions={setOptions}
					values={values}
					setValues={setValues}
					afterInputChangeCallback={onCitySelect}
					disabled={disabled}
				/>
				<SelectInput
					label={STATE_LABEL}
					name="stateName"
					options={options?.stateName}
					setOptions={setOptions}
					values={values}
					setValues={setValues}
					afterInputChangeCallback={onStateSelect}
					disabled={disabled}
				/>
				<SelectInput
					label={DISTRICT_LABEL}
					name="districtName"
					options={options?.districtName}
					setOptions={setOptions}
					values={values}
					setValues={setValues}
					disabled={disabled}
				/>
				<TextFieldInput
					label={PIN_CODE_LABEL}
					values={values}
					setValues={setValues}
					name="pinCode"
					onChange={onChangeHandler}
					formatter='number'
					disabled={disabled}
				/>
		</div>
	);
};

export default AddressForm;
