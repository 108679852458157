import React from 'react'
import "./index.css";
import bg2 from '../../assets/bg2.png'
import { NOT_FOUND } from '../../utils/cms';
import Alert from '@mui/material/Alert';

const JourneyPages = () => {
  return (
    <div className="page-container" style={{height:window.innerHeight-190}}>
      <div className="body-content">
        <div className="landing-page-body">
          <div className='notfound-toaster'>
            <Alert variant="outlined" severity="error">
              {NOT_FOUND}
            </Alert>
            </div>
        <div className='bottom-background'>
          <img src={bg2} alt="Bottom-Footer"/>
        </div>
        </div>     
      </div>
    </div>
  );
}

export default JourneyPages;