export const dropDownData = [
  {
    label: "Label 1",
    value: "item_1",
  },
  {
    label: "Label 2",
    value: "item_2",
  },
  {
    label: "Label 3",
    value: "item_3",
  },
];

export const residenceAddressOptions = [
  {
    label: "Current Address",
    value: "currentAddress",
    name: "currentAddress",
  },
  {
    label: "Permanent Address",
    value: "permanentAddress",
    name: "permanentAddress",
  },
  {
    label: "Both (Current & Permanent Address)",
    value: "bothAddress",
    name: "bothAddress",
  },
  {
    label: "None (The address on my Aadhaar is no more my current or permanent address)",
    value: "noneAddress",
    name: "noneAddress",
  },
];

export const addressOptions2 = [
  {
    label: "Same as my Current Residence Address",
    value: "current_address",
    name: "current_address",
  },
  {
    label: "Same as my Permanent Residence Address",
    value: "permanent_address",
    name: "permanent_address",
  },
  {
    label: "Different from my Residence Addresses",
    value: "different_address",
    name: "different_address",
  },
];
