import React from "react";
import { VEHICLE_REGISTRATION_FIELD_ERROR_TEXT } from "../../../utils/cms";
import TextFieldInput from "../TextFieldInput";
import {
	checkValidVehicleRegistration,
	removeInvaildCharFromPANField,
} from "../../../utils/utils";
import "./VehicleRegistration.css";

const VehicleRegistration = ({
	values,
	errors,
	setErrors,
	name,
	label,
	hintText,
	setFieldValue,
	currentJourneyType,
	disableVehicleRegi = "false",
}) => {
	const inputChangeHandler = (e) => {
		let value = e.target.value;
		value = removeInvaildCharFromPANField(value);
		setFieldValue(value);
		if (errors?.[name]) {
			setErrors({
				...errors,
				[name]: null,
			});
		}
	};

	const onBlurHandler = (e) => {
		let value = e.target.value.toUpperCase();
		const is3nad4Char0 = value?.slice(2, 4) === "00";
		const isLast4Char0 = value?.slice(-4) === "0000";
		if (
			checkValidVehicleRegistration(value) &&
			!is3nad4Char0 &&
			!isLast4Char0
		) {
			setErrors({
				...errors,
				[name]: null,
			});
		}
		else if ((!errors?.[name] && value) || is3nad4Char0 || isLast4Char0) {
			setErrors({
				...errors,
				[name]: [VEHICLE_REGISTRATION_FIELD_ERROR_TEXT],
			});
		}
	};

	return (
		<TextFieldInput
			onChange={inputChangeHandler}
			maskPlaceholder={null}
			onBlur={onBlurHandler}
			value={values?.[name] || ""}
			values={values}
			errors={errors?.[name]}
			name={name}
			label={label}
			className="vehicle-registration-input"
			hintText={hintText}
		/>
	);
};

export default VehicleRegistration;