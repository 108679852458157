import { Box, Paper } from '@mui/material'
import React from 'react'
import bg2 from '../../assets/bg2.png'


const MessageHintCard = ({title,image,description}) => {
  return (
    <div className="body-content">
    <div className="landing-page-body">
    <Box className='welcome-content'>
      <Paper elevation={3}>
        <div className='welcome-text-box'>{title}</div>
        <div>
          <img src={image} alt="" className='welcome-img'/>
        </div>
        <Paper className='welcome-bottom-text'>
          {description}
        </Paper>
      </Paper>
    </Box>            
    <div className='bottom-background'>
      <img src={bg2} alt="Bottom-Footer"/>
    </div>
    </div>
  </div>    
  )
}

export default MessageHintCard