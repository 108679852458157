import "./InsuranceTerms.css";
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {  
MOTOR_TERMS_HEADING,
MOTOR_TERMS_TEXT,
MOTOR_TERMS_TEXT1,
MOTOR_TERMS_TEXT2,
MOTOR_TERMS_TEXT3,
MOTOR_TERMS_TEXT4,
MOTOR_TERMS_TEXT5,
MOTOR_TERMS_TEXT6,
MOTOR_TERMS_TEXT7,
MOTOR_TERMS_TEXT8,
MOTOR_TERMS_TEXT9,
ACCEPT_BUTTON,
HEALTH_INSURANCE5_TERMS_CONTENT
} from "./../../../utils/cms";

const InsuranceTerms = (props) => {
  const { closeTersmAction, acceptTersmAction, healthConsent} = props
   return (
     <>
      <div className="insurance-consent-content">
      <h3 className="insurance-terms-heading">
              {MOTOR_TERMS_HEADING} <CloseIcon onClick={() => closeTersmAction()} />
            </h3>
            <div className="consent-body">
              {healthConsent ? <p>{HEALTH_INSURANCE5_TERMS_CONTENT}</p> : <>
              <p>{MOTOR_TERMS_TEXT}</p>
              <p>{MOTOR_TERMS_TEXT1}</p>
              <p>{MOTOR_TERMS_TEXT2}</p>
              <p>{MOTOR_TERMS_TEXT3}</p>
              <p>{MOTOR_TERMS_TEXT4}</p>
              <p>{MOTOR_TERMS_TEXT5}</p>
              <p>{MOTOR_TERMS_TEXT6}</p>
              <p>{MOTOR_TERMS_TEXT7}</p>
              <p>{MOTOR_TERMS_TEXT8}</p>
              <p>{MOTOR_TERMS_TEXT9}</p></>}
            </div>
      </div>
      <Button style={{backgroundColor: "#870016"}} className="consent-action-button" variant="contained" color="secondary" onClick={() => acceptTersmAction()}><b>{ACCEPT_BUTTON}</b></Button>
    </>
  );
};

export default InsuranceTerms;