/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react'
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "./index.css";
import JourneyType1 from '../../assets/journey-type1.png'
import JourneyType2 from '../../assets/journey-type2.png'
import JourneyType3 from '../../assets/journey-type3.png'
import JourneyType4 from '../../assets/journey-type4.png'
import JourneyType5 from '../../assets/PreApproved/preApproved.png'
import bg2 from '../../assets/bg2.png'
import { HELLO_HEADER, WELCOME_SUB_HEADER, JOURNEY_TYPE_1, JOURNEY_TYPE_2, JOURNEY_TYPE_3, JOURNEY_TYPE_4, WELCOME_TEXT_MGS, WELCOME_TEXT_HEADER, JOURNEY_TYPE_5, CLOSE_ACTION, VKYC_REJECTED_BODYTEXT3, VKYC_IS_COMPLETED_BODYTEXT4, UNABLE_TO_COMPLETE_VKYC_BODYTEXT3, CONTACT_BRANCH_ERROR} from '../../utils/cms'
import Header from '../../components/Header'
import {useDispatch, useSelector} from 'react-redux'
import { pageBasicDetails } from '../../store/pageDetails'
import Paper from '@mui/material/Paper';
import welcomeScreen from '../../assets/welcomeScreen.png'
import MasterServices from '../../services/masters'
import Loader from '../../components/Loader/Loader';
//import { Decryption } from '../../utils/payload';
import { userBasicDetails } from '../../store/userDetails';
import { updateJourneyStatus } from '../../utils/updateJouneyStatus';
import ErrorModal from "../../components/ErrorModal";
import { kycStatusToReducedStatusCustomer } from '../VideoKyc/data';

const JourneyPages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeValues = useSelector((state) => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [showLandingPage, setLandingPage] = useState(true);
  const [loading, setLoading] = useState(false)
  const [preConfig, setPreConfig] = useState( {
    carJourney: true,
    bikeJourney: true,
    usedCarJourney: true,
    refinanceJourney: true,
    preApprovedJourney: false,
  });
  const [errorOptions, setErrorOptions] = useState({
		// errorTitle: ERROR_TEXT,
    openErrorModal : false,
		errorSubContent: "",
		errorContent: "",
		clearCurrentSession: false,
	});

  const handleClick = (type) => {
    dispatch(pageBasicDetails({journeyType:type}));
    if (userDetails?.channel === 'IndusMobile' || userDetails?.leadSource === 'IndusMobile' || userDetails?.customerType === 'ETB') {
      if (type === JOURNEY_TYPE_5) {
        navigate('/preApprovedOffer')
      } else {
        dispatch(pageBasicDetails({preApprovedJourney:false}));
        navigate("/login");
      }
      return;
    }
    setLandingPage(false)
  };
  //console.log('IIIII', Decryption('d2ofMJl4rj4RBcajT+i8c3Hy7xWw5AXTrLJUm/Ruaat0DVe7Arqt6+4tkssU0BeeVIs5q2BZZsR8iU0CiflV8IHuZfUEkS6QEQwOdi0lI20zJXkr5/YYgTb0walb/AmkZaJPETDzrWGbq/YpLQH+7skj1SzcmBzME2j2UdGZLZ25xQCFxw0KlUrjl5cAl+kTT0g8UMNjqlcBWuvkDhqiDg=='))
  useEffect(() => {
    if (!showLandingPage) {
      if (userDetails?.channel === 'IndusMobile') {
        navigate("/login");
        return;
      }
      setTimeout(() => {
        navigate("/name");
      }, 3000);
    }
  })

  const cb = (success, resp) => {
    if (!pageDetails?.d2CApplicantID) setLoading(false)
    if (success) {
      dispatch(pageBasicDetails({tilesConfig: resp}));
      let tileObj = {}
      const response = resp?.tilesResponse;
      for (let j = 0; j < response.length; j++){
        if (response[j].tilesName === 'PV NEW' && response[j].tileActiveFlag === 'Y') tileObj.carJourney = true;
        if (response[j].tilesName === 'PV USED' && response[j].tileActiveFlag === 'Y') tileObj.usedCarJourney = true;
        if (response[j].tilesName === 'TW NEW' && response[j].tileActiveFlag === 'Y') tileObj.bikeJourney = true;
        if (response[j].tilesName === 'REFINANCE CAR' && response[j].tileActiveFlag === 'Y') tileObj.refinanceJourney = true;
        if (response[j].tilesName === 'PREAPPROVED' && response[j].tileActiveFlag === 'Y' && resp?.preapprovedMasterList?.length > 0) {
          tileObj.preApprovedJourney = true;
          dispatch(userBasicDetails({ customerType: "ETB" }));
        }
      }
      setPreConfig(tileObj)
    }
  }

  const fetchHomeScreenData = () => {
    //setLoading(true);
    setTimeout(() => {
      MasterServices.getHomeTilesConfig(cb)
    },0)
  }

  const videoKycUrlCb = (success, data) => {
    setLoading(false);
    let reducedStatus = kycStatusToReducedStatusCustomer(data.message);
     if(reducedStatus === "completed") {
      setErrorOptions({
        errorSubContent : data.status,
        errorContent : VKYC_IS_COMPLETED_BODYTEXT4,
        openErrorModal : true
       })
    } else if(reducedStatus === "inprogress") {
      setErrorOptions({
        errorSubContent : data.status,
        errorContent : UNABLE_TO_COMPLETE_VKYC_BODYTEXT3,
        openErrorModal : true
       });
    } else if(reducedStatus === "rejected") {
      setErrorOptions({
        errorContent : VKYC_REJECTED_BODYTEXT3,
        openErrorModal : true
       })
    } else if (success && data && data?.waitPageUrl && data?.message !== 'NoHandshakeNeeded') {
      updateJourneyStatus(28, 'Video KYC in Progress', data?.trackingId);
      dispatch(pageBasicDetails({ videoKyc: data, d2CApplicantID: null }));
      window.open(data.waitPageUrl, "_self");
    } else {

    }
  };

  const vkycProcess = (d2cId) => {
    setLoading(true);
    let payload = {
      d2CApplicantID: d2cId || "",
      redirectUrl: `${window.location.origin}`,
      isLoggedInSession: false
    };
    setTimeout(() => {
      MasterServices.generateVideoKycLink(videoKycUrlCb, payload);
    },2000); 
  }

  useEffect(() => {
    fetchHomeScreenData()
    if (pageDetails?.d2CApplicantID) {
      vkycProcess(pageDetails?.d2CApplicantID);
    }
  }, [])

  return (
    <div className="page-container" style={{height:window.innerHeight-200}}>
      <Loader isLoading={loading} />
      {showLandingPage ? <>
      <Header journeyTitle='' title={HELLO_HEADER} subtitle={WELCOME_SUB_HEADER} />
      <div className="body-content journey-body-content">
        <div className="landing-page-body">
       {errorOptions.openErrorModal ? <ErrorModal
      errorTitle={"VKYC Status "}
      errorContent={errorOptions.errorContent}
      openErrorModal={errorOptions.openErrorModal}
      closeErrorModal={() => setErrorOptions({
        ...errorOptions,
        openErrorModal: false,
        errorSubContent: "",
        errorContent: "",
      })} 
       buttonText={CLOSE_ACTION} /> : ""}
        <Box>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, lg: 2 }}
            columnSpacing={{ xs: 1, sm: 2, lg: 2 }}
          >
            {preConfig?.carJourney && <Grid item xs={6} md={3} sx={{ zIndex: "3" }} onClick={() => handleClick(JOURNEY_TYPE_1)}>
              <Card sx={{ borderRadius: "5px" }} variant="outlined">
                <img src={JourneyType1} alt="Journey-Logo" className="journey-logo" />
                <div className="loan-title-btn">
                  <p className="journey-action-button">
                    {JOURNEY_TYPE_1}
                  </p>
                </div>
              </Card>
            </Grid>}
            {preConfig?.usedCarJourney && <Grid item xs={6} md={3} sx={{ zIndex: "3" }} onClick={() => handleClick(JOURNEY_TYPE_2)}>
              <Card sx={{ borderRadius: "5px" }} variant="outlined">
                <img src={JourneyType2} alt="Journey-Logo" className="journey-logo" />
                <div className="loan-title-btn">
                  <p className="journey-action-button">
                  {JOURNEY_TYPE_2}
                  </p>
                </div>
              </Card>
            </Grid>}
            {preConfig?.bikeJourney && <Grid item xs={6} md={3} sx={{ zIndex: "3" }} onClick={() => handleClick(JOURNEY_TYPE_3)}>
              <Card sx={{ borderRadius: "5px" }} variant="outlined">
                <img src={JourneyType3} alt="Journey-Logo" className="journey-logo" />
                <div className="loan-title-btn">
                  <p className="journey-action-button">
                  {JOURNEY_TYPE_3}
                  </p>
                </div>
              </Card>
            </Grid>}
            {preConfig?.refinanceJourney && <Grid item xs={6} md={3} sx={{ zIndex: "3" }} onClick={() => handleClick(JOURNEY_TYPE_4)}>
              <Card sx={{ borderRadius: "5px" }} variant="outlined">
                <img src={JourneyType4} alt="Journey-Logo" className="journey-logo" />
                <div className="loan-title-btn">
                  <p className="journey-action-button">
                  {JOURNEY_TYPE_4}
                  </p>
                </div>
              </Card>
            </Grid>}
            {preConfig?.preApprovedJourney && <Grid item xs={6} md={3} sx={{ zIndex: "3" }} onClick={() => handleClick(JOURNEY_TYPE_5)}>
              <Card sx={{ borderRadius: "5px", marginBottom: "40px" }} variant="outlined">
                <img src={JourneyType5} alt="Journey-Logo" className="journey-logo" />
                <div className="loan-title-btn">
                  <p className="journey-action-button">
                  {JOURNEY_TYPE_5}
                  </p>
                </div>
              </Card>
            </Grid>}                
          </Grid>
        </Box>
        <div className='bottom-background'>
          <img src={bg2} alt="Bottom-Footer"/>
        </div>
        </div>     
      </div>
      </>: 
        <div className="body-content" style={window.innerWidth > 600 ? {marginTop:'0px'} : {marginTop:'50px'}}>
          <div className="landing-page-body">
          <Box className='welcome-content'>
            <Paper elevation={3}>
              <div className='welcome-text-box'>{WELCOME_TEXT_HEADER}</div>
              <div>
                <img src={welcomeScreen} alt="" className='welcome-img'/>
              </div>
              <Paper className='welcome-bottom-text'>
                {WELCOME_TEXT_MGS}
              </Paper>
            </Paper>
          </Box>            
          <div className='bottom-background'>
            <img src={bg2} alt="Bottom-Footer"/>
          </div>
          </div>
        </div>      
      }
    </div>
  );
}

export default JourneyPages;