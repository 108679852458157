import masterServices from "../../services/masters.js";
export const nipApiHandler = (payload, navigate) => {
    const cb = (success) => {
        if (success) {
            navigate("/roadmap/2");
        }
    }
    if (payload?.nip) {
        masterServices.getVerificationDetails(
            cb,
            "nip",
            "",
            payload
          );
    }
    else {
        cb(true)
    }
    
}