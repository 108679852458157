/*eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable no-unused-vars */
import './index.css'
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import mobileVideo from '../../assets/video.mp4';
import desktopVideo from '../../assets/videoDesktop.mp4';
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import { ALERT_TITLE, ANIMATION_TEXT1, ANIMATION_TEXT2, BE_DETAILS, DEDUPE_ERROR, ERROR_TEXT, GATTING_JOURNEY_STOPS1, GATTING_JOURNEY_STOPS2, OTP_GENERATION_FAILED_TRY_AGAIN, RESEND_OTP_ERROR_TEXT, RETRY_ACTION } from '../../utils/cms';
import carAnimation from '../../assets/car-animation.png'
import bikeAnimation from '../../assets/bike-animation.png';
import { useSelector, useDispatch } from 'react-redux'
import initialOfferServices from "../../services/masters";
import { GENERATE_FINAL_OFFER, GENERATE_INITIAL_OFFER, GENERATE_PRE_APPROVED_FINAL_OFFER } from '../../config/config';
import { pageBasicDetails } from '../../store/pageDetails';
import ErrorModal from '../../components/ErrorModal';
import { phoneNumberFormat } from '../../utils/utils';
import { initailOfferPayloadGenerate } from '../InitialOfferPage/ReuseFunctions';
import { dropOffApiCall } from '../../utils/dropOff';

const Landing = ({ isFinalOffer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValues = useSelector(state => state);
  const { pageDetails: { journeyType, completedPages, preApprovedJourney } } = storeValues || {};
  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [movetoAssisted, setMovetoAssisted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [journeyStops, setJourneyStops] = useState(false);
  const [alertTitle, setAlertTitle] = useState(false)

  const cb = (success, res) => {
    if (success) {
      if(!res?.gatingScreeningOutcome && res?.stopJourneyFlagOfferEngine) {
        setAlertTitle(true);
        if(res?.gatingResponse && res?.gatingResponse?.includes("Journey Stops")) {
          setOpenErrorModal(true);
          setRetryCount(2);
          setJourneyStops(true);
          setErrorMessage(GATTING_JOURNEY_STOPS1);
          setErrorContent(GATTING_JOURNEY_STOPS2);
        } else {
          setOpenErrorModal(true);
          setMovetoAssisted(true);
          setErrorMessage(BE_DETAILS.replace('#bename#', res?.executiveName).replace('#bemobile#', phoneNumberFormat(res?.executiveContact)))
        }
        return;
      }
      if ((!res?.breDecision && res?.stopJourneyFlagOfferEngine) || res?.stopJourneyFlagOfferEngine) {
        setAlertTitle(true);
        setOpenErrorModal(true);
        setMovetoAssisted(true);
        setErrorMessage(BE_DETAILS.replace('#bename#', res?.executiveName).replace('#bemobile#', phoneNumberFormat(res?.executiveContact)))
        return;
      }
      else if ((!res?.getCIBILReport || !res?.runEMIEngine || !res?.ltvEngine || !res?.pricingEngine || !res?.gatingScreeningOutcome || !res?.breDecision) && retryCount >= 1) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(RESEND_OTP_ERROR_TEXT);
      }
      else if ((!res?.getCIBILReport || !res?.runEMIEngine || !res?.ltvEngine || !res?.pricingEngine || (isFinalOffer ? false : !res?.gatingScreeningOutcome) || !res?.breDecision)) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
      }
      else {
        setConfirmationScreen(false)
        dispatch(pageBasicDetails({ finalOffer: res, initialOffer: res, completedPages: { ...completedPages, animationPage: true } }))
        setTimeout(() => {
          navigate(isFinalOffer ? '/finaloffer' : '/initialoffer');
        }, 5000)
      }
      setRetryCount(retryCount + 1)
    } else {
      if(!res?.gatingScreeningOutcome && res?.stopJourneyFlagOfferEngine) {
        setAlertTitle(true);
        if(res?.gatingResponse && res?.gatingResponse?.includes("Journey Stops")) {
          setOpenErrorModal(true);
          setRetryCount(2);
          setJourneyStops(true);
          setErrorMessage(GATTING_JOURNEY_STOPS1);
          setErrorContent(GATTING_JOURNEY_STOPS2);
        } else {
          setOpenErrorModal(true);
          setMovetoAssisted(true);
          setErrorMessage(BE_DETAILS.replace('#bename#', res?.executiveName).replace('#bemobile#', phoneNumberFormat(res?.executiveContact)))
        }
        return;
      }
      if (!res?.breDecision && res?.stopJourneyFlagOfferEngine) {
        setAlertTitle(true);
        setOpenErrorModal(true);
        setMovetoAssisted(true);
        setErrorMessage(BE_DETAILS.replace('#bename#', res?.executiveName).replace('#bemobile#', phoneNumberFormat(res?.executiveContact)))
        return;
      }
      if (retryCount >= 1) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(RESEND_OTP_ERROR_TEXT);
      } else if (!res?.getCIBILReport || !res?.runEMIEngine || !res?.ltvEngine || !res?.pricingEngine || !res?.gatingScreeningOutcome || !res?.breDecision || !res?.response?.data) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
      }
      setRetryCount(retryCount + 1)
    }
  };

  let payload = initailOfferPayloadGenerate(storeValues, isFinalOffer);

  const initiateInitialOffer = async () => {
    initialOfferServices.initialOffer(
      cb,
      isFinalOffer ? (preApprovedJourney ? GENERATE_PRE_APPROVED_FINAL_OFFER : GENERATE_FINAL_OFFER) : GENERATE_INITIAL_OFFER,
      payload
    );
  }

  useEffect(() => {
    if (completedPages?.animationPage) {
      navigate(isFinalOffer ? '/finaloffer' : '/initialoffer');
    }
    initiateInitialOffer();
  }, [])

  return (
    <div className='animation-wrapper'>
      {!openErrorModal ?
        !confirmationScreen ?
          (window.innerWidth > 1024 ? <video src={desktopVideo} muted autoPlay={true} style={{ width: "100%", height: '501px' }} /> : <video src={mobileVideo} muted autoPlay={true} style={{ width: "100%", height: 'auto' }} />)
          : <>
            <div className="animation-container">
              <div className="landing-page-body">
                <Box>
                  <Paper elevation={3}>
                    <div className='animation-text-box'>{ANIMATION_TEXT1}</div>
                    <div>
                      <img src={journeyType?.includes("Car") ? carAnimation : bikeAnimation} alt="" className='animation-img' />
                    </div>
                    <Paper className='animation-bottom-text'>
                      {ANIMATION_TEXT2}
                    </Paper>
                  </Paper>
                </Box>

              </div>
            </div>
          </>
        :
        <ErrorModal
          errorTitle={alertTitle ? ALERT_TITLE : ERROR_TEXT}
          errorContent={errorMessage}
          errorSubContent={errorContent}
          openErrorModal={openErrorModal}
          closeErrorModal={() => {
            if (retryCount <= 1 && !movetoAssisted) {
              initiateInitialOffer();
              setOpenErrorModal(false)
            } else if (retryCount === 2 || movetoAssisted) {
              let dropOffReason = isFinalOffer ? "Final Offer Dropoff" : "Initial Offer Dropoff"
              if (retryCount === 2 && journeyStops) dropOffReason = "Move to assisted";
              if (movetoAssisted) dropOffReason = "Move to assisted"; 
              dropOffApiCall(dropOffReason);
              setTimeout(() => {
                sessionStorage.clear();
                window.location.href = '/';
              }, 0)
            }
          }} buttonText={(retryCount <= 1 && !movetoAssisted) ? RETRY_ACTION : null} />
      }
    </div>
  )
}

export default Landing;
