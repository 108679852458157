import MasterServices from "../services/masters";
import { store } from '../store/index'
import { getJourneyType, getVehicleType } from "./utils";

export function updateJourneyStatus(screenID, leadStatus, vkycTrackingId) {
    const storeValues = store.getState();
    const { pageDetails: { currentApplication, preApprovedJourney, aggregatorJourney, aggregatorHandshakeResp } } = storeValues || {};
    const payload = {
        D2CApplicantID : currentApplication?.d2CApplicantID,
        screenID : screenID,
        vehicleType: currentApplication?.vehicleType || getVehicleType(storeValues?.pageDetails?.journeyType),
        journeyType: currentApplication?.journeyType || getJourneyType(storeValues?.pageDetails?.journeyType),
        aggregatorName: aggregatorJourney ? aggregatorHandshakeResp?.leadSource : "",
        preApproved: preApprovedJourney ? "Y" : "N",
        leadStatus: leadStatus || currentApplication?.leadStatus || "Basic Details in Progress",
        vkycTrackingId: vkycTrackingId || ""
    }
    if (screenID === 99 || screenID === 100) payload.screenID = 15;
    MasterServices.updateJourneyStatus(()=>{}, payload);
}