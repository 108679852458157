/* API Domains*/
export const GENERATE_OTP = 'v1/vehicleloan/initialoffer/mobileotp';
export const VERIFY_OTP = 'v1/vehicleloan/initialoffer/verifymobileotp';
export const AADHAR_VALIDATION = 'v1/vehicleloan/initialoffer/uidai/otp';
export const AADHAR_OTP_VERIFICATION = 'v1/vehicleloan/initialoffer/uidai/verifyotp';
export const GET_MASTER_DATA = 'v1/vehicleloan/initialoffer/masterdata/';
export const DEDUPE_VERIFICATION = 'v1/vehicleloan/initialoffer/initialinfo';
export const INITIAL_OFFER_DATA = 'v1/vehicleloan/initialoffer/';
export const GENERATE_INITIAL_OFFER = 'v1/vehicleloan/offer/generateoffer';
export const USER_DROP_OFF = 'v1/journey/dropoff';
export const VERIFICATION_DATA = 'v1/vehicleloan/finaloffer/';
export const SAVE_INITIAL_OFFER = 'v1/vehicleloan/offer/save/initialoffer';
export const HOME_PAGE_TILES_CONFIG = 'v1/journey/tilesconfig';
export const AGGREGATOR_HANDSHAKE_URL = 'v1/aggregator/handshake';
export const ROADMAP_URL = 'v1/vehicleloan/roadmap';
export const GENERATE_FINAL_OFFER = 'v1/vehicleloan/finaloffer/offer';
export const EXPOSURE_ETB_URL = 'v1/vehicleloan/finaloffer/exposure/etb';
export const DEVIATION_URL = 'v1/vehicleloan/finaloffer/deviation';
export const STP_URL = 'v1/vehicleloan/finaloffer/stp';
export const SAVE_FINAL_OFFER = 'v1/vehicleloan/finaloffer/offer/save';
export const GET_FINAL_OFFER_ACCEPTANCE='v1/vehicleloan/finaloffer/getfinalofferacceptance';
export const SAVE_FINAL_OFFER_ACCEPTANCE='v1/vehicleloan/finaloffer/savefinalofferacceptance';
export const GET_VIDEO_KYC_URL = '/v1/vehicleloan/videokyc/customerdata';
export const VIDEO_KYC_TRACKING = '/v1/vehicleloan/videokyc/tracking';
export const GENERATE_PRE_APPROVED_FINAL_OFFER = 'v1/vehicleloan/preapproved/finaloffer/offer';
export const UPDATE_JOURNEY_STATUS = '/v1/vehicleloan/journey/status';
export const GENERATE_AGGREGATOR_INITIAL_OFFER = '/v1/vehicleloan/offer/generateaggregatoroffer';
export const VKYC_SYNC = '/v1/vehicleloan/videokyc/update/sfsync';
export const MO_ENGAGE_URL = '/v1/vehicleloan/initialoffer/moengage';