/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";import { BASIC_DETAILS_STEP_HEADING, CLOSE_ACTION, DEDUPE_ERROR, ERROR_TEXT, OTP_GENERATION_FAILED_TRY_AGAIN } from "../../utils/cms";
import FullNameInput from "../../components/CustomInput/FullNameInput/FullNameInput";
import SelectInput from "../../components/CustomInput/SelectInput";
import MasterServices from "../../.././src/services/masters";
import { useDispatch, useSelector } from "react-redux";
import { getJourneyType, getVehicleType } from "../../utils/utils";
import { pageBasicDetails } from "../../store/pageDetails";

const DemographicDetails = ({
	values,
	setValues,
	setIsDisabled,
	isDisabled,
	maritalStatus,
	setLoading,
	setModalOptions,
	responseData,
	setResponseData,
	...props
}) => {
	const dispatch = useDispatch();
	const [options, setOptions] = useState({QUALIFICATION: [{"code":"B8","value":"+2"},{"code":"P3","value":"A.C.S"},{"code":"B2","value":"B.A"},{"code":"B3","value":"B.COM"},{"code":"P6","value":"B.E"},{"code":"B1","value":"B.Sc"},{"code":"P1","value":"C.A"},{"code":"P16","value":"DPEC"},{"code":"P22","value":"D.B.A"},{"code":"P11","value":"D.B.M"},{"code":"P10","value":"D.C.A"},{"code":"P21","value":"D.I.R.&P.M"},{"code":"P17","value":"D.M.E"},{"code":"P14","value":"D.M.M"},{"code":"DIPL","value":"DIPLOMAVOCATIONAL"},{"code":"ELEM","value":"ELEMENTARY"},{"code":"GRAD","value":"GRADUATE"},{"code":"P9","value":"H.R.D"},{"code":"HSEC","value":"HIGHERSECONDARY"},{"code":"P2","value":"I.C.W.A"},{"code":"P19","value":"ICWAINTER"},{"code":"ILLI","value":"ILLITERATE"},{"code":"P12","value":"L.L.B"},{"code":"B5","value":"M.A"},{"code":"P4","value":"M.B.A"},{"code":"P5","value":"M.C.A"},{"code":"B6","value":"M.COM"},{"code":"P7","value":"M.E"},{"code":"P20","value":"M.M.S"},{"code":"B4","value":"M.SC"},{"code":"P18","value":"OTHERS"},{"code":"P8","value":"P.G.D.C.A"},{"code":"P15","value":"P.G.D.M.M"},{"code":"PGRA","value":"POSTGRADUATE"},{"code":"PROF","value":"PROFESSIONAL"},{"code":"B7","value":"SSLC"},{"code":"SECO","value":"TENTH"}]});
	const storeValues = useSelector((state) => state);
	const [errors, setErrors] = useState({});
	const {
	  pageDetails: { journeyType, currentApplication, demographicDetails }
	} = storeValues || {};
	const payload = {
		D2CApplicantID: currentApplication?.d2CApplicantID || "",
		vehicleType: getVehicleType(journeyType),
		journeyType: getJourneyType(journeyType),
		screenID: "17",
		referenceNumber: currentApplication?.referenceNumber || ''
	};
	// const callBackFun = (success, response, type) => {
	// 	setLoading(false);
	// 	if (success) {
	// 		setOptions({
	// 			...options,
	// 			QUALIFICATION: response.cacheValuesList,
	// 		});
	// 	}
	// };

	// const getEducationQualification = () => {
	// 	setLoading(true);
	// 	MasterServices.getMasterList(
	// 		callBackFun,
	// 		'EDUCATIONALQUALIFICATIONS',
	// 		'EDUCATIONALQUALIFICATIONS'
	// 	);
	// }

	const callbackFunction = (success, response, type) => {
		setLoading(false)
		if (success === true) {
			setValues(response);
			dispatch(pageBasicDetails({ demographicDetails: response }));
			if (response?.qualificationCode !== "") {
				const qualificationDetail =
					options?.['QUALIFICATION'].find(
						(option) =>
						option.code ===
						response.qualificationCode || option.value ===
						response.qualificationCode
					);
				setValues({
					...response,
					qualificationCode: qualificationDetail,
				});
			}
		} else {
			setModalOptions({
			errorTitle: ERROR_TEXT,
			errorContent: DEDUPE_ERROR,
			errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
			openErrorModal: true,
			primaryAction: true,
			primaryBtnText: CLOSE_ACTION,
			callBackAction: (val) => {
				setModalOptions({})
			},
			});
		}
	};

	useEffect(() => {
		setValues({});
		setLoading(true);
		MasterServices.getVerificationDetails(
			callbackFunction,
			"getdemography",
			"getdemography",
			payload
		);
		///getEducationQualification();
		// setOptions({
		// 	...options,
		// 	QUALIFICATION: [{"code":"B8","value":"+2"},{"code":"P3","value":"A.C.S"},{"code":"B2","value":"B.A"},{"code":"B3","value":"B.COM"},{"code":"P6","value":"B.E"},{"code":"B1","value":"B.Sc"},{"code":"P1","value":"C.A"},{"code":"P16","value":"DPEC"},{"code":"P22","value":"D.B.A"},{"code":"P11","value":"D.B.M"},{"code":"P10","value":"D.C.A"},{"code":"P21","value":"D.I.R.&P.M"},{"code":"P17","value":"D.M.E"},{"code":"P14","value":"D.M.M"},{"code":"DIPL","value":"DIPLOMAVOCATIONAL"},{"code":"ELEM","value":"ELEMENTARY"},{"code":"GRAD","value":"GRADUATE"},{"code":"P9","value":"H.R.D"},{"code":"HSEC","value":"HIGHERSECONDARY"},{"code":"P2","value":"I.C.W.A"},{"code":"P19","value":"ICWAINTER"},{"code":"ILLI","value":"ILLITERATE"},{"code":"P12","value":"L.L.B"},{"code":"B5","value":"M.A"},{"code":"P4","value":"M.B.A"},{"code":"P5","value":"M.C.A"},{"code":"B6","value":"M.COM"},{"code":"P7","value":"M.E"},{"code":"P20","value":"M.M.S"},{"code":"B4","value":"M.SC"},{"code":"P18","value":"OTHERS"},{"code":"P8","value":"P.G.D.C.A"},{"code":"P15","value":"P.G.D.M.M"},{"code":"PGRA","value":"POSTGRADUATE"},{"code":"PROF","value":"PROFESSIONAL"},{"code":"B7","value":"SSLC"},{"code":"SECO","value":"TENTH"}]
		// });
	}, []);

	useEffect(() => {
		if (
			values.fatherName &&
			values.motherName &&
			values.qualificationCode &&
			(demographicDetails?.maritalStatus === "Single" ? true : values.spouseName)
		) {
			setIsDisabled(false);
		} else {
			if (!isDisabled) {
				setIsDisabled(true);
			}
		}
	}, [values, errors]);

	return (
		<div className="demographic-details">
			<div className="heading">
				{BASIC_DETAILS_STEP_HEADING.replace(
					"#category#",
					"Demographic "
				)}
				{<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
			</div>
			<FullNameInput
				values={values}
				errors={errors}
				setErrors={setErrors}
				label={"Father's Name"}
				setFieldValue={(val) =>
					setValues({ ...values, fatherName: val })
				}
				name={"fatherName"}
				maxLength={40}
			/>
			<FullNameInput
				values={values}
				errors={errors}
				setErrors={setErrors}
				label={"Mother's Name"}
				setFieldValue={(val) =>
					setValues({ ...values, motherName: val })
				}
				name={"motherName"}
				//disabled={!values.fatherName?.length}
				maxLength={40}
			/>
			{(demographicDetails?.maritalStatus === "MARR" || demographicDetails?.maritalStatus === "MARRIED" || demographicDetails?.maritalStatus === "Marr" || demographicDetails?.maritalStatus === "Married") && (
				<FullNameInput
					values={values}
					errors={errors}
					setErrors={setErrors}
					label={"Spouse Name"}
					setFieldValue={(val) =>
						setValues({ ...values, spouseName: val })
					}
					name={"spouseName"}
					//disabled={!values.fatherName?.length}
					maxLength={40}
				/>
			)}			
			<SelectInput
				options={options?.['QUALIFICATION']}
				label={"Customer Qualification"}
				name={"qualificationCode"}
				TextOnlySearch
				values={values}
				setValues={setValues}
				errors={errors?.["qualification"]}
				//disabled={!values.motherName?.length}
			/>
		</div>
	);
};

export default DemographicDetails;
