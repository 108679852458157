/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Button from "@mui/material/Button";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CheckIcon from "@mui/icons-material/Check";
import FormGenerate from "../../components/FormGenerate/FormGenerate";
import CheckboxInput from "../../components/CustomInput/CheckboxInput";
import { screenData } from "../../utils/screenData";
import SelectInput from "../../components/CustomInput/SelectInput";
import {
  BASIC_DETAILS_HEADING,
  SAVE_CONTINUE_ACTION,
  BASIC_DETAILS_SUBHEADING_1,
  BASIC_DETAILS_STEP_HEADING,
  BASIC_DETAILS_WHATSAPP_TEXT,
  JOURNEY_TYPE_1,
  JOURNEY_TYPE_3,
  JOURNEY_TYPE_2,
  JOURNEY_TYPE_4,
  ERROR_TEXT,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  DEDUPE_ERROR,
  AGGREGATOR_BASIC_DETAILS_SUB_HEADING,
  AGGREGATOR_BASIC_DETAILS_STEPPER_HEADING,
  PINCODE_VALIDATION_MESSAGE,
  MONTHLY_EMI_ERROR_MESSAGE,
  TW_ORP_ERROR_MESSAGE,
  GATTING_JOURNEY_STOPS3,
  RESEND_OTP_ERROR_TEXT,
  BE_DETAILS_3,
  RETRY_ACTION,
  PINCODE_BE_MAP_ERROR
} from "../../utils/cms";
import { useNavigate, useParams } from "react-router-dom";
import { addCompletedScreen, updateCompletedScreens } from "../../store/features/toolsSlice";
import { useEffect, useState } from "react";
import {
  SAVEANDCONTINUEBUTTON,
  CITY,
  MARITALSTATUS,
  OCCUPATION,
  PROFESSIONALCATEGORY,
  section_1_data,
  section_2_data,
  section_1_data_2_wheeler,
  MAKE,
  MODEL,
  VARIANT,
  ONROADPRICE,
  section2UsedLoan,
  sectionRefinanceCar,
  GENDER_SPEC,
  transgenderOptions,
  TRANS_CODE,
  preApprovedJourneySection,
  structuringDataHandler,
  groupingFunction,
  groupsToOptionsGenerate
} from "./data";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import MasterServices from "../../services/masters";
import { pageBasicDetails } from "../../store/pageDetails";
import Loader from "../../components/Loader/Loader";
import PhoneNumberInput from "../../components/CustomInput/PhoneNumberInput/PhoneNumberInput";
import { changeValueToCurrency, cursorPositionForCurrencyInput, getJourneyType, onlyNumbersAccept, validatingValueHandler, phoneNumberFormat,   currentScreenDetails, isUatEnvironment } from "../../utils/utils";
import TextFieldInput from "../../components/CustomInput/TextFieldInput";
import ErrorModal from "../../components/ErrorModal";
import step1Active from '../../assets/BasicDetailsStepper/step-1-active.png'
import step2Active from '../../assets/BasicDetailsStepper/step-2-active.png'
import step3ActiveCar from '../../assets/BasicDetailsStepper/step-3-active-car.png'
import step3ActiveBike from '../../assets/BasicDetailsStepper/step-3-active-bike.png'
import step1Inactive from '../../assets/BasicDetailsStepper/step-1-inactive.png'
import step2Inactive from '../../assets/BasicDetailsStepper/step-2-inactive.png'
import step3InactiveCar from '../../assets/BasicDetailsStepper/step-3-inactive-car.png'
import step3InactiveBike from '../../assets/BasicDetailsStepper/step-3-inactive-bike.png'
import { initailOfferPayloadGenerate } from "../InitialOfferPage/ReuseFunctions";
import { GENERATE_AGGREGATOR_INITIAL_OFFER } from "../../config/config";
import { dropOffApiCall } from "../../utils/dropOff";
import { Decryption } from "../../utils/payload";
let aggregatorJourneyOffer = false;

function BasicDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step = 0 } = useParams();

  const storeValues = useSelector((state) => state);
  const {
    pageDetails: { journeyType, currentApplication, completedPages, cityCode, aggregatorJourney, aggregatorHandshakeResp, preApprovedJourney, config, encryptedPerfios, dropOffScreenRoute, professionPageData }, userDetails } = storeValues || {};
  const [responseData, setResponseData] = useState();
  const [values, setValues] = useState({ whatsAppRegisteredFlag: true });
  const [onRoadPrice, setOnRoadPrice] = useState(null);
  const [errors, setError] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [maritalList, setMaritalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSubMessage, setErrorSubMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [clearCurrentSession, setClearCurrentSession] = useState(false);
  const [section1Data, setSection1Data] = useState(section_1_data);
  const [previousPincode, setpreviousPincode] = useState(null);
  const [disableORP, setDisableORP] = useState(false);
  const [movetoAssisted, setMovetoAssisted] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [journeyStops, setJourneyStops] = useState(false);
  const [errorContent, setErrorContent] = useState('');
  const [prevNextBtnActive, setPrevNextBtnActive] = useState({next: false, prev: false}); 
  let [groups, setGroups] = useState({
    makeCode: {},
    modelCode: {},
    variantCode: {}
  })

  const mmvType = `${journeyType === JOURNEY_TYPE_3 ? 'TW' : 'PV'}`

  useEffect(() => {
    if (journeyType == JOURNEY_TYPE_3) {
      setSection1Data(section_1_data_2_wheeler)
    }
    if (preApprovedJourney) {
      setSection1Data(preApprovedJourneySection)
    }
  }, [journeyType])

  const payload = {
    D2CApplicantID: currentApplication?.d2CApplicantID || "",
    vehicleType: currentApplication?.vehicleType || (storeValues?.pageDetails?.journeyType === "Two Wheeler Loan" ? "H" : "C"),
    journeyType: currentApplication?.journeyType || getJourneyType(storeValues?.pageDetails?.journeyType),
    customerType: currentApplication?.customerType,
    SFApplicantId: currentApplication?.sfapplicantId || "",
    loanApplicationId: currentApplication?.loanApplicationId || "",
    leadNumber: currentApplication?.leadId || "",
    documentId: currentApplication?.documentId || "",
    applicantId: currentApplication?.sfapplicantId || "",
  }

  const getDate = (val, payload) => {
    const splittedDate = val?.split(' ')
    if (splittedDate?.[0]) {
      if (payload) return splittedDate?.[0];
      const newDate = splittedDate[0]?.split('-');
      return newDate?.[2] + '/' + newDate?.[1] + '/' + newDate?.[0];
    }
    return val;
  }

  useEffect(() => {
    if (
      parseInt(step) === 0 &&
      (values?.whatsAppRegisteredFlag ||
        (!errors?.whatsAppCellNumber &&
          values?.whatsAppCellNumber?.length === 11)) &&
      values?.maritalStatus && (responseData?.genderCode === "T" ? values?.[GENDER_SPEC] : true)
    ) {
      enableSubmitButton();
    } else if (
      parseInt(step) === 1 &&
      !errors?.existingEMIPerMonth &&
      values?.professionalCode &&
      values?.occupationCode &&
      values?.declaredMonthlyIncome &&
      values.declaredMonthlyIncome != 0
      && (journeyType == JOURNEY_TYPE_3 ? true : (parseInt(values?.declaredMonthlyIncome?.split(',')?.join('')) > parseInt((values?.existingEMIPerMonth?.split(',')?.join('')))))
    ) {
      enableSubmitButton();
    } else if (
      parseInt(step) === 2 && (journeyType == JOURNEY_TYPE_2 || journeyType == JOURNEY_TYPE_4)
        ? (values?.locationPINCode?.length === 6 &&
          values?.estimatedPrice && values?.estimatedPrice != 0 &&
          !errors?.estimatedPrice)
        : (values?.locationPINCode?.length === 6 &&
          values?.cityCode &&
          values?.makeCode &&
          values?.modelCode &&
          values?.variantCode && onRoadPrice && onRoadPrice != 0 &&
          !errors?.onRoadPrice)
    ) {
      enableSubmitButton();
    } else if (parseInt(step) === 1 && preApprovedJourney && values?.professionalCode && values?.occupationCode) {
      enableSubmitButton();
    }
    else {
      if (!isDisabled) {
        setIsDisabled(true);
      }
    }

  }, [values, onRoadPrice, step, errors]);

  const fetchMarritialStatus = () => {
    MasterServices.getMasterList(
      callBackFunc,
      MARITALSTATUS,
      MARITALSTATUS
    );
  }

  const onLoadDataHandling = async (data) => {
    if (data && (data?.maritalStatus?.length || data?.profession || data?.locationPINCode?.length)) {
      if (step == 0) {
        MasterServices.getMasterListReturn(MARITALSTATUS, MARITALSTATUS)
          .then((res) => {
            let options = res?.cacheValuesList;
            let maritialOption = options?.find(
              (each) => each.code == data.maritalStatus
            );
            let whatsAppCellNumber = data.whatsAppCellNumber;
            let whatsAppRegisteredFlag =  dropOffScreenRoute === undefined ? true : data.whatsAppRegisteredFlag === "Y" ? true : false;
            setValues({ ...values, maritalStatus: maritialOption, whatsAppCellNumber : whatsAppCellNumber, whatsAppRegisteredFlag : whatsAppRegisteredFlag });
            setMaritalList(options);
            setLoading(false);
          })
          .catch(() => { });
      } else if (step == 1) {
        let professionalCode = data?.profession || data?.professionalCode;
        let optionsUpdate = {};
        if (professionalCode) {
          let options = await fetchProfessionalOptions(professionalCode);
          options?.map((each, i) => {
            if (i == 0) {
              optionsUpdate.professionalCode = each?.cacheValuesList;
            }
            if (i == 1) {
              optionsUpdate.occupationCode = each?.cacheValuesList || each?.cacheValuesListForProfession;
            }
            setOptions(optionsUpdate);
          });
        }
        let update = {
          whatsAppCellNumber: data.whatsAppCellNumber,
          existingEMIPerMonth:
            changeValueToCurrency.format(
              data.existingEMIPerMonth
            ) === "0" && data?.existingEMIPerMonth !== 0 && data?.existingEMIPerMonth !== "0"
              ? ""
              : changeValueToCurrency.format(
                data.existingEMIPerMonth
              ),
          declaredMonthlyIncome:
            changeValueToCurrency.format(
              data.declaredMonthlyIncome ||
              data?.monthlyIncome
            ) === "0" && (data?.monthlyIncome !== 0 || data?.declaredMonthlyIncome !== 0) && (data?.monthlyIncome !== "0" || data?.declaredMonthlyIncome !== "0")
              ? ""
              : changeValueToCurrency.format(
                data.declaredMonthlyIncome ||
                data?.monthlyIncome
              ),
        };
        let profOption = optionsUpdate.professionalCode?.find(
          (each) => (each.code == (data?.profession || data?.professionalCode) || each.value?.toUpperCase() == data?.profession?.toUpperCase())
        );
        update.professionalCode = profOption;
        if (data.occupationCode || data?.occupation) {
          let ocuOption = optionsUpdate.occupationCode?.find(
            (each) => (each.code == (data?.occupation || data.occupationCode) || each.value?.toUpperCase() == data?.occupation?.toUpperCase())
          );
          update.occupationCode = ocuOption;
        }
        let sectionData = section_1_data
        if (journeyType == JOURNEY_TYPE_3) {
          sectionData = section_1_data_2_wheeler
        }
        if (preApprovedJourney) {
          sectionData = preApprovedJourneySection
        }
        if (update?.professionalCode && sectionData[2]) {
          sectionData[2].disabled = false;
        }
        if (update?.declaredMonthlyIncome && journeyType != JOURNEY_TYPE_3 && sectionData[3]) {
          sectionData[3].disabled = false;
        }
        setSection1Data([...sectionData])
        setValues(update);
        setLoading(false);
      } else if (step == 2) {
        let optionsUpdate = {};
        let groupsUpdate = {}
        let options = await fetchVehicleDetailsOptions(
          data?.locationPINCode
        )
        options?.forEach((each, i) => {
          if (i == 0 && each?.cacheValuesList) {
            optionsUpdate.cityCode = each?.cacheValuesList;
          }
          if (i == 1 && each?.[0]?.records) {
            let [key, makeOptions, groupOfMakes] = structuringDataHandler(each[0]?.records, mmvType, journeyType === JOURNEY_TYPE_1);
            groupsUpdate[key] = groupOfMakes;
            optionsUpdate[key] = makeOptions;

            if (data?.makeCode) {
              let selectedMake = groupOfMakes[data?.makeCode];
              let groupOfModels = groupingFunction(selectedMake, MODEL);
              groupsUpdate[MODEL] = groupOfModels;
              let [key, groupOptions] = groupsToOptionsGenerate(groupOfModels, MODEL);
              optionsUpdate[key] = groupOptions;
            }

            if (data?.[MAKE] && data?.[MODEL]) {
              let selectedModel = groupsUpdate?.modelCode[data?.modelCode];
              let groupOfVariants = groupingFunction(selectedModel, VARIANT);
              groupsUpdate[VARIANT] = groupOfVariants;
              let [key, groupOptions] = groupsToOptionsGenerate(groupOfVariants, VARIANT);
              optionsUpdate[key] = groupOptions;
            }
          }
          setOptions(optionsUpdate);
          setGroups(groupsUpdate)
        });

        let update = {
          locationPINCode: data.locationPINCode,
        };
        let cityOption = optionsUpdate.cityCode?.find(
          (each) => (each?.code?.split('-')?.[1] || each.code) == data.cityCode
        );
        update.cityCode = cityOption;

        let makeOption = optionsUpdate.makeCode?.find(
          (each) => each.code == data.makeCode
        );
        update.makeCode = makeOption;

        let modelOption = optionsUpdate.modelCode?.find(
          (each) => each.code == data.modelCode
        );
        update.modelCode = modelOption;

        let variantOption = optionsUpdate.variantCode?.find(
          (each) => each.code == data.variantCode
        );
        update.variantCode = variantOption;

        if (data.onRoadPrice || variantOption) {
          setDisableORP(false);
          let payload = {
            vehicleType: journeyType === JOURNEY_TYPE_3 ? '2' : '1',
            cityId: data?.cityCode || '',
            variantCode: variantOption?.code,
            leadId: currentApplication?.leadId || "",
            makerId: "0",
            D2CApplicantID: currentApplication?.d2CApplicantID || "",
          };
          MasterServices.initialOfferPage(
            callBackFunc,
            ONROADPRICE,
            ONROADPRICE,
            payload
          );
        }
        if (data?.onRoadPrice && (journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4)) {
          setOnRoadPrice(null);
          update.estimatedPrice = changeValueToCurrency.format(JSON.parse(data?.onRoadPrice));
        }
        setValues(update);
        setLoading(false);
      }
    } else {
      if (step == 0) {
        setLoading(true);
        fetchMarritialStatus();
      } else if (step == 1) {
        setLoading(true);
        MasterServices.getMasterList(
          callBackFunc,
          PROFESSIONALCATEGORY,
          PROFESSIONALCATEGORY
        );
      } else if (step == 2) {
        setLoading(false);
      }
    }
  };
  const fetchProfessionalOptions = async (professionalCode) => {
    try {
      const res = await Promise.all([
        MasterServices.getMasterListReturn(
          PROFESSIONALCATEGORY,
          PROFESSIONALCATEGORY
        ),
        MasterServices.getMasterListReturn(professionalCode, OCCUPATION),
      ]);
      return res;
    } catch {
    }
  };
  const fetchVehicleDetailsOptions = async (pincode) => {
    let optionRoutes = [];
    if (pincode?.length === 6) {
      setpreviousPincode(pincode)
      optionRoutes.push(`CITY/${pincode?.slice(0, 2)}`);
      optionRoutes.push(`${journeyType === JOURNEY_TYPE_3 ? 'TW' : 'PV'}`);
    }

    try {
      const res = await Promise.all(
        optionRoutes?.map((each) => MasterServices.getMasterListReturn(each))
      );
      return res;
    } catch {
    }
  };

  const onLoadCallback = (success, response, type) => {
    if (success) {
      if (step == 0) {
        if (response?.aadharDistrictName === 'null' || response?.aadharPinCode === 'null' || response?.aadharStateName === 'null' || response?.dateOfBirth === 'null') {
          setOpenErrorModal(true);
          setClearCurrentSession(true)
          setErrorMessage(DEDUPE_ERROR);
          setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
          setPageReload(false);
          return;
        } 
        setResponseData(response);
        onLoadDataHandling(response);
      }
      if (step == 1) {
        dispatch(pageBasicDetails({professionPageData: response?.profession ? response : professionPageData}));
        onLoadDataHandling(response?.profession ? response : professionPageData);
        setResponseData(response);
      }
      if (step == 2) {
        onLoadDataHandling(response);
        setResponseData(response);
      }
    } else {
      setLoading(false);
      if (!response?.response?.data) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
        setPageReload(true);
      }
    }
  };

  const offerCb = (success, res) => {
    setLoading(false);
    if(!res?.gatingScreeningOutcome && res?.stopJourneyFlagOfferEngine) {
      if(res?.gatingResponse.includes("Journey Stops")) {
        setOpenErrorModal(true);
        setRetryCount(2);
        setJourneyStops(true);
        setErrorMessage(GATTING_JOURNEY_STOPS3);
        setErrorContent('')
      } else {
        setOpenErrorModal(true);
        setMovetoAssisted(true);
        setErrorMessage(BE_DETAILS_3.replace('#bename#', res?.executiveName).replace('#bemobile#', phoneNumberFormat(res?.executiveContact)))
        setErrorContent('')
      }
      return;
    }
    else if (!res?.getCIBILReport || !res?.runEMIEngine) {
      setOpenErrorModal(true);
      setErrorMessage(DEDUPE_ERROR);
      setErrorContent(retryCount >= 1 ? RESEND_OTP_ERROR_TEXT : OTP_GENERATION_FAILED_TRY_AGAIN);
    } else {
      dispatch(pageBasicDetails({ initialOffer: res }))
      navigate('/roadmap/1');
    }
    setRetryCount(retryCount + 1)
  };


  const aggregatorJourneyInitialOffer = () => {
    aggregatorJourneyOffer = true;
    let payload = initailOfferPayloadGenerate(storeValues);
    payload.professionalId = values?.occupationCode?.professionalId || '';
    payload.pinCode =  currentApplication?.pinCode || aggregatorHandshakeResp?.pinCode || '';
    payload.incomePerMonth = values?.declaredMonthlyIncome?.replaceAll(',', '') || '0';
    payload.existingEMI = values?.existingEMIPerMonth?.replaceAll(',', '') || '0';
    MasterServices.initialOffer(
      offerCb,
      GENERATE_AGGREGATOR_INITIAL_OFFER,
      payload
    );
  }

  const callBackFunc = (success, response, type) => {
    if (success) {
      setLoading(false);
      if (type === PROFESSIONALCATEGORY) {
        setLoading(false);
        setOptions({ ...options, professionalCode: response?.cacheValuesList || response?.cacheValuesListForProfession });
      } else if (type === SAVEANDCONTINUEBUTTON) {
        if (step == 2 && response?.statusCode !== '200' && response?.includes('Bank Executive details')) {
          setOpenErrorModal(true);
          setErrorMessage(PINCODE_BE_MAP_ERROR);
          //setRetryCount(2);
          return;
        }

        let pageName = "mmvPage"
        if (step == 0) {
          pageName = "personalPage";
          MasterServices.makeMoEngage(
            {
                d2cId: currentApplication?.d2CApplicantID,
                screenId: "7"
            }
          ); 
        } else if (step == 1) {
          pageName = "professionPage";
          MasterServices.makeMoEngage(
            {
                d2cId: currentApplication?.d2CApplicantID,
                screenId: "8"
            }
          );
        }

        dispatch(pageBasicDetails({ completedPages: { ...completedPages, [pageName]: true } }));

        if (step == 1 && (aggregatorJourney || preApprovedJourney)) {
          if (aggregatorJourney) {
            setLoading(true);
            aggregatorJourneyInitialOffer();
            return;
          }
          navigate('/roadmap/1')
          return;
        }
        if (step == 2) {
          navigate("/animation");
          return;
        }
        navigate("/basicdetails/" + (parseInt(step) + 1));
      } else if (type === MARITALSTATUS) {
        setMaritalList(response?.cacheValuesList);
        return;
      } else if (type == ONROADPRICE) {
        if (response?.calculatedOnRoadPrice) {
          let onRoadPrice = changeValueToCurrency.format(response?.calculatedOnRoadPrice) === "0" ? "" : changeValueToCurrency.format(response?.calculatedOnRoadPrice);
          setOnRoadPrice(onRoadPrice);
          if (parseInt(onRoadPrice) > 0) { 
            setDisableORP(true); 
          } else {
            setDisableORP(false);
          }
        } else {
          setDisableORP(false);
        }
      } else {
        setOptions({ ...options, [type]: response?.cacheValuesList || response?.cacheValuesListForProfession });
      }
      dispatch(pageBasicDetails({ [type]: response?.cacheValuesList || response?.cacheValuesListForProfession }));
    } else {
      setLoading(false);
      if (type == ONROADPRICE || type == CITY) return;
      setRetryCount(retryCount + 1)
      if (!response?.response?.data) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(retryCount !== config?.PAGE_RETRY_LIMIT -1 ? OTP_GENERATION_FAILED_TRY_AGAIN : RESEND_OTP_ERROR_TEXT);
      }
    }
  };


  useEffect(() => {
    if (step == 0) {
      setLoading(true);
      payload.referenceNumber = currentApplication?.referenceNumber || '';
      payload.isPreApproved = preApprovedJourney ? 'Y' : 'N';
      payload.isAggregator = aggregatorJourney ? 'Y' : 'N';
      payload.beContactNumber =  currentApplication?.beContactNumber || "";
      payload.isApplicationPreview = dropOffScreenRoute ? true : false;
      MasterServices.initialOfferPage(
        onLoadCallback,
        "getpersonalinfo",
        "",
        payload
      );
    } else if (step == 1) {
      setLoading(true);
      payload.referenceNumber = currentApplication?.referenceNumber || '';
      payload.isApplicationPreview = dropOffScreenRoute ? true : false;    
      MasterServices.initialOfferPage(
        onLoadCallback,
        "getprofessionalinfo",
        "",
        payload
      );
    } else if (step == 2) {
      setLoading(true);
      payload.referenceNumber = currentApplication?.referenceNumber || '';
      MasterServices.initialOfferPage(
        onLoadCallback,
        "getassetinfo",
        "",
        payload
      );
    }
    const [,,route] = currentScreenDetails();
    if(journeyType == JOURNEY_TYPE_3 ) {
      switch (route) {
        case "basicdetails/0":
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{ prev: false, next: dropOffScreenRoute ? true : false },
          }));
          break;
        case "basicdetails/1":
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{
              prev: true,
              next: dropOffScreenRoute ? dropOffScreenRoute !== route : false,
            },
          }));
          break;
        case "basicdetails/2":
          setPrevNextBtnActive((prevNext) => ({
            ...prevNext,
            ...{ prev: true, next: false },
          }));
          break;
        default:
          break;
      }
    }
  }, [step]);


  const enableSubmitButton = () => {
    if (isDisabled) {
      setIsDisabled(false);
    }
  };

  let screenIdtoName = () => {
    if (step == 0) return "PersonalDetails"
    else if (step == 1) return "ProfessionalDetails"
    else if (step == 2) return "AssetDetails"
    else return ""
  }
  const handleRedirect = () => {
    setLoading(true);
    payload.screenCode = parseInt(step) + 1;
    payload.screenName = screenIdtoName()
    if (step == 0 || !step) {
      payload.maritalStatus = values.maritalStatus.code;
      payload.mobileNumber = userDetails?.mobileNumber;
      payload.whatsAppRegisteredFlag = values.whatsAppRegisteredFlag
        ? "Y"
        : "N";
      payload.whatsAppCellNumber = values.whatsAppRegisteredFlag
        ? userDetails?.mobileNumber
        : values.whatsAppCellNumber?.replace(/\s/g, '');
      payload.name = responseData?.firstName + ' ' + responseData?.lastName;
      payload.gender = responseData?.genderCode === "M" ? "MALE" : "FEMALE";
      if (responseData?.genderCode == TRANS_CODE) {
        payload.gender = values[GENDER_SPEC]?.code
      }
      payload.addressLine1 = responseData?.aadharAddressLine1;
      payload.addressLine2 = responseData?.aadharAddressLine2;
      payload.pinCode = responseData?.aadharPinCode;
      payload.city = responseData?.aadharCityName;
      payload.district = responseData?.aadharDistrictName;
      payload.state = responseData?.aadharStateName;
      payload.dob = getDate(responseData?.dateOfBirth, true);
      MasterServices.initialOfferPage(
        callBackFunc,
        "savepersonalinfo",
        SAVEANDCONTINUEBUTTON,
        payload
      );
    }
    if (step == 1) {
      payload.profession = values.professionalCode.code;
      payload.professionalId = values?.occupationCode?.professionalId;
      payload.occupation = values.occupationCode.code;
      payload.occupationValue = values?.occupationCode?.value;
      payload.declaredIncome = values?.declaredMonthlyIncome?.replaceAll(',', '') || '0';
      payload.existingEMI = values?.existingEMIPerMonth?.replaceAll(',', '') || '0';
      payload.isAggregator = aggregatorJourney || false;
      if (aggregatorJourney) {
        payload.purchasePinCode = currentApplication?.pinCode || aggregatorHandshakeResp?.pinCode || '';
      }
      dispatch(pageBasicDetails({ professionlDetails: { declaredIncome: payload?.declaredIncome, existingEMI: payload?.existingEMI } }));
      MasterServices.initialOfferPage(
        callBackFunc,
        "saveprofessionalinfo",
        SAVEANDCONTINUEBUTTON,
        payload
      );
    }
    if (step == 2) {
      payload.locationPINCode = values.locationPINCode;
      payload.cityCode = ((values.cityCode?.code)?.includes('-') ? (values.cityCode?.code)?.split('-')[1] : values.cityCode?.code) || "";
      payload.stateCode = ((values.cityCode?.code)?.includes('-') ? (values.cityCode?.code)?.split('-')[0] : "") || ((cityCode?.[0]?.code)?.includes('-') ? (cityCode?.[0]?.code)?.split('-')[0] : "") || "";
      payload.cityName = values.cityCode?.value || cityCode?.[0]?.value || "";
      payload.makeCode = values.makeCode?.code || "";
      payload.modelCode = values.modelCode?.code || "";
      payload.variantCode = values.variantCode?.code || "";
      payload.makeName = values?.makeCode?.value || "";
      payload.modelName = values?.modelCode?.value || "";
      payload.variantName = values?.variantCode?.value || "";
      payload.onRoadPrice = onRoadPrice?.replaceAll(',', '') || "0";
      if (journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) payload.onRoadPrice = values?.estimatedPrice?.replaceAll(',', '') || "0"
      payload.beContactNumber = values.beContactNumber || "";
      payload.beSFDCId = values.beSFDCId || "";
      payload.beName = values.beName || "";
      payload.beId = values.beId || "";
      payload.manufacturerCode = values.variantCode?.manufacturerCode || "";
      payload.subCategory = values.variantCode?.subCategoryCode || "";
      dispatch(pageBasicDetails({ manufactureCode: values.variantCode?.manufacturerCode || '', subCategory: values.variantCode?.subCategoryCode || '', stateCode: payload.stateCode }));
      MasterServices.initialOfferPage(
        callBackFunc,
        "saveassetdetails",
        SAVEANDCONTINUEBUTTON,
        payload
      );
    }
  };

  const CustomStepIcon = (props) => {
    const stepIcons = {
      1: props.completed ? <CheckIcon /> : props.active ? <img src={step1Active} alt="Journey-Logo" className="journey-logo" style={{ margin: '-4px 0px 0px 2px' }} /> : <img src={step1Inactive} alt="Journey-Logo" className="journey-logo" />,
      2: props.completed ? <CheckIcon /> : props.active ? <img src={step2Active} alt="Journey-Logo" className="journey-logo" /> : <img src={step2Inactive} alt="Journey-Logo" className="journey-logo" />,
      3: props.completed ? <CheckIcon /> : props.active ? <img src={journeyType?.includes("Car") ? step3ActiveCar : step3ActiveBike} alt="Journey-Logo" className="journey-logo" /> : <img src={journeyType?.includes("Car") ? step3InactiveCar : step3InactiveBike} alt="Journey-Logo" className="journey-logo" />,
    };
    return <div className="stepper_svg">{stepIcons[String(props.icon)]}</div>;
  };


  const MmvApiHandlerCallback = (success, res, type) => {
    setLoading(false);
    if (success) {
      let [key, makeOptions, groupOfMakes] = structuringDataHandler(res[0]?.records, type, journeyType === JOURNEY_TYPE_1);
      setGroups({ ...groups, [key]: groupOfMakes });
      setOptions({ ...options, [key]: makeOptions });
    }
  }

  const existingEMIError = (fieldValues) => {
    if (parseInt(fieldValues?.existingEMIPerMonth?.split(',')?.join('')) > parseInt((fieldValues?.declaredMonthlyIncome?.split(',')?.join('')))) {
      setError({
        existingEMIPerMonth: [MONTHLY_EMI_ERROR_MESSAGE]
      })
    } else if (errors?.existingEMIPerMonth) {
      setError({
        existingEMIPerMonth: null
      })
    }
  }

  const afterInputChangeCallback = (updatedValues, name, value) => {
    if (name == "professionalCode") {
      if (value?.code) {
        setLoading(true);
        MasterServices.getMasterList(callBackFunc, value?.code, OCCUPATION);
      }
      setValues({
        ...updatedValues,
        professionalCode: value,
        occupationCode: null,
      });
      setOptions({ ...options, occupationCode: null });
    } else if (name == "occupationCode") {
      let updateSectionData = section1Data?.map(each => {
        if (each.name === 'declaredMonthlyIncome') {
          each.disabled = false;
          return each;
        }
        return each;
      })
      setSection1Data(updateSectionData)
    } else if (name == "declaredMonthlyIncome") {
      let updateSectionData = section1Data?.map(each => {
        if (each.name === 'existingEMIPerMonth') {
          each.disabled = false;
          return each;
        }
        return each;
      })
      setSection1Data(updateSectionData)
      existingEMIError(null);
    } else if (name === "existingEMIPerMonth") {
      existingEMIError(null);
    } else if (name == "locationPINCode") {
      if (value?.length === 6) {
        if (errors.locationPINCode) setError({ ...errors, locationPINCode: null })
        if (previousPincode?.slice(0, 2) !== value?.slice(0, 2)) {
          setpreviousPincode(value);
          setLoading(true);
          setValues({
            ...updatedValues,
            [CITY]: null,
            [MAKE]: null,
            [MODEL]: null,
            [VARIANT]: null,
          });
          setError({
            ...errors,
            onRoadPrice: null
          })
          setOptions({ ...options, [CITY]: [], [MAKE]: [], [MODEL]: [], [VARIANT]: [] });
          setOnRoadPrice(null);
          dispatch(pageBasicDetails({ [CITY]: [], [MAKE]: [], [MODEL]: [], [VARIANT]: [] }));
          MasterServices.getMasterList(callBackFunc, `CITY/${value?.slice(0, 2)}`, CITY);
        }
      } else {
        if (previousPincode?.slice(0, 2) !== value?.slice(0, 2) && values?.cityCode) {
          setValues({
            ...updatedValues,
            [CITY]: null,
            [MAKE]: null,
            [MODEL]: null,
            [VARIANT]: null,
          });
          setError({
            ...errors,
            onRoadPrice: null
          })
          setOptions({ ...options, [MAKE]: [], [MODEL]: [], [VARIANT]: [] });
          dispatch(pageBasicDetails({ [MAKE]: [], [MODEL]: [], [VARIANT]: [] }));
          setOnRoadPrice(null);
        }
      }
    } else if (name == "cityCode" && value?.code) {
      if (values?.variantCode?.code) {
        setOnRoadPrice(null);
        setError({
          ...errors,
          onRoadPrice: null
        })
        setValues({
          ...updatedValues,
          [VARIANT]: null,
        });
      }
      if (groups?.makeCode && Object.keys(groups?.makeCode)?.length) {
        let [key, makeOptions] = groupsToOptionsGenerate(groups?.makeCode, MAKE, mmvType)
        setOptions({ ...options, [key]: makeOptions })
        return;
      }
      setLoading(true);
      MasterServices.getMasterList(MmvApiHandlerCallback, mmvType, mmvType);
    }
    else if (name == MAKE) {
      setValues({
        ...updatedValues,
        [MAKE]: value,
        [MODEL]: null,
        [VARIANT]: null,
      });
      setOnRoadPrice(null);
      setError({
        ...errors,
        onRoadPrice: null
      })
      if (value?.code) {
        let selectedMake = groups?.makeCode?.[value?.code];
        let groupOfModels = groupingFunction(selectedMake, MODEL);
        setGroups({ ...groups, modelCode: groupOfModels })
        let [key, modelOptions] = groupsToOptionsGenerate(groupOfModels, MODEL);
        setOptions({ ...options, [key]: modelOptions, [VARIANT]: [] })
        return
      }
      setOptions({ ...options, [MODEL]: [], [VARIANT]: [] })
    } else if (name == MODEL) {
      setValues({
        ...updatedValues,
        [MODEL]: value,
        [VARIANT]: null,
      });
      setError({
        ...errors,
        onRoadPrice: null
      })
      setOnRoadPrice(null);
      if (value?.code) {
        let selectedModel = groups?.modelCode?.[value?.code];
        let groupOfVariants = groupingFunction(selectedModel, VARIANT);
        setGroups({ ...groups, variantCode: groupOfVariants })
        let [key, variantOptions] = groupsToOptionsGenerate(groupOfVariants, VARIANT);
        setOptions({ ...options, [key]: variantOptions })
        return
      }
      setOptions({ ...options, [VARIANT]: [] });
    } else if (name == VARIANT) {
      setOnRoadPrice(null);
      setError({
        ...errors,
        onRoadPrice: null
      })
      if (value?.code) {
        setLoading(true);
        setDisableORP(false);
        let payload = {
          vehicleType: journeyType === JOURNEY_TYPE_3 ? '2' : '1',
          cityId: ((values.cityCode?.code)?.includes('-') ? (values.cityCode?.code)?.split('-')[1] : values.cityCode?.code) || '',
          variantCode: value?.code,
          leadId: currentApplication?.leadId || "",
          makerId: "0",
          D2CApplicantID: currentApplication?.d2CApplicantID || "",
        };
        MasterServices.initialOfferPage(
          callBackFunc,
          ONROADPRICE,
          ONROADPRICE,
          payload
        );
      }
    } else if (name === "estimatedPrice") {
      (errors?.estimatedPrice) && onRoadPriceSetError(name, parseInt(updatedValues?.[name]?.split(',')?.join('')))
    }
  };


  const onRoadPriceSetError = (name, value) => {
    if ((value < 20000 || value > 500000) && journeyType === JOURNEY_TYPE_3) {
      setError({ ...errors, [name]: [TW_ORP_ERROR_MESSAGE] })
    }
    else if (errors?.[name]) {
      setError({ ...errors, [name]: null })
    }
  }

  const inputsOnBlurHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "locationPINCode" && (value.indexOf(0) === 0 || value.length < 6)) {
      setError({ ...errors, [name]: [PINCODE_VALIDATION_MESSAGE] })
    } else if (name === "locationPINCode" && value?.length === 6) {
      setError({ ...errors, [name]: null })
    } else if (name === "onRoadPrice" || name === "estimatedPrice") {
      onRoadPriceSetError(name, parseInt(value?.split(',')?.join('')))
    } else if (name == "declaredMonthlyIncome") {
      existingEMIError(values);
    } else if(name === "existingEMIPerMonth") {
      existingEMIError(values);
    }
  }
  return (
    <>
      <Loader isLoading={loading} />
      <div className="page-container basic_details">
        <Header
          journeyTitle=""
          title={BASIC_DETAILS_HEADING}
          subtitle={(aggregatorJourney || preApprovedJourney) ? AGGREGATOR_BASIC_DETAILS_SUB_HEADING : BASIC_DETAILS_SUBHEADING_1}
          topSlider={20}
        />
        <div className="top-round-card-header"></div>
        <div className="page-body-content">
          <div className="page-body-contents">
            <div className="basic_details_stepper">
              <Stepper activeStep={step ? parseInt(step) : 0} alternativeLabel>
                <Step>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    className="first-stepper"
                  ></StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                </Step>
                {(!aggregatorJourney && !preApprovedJourney) ? <Step>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    className="third-stepper"
                  ></StepLabel>
                </Step> : null}
              </Stepper>
            </div>
            <div className="user_details">
              {step == 0 ? (
                <>
                  <div className="heading">
                    {BASIC_DETAILS_STEP_HEADING.replace(
                      "#category#",
                      "Personal"
                    )}
                    {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
                  </div>
                  <div className="name">
                    {validatingValueHandler(responseData?.firstName)} {validatingValueHandler(responseData?.lastName)}
                  </div>
                  <div className="gender">
                    {responseData?.genderCode === "M" && "Male"}
                    {responseData?.genderCode === "F" && "Female"}
                    {responseData?.genderCode === TRANS_CODE && "Transgender"}
                  </div>
                  <div className="dob">{getDate(responseData?.dateOfBirth)}</div>
                  <div className="address">
                    <p>{validatingValueHandler(responseData?.aadharAddressLine1?.replaceAll(',', ', '))}</p>
                    <p>{validatingValueHandler(responseData?.aadharAddressLine2)}</p>
                    <p>{validatingValueHandler(responseData?.aadharCityName)}</p>
                    <p>{validatingValueHandler(responseData?.aadharDistrictName)}</p>
                    <p>{validatingValueHandler(responseData?.aadharStateName)}</p>
                    <p>{validatingValueHandler(responseData?.aadharPinCode)}</p>
                  </div>
                  {userDetails?.mobileNumber && <div className="phone">+91 {phoneNumberFormat(userDetails?.mobileNumber)}</div>}
                  <div className="whatsapp_check_cont">
                    <CheckboxInput
                      values={values}
                      setValues={setValues}
                      name="whatsAppRegisteredFlag"
                      label={BASIC_DETAILS_WHATSAPP_TEXT}
                      disabled={
                        responseData?.whatsAppRegisteredFlag === "Y" || prevNextBtnActive.next ? true : false 
                      }
                    />
                  </div>
                  {!values?.whatsAppRegisteredFlag && (
                    <div className="whatsapp_cont">
                      <PhoneNumberInput
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setError}
                        name="whatsAppCellNumber"
                        label="WhatsApp Number"
                        disabled = {prevNextBtnActive.next}
                      />
                    </div>
                  )}
                  {responseData?.genderCode === TRANS_CODE && <div className="marital_status">
                    <SelectInput
                      errors={errors.transgender}
                      options={transgenderOptions}
                      afterInputChangeCallback={() => {
                        if (!maritalList) {
                          setLoading(true);
                          fetchMarritialStatus()
                        }
                      }}
                      label={"Gender Specification"}
                      name={GENDER_SPEC}
                      values={values}
                      setValues={setValues}
                    />
                  </div>}
                  <div className="marital_status">
                    <SelectInput
                      disabled = {prevNextBtnActive.next}
                      errors={errors.select}
                      options={maritalList}
                      label={"Marital Status"}
                      name="maritalStatus"
                      values={values}
                      setValues={setValues}
                    />
                  </div>
                </>
              ) : null}
              {step == 1 ? (
                <>
                  <div className="heading">
                    {(aggregatorJourney ? AGGREGATOR_BASIC_DETAILS_STEPPER_HEADING : BASIC_DETAILS_STEP_HEADING).replace(
                      "#category#",
                      "Professional"
                    )}
                    {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
                  </div>
                  <br />
                  <FormGenerate
                    values={values}
                    setValues={setValues}
                    formInputs={section1Data}
                    errors={errors}
                    options={options}
                    afterInputChangeCallback={afterInputChangeCallback}
                    onBlurHanlder={inputsOnBlurHandler}
                    disabled={prevNextBtnActive.next}
                  />              
                </>
              ) : null}
              {step == 2 ? (
                <>
                  <div className="heading">
                    {BASIC_DETAILS_STEP_HEADING.replace(
                      "#category#",
                      "Vehicle"
                    )}
                    {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
                  </div>
                  <br />
                  <FormGenerate
                    values={values}
                    setValues={setValues}
                    formInputs={
                      journeyType == JOURNEY_TYPE_2
                        ? section2UsedLoan
                        : (journeyType == JOURNEY_TYPE_4 ? sectionRefinanceCar : section_2_data)
                    }
                    errors={errors}
                    options={options}
                    afterInputChangeCallback={afterInputChangeCallback}
                    onBlurHanlder={inputsOnBlurHandler}
                  />
                  {(onRoadPrice || values?.variantCode) && (journeyType === JOURNEY_TYPE_1 || journeyType === JOURNEY_TYPE_3) && (
                    <TextFieldInput
                      formatter="currency"
                      label="On-Road Price (Tentative)"
                      values={{ onRoadPrice }}
                      name="onRoadPrice"
                      onChange={(e) => {
                        let value = e.target?.value;
                        let caret = e.target?.selectionStart
                        value = onlyNumbersAccept(value);
                        value = changeValueToCurrency.format(value);
                        value = value === "0" ? "" : value;
                        if (value.length > 13) return;
                        e.target.value = value;
                        cursorPositionForCurrencyInput(e, onRoadPrice, caret)
                        setOnRoadPrice(value);
                        errors?.onRoadPrice && onRoadPriceSetError("onRoadPrice", parseInt(value?.split(',')?.join('')))
                      }}
                      errors={errors?.onRoadPrice}
                      onBlur={inputsOnBlurHandler}
                      disabled={disableORP}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
          <Footer
            callBackAction={handleRedirect}
            disableButton={isDisabled || loading}
            buttonText={SAVE_CONTINUE_ACTION}
            prevNextBtnActive={prevNextBtnActive}
            removeBtn = {prevNextBtnActive.next}
          />
        </div>
      </div>
      {errorMessage && <ErrorModal errorTitle={errorSubMessage ? 'Information' : ERROR_TEXT} errorContent={errorMessage} errorSubContent={errorSubMessage || errorContent} openErrorModal={openErrorModal} closeErrorModal={() => {
        if (aggregatorJourney && aggregatorJourneyOffer) {
          if (retryCount <= 1 && !movetoAssisted) {
            aggregatorJourneyInitialOffer();
            setOpenErrorModal(false);
            setLoading(true);
          } else if (retryCount === 2 || movetoAssisted) {
            let dropOffReason = "Initial Offer Dropoff"
            if (retryCount === 2 && journeyStops) dropOffReason = "Journey Stops";
            if (movetoAssisted) dropOffReason = "Move to assisted"; 
            dropOffApiCall(dropOffReason);
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = '/';
            }, 0)
          }
          return;
        }
        if (!errorMessage?.includes('our services do not cover your postal code')) {
          if (retryCount !== config?.PAGE_RETRY_LIMIT) {
            handleRedirect();
          }
          if (retryCount === config?.PAGE_RETRY_LIMIT) {
            sessionStorage.clear();
            window.location.href = '/';
          }
        }
        setOpenErrorModal(false);
        setErrorMessage('');
        setErrorSubMessage('');
        setErrorContent('');
        if (pageReload) {
          window.location.reload();
        }
      }} clearCurrentSession={clearCurrentSession} buttonText={(retryCount <= 1 && !movetoAssisted && aggregatorJourney) || retryCount !== config?.PAGE_RETRY_LIMIT ? RETRY_ACTION : null} />}
    </>
  );
}

export default BasicDetailsPage;
