import React from "react";
const BlastComponent = (props) => {
  return (
    <div {...props}>
      <div class="pyro">
        <div class="before"></div>
        <div class="after"></div>
      </div>
    </div>
  );
};
export default BlastComponent;