export const section_1_data_2_wheeler = [
    {
        label: 'Professional Type',
        inputType: 'select',
        options: [],
        hint: 'Eg: Salaried',
        twHint: 'Eg: Salaried',
        name: 'professionalCode',
    },
    {
        label: 'Occupation',
        inputType: 'select',
        options: [],
        hint: 'Eg: Software Engineer, Manager',
        twHint: 'Eg: Software Engineer, Manager',
        name: 'occupationCode',
    },
    {
        label: 'Monthly Income',
        inputType: 'text',
        formatter: 'currency',
        hintText: 'After tax deduction (will be verified later)',
        name: 'declaredMonthlyIncome',
        disabled: true
    },

]

export const preApprovedJourneySection = [
    {
        label: 'Professional Type',
        inputType: 'select',
        options: [],
        hint: 'Eg: Salaried',
        twHint: 'Eg: Salaried',
        name: 'professionalCode',
    },
    {
        label: 'Occupation',
        inputType: 'select',
        options: [],
        hint: 'Eg: Software Engineer, Manager',
        twHint: 'Eg: Software Engineer, Manager',
        name: 'occupationCode',
    },
]

export const section_1_data = [
    ...section_1_data_2_wheeler,
    {
        label: 'Existing EMI/Month',
        inputType: 'text',
        formatter: 'currency',
        hint: '',
        name: 'existingEMIPerMonth',
        disabled: true
    }
]



export const section_2_data = [
    {
        label: 'Dealer PIN Code',
        inputType: 'text',
        hintText: 'Purchase Location PIN Code',
        name: 'locationPINCode',
        formatter: 'number',
        maxLength: 6
    },
    {
        label: 'City',
        inputType: 'select',
        options: [],
        hint: 'Purchase Location City',
        twHint: 'Purchase Location City',
        name: 'cityCode'
    },
    {
        label: 'Make',
        inputType: 'select',
        options: [],
        hint: 'Eg: Hyundai',
        twHint: 'Eg: TVS',
        name: 'makeCode'
    },
    {
        label: 'Model',
        inputType: 'select',
        options: [],
        hint: 'Eg: Grand i10',
        twHint: 'Eg: Apache',
        name: 'modelCode'
    },
    {
        label: 'Variant',
        inputType: 'select',
        options: [],
        hint: 'Eg: Nios',
        twHint: 'Eg: RTR 160',
        name: 'variantCode'
    }
]

export const section2UsedLoan = [
    {
        label: 'Seller PIN Code',
        inputType: 'text',
        hintText: 'Purchase Location PIN Code',
        name: 'locationPINCode',
        formatter: 'number',
        maxLength: 6
    },
    {
        label: 'Estimated Price',
        inputType: 'text',
        formatter: 'currency',
        hint: '',
        name: 'estimatedPrice',
    }
]

export const sectionRefinanceCar = [
    {
        label: 'Current Vehicle PIN Code',
        inputType: 'text',
        hintText: 'Location PIN Code of the car',
        name: 'locationPINCode',
        formatter: 'number',
        maxLength: 6
    },
    {
        label: 'Dealer Quoted On-Road Price',
        inputType: 'text',
        formatter: 'currency',
        hintText: 'Value as per invoice',
        name: 'estimatedPrice',
    }
]



export const WhatsappAdornment = () => {
    return (
        <div className="adornment">+91</div>
    )
}
export const transgenderOptions = [
    {
        code: 'OTHERS MALE',
        value: 'Transgender - Male'
    },
    {
        code: 'OTHERS FEMALE',
        value: 'Transgender - Female'
    },
    {
        code: 'OTHERS PREFER NOT TO DISCLOSE',
        value: 'Prefer not to disclose'
    },
]
export const MARITALSTATUS = "MARITALSTATUS"
export const PROFESSIONALCATEGORY = "PROFESSIONALCATEGORY"
export const OCCUPATION = "occupationCode"
export const CITY = "cityCode"
export const SAVEANDCONTINUEBUTTON = "SAVEANDCONTINUEBUTTON"
export const MAKE = 'makeCode'
export const MODEL = 'modelCode'
export const VARIANT = 'variantCode'
export const ONROADPRICE = 'vehicleorpprice'
export const GENDER_SPEC = 'gender'
export const TRANS_CODE = 'T'

const sortingArrayOfObjects = (data, key = "code") => {
    return data.sort((a, b) => (a?.[key] > b?.[key] ? 1 : -1));
};

export const groupsToOptionsGenerate = (groups, key, type) => {
    if (key === "makeCode") {
        let optionsData = Object.keys(groups || {})?.map((makeCode) => {
            let singleData = groups?.[makeCode]?.[0];
            let manufacturerCode = singleData?.manufacturerCode;
            let subCategoryCode = singleData?.subCategoryCode;
            let label =
                singleData?.parentProductR?.[
                    type === "PV"
                        ? "carParentProductRepeat"
                        : "TwoWheelerParentProductRepeat"
                ]?.makeName;
            return { code: makeCode, value: label, manufacturerCode, subCategoryCode };
        });
        optionsData = sortingArrayOfObjects(optionsData);
        return [key, optionsData]
    }
    if (key === "modelCode") {
        let optionsData = Object.keys(groups || {})?.map((modelCode) => {
            let singleData = groups[modelCode]?.[0];
            let label = singleData?.parentProductR?.modelName;
            return { code: modelCode, value: label };
        });
        optionsData = sortingArrayOfObjects(optionsData);
        return [key, optionsData]

    }
    if (key === "variantCode") {
        let optionsData = Object.keys(groups || {})?.map((variantCode) => {
            let singleData = groups[variantCode]?.[0];
            let label = singleData?.variantName;
            let manufacturerCode = singleData?.manufacturerCode;
            let subCategoryCode = singleData?.subCategoryCode;
            return { code: variantCode, value: label, manufacturerCode, subCategoryCode };
        });
        optionsData = sortingArrayOfObjects(optionsData);
        return [key, optionsData]

    }
};

export const groupingFunction = (data, key, isNewCarJourney) => {
    let groups = data?.reduce(
        (groups, item) => {
            if (isNewCarJourney && item.emissionNorms !== "BS6") return groups
            return ({
                ...groups,
                [item[key]]: [...(groups[item[key]] || []), item],
            })
        },
        {}
    );
    return groups;
};

export const structuringDataHandler = (data, type, isNewCarJourney) => {
    if (!type) type = "PV"
    let groupOfMakes = groupingFunction(data, "makeCode", isNewCarJourney);
    let [key, options] = groupsToOptionsGenerate(groupOfMakes, "makeCode", type);
    return [key, options, groupOfMakes]

};
