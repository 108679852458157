export const VKYC_lANDING = {
    id: 28,
    name: "Video-KYC Landing",
    route: "vkyclanding?kyc_status=Open"
}

export const screenData = [
    {
        id: 0,
        name: "Tiles page",
        route: "",
    },
    {
        id: 1,
        name: "Full Name",
        route: "name",
    },
    {
        id: 2,
        name: "Phone Number",
        route: "mobile",
    },
    {
        id: 3,
        name: "PAN & Email",
        route: "pan",
    },
    {
        id: 4,
        name: "ETB Dashboard",
        route: "vehicleLoanDashboard",
    },
    {
        id: 5,
        name: "Aadhar-E-KYC",
        route: "aadhar",
    },
    {
        id: 6,
        name: "Milestone Roadmap 0",
        route: "roadmap/0",
    },
    {
        id: 7,
        name: "Personal details",
        route: "basicDetails/0",
    },
    {
        id: 8,
        name: "Professional details",
        route: "basicDetails/1",
    },
    {
        id: 9,
        name: "Asset Details-Initial Offer",
        route: "basicDetails/2",
    },
    {
        id: 10,
        name: "Animation",
        route: "animation",
    },
    {
        id: 11,
        name: "Initial offer",
        route: "initialoffer",
    },
    {
        id: 12,
        name: "Milestone Roadmap 1",
        route: "roadmap/1",
    },
    {
        id: 14,
        name: "Aadhar-Re-E-KYC",
        route: "verification/aadharVerify",
    },
    {
        id: 15,
        name: "Residence Address",
        route: "verification/0",
    },
    {
        id: 16,
        name: "Office address",
        route: "verification/1",
    },
    {
        id: 17,
        name: "Demographic screen",
        route: "verification/2",
    },
    {
        id: 18,
        name: "Income verification",
        route: "verification/3",
    },
    {
        id: 19,
        name: "Income verification Landing",
        route: "perfios-verification",
    },
    {
        id: 20,
        name: "Milestone Roadmap 2",
        route: "roadmap/2",
    },
    {
        id: 21,
        name: "Vehicle Finalization",
        route: "dealerVehicle/0",
    },
    {
        id: 22,
        name: "Vehicle Valuation",
        route: "dealerVehicle/1",
    },
    {
        id: 23,
        name: "Final offer Animation",
        route: "finalAnimation",
    },
    {
        id: 24,
        name: "Final offer",
        route: "finalOffer",
    },
    {
        id: 25,
        name: "Insurance",
        route: "motorInsurance",
    },
    {
        id: 26,
        name: "Milestone Roadmap 4",
        route: "roadmap/4",
    },
    {
        id: 27,
        name: "Video-KYC",
        route: "vkyclanding",
    },
    {
        ...VKYC_lANDING
    },
    {
        id: 29,
        name: "Final offer acceptance",
        route: "finalOfferAccept",
    },
    {
        id: 30,
        name: "Milestone Roadmap 5",
        route: "roadmap/5",
    },
    {
        id: -1,
        name: "Login",
        route: "login",
    },
    {
        id: -2,
        name: "Main Route",
        route: "/",
    },
    {
        id: -3,
        name: "Aggregator Form",
        route: "aggregator-form",
    },
    {
        id: 99,
        name: "NTB Residence Address",
        route: "verification/ntbAddress",
    },
    {
        id: 100,
        name: "ETB Residence Address",
        route: "verification/etbAddress",
    },
];