/* eslint-disable no-unused-vars */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import React, {  useState } from "react";
import TextFieldInput from "../../components/CustomInput/TextFieldInput";
import { AADHAR_FIELD_INFO, AADHAR_PLACEHOLDER_LABEL } from "../../utils/cms";
import InputMask from "react-input-mask";
import "./index.css";

const AdhaarInput = ({ values, setValues }) => {
  const [showAadhar, setShowAdhaar] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const inputChangeHandler = (e) => {
    let value = e.target.value;
    setValues({ ...values, aadhar: value });
  };

  const adhaarValueGenerate = () => {
    let hiddenAdhaar = new Array(
      (values?.aadhar || "")?.split(" ")?.join("")?.length + 1
    )
      .join("X")
      ?.match(/.{1,4}/g)
      ?.join(" ");
    return hiddenAdhaar;
  };

  return (
    <div className="relative aadhaar-field">
      {!showAadhar && <p className="hidden-adhaar">{adhaarValueGenerate()}</p>}
      <InputMask
        mask="9999 9999 9999"
        onChange={inputChangeHandler}
        maskPlaceholder={null}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      >
        <TextFieldInput
          label={AADHAR_PLACEHOLDER_LABEL}
          hintText={AADHAR_FIELD_INFO}
          errors={null}
          endAdornment={
            (isFocus || values.aadhar) ?
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowAdhaar(!showAadhar);
                }}
              >
                {!showAadhar ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            : null
          }
          formatter='number'
        />
      </InputMask>
    </div>
  );
};

export default AdhaarInput;
