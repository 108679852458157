import React from "react";
import {
  TERMS_HEADING,
  PERM_TERMS_HEADING,
  PERM_TERMS_CONTENT_1,
  PERM_TERMS_SUB_CONTENT_1,
  PERM_TERMS_CONTENT_2,
  PERM_TERMS_SUB_CONTENT_2,
  PERM_TERMS_NOTE,
} from "../../utils/cms";
import TermsAndConditionsWrapper from "../../components/TermsAndConditionsWrapper/TermsAndConditionsWrapper";
import { Button } from "@mui/material";
const PermanentDetailsTerms = ({ setShowTerms }) => {
  return (
    <div className="permanentTerms">
      <TermsAndConditionsWrapper>
        <div className="terms-conditions-container">
          <div className="top-round-card-header"></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              <div className="">
                <div className="name-consent-content">
                  <div>
                    <h3 className="terms-heading">
                      {TERMS_HEADING}
                    </h3>
                    <div className="consent-body">
                      <div className="terms-content-wrapper">
                        <p>{PERM_TERMS_HEADING}</p>
                        <>
                          <h3>{PERM_TERMS_CONTENT_1}</h3>
                          <ol>
                            {PERM_TERMS_SUB_CONTENT_1?.map((each) => (
                              <li>
                                <p>{each}</p>
                              </li>
                            ))}
                          </ol>
                        </>
                        <>
                          <h3>{PERM_TERMS_CONTENT_2}</h3>
                          <ol>
                            {PERM_TERMS_SUB_CONTENT_2?.map((each) => (
                              <li>
                                <p>{each}</p>
                              </li>
                            ))}
                          </ol>
                        </>
                        <p>
                          <b>Note : </b> {PERM_TERMS_NOTE}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                          </div>
                          <Button
              style={{ backgroundColor: "#870016" }}
              className="consent-action-button"
              variant="contained"
              color="secondary"
              onClick={() => setShowTerms(false)}
            >
              <b>Accept</b>
            </Button>
            </div>
           
          </div>
        </div>
      </TermsAndConditionsWrapper>
    </div>
  );
};

export default PermanentDetailsTerms;
