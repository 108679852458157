/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
import React, { useEffect, useState } from "react";
import FormGenerate from "../../components/FormGenerate/FormGenerate";
import {
  VEHICLE_FINALIZATION_STEP_HEADING,
  DEALER_NAME_LABEL,
  VEHICLE_REGISTRATION_FIELD_LABEL,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  DEDUPE_ERROR,
  JOURNEY_TYPE_3,
  DEALER_DISCOUNT_LABEL,
  DEALER_QUOTED_ORP_LABEL,
  JOURNEY_TYPE_2,
  JOURNEY_TYPE_4,
  JOURNEY_TYPE_1,
  TW_ORP_ERROR_MESSAGE
} from "../../utils/cms";
import { dealerVehicleDetailsInputs } from "./data";
import SelectInput from "../../components/CustomInput/SelectInput";
import {
  checkValidVehicleRegistration,
  getJourneyType,
  changeValueToCurrency,
  onlyNumbersAccept,
  getVehicleType,
} from "../../utils/utils";
import VehicleRegistration from "../../components/CustomInput/VehicleRegistration/VehicleRegistration";
import MakeModelVariant from "./MMV/MakeModelVariant";
import PincodeAndCity from "./PincodeAndCity/PincodeAndCity";
import Loader from "../../components/Loader/Loader";
import MasterServices from "../../services/masters";
import AssistanceCard from "../../components/AssistanceCard/AssistanceCard";
import { useDispatch, useSelector } from "react-redux";
import { pageBasicDetails } from "../../store/pageDetails";

const DealerVehicle = ({
  globalValues,
  setGlobalValues,
  setIsDisabled,
  isDisabled,
  errorOptions,
  setErrorOptions,
}) => {

  const [dealeroptions, setDealerOptions] = useState(null);
  const [cityoptions, setCityOptions] = useState({});
  const [mmvOptions, setMMVOptions] = useState({});
  const [values, setValues] = useState({ ...globalValues })
  const [orp, setOrp] = useState({})
  const [formGenerateData, setFormGenerateData] = useState(dealerVehicleDetailsInputs)

  const [groups, setGroups] = useState({
    makeCode: {},
    modelCode: {},
    variantCode: {},
  });

  const [enableMake, setEnableMake] = useState(false);
  const [prefillData, setPrefillData] = useState({});
  const [beDetails, setBeDetails] = useState({});
  const [makeValues, setMakeValues] = useState({})
  const [dealerValues, setDealerValue] = useState({})
  const [errors, setErrors] = useState({});
  const [isDealerPrefill, setDealerPrefill] = useState(true)
  const [onLoadloading, setOnLoadLoading] = useState(false);
  const [mmvLoading, setMMVLoading] = useState(false)
  const [pinCodeloading, setPincodeLoading] = useState(false)
  const [orpLoading, setORPLoading] = useState(false)
  const [dealerloading, setDealerLoading] = useState(false)
  const [beDetailsloading, setBeDetailsLoading] = useState(false)

  const storeValues = useSelector((state) => state);
  const {
    pageDetails: { journeyType, currentApplication, preApprovedJourney, aggregatorJourney },
  } = storeValues || {};

  const dispatch = useDispatch();

  const isDealerAvailable = () => {
    let val = true;
    if ((journeyType === JOURNEY_TYPE_2 && aggregatorJourney) || journeyType === JOURNEY_TYPE_4) {
      val = false;
    }
    return val;
  }

  const callBackFunc = (success, response, type) => {
    if (success) {
      if (type === "dealerName") {
        setDealerLoading(false)
        if (response.statusCode === "204 NO_CONTENT") {
          setErrorOptions({
            ...errorOptions,
            openErrorModal: true,
            errorContent: "There are no dealers available for the selected pin code. Please choose a different pin code",
            errorSubContent: ""
          });
          return
        }
        if (typeof response.respDesc === "string" || !response.respDesc) {
          setErrorOptions({
            ...errorOptions,
            openErrorModal: true,
            errorContent: DEDUPE_ERROR || response?.respDesc,
            errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN
          });
          return
        }
        let resDealerOptions = []
        response?.respDesc?.map(dealer => {
          resDealerOptions.push({
            code: dealer?.dealerID,
            value: dealer?.dealerName,
            benCode: dealer?.benCode,
            dealerType: dealer?.Type_Of_Dealer,
            dealerCategorization: dealer?.dealerCategorization,
          })
        })
        setDealerOptions(resDealerOptions);
        if (prefillData?.dealerId && isDealerPrefill) {
          setDealerPrefill(false)
          const prefillDealer = resDealerOptions.find(dealer => (dealer?.code === prefillData?.dealerId))
          setDealerValue({
            dealerName: prefillDealer
          })
        }
      }
      else if (type === "vehicleorpprice") {
        setORPLoading(false)
        if (response?.statusCode !== '200') {
          let updatedData = formGenerateData.map((data) => {
            data.disabled = true
            if (data.name === 'dealerOnRoadPrice') data.disabled = false
            return data
          })
          setFormGenerateData(updatedData)
          return
        }
        const estimatedOnRoadPrice = changeValueToCurrency.format(response.calculatedOnRoadPrice)
        const otherCharges = changeValueToCurrency.format(parseInt(response.hypothecationCharges || 0) + parseInt(response.fasTag || 0) + parseInt(response.insurance || 0) + parseInt(response.otherCharges || 0))
        //const dealerOnRoadPrice = changeValueToCurrency.format(response.calculatedOnRoadPrice)
        setOrp({
          ...orp,
          showroomPrice: changeValueToCurrency.format(response.exShowRoomPrice),
          rtoCharges: changeValueToCurrency.format(response.rto),
          insurance: changeValueToCurrency.format(response.insurance),
          fasTag: changeValueToCurrency.format(response.fasTag),
          estimatedOnRoadPrice: estimatedOnRoadPrice,
          orp: estimatedOnRoadPrice,
          otherCharges: otherCharges,
          carWaleResponse: response.carwaleResponse,
          dealerDiscount: ""
          //dealerOnRoadPrice: dealerOnRoadPrice
        });
        let updatedData = formGenerateData.map((data) => {
          data.disabled = true
          if ((data.label === DEALER_DISCOUNT_LABEL) || (data.label === DEALER_QUOTED_ORP_LABEL)) {
            data.disabled = false
          }
          return data
        })
        setFormGenerateData(updatedData)
      }
      else if (type === "bedetails") {
        setBeDetailsLoading(false)
        setBeDetails(response);
        dispatch(pageBasicDetails({ beDetails: response }));
      }
    } else {
      if (type === "dealerName") {
        setDealerLoading(false)
        setDealerOptions([]);
        setErrorOptions({
          ...errorOptions,
          openErrorModal: true,
          errorContent: DEDUPE_ERROR || response?.respDesc,
          errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
        });
      }
      else if (type === "vehicleorpprice") {
        setORPLoading(false)
        let updatedData = formGenerateData.map((data) => {
          data.disabled = true
          if (data.name === 'dealerOnRoadPrice') data.disabled = false
          return data
        })
        setFormGenerateData(updatedData)
      }
      else if (type === "bedetails") {
        setBeDetailsLoading(false)
      }
    }
  };

  function onPageLoadCallback(success, response, type) {
    setOnLoadLoading(false);
    if (success) {
      dispatch(pageBasicDetails({ finalVehicleDetails: response }));
      setValues({
        pin: response?.locationPINCode,
        cityCode: { code: response?.cityCode, value: '' },
        showroomPrice: changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleExShowroomPrice)
        ) === '0' ? '' : changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleExShowroomPrice)
        ),
        dealerDiscount: changeValueToCurrency.format(
          onlyNumbersAccept(response?.dealerDiscountAmount)
        ) === '0' ? '' : changeValueToCurrency.format(
          onlyNumbersAccept(response?.dealerDiscountAmount)
        ),
        rtoCharges: changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleRTOCharges)
        ) === '0' ? '' : changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleRTOCharges)
        ),
        estimatedOnRoadPrice: changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleTotalEstimatedORP)
        ) === '0' ? '' : changeValueToCurrency.format(
          onlyNumbersAccept(response?.waleTotalEstimatedORP)
        ),
        dealerOnRoadPrice: changeValueToCurrency.format(
          onlyNumbersAccept(response?.delaerQuotedORP || response?.onRoadPrice)
        ) === '0' ? '' : changeValueToCurrency.format(
          onlyNumbersAccept(response?.delaerQuotedORP || response?.onRoadPrice)
        ),
        makeCode: response?.makeCode,
        modelCode: response?.modelCode,
        variantCode: response?.variantCode,
        vehicleRegistrationNumber: response?.rcNumber || currentApplication?.registrationNumber,
        beName: response?.beName,
        contactNumber: response?.beContactNumber,
        beSFDCID: response?.beSFDCID,
        beID: response?.beID
      });
      setPrefillData({
        dealerCode: response?.dealerSFID,
        pin: journeyType !== JOURNEY_TYPE_2 ? response?.locationPINCode : null,
        cityCode: response?.cityCode,
        cityName: response?.cityName,
        makeCode: response?.makeCode,
        modelCode: response?.modelCode,
        variantCode: response?.variantCode,
        dealerId: journeyType !== JOURNEY_TYPE_4 ? response?.dealerId : null,
        vehicleRegistrationNumber: response?.rcNumber || currentApplication?.registrationNumber,
      });
      if (response?.makeCode && isDealerAvailable()) {
        setDealerLoading(true)
      }
      if ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) && !response?.makeCode) {
        setMMVLoading(true)
        setEnableMake(true);
      }
      if (response?.beName && response?.beContactNumber) {
        setBeDetails({
          beName: response?.beName,
          contactNumber: response?.beContactNumber,
          agentBLCode: response?.beBlCode,
          beSFDCID: response?.beSFDCID,
          beID: response?.beID
        });
      }
      if (response?.cityCode && response?.variantCode && journeyType !== JOURNEY_TYPE_2 && journeyType !== JOURNEY_TYPE_4) {
        setORPLoading(true);
        getORP({
          cityCode: { code: response?.cityCode },
          variantCode: response?.variantCode,
        });
      }
    } else {
      setErrorOptions({
        ...errorOptions,
        openErrorModal: true,
        errorContent: response?.respDesc || DEDUPE_ERROR,
        errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
      });
    }
  }

  let payload = {
    D2CApplicantID: currentApplication?.d2CApplicantID || "",
    vehicleType:
      currentApplication?.vehicleType ||
      getVehicleType(storeValues?.pageDetails?.journeyType),
    journeyType:
      currentApplication?.journeyType ||
      getJourneyType(storeValues?.pageDetails?.journeyType),
    screenID: "20",
  };

  useEffect(() => {
    setOnLoadLoading(true);
    payload.isPACustomer = preApprovedJourney || false;
    payload.referenceNumber = currentApplication?.referenceNumber || '';
    MasterServices.getVerificationDetails(
      onPageLoadCallback,
      "getassetdetails",
      "getassetdetails",
      payload
    );
  }, []);

  useEffect(() => {
    if (
      !errors?.vehicleRegistrationNumber &&
      !errors?.dealerDiscount &&
      !errors?.dealerOnRoadPrice &&
      makeValues?.makeCode?.code &&
      makeValues?.modelCode?.code &&
      makeValues?.variantCode?.code &&
      ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) ? checkValidVehicleRegistration(values.vehicleRegistrationNumber) : true) &&
      ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) ?
        true : (values.pin && values.cityCode?.code &&
          parseInt(inrToNumber(orp?.dealerOnRoadPrice)) > 0)) &&
      (isDealerAvailable() ? dealerValues?.dealerName : true)
    ) {
      setIsDisabled(false)
      setGlobalValues({ ...values, ...makeValues, ...orp, ...dealerValues })
    } else {
      if (!isDisabled) {
        setIsDisabled(true)
      }
    }
  }, [values, makeValues, orp, dealerValues, errors]);


  const onCitySelect = (updatedValues, name, value) => {
    setEnableMake(value || "");
    setOrp({});
    setDealerOptions([]);
    setDealerValue({})
    let updatedData = formGenerateData.map((data) => {
      data.disabled = true
      return data
    })
    setFormGenerateData(updatedData)
  };

  const getDealerDetails = (payloadValues) => {
    let dealerPayload = {
      D2CApplicantID: payload?.D2CApplicantID || "",
      screenID: "21",
      vehicleType: payload?.vehicleType,
      journeyType: payload?.journeyType,
      productType: getVehicleType(journeyType)==="C" ? "Passenger Vehicle" : "Two Wheeler",
      loanApplicationId: currentApplication?.loanApplicationId,
      ...payloadValues,
    }
    setDealerLoading(true)
    MasterServices.getVerificationDetails(
      callBackFunc,
      "getdealerslist",
      "dealerName",
      dealerPayload
    );
  };

  const onVariantSelect = (updatedValues, name, value) => {
    setOrp({});
    setDealerOptions([])
    setDealerValue({});
    if (updatedValues?.variantCode?.code && journeyType !== JOURNEY_TYPE_2 && journeyType !== JOURNEY_TYPE_4) {
      setORPLoading(true);
      getORP({
        cityCode: values?.cityCode,
        variantCode: updatedValues?.variantCode?.code,
      });
    }
  };

  const getORP = (payloadValues) => {
    const ORPPayload = {
      vehicleType: journeyType === JOURNEY_TYPE_3 ? "2" : "1",
      cityId:
        (payloadValues.cityCode?.code?.includes("-")
          ? payloadValues.cityCode?.code?.split("-")[1]
          : payloadValues.cityCode?.code) || "",
      variantCode: payloadValues?.variantCode,
      leadId: currentApplication?.leadId || "",
      makerId: "0",
      D2CApplicantID: currentApplication?.d2CApplicantID || "",
    };
    setORPLoading(true)
    MasterServices.getVerificationDetails(
      callBackFunc,
      "vehicleorpprice",
      "vehicleorpprice",
      ORPPayload
    );
  };

  useEffect(() => {
    if (
      isDealerAvailable() &&
      beDetails &&
      values?.pin &&
      makeValues?.makeCode?.code &&
      makeValues?.modelCode?.code &&
      makeValues?.variantCode?.code) {
      getDealerDetails({
        pinCode: values?.pin,
        makeCode: makeValues?.makeCode?.code,
        modelCode: makeValues?.modelCode?.code,
        variantCode: makeValues?.variantCode?.code,
        agentBLCode: beDetails?.agentBLCode,
      });
    }
  }, [beDetails, makeValues])

  const makeModelVariantWithProps = () => (<MakeModelVariant
    isEnableMake={enableMake}
    values={makeValues}
    setValues={setMakeValues}
    errors={errors}
    setErrors={setErrors}
    options={mmvOptions}
    prefillData={prefillData}
    setPrefillData={setPrefillData}
    setLoading={setMMVLoading}
    setOptions={setMMVOptions}
    onVariantSelect={onVariantSelect}
    errorOptions={errorOptions}
    setErrorOptions={setErrorOptions}
    groups={groups}
    setGroups={setGroups}
    journeyType={journeyType}
    setDealerLoading={setDealerLoading}
    setOrp={setOrp}
    setDealerValue={setDealerValue}
    setDealerOptions={setDealerOptions}
  />)

  const inrToNumber = (number) => {
    if (!number) return ''
    return number?.replaceAll(',', '')
  }

  const onBlurHanlder = (e) => {
    const fieldName = e?.target?.name;
    if ((fieldName === "dealerDiscount" || fieldName === "estimatedOnRoadPrice" || fieldName === "dealerOnRoadPrice") && journeyType === JOURNEY_TYPE_1) {
      if (
        parseInt(inrToNumber(orp?.dealerDiscount)) !== 0 &&
        orp?.dealerDiscount !== "" && orp?.orp &&
        parseInt(inrToNumber(orp?.dealerDiscount)) >= parseInt(inrToNumber(orp?.orp))
      ) {
        setErrors({ ...errors, dealerDiscount: ['Dealer Discount cannot be higher than the Estimated ORP'] })
        setOrp({
          ...orp,
          estimatedOnRoadPrice: orp?.orp
        })
      }
      else if (orp?.orp) {
        setErrors({ ...errors, dealerDiscount: null })
        setOrp({
          ...orp,
          estimatedOnRoadPrice: changeValueToCurrency.format(parseInt(inrToNumber(orp?.orp) || 0) - parseInt(inrToNumber(orp?.dealerDiscount) || 0)) || ''
        })
      }
    }
    if (fieldName === "dealerOnRoadPrice" && journeyType === JOURNEY_TYPE_3) {
      const value = parseInt(inrToNumber(e?.target?.value));
      if (value < 20000 || value > 500000) {
        setErrors({ ...errors, dealerOnRoadPrice: [TW_ORP_ERROR_MESSAGE] })
      } else if (errors?.dealerOnRoadPrice) {
        setErrors({ ...errors, dealerOnRoadPrice: null })
      }
    }
  };

  const afterORPFieldsChange = (updatedValues, name, value) => {
    if (name === "dealerDiscount" && errors?.dealerDiscount) {
      setErrors({
        ...errors,
        dealerDiscount: null,
      })
    }
    else if (name === "estimatedOnRoadPrice") {
      setOrp({
        ...updatedValues,
        orp: updatedValues?.estimatedOnRoadPrice
      })
    }
  }

  return (
    <>
      <Loader isLoading={onLoadloading || mmvLoading || pinCodeloading || orpLoading || dealerloading || beDetailsloading} />
      <div className="vehicle-details">
        <div className="heading">
          {VEHICLE_FINALIZATION_STEP_HEADING.replace(
            "#category#",
            (isDealerAvailable()) ? "Dealer & Vehicle " : "Vehicle"
          )}
          {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
        </div>
        {journeyType === JOURNEY_TYPE_1 || journeyType === JOURNEY_TYPE_3 ? (
          <PincodeAndCity
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            options={cityoptions}
            setOptions={setCityOptions}
            setDealerOptions={setDealerOptions}
            setLoading={setPincodeLoading}
            onCitySelect={onCitySelect}
            prefillData={prefillData}
            setPrefillData={setPrefillData}
            errorOptions={errorOptions}
            setErrorOptions={setErrorOptions}
            setMakeValues={setMakeValues}
            setOrp={setOrp}
            setDealerValue={setDealerValue}
          />
        ) : (
          <>
            <VehicleRegistration
              label={VEHICLE_REGISTRATION_FIELD_LABEL}
              values={values}
              name="vehicleRegistrationNumber"
              setFieldValue={(val) =>
                setValues({
                  ...values,
                  vehicleRegistrationNumber: val,
                })
              }
              setErrors={setErrors}
              errors={errors}
              journeyType={journeyType}
            />
          </>
        )}
        {makeModelVariantWithProps()}
        {isDealerAvailable() &&
          <>
            <SelectInput
              values={dealerValues}
              setValues={setDealerValue}
              label={DEALER_NAME_LABEL}
              name="dealerName"
              options={dealeroptions}
            />
          </>
        }
        {(journeyType === JOURNEY_TYPE_1 || journeyType === JOURNEY_TYPE_3) && <FormGenerate
          values={orp}
          setValues={setOrp}
          formInputs={formGenerateData}
          errors={errors}
          onBlurHanlder={onBlurHanlder}
          afterInputChangeCallback={afterORPFieldsChange}
        />}
        {beDetails?.beName && beDetails?.contactNumber && (
          <AssistanceCard
            beDetails={{
              beName: beDetails?.beName,
              beContactNumber: beDetails?.contactNumber,
            }}
          />
        )}
      </div>
    </>
  );
};

export default DealerVehicle;
