/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import "./FinalOfferAcceptance.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
	ACCEPTANCE_TEXT,
	FINAL_OFFER_SUBHEADING_1,
	TOP_HEADER_TEXT,
	TOP_HEADER_TEXT_2,
	STAMP_TEXT,
	FINAL_OFFER_REQUEST_TEXT,
	INFORMATION_TEXT,
	OTP_GENERATION_FAILED_TRY_AGAIN,
	DEDUPE_ERROR,
	OKAY_ACTION,
	ACCEPT_CONTINUE_ACTION,
	LOAN_AMOUNT_TEXT,
	EMI_TEXT,
	TENURE_TEXT,
	DOCUMENT_TEXT,
	SERVICE_TEXT,
	NAME,
	CONTACT_NUMBER,
	APPLICATION_REFERENCE_NUMBER,
	VEHICLE_DETAILS,
	ERROR_TEXT,
	CONGRATULATIONS,
	NON_STP_CUSTOMER,
	DARK_GREEN,
	LIGHT_GREEN,
	AGREE_TEXT,
	AGREE_TERMS_TEXT,
	VIEW_DASHBOARD,
	INTEREST_RATE,
	GST_INCLUSIVE_TEXT,
	INSURANCE_TEXT,
	EMP_REF_ID,
	EMP_REF_ID_ERROR,
	EMP_REF_INFO
} from "../../utils/cms";
import {
	changeValueToCurrency,
	getJourneyType,
	getVehicleType,
	removeInvaildCharFromPANField,
} from "../../utils/utils";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import MasterServices from "../../services/masters";
import { pageBasicDetails } from "../../store/pageDetails";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckboxInput from "../../components/CustomInput/CheckboxInput";
import Loader from "../../components/Loader/Loader";
import ErrorModal from "../../components/ErrorModal";
import Terms from "../Terms";
import LoginServices from "../../services/login";
import { updateCompletedScreens } from "../../store/features/toolsSlice";
import { InputAdornment, InputLabel, Input, Tooltip } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AssistanceCard from "../../components/AssistanceCard/AssistanceCard";
import { updateJourneyStatus } from "../../utils/updateJouneyStatus";

const FinalOfferAcceptance = () => {
	let navigate = useNavigate();
	const [accepted, isTermsAccepted] = useState(false);
	const [terms, openTermsModal] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(true);
	const [responseData, setResponseData] = useState({});
	const [loading, setLoading] = useState(false);
	const [offerAccepted, setofferAccepted] = useState(false);
	const [empRefId, setEmpRefId] = useState('');
	const [error, setError] = useState('');
	const [errorOptions, setErrorOptions] = useState({
		openErrorModal: false,
		errorTitle: ERROR_TEXT,
		errorSubContent: "",
		errorContent: "",
		clearCurrentSession: false,
	});
	const dispatch = useDispatch();
	const storeValues = useSelector((state) => state);
	const {
		pageDetails: { journeyType, currentApplication, finalOffer, completedPages }, tools: { completedScreens }
	} = storeValues || {};

	useEffect(() => {
		const topImage = document.getElementsByClassName("top-background")[0];
		topImage.style.display = "block";
	}, [terms]);

	const payload = {
		D2CApplicantID: currentApplication?.d2CApplicantID || "",
		vehicleType: getVehicleType(journeyType),
		journeyType: getJourneyType(journeyType),
		screenID: "29",
	};
	const saveAndContinue = {
		d2CApplicantId: currentApplication?.d2CApplicantID || "",
		customerType: responseData?.customerType || "",
		loanAmount: responseData?.loanAmount || "0",
		tenure: responseData?.tenure || "0",
		interestRate: responseData?.interestRate || "0",
		newEmiAmount: responseData?.newEmiAmount || "0",
		documentCharges: responseData?.documentCharges || "0",
		serviceCharges: responseData?.serviceCharges || "0",
		sfLoanApplicationId: currentApplication?.loanApplicationId || "",
		sfFinalTermsId: finalOffer?.finalTermsId || "",
		iblEmployeeRefId: empRefId?.toUpperCase() || ""
	};
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			MasterServices.getFinalOfferAcceptData(
				callBackFunc,
				"getfinalOffer",
				payload
			);
		}, 5000);
	}, []);

	const handleRedirect = () => {
		if (!currentApplication?.finalOfferAccepted && !offerAccepted) {
			setLoading(true);
			MasterServices.saveFinalOfferAccept(
				callBackFunc,
				"savefinalOffer",
				saveAndContinue
			);
		} else if (currentApplication?.vkyc) {
			navigate('/vkyclanding');
		} else {
			dedupeCall();
		}
	};

	const callBackFunc = (success, response, type) => {
		setLoading(false);
		if (success) {
			if (type === "getfinalOffer") {
				setResponseData(response);
				dispatch(pageBasicDetails({ finalOffer: response }));
				return;
			}
			if (response?.statusCode !== '200') {
				setErrorOptions({
					...errorOptions,
					openErrorModal: true,
					errorContent: response?.respDesc || DEDUPE_ERROR,
					errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
				});
				return;
			  }
			if (type === "savefinalOffer") {
				dispatch(pageBasicDetails({ startNewApplication: false }));
				setofferAccepted(true)
				setShowConfirmation(true);
				setErrorOptions({
					...errorOptions,
					openErrorModal: true,
					errorTitle: INFORMATION_TEXT,
					errorContent: FINAL_OFFER_REQUEST_TEXT,
				});
			}
		} else {
			if (type === "savefinalOffer") {
				handleRedirect();
				return
			}			
			setShowConfirmation(false);
			setErrorOptions({
				...errorOptions,
				openErrorModal: true,
				errorContent: DEDUPE_ERROR || response?.respDesc,
				errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
			});
		}
	};
	const disableButton =
		responseData?.name &&
		responseData?.loanAmount &&
		responseData?.tenure &&
		responseData?.interestRate &&
		responseData?.newEmiAmount &&
		responseData?.documentCharges &&
		responseData?.serviceCharges &&
		responseData?.contactNumber &&
		responseData?.make &&
		responseData?.model &&
		responseData?.variant &&
		responseData?.applicationRefNumber &&
		(!currentApplication?.finalOfferAccepted ? accepted : true);

	const dedupeCall = () => {
		setLoading(true);
		if (!currentApplication?.finalOfferAccepted) updateJourneyStatus(29, 'Application Submitted');
		LoginServices.dedupeVerification(callBackFunction);
	};

	const callBackFunction = (success, response) => {
		setLoading(false);
		if (success) {
			dispatch(
				pageBasicDetails({
					dedupeResponse: response,
					currentApplication: {},
					finalOffer: {},
					initialOffer: {},
					completedPages: { ...completedPages, animationPage: false }
				})
			);
			if (response?.dashBoardInfoList != null && response?.dashBoardInfoList?.length > 0) {
				let removeAfterDashboardScreens = completedScreens?.filter(each => each < 4)
				dispatch(updateCompletedScreens(removeAfterDashboardScreens))
				navigate('/vehicleLoanDashboard');
			}
		} else {
			if (response?.statusCode === "500") {
				setErrorOptions({
					...errorOptions,
					openErrorModal: true,
					errorContent: response?.respDesc,
				});
				return;
			}
			if (!response?.response?.data) {
				setShowConfirmation(false);
				setErrorOptions({
					...errorOptions,
					errorTitle: ERROR_TEXT,
					errorContent: DEDUPE_ERROR,
					errorSubContent: OTP_GENERATION_FAILED_TRY_AGAIN,
				});
			}
		}
	};

	// const empRefError = (value) => {
	// 	if (value?.length > 0 && value?.length !== 10) {
	// 		setError(EMP_REF_ID_ERROR);
	// 	} else {
	// 		setError('');
	// 	}
	// }

	return (
		<>
			{terms === false && (
				<div className="page-container">
					<Loader isLoading={loading} />
					<Header
						title={FINAL_OFFER_SUBHEADING_1}
						subtitle={ACCEPTANCE_TEXT}
						topSlider={currentApplication?.vkyc ? 80 : 100}
					/>
					<div className="final-offer">
						<div className="top-round-card-header">
							{responseData?.riskBandValue ===
								LIGHT_GREEN ||
							responseData?.riskBandValue === DARK_GREEN
								? `${CONGRATULATIONS} ${
										responseData?.name || ""
								  }${"!"} 
							${
								responseData?.customerType ===
								NON_STP_CUSTOMER
									? TOP_HEADER_TEXT_2
									: TOP_HEADER_TEXT
							} `
								: `${CONGRATULATIONS} ${
										responseData?.name || ""
								  }${"!"} ${TOP_HEADER_TEXT_2}`}
						</div>

						<div className="page-body-content">
							<div className="page-body-contents top-section">
								<div className="section-1">
									<div className="final-offer-loan-amount">
										<div className="text-content">
											{
												LOAN_AMOUNT_TEXT
											}
										</div>
										<div className="text-content">
											{INSURANCE_TEXT}
										</div>
										<div className="text-content">
											{EMI_TEXT}
										</div>
										<div className="text-content">
											{INTEREST_RATE}
										</div>
										<div className="text-content">
											{TENURE_TEXT}
										</div>
										<div className="text-content">
											{DOCUMENT_TEXT}
										</div>
										<div className="text-content">
											{SERVICE_TEXT}
										</div>
									</div>

									<div className="final-offer-value">
										<div className="value-content">
											<CurrencyRupeeIcon
												sx={{
													height: "17px",
												}}
											/>
											{changeValueToCurrency.format(
												responseData?.loanAmount ||
													"0"
											)}
										</div>
										<div className="value-content">
											{" "}
											<CurrencyRupeeIcon
												sx={{
													height: "17px",
												}}
											/>
											{changeValueToCurrency.format(
												responseData?.fundedInsurancePremium ||
													"0"
											)}
										</div>
										<div className="value-content">
											{" "}
											<CurrencyRupeeIcon
												sx={{
													height: "17px",
												}}
											/>
											{changeValueToCurrency.format(
												responseData?.newEmiAmount ||
													"0"
											)}
										</div>
										<div className="value-content">
											{changeValueToCurrency.format(
												responseData?.interestRate ||
													"0"
											)}{" "}
											%
										</div>
										<div className="value-content">
											{changeValueToCurrency.format(
												responseData?.tenure ||
													"0"
											)}
										</div>
										<div className="value-content">
											<CurrencyRupeeIcon
												sx={{
													height: "17px",
												}}
											/>
											{changeValueToCurrency.format(
												responseData?.documentCharges ||
													"0"
											)}
										</div>
										<div className="value-content">
											<CurrencyRupeeIcon
												sx={{
													height: "17px",
												}}
											/>
											{changeValueToCurrency.format(
												responseData?.serviceCharges ||
													"0"
											)}
										</div>
									</div>
								</div>

								<div className="stamp_text">
									<div className="stamp-text-content">
										{STAMP_TEXT}<br/>{GST_INCLUSIVE_TEXT}
									</div>
								</div>
							</div>
						</div>

						<div className="content-section">
							<div className="section-2">
								<div className="contents">
									<div className="label-content">
										{NAME}
									</div>
									<div className="data-content">
										{responseData?.name}
									</div>
								</div>
								<div className="contents">
									<div className="label-content">
										{CONTACT_NUMBER}
									</div>
									<div className="data-content">
										{responseData?.contactNumber
											? `+91 ${responseData?.contactNumber
													?.match(
														/.{1,5}/g
													)
													?.join(" ")}`
											: ""}
									</div>
								</div>
								<div className="contents">
									<div className="label-content">
										{APPLICATION_REFERENCE_NUMBER}
									</div>

									<div className="data-content">
										{
											responseData?.applicationRefNumber
										}
									</div>
								</div>
								<div className="contents">
									<div className="label-content">
										{VEHICLE_DETAILS}
									</div>

									<div className="data-content">
										{responseData?.variant}
									</div>
								</div>

								{currentApplication?.finalOfferAccepted ?
									<>
										<div className="contents">
											<div className="label-content">
												{EMP_REF_ID}
											</div>
											<div className="data-content">
												{responseData?.iblEmployeeRefId ? responseData?.iblEmployeeRefId : 'NA'}
											</div>
										</div>
									</> 
								: <>
									<div className="emp-ref-content">
										<InputLabel>
											{EMP_REF_ID}
											<Tooltip title={EMP_REF_INFO} placement="top" enterTouchDelay={0}>
												<InfoOutlinedIcon className="infoOutlinedIcon" />
											</Tooltip>
										</InputLabel>
									</div>
									<Input
										value={empRefId}
										className="emp-ref-value"
										startAdornment={
											<InputAdornment position="End" style={{paddingRight:'5px'}}>
												<AccountCircle />
											</InputAdornment>
										}
										onChange={(e) => {
											let value = e.target.value;
											value = removeInvaildCharFromPANField(value);
											//empRefError(value);
											if (value?.length > 10) return;
											setEmpRefId(value);											
										}}
										// onMouseLeave={(e) => {
										// 	let value = e.target.value;
										// 	empRefError(value);
										// }}
										inputProps={{ maxLength: 10 }}
									/>
									{error && (
										<div className="error-messages">
											<p className="error-message error">{error}</p>
										</div>
									)}
									<br/><br/>
								</>
								}

							</div>

							{!currentApplication?.finalOfferAccepted && <div className="page-body-checkbox">
								<CheckboxInput
									checked={accepted}
									disabled={!accepted}
									openTermsModal={() =>
										!accepted &&
										openTermsModal(true)
									}
									name="terms_checkbox"
									className="consent-check-box"
								/>
								<p>
									{AGREE_TEXT}
									<label
										className="page-body-check-box-click"
										onClick={() =>
											openTermsModal(true)
										}
									>
										{AGREE_TERMS_TEXT}
									</label>
								</p>
							</div>}
							{responseData?.beName && responseData?.beContactNumber && (
								<div className="section-2">
									<AssistanceCard
										beDetails={{
										beName: responseData?.beName,
										beContactNumber: responseData?.beContactNumber,
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<Footer
						callBackAction={handleRedirect}
						disableButton={!disableButton}
						buttonText={!currentApplication?.finalOfferAccepted ? ACCEPT_CONTINUE_ACTION : VIEW_DASHBOARD}
						apiTriggered={false}
					/>
				</div>
			)}

			{terms && (
				<div className="terms-conditions-container">
					<div className="top-round-card-header" style={{ marginTop: "0px" }}></div>
					<div className="page-body-content">
						<div className="page-body-contents">
							<div className="">
								<Terms
									closeTersmAction={() =>
										openTermsModal(false)
									}
									acceptTersmAction={() => {
										openTermsModal(false);
										isTermsAccepted(true);
									}}
									consent="Final-Accept"
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			<ErrorModal
				openErrorModal={errorOptions?.openErrorModal}
				errorTitle={errorOptions?.errorTitle}
				errorContent={errorOptions?.errorContent}
				errorSubContent={errorOptions?.errorSubContent}
				closeErrorModal={() => {
					setErrorOptions({
						...errorOptions,
						openErrorModal: "",
						errorSubContent: "",
						errorContent: "",
					});
					if (currentApplication?.vkyc) {
						navigate('/vkyclanding');
					} else {
						showConfirmation && dedupeCall();
					}
				}}
				primaryAction={true}
				buttonText={showConfirmation && OKAY_ACTION}
			/>
		</>
	);
};

export default FinalOfferAcceptance;
