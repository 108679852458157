import axios from "axios"
import { GENERATE_OTP, VERIFY_OTP, AADHAR_VALIDATION, AADHAR_OTP_VERIFICATION, DEDUPE_VERIFICATION } from '../config/config';
import { store } from '../store/index'
import { Decryption } from "../utils/payload";
import { getJourneyType, getName, getVehicleType } from "../utils/utils";

const agent = {  
  rejectUnauthorized: false
};

const api = {
  generateOTP: async function (cb) {
    const storeValues = store.getState();
    const { userDetails: { userName, mobileNumber }, pageDetails: { journeyType } } = storeValues || {};
    const payload = {
      firstName: getName(userName, 'firstname') || '',
      lastName: getName(userName, 'lastname') || ' ',
      mobileNumber: mobileNumber,
      productType: getVehicleType(journeyType),
      journeyType: getJourneyType(journeyType),
    }
    await axios.post(GENERATE_OTP, payload, { httpsAgent: agent }).then((response) => {
      cb(true, response);
    }).catch((err) => {
      cb(false, err);
    })
  },
  validateOTP: async function (cb, otp) {
    const storeValues = store.getState();
    const { userDetails: { userName, mobileNumber } } = storeValues || {};
    const payload = {
      firstName: getName(userName, 'firstname') || '',
      lastName: getName(userName, 'lastname') || ' ',
      mobileNumber: mobileNumber,
      otpNumberEntered: otp,
    }
    await axios.post(VERIFY_OTP, payload).then((response) => {
      cb(true, response);
    }).catch((err) => {
      cb(false, err);
    })
  },
  validateAadhar: async function (cb) {
    const storeValues = store.getState();
    const { userDetails: { profile }, pageDetails: { currentApplication } } = storeValues || {};
    const payload = {
      aadharNumber: JSON.parse(Decryption(profile))?.replaceAll(' ', ''),
      d2cId: currentApplication?.d2CApplicantID || '',
      loanApplicationId: currentApplication?.loanApplicationId || "",
	    documentId: currentApplication?.documentId || "",
	    applicantId: currentApplication?.sfapplicantId || "",
    }
    await axios.post(AADHAR_VALIDATION, payload).then((response) => {
      cb(true, response);
    }).catch((err) => {
      cb(false, err);
    })
  },
  verifyAadharOTP: async function (cb, otp) {
    const storeValues = store.getState();
    const { userDetails: { profile, mobileNumber }, pageDetails: { currentApplication, aadharResponse, encryptedPerfios } } = storeValues || {};
    const payload = {
      aadharNumberEntered: JSON.parse(Decryption(profile))?.replaceAll(' ', ''),
      aadharOTPEntered: otp || '000000',
      leadId: currentApplication?.leadId || '0',
      markerId: "0",
      rrn: aadharResponse?.rrn || '0',
      txn: aadharResponse?.txn || '0',
      d2cId: currentApplication?.d2CApplicantID || '',
      loanApplicationId: currentApplication?.loanApplicationId || "",
	    documentId: currentApplication?.documentId || "",
	    applicantId: currentApplication?.sfapplicantId || "",
      pan: JSON.parse(Decryption(encryptedPerfios || "") || "{}") || '',
      mobileNumber: mobileNumber || '',
    }
    await axios.post(AADHAR_OTP_VERIFICATION, payload).then((response) => {
      cb(true, response);
    }).catch((err) => {
      cb(false, err);
    })
  },
  dedupeVerification: async function (cb, otp) {
    const storeValues = store.getState();
    const { userDetails: { userName, mobileNumber, email }, pageDetails: { journeyType, startNewApplication, encryptedPerfios, aggregatorJourney, currentApplication, aggregatorHandshakeResp, whatsAppSubscribed, preApprovedJourney, preapprovedMaster, dedupeResponse, aggregatorResponse, utmFlags } } = storeValues || {};
    const nameAsPerPan = dedupeResponse?.dashBoardInfoList?.find(x => x.nameAsPerPan)?.nameAsPerPan || "";
    const payload = {
      applicantFirstName: getName(userName, 'firstname') || '',
      applicantLastName: getName(userName, 'lastname') || ' ',
      applicantMobileNumber: mobileNumber || '',
      productType: getVehicleType(journeyType),
      journeyType: getJourneyType(journeyType),
      pan: (JSON.parse(Decryption(encryptedPerfios || "") || "{}"))?.toUpperCase() || '',
      email: email?.toLowerCase() || '',
      makerId: "0",
      startNewApplication: startNewApplication || false,
      leadSource: aggregatorHandshakeResp?.leadSource || 'IndusMobile',
      aggregatorJourney: aggregatorJourney || false,
      d2cId: currentApplication?.d2CApplicantID || aggregatorHandshakeResp?.d2cApplicantId || "0",
      whatsAppSubscribed,
      preApprovedJourney: preApprovedJourney || false,
      preapprovedMasterId: preapprovedMaster?.preapprovedMasterId?.toString() || "",
      nameAsPerPan,
      registrationNumber: aggregatorResponse?.d2cResponse?.registrationNumber?.replace(/ +/g, "")?.toUpperCase() || aggregatorHandshakeResp?.registrationNumber?.replace(/ +/g, "")?.toUpperCase() || '',
      loanAmount: preapprovedMaster?.preApprovedLimit?.toString() || "",
      ioRequiredRoi: preapprovedMaster?.interestRate?.toString() || "",
      requiredTenure: preapprovedMaster?.maxTenure?.toString() || "",
      finalTermIoLoanAmount: preapprovedMaster?.preApprovedLimit?.toString() || "",
      ioTenure: preapprovedMaster?.maxTenure?.toString() || "",
      utmSource: utmFlags?.utmSource || "",
      utmMedium: utmFlags?.utmMedium || "",
      utmCampaign: utmFlags?.utmCampaign || "",
      utmTerm: utmFlags?.utmTerm || "",
      utmContent: utmFlags?.utmContent || "",
      ...(preApprovedJourney && { etcntc: preapprovedMaster?.etcntc || "NTC" }),
    }
    await axios.post(DEDUPE_VERIFICATION, payload).then((response) => {
      cb(true, response);
    }).catch((err) => {
      cb(false, err);
    })
  },
}

export default api;
