import {useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { TextField } from "@mui/material";
import MasterServices from '../../services/masters';
import Loader from "../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { pageBasicDetails } from "../../store/pageDetails";
import { useNavigate } from "react-router-dom";
import { isProdEnvironment } from "../../utils/utils";

function TestLoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    "aggregatorReferenceId": "MFC000000001",
    "firstName": "Rekha",
    "mobileNumber": "9840142345",
    "PAN":"AXPPB6921G", 
    "vehicleType":"C", // PV or TW 
    "productType":"Used", // New or Used 
    "sellingPrice":"920000", // ORP 
    "leadSource":"MFC",  // droom/dealer/carwale/otherwebsite
    "pinCode" : "524001", // of the car purchase
    "rcNumber": "TN09CA8966",
    "requestedLoanAmount":"1100000",  // rupees,no commas
    "requestedLoanTenureinMonths" : "36",
    "interestRate":"9.5",  //percentage
    "emiPerMonth":"26597", // mandatory
    "loanAmount":"950000", 
    "loanTenureOffered":"60", // mandatory months
    "vehicleMakeCode":"HYUNDAI",
    "vehicleModelCode":"P679", 
    "vehicleVariantCode":"HYI10NIOD",
    "cityOfPurchase":"ABHANPUR",
    "employmentType":"SAL",
    "employmentName":"SOFTWARE ENGINEER",
    "organizationName":"IBM",
    "valuationMinPrice": "1240000",
    "valuationMaxPrice": "1450000",
    "valuationAvgPrice": "1350000",
    "valuationPrice": "1300000",
    "insuranceNumber": "232239202322",
    "insurerName": "Rekha Nair",
    "insuranceType": "Comprehensive",
    "insuranceDeclaredValue": "650000",
    "insuranceIssuanceDate": "2022-10-10",
    "insuranceExpiryDate": "2024-05-10",
    "state": "33",
    "colorOfVehicle": "Silver",
    "kmsRun": "88900",
    "lastName":"Nair",
    "emailId": "rekha.n@hotmail.com", 
    "dateOfBirth": "1976-12-29", 
    "aadharNumber":"0928", 
    "gender": "FEMALE", 
    "maritalStatus":"Single", //Single or Married 
    "annualIncome" : "1950000", // per year 
    "existingEMI" : "7500", 
    "aggregatorValuationReportURL": "https://aiv3portal.autoinspekt.com/report/view/Mjk0NDMwNzM0/NFdSVEwyOTQ0MzA3MzQ="
  });

  const callBackFunc = (success, response) => {
    setLoading(false)
    if (success) {
      dispatch(
        pageBasicDetails({ aggregatorResponse: response })
      );
      if (response?.d2cResponse?.d2cURL && (response?.d2cResponse?.d2cURL.includes('apps.npd2ccfd.indusind.com') || response?.d2cResponse?.d2cURL.includes('uatd2c.cfd.indusind.com'))) {
        if (window.location.href.includes('localhost')) {
          window.location.href = '/?' + response?.d2cResponse?.d2cURL?.split('?')[1];
        } else {
          window.location.href = response?.d2cResponse?.d2cURL;
        }
      }
    }
  };

  const handleClick = () => {
    setLoading(true)
    MasterServices.aggregatorForm(callBackFunc, {...values, name: values['firstName'] +" "+ values['lastName']});
  };

  const inputChangehandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isProdEnvironment) navigate("/");
  }, []);

  return (
    <><Loader isLoading={loading} />
    <div className="page-container">
      <Header
        journeyTitle="Aggregator Form"
        title="Welcome"
        subtitle="Let us know your information"
      />
      <div className="top-round-card-header" style={{ marginTop: "20px" }}></div>
      <div className="page-body-content">
        <div className="page-body-contents">
          <TextField
            id="standard-basic"
            label="Aggregator ReferenceId"
            variant="standard"
            onChange={inputChangehandler}
            value={values["aggregatorReferenceId"]}
            setValues={setValues}
            name="aggregatorReferenceId"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="First Name"
            variant="standard"
            name="firstName"
            value={values['firstName']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="Last Name"
            variant="standard"
            name="lastName"
            value={values['lastName']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="Mobile Number"
            variant="standard"
            name="mobileNumber"
            type="number"
            value={values['mobileNumber']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="PAN"
            variant="standard"
            name="PAN"
            value={values['PAN']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="Email ID"
            variant="standard"
            name="emailId"
            value={values['emailId']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />          
          <TextField
            id="standard-basic"
            label="vehicle type"
            variant="standard"
            name="vehicleType"
            value={values['vehicleType']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="product type"
            variant="standard"
            name="productType"
            value={values['productType']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="selling price"
            variant="standard"
            name="sellingPrice"
            value={values['sellingPrice']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="lead source"
            variant="standard"
            name="leadSource"
            value={values['leadSource']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="pin code"
            variant="standard"
            value={values['pinCode']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
            name="pinCode"
          />
          <TextField
            id="standard-basic"
            label="Registration Number"
            variant="standard"
            onChange={inputChangehandler}
            value={values['rcNumber']}
            setValues={setValues}
            name="rcNumber"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="requested loan amount"
            variant="standard"
            name="requestedLoanAmount"
            value={values['requestedLoanAmount']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="requested loan tenure in months"
            variant="standard"
            name="requestedLoanTenureinMonths"
            value={values['requestedLoanTenureinMonths']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="aadhar number"
            variant="standard"
            name="aadharNumber"
            value={values['aadharNumber']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
            inputProps={{ maxLength: 4 }}
          />
          <TextField
            id="standard-basic"
            label="gender"
            variant="standard"
            name="gender"
            value={values['gender']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="marital status"
            variant="standard"
            name="maritalStatus"
            value={values['maritalStatus']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="annual income"
            variant="standard"
            name="annualIncome"
            value={values['annualIncome']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="existing emi"
            variant="standard"
            name="existingEMI"
            value={values['existingEMI']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
                    <TextField
            id="standard-basic"
            label="DOB"
            variant="standard"
            name="dateOfBirth"
            value={values['dateOfBirth']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="interest rate"
            variant="standard"
            name="interestRate"
            value={values['interestRate']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="emi per month"
            variant="standard"
            name="emiPerMonth"
            value={values['emiPerMonth']}
            setValues={setValues}
            onChange={inputChangehandler}
            style={{marginBottom: '20px'}}
          />
          <TextField
            onChange={inputChangehandler}
            id="standard-basic"
            label="loan amount"
            variant="standard"
            name="loanAmount"
            value={values['loanAmount']}
            setValues={setValues}
            style={{marginBottom: '20px'}}
          />
          <TextField
            onChange={inputChangehandler}
            id="standard-basic"
            label="loan tenure offered"
            variant="standard"
            name="loanTenureOffered"
            value={values['loanTenureOffered']}
            setValues={setValues}
            style={{marginBottom: '20px'}}
          />
          <TextField
            onChange={inputChangehandler}
            id="standard-basic"
            label="vehicle make"
            variant="standard"
            name="vehicleMakeCode"
            value={values['vehicleMakeCode']}
            style={{marginBottom: '20px'}}
          />
          <TextField
            onChange={inputChangehandler}
            id="standard-basic"
            label="vehicle model"
            variant="standard"
            name="vehicleModelCode"
            value={values['vehicleModelCode']}
            setValues={setValues}
            style={{marginBottom: '20px'}}
          />
          <TextField
            onChange={inputChangehandler}
            id="standard-basic"
            label="vehicle varient"
            variant="standard"
            value={values['vehicleVariantCode']}
            setValues={setValues}
            name="vehicleVariantCode"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="city of purchase"
            variant="standard"
            onChange={inputChangehandler}
            value={values['cityOfPurchase']}
            setValues={setValues}
            name="cityOfPurchase"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="employment type"
            variant="standard"
            onChange={inputChangehandler}
            value={values['employmentType']}
            setValues={setValues}
            name="employmentType"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="employment name"
            variant="standard"
            onChange={inputChangehandler}
            value={values['employmentName']}
            setValues={setValues}
            name="employmentName"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="organization"
            variant="standard"
            onChange={inputChangehandler}
            value={values['organizationName']}
            setValues={setValues}
            name="organizationName"
            style={{marginBottom: '20px'}}
          />
          <TextField
            id="standard-basic"
            label="Valuation Min Price"
            variant="standard"
            onChange={inputChangehandler}
            value={values["valuationMinPrice"]}
            setValues={setValues}
            name="valuationMinPrice"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Valuation Max Price"
            variant="standard"
            onChange={inputChangehandler}
            value={values["valuationMaxPrice"]}
            setValues={setValues}
            name="valuationMaxPrice"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Valuation Avg Price"
            variant="standard"
            onChange={inputChangehandler}
            value={values["valuationAvgPrice"]}
            setValues={setValues}
            name="valuationAvgPrice"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Valuation Price"
            variant="standard"
            onChange={inputChangehandler}
            value={values['valuationPrice']}
            setValues={setValues}
            name="valuationPrice"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurance Number"
            variant="standard"
            onChange={inputChangehandler}
            value={values['insuranceNumber']}
            setValues={setValues}
            name="insuranceNumber"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurer Name"
            variant="standard"
            onChange={inputChangehandler}
            value={values["insurerName"]}
            setValues={setValues}
            name="insurerName"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurance Type"
            variant="standard"
            onChange={inputChangehandler}
            value={values["insuranceType"]}
            setValues={setValues}
            name="insuranceType"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurance Declared Value"
            variant="standard"
            onChange={inputChangehandler}
            value={values["insuranceDeclaredValue"]}
            setValues={setValues}
            name="insuranceDeclaredValue"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurance Issuance Date"
            variant="standard"
            onChange={inputChangehandler}
            value={values['insuranceIssuanceDate']}
            setValues={setValues}
            name="insuranceIssuanceDate"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Insurance Expiry Date"
            variant="standard"
            onChange={inputChangehandler}
            value={values["insuranceExpiryDate"]}
            setValues={setValues}
            name="insuranceExpiryDate"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="State"
            variant="standard"
            onChange={inputChangehandler}
            value={values["state"]}
            setValues={setValues}
            name="state"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Color Of Vehicle"
            variant="standard"
            onChange={inputChangehandler}
            value={values["colorOfVehicle"]}
            setValues={setValues}
            name="colorOfVehicle"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="Kms Run"
            variant="standard"
            onChange={inputChangehandler}
            value={values["kmsRun"]}
            setValues={setValues}
            name="kmsRun"
            style={{ marginBottom: '20px' }}
          />
          <TextField
            id="standard-basic"
            label="URL"
            variant="standard"
            onChange={inputChangehandler}
            value={values["aggregatorValuationReportURL"]}
            setValues={setValues}
            name="aggregatorValuationReportURL"
            style={{ marginBottom: '20px' }}
          />
        </div>
      </div>
      <Footer callBackAction={handleClick} buttonText="Next" />
    </div></>
  );
}
export default TestLoginPage;
