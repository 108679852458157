/*eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Dialog, Paper, Slide } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./InitialOfferPage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MobileWidthLayout from "../../components/MobileWidthLayout/MobileWidthLayout";
import {
  GENERATE_EMI_BTN_TEXT,
  INTEREST_RATE,
  IN_CASE_OF_QUERY,
  LOAN_REFERENCE_TEXT,
  PROCEED_YOUR_FINAL,
  EMI_GENERATION_TEXT,
  ERROR_TEXT,
  BE_DETAILS,
  SYSTEM_ERROR2,
  SYSTEM_ERROR1,
  RETRY_ACTION,
  ANIMATION_TEXT1,
  ANIMATION_TEXT2,
  DEDUPE_ERROR,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  BASIC_DETAILS_SUBHEADING_1,
  FINAL_OFFER_HEADING,
  ANIMATION_TEXT3,
  PRE_APPROVED_OFFER_HEADING,
} from "../../utils/cms";
import LoanAmountInput from "./LoanAmountInput/LoanAmountInput";
import TenureAmountInput from "./TenureAmountInput/TenureAmountInput";
import Loader from "../../components/Loader/Loader";
import {
  changeValueToCurrency,
  getJourneyType,
  getVehicleType,
  onlyNumbersAccept,
  phoneNumberFormat,
} from "../../utils/utils";
import ErrorModal from "../../components/ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { pageBasicDetails } from "../../store/pageDetails";
import initialOfferServices from "../../services/masters";
import {
  GENERATE_FINAL_OFFER,
  GENERATE_INITIAL_OFFER,
  GENERATE_PRE_APPROVED_FINAL_OFFER,
} from "../../config/config";
import { initailOfferPayloadGenerate } from "./ReuseFunctions";
import { Box } from "@mui/system";
import carAnimation from "../../assets/car-animation.png";
import bikeAnimation from "../../assets/bike-animation.png";
import BlastComponent from "./BlastComponent";
import { useNavigate } from "react-router-dom";
import { updateJourneyStatus } from '../../utils/updateJouneyStatus';

const InitialOfferPage = ({ isFinalOffer }) => {
  const navigate = useNavigate();
  const storeValues = useSelector((state) => state);
  const {
    pageDetails: {
      initialOffer,
      journeyType,
      currentApplication,
      preApprovedJourney,
      finalOffer,
      aggregatorHandshakeResp
    },
  } = storeValues || {};

  const [values, setValues] = useState({
    loanAmount: 1,
    loanAmount_text_field: "1,00,000",
    tenure: 6,
  });

  const [loading, setLoading] = useState(false);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [retryCount, setRetryCount] = useState(0);
  const [enableSaveBtn, setEnableSaveBtn] = useState(true);
  const [confirmationScreen, setConfirmationScreen] = useState(true);
  const [apiError, setApiError] = useState(false);

  const dispatch = useDispatch();
  const cb = (success, res) => {
    setLoading(false);
    if (success) {
      if (
        !res?.loanAmount ||
        !res?.minLoanAmount ||
        !res?.maxLoanAmount ||
        !res?.tenure ||
        !res?.minTenure ||
        !res?.maxTenure
      ) {
        setOpenErrorModal(true);
        setErrorMessage(SYSTEM_ERROR1);
        setRetryCount(retryCount + 1);
        return;
      }
      if (
        res?.stopJourneyFlagOfferEngine ||
        ((!res?.gatingScreeningOutcome || !res?.breDecision) && retryCount >= 1)
      ) {
        setOpenErrorModal(true);
        setErrorMessage(
          BE_DETAILS.replace("#bename#", res?.executiveName).replace(
            "#bemobile#",
            phoneNumberFormat(res?.executiveContact)
          )
        );
        setRetryCount(retryCount + 1);
      } else if (
        (!res?.getCIBILReport ||
          !res?.runEMIEngine ||
          !res?.ltvEngine ||
          !res?.pricingEngine ||
          !res?.gatingScreeningOutcome ||
          !res?.breDecision) &&
        retryCount >= 1
      ) {
        setOpenErrorModal(true);
        setErrorMessage(SYSTEM_ERROR2);
        setRetryCount(retryCount + 1);
      } else if (
        !res?.getCIBILReport ||
        !res?.runEMIEngine ||
        !res?.ltvEngine ||
        !res?.pricingEngine ||
        !res?.gatingScreeningOutcome ||
        !res?.breDecision
      ) {
        setOpenErrorModal(true);
        setRetryCount(retryCount + 1);
        setErrorMessage(SYSTEM_ERROR1);
      } else {
        valuesApplyFromResponse(res);
        setEnableSaveBtn(true);
        setIsValuesChanged(false);
        dispatch(pageBasicDetails({ initialOffer: res }));
      }
    } else {
      if (!res?.response?.data) {
        setOpenErrorModal(true);
        setApiError(true);
        setErrorMessage(DEDUPE_ERROR);
        return;
      }
    }
  };

  let payload = initailOfferPayloadGenerate(storeValues, isFinalOffer);

  const generateEmi = async () => {
    setLoading(true);
    let generateEmiPayload = { ...payload };
    generateEmiPayload.offerRunCount = 1;
    generateEmiPayload.requiredTenureFromSlider = values?.tenure;
    generateEmiPayload.engineBankIrr = initialOffer?.engineBankIrr || "";
    generateEmiPayload.engineLTV = initialOffer?.engineLTV || "";
    generateEmiPayload.engineRiskBand = initialOffer?.engineRiskBand || "";
    generateEmiPayload.newexistingEMI = initialOffer?.existingEMI || "";
    generateEmiPayload.sumofAllLowerEMIsFromEMIEngine = initialOffer?.sumofAllLowerEMIsFromEMIEngine || "";
    generateEmiPayload.requiredLoanAmountFromSlider = onlyNumbersAccept(
      values?.loanAmount_text_field
    );
    initialOfferServices.initialOffer(
      cb,
      isFinalOffer
        ? preApprovedJourney
          ? GENERATE_PRE_APPROVED_FINAL_OFFER
          : GENERATE_FINAL_OFFER
        : GENERATE_INITIAL_OFFER,
      generateEmiPayload
    );
  };

  const lakhsGenerte = (value) => {
    let intValue = parseInt(value);
    if (intValue) {
      return intValue;
    }
  };

  const valuesApplyFromResponse = (res) => {
    let {
      loanAmount: intLoanAmount,
      minLoanAmount,
      maxLoanAmount,
      tenure,
      minTenure,
      maxTenure,
      expectedEMIAmount,
    } = res || {};
    let loanAmount = parseInt(intLoanAmount);
    if (parseInt(tenure) <= parseInt(minTenure)) {
      tenure = minTenure;
    }
    if (parseInt(tenure) >= parseInt(maxTenure)) {
      tenure = maxTenure;
    }
    if (parseInt(loanAmount) <= parseInt(minLoanAmount)) {
      loanAmount = minLoanAmount;
    }
    if (parseInt(loanAmount) >= parseInt(maxLoanAmount)) {
      loanAmount = maxLoanAmount;
    }
    let loanAmount_text_field = changeValueToCurrency.format(loanAmount + "");
    loanAmount = lakhsGenerte(loanAmount);
    minLoanAmount = lakhsGenerte(minLoanAmount);
    maxLoanAmount = lakhsGenerte(maxLoanAmount);
    setValues({
      loanAmount_text_field,
      loanAmount,
      minLoanAmount,
      maxLoanAmount,
      tenure,
      minTenure: parseInt(minTenure),
      maxTenure: parseInt(maxTenure),
      expectedEMIAmount: changeValueToCurrency.format(expectedEMIAmount),
    });
  };

  useEffect(() => {
    if (isFinalOffer) {
      updateJourneyStatus(24, 'Final Offer in Progress');
    } else {
      updateJourneyStatus(11, 'Initial Offer in Progress');
    }
    if (initialOffer) {
      valuesApplyFromResponse(initialOffer);
    }
    if (confirmationScreen) {
      setTimeout(() => {
        setConfirmationScreen(false);
      }, 3000);
    }
    setIsValuesChanged(true);
  }, []);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const saveCb = (success, res) => {
    setLoading(false);
    if (success) {
      if (res?.statusCode === "200") {
        navigate(isFinalOffer ? "/motorInsurance" : "/roadmap/1");
      } else {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setApiError(true);
      }
    } else {
      if (!res?.response?.data) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setApiError(true);
      }
    }
  };

  const handleRedirect = () => {
    setLoading(true);
    let savePayload = {};
    if (isFinalOffer) {
      savePayload = {
        d2cApplicantId: currentApplication?.d2CApplicantID || "",
        leadNumber: currentApplication?.leadId || "",
        documentId: currentApplication?.documentId || "",
        applicantId: currentApplication?.sfapplicantId || "",
        productType: getVehicleType(journeyType),
        vehicleType: getJourneyType(journeyType),
        offerengineMinTenure: initialOffer?.minTenure || 0,
        offerengineMaxTenure: initialOffer?.maxTenure || 0,
        maximumTenure: initialOffer?.tenure || 0,
        emiAmount: initialOffer?.expectedEMIAmount || 0,
        loanAmount: initialOffer?.loanAmount || 0,
        offerengineMaxLoanAmount: initialOffer?.maxLoanAmount || 0,
        offerengineMinLoanAmount: initialOffer?.minLoanAmount || 0,
        serviceCharges: initialOffer?.serviceCharges || 0,
        documentationCharges: initialOffer?.documentationCharges || 0,
        dealerIncentiveAmountMainDealer: initialOffer?.dealerIncentive || 0,
        dealerIncentiveAmountSubDealer: initialOffer?.dealerIncentiveSub || 0,
        provisionalChannelCost: initialOffer?.provisionCost || 0,
        fIwaiverCurrentAddress:
          finalOffer?.fiDecision?.find((each) => each?.fiType === "C")
            ?.fiStatus || "",
        fIwaiverPresentAddress:
          finalOffer?.fiDecision?.find((each) => each?.fiType === "P")
            ?.fiStatus || "",
        fIwaiverOffAddress:
          finalOffer?.fiDecision?.find((each) => each?.fiType === "O")
            ?.fiStatus || "",
        pricingEngineThresholdNetrr:
          initialOffer?.ioPricingEnginethresholdNetrr || "",
        ltv: initialOffer?.engineLTV,
        scorecardDecision: initialOffer?.ioScorecardDecision || "",
        checkEligibilityMessage: initialOffer?.ioCheckEligibilityMessage || "",
        vehicleDetailsId: initialOffer?.vehicleDetailId || "",
        crmIrr: initialOffer?.interestRate || 0,
        mfrIncentive: initialOffer?.mfrIncentive || "",
        basePrice: 0,
        stampingCharges: "",
        tradeCertificate: "",
        opportunityId: currentApplication?.loanApplicationId || "",
        rcuRetentionCharges: "",
        requiredROI: "",
        grossIrr: initialOffer?.grossIrr || 0,
        inputtedIrr: initialOffer?.inputtedIrr || 0,
        netIrr: initialOffer?.netIrr || 0,
        finalTermId: initialOffer?.finalTermsId || 0,
        dealNo: "0",
        isPACustomer: preApprovedJourney || false,
        executiveName: initialOffer?.executiveName || "",
        executiveContact: initialOffer?.executiveContact || "",
      };
    } else {
      savePayload = {
        d2cApplicantId: currentApplication?.d2CApplicantID || "",
        SFLoanApplicationId: currentApplication?.loanApplicationId || "",
        leadNumber: currentApplication?.leadId || "",
        documentId: currentApplication?.documentId || "",
        SFApplicantId: currentApplication?.sfapplicantId,
        leadSource: aggregatorHandshakeResp?.leadSource || 'IndusMobile',
        d2cLoanTypeId: getVehicleType(journeyType),
        journeyType: getJourneyType(journeyType),
        loanApplStatus: 11,
        intialOfferAmt: initialOffer?.loanAmount || 0,
        ...initialOffer,
      };
      delete savePayload.getCIBILReport;
      delete savePayload.gatingScreeningOutcome;
      delete savePayload.runEMIEngine;
      delete savePayload.breDecision;
      delete savePayload.ltvEngine;
      delete savePayload.pricingEngine;
      delete savePayload.journeyStop;
    }
    initialOfferServices.saveInitialOffer(saveCb, savePayload, isFinalOffer);
  };

  return (
    <div className="page-container">
      {openErrorModal && (
        <ErrorModal
          errorTitle={ERROR_TEXT}
          errorContent={errorMessage}
          errorSubContent={apiError ? OTP_GENERATION_FAILED_TRY_AGAIN : ""}
          openErrorModal={openErrorModal}
          closeErrorModal={() => {
            setOpenErrorModal(false);
            if (apiError) {
              setApiError(false);
              valuesApplyFromResponse(initialOffer);
              return;
            }
            if (retryCount <= 1) {
              generateEmi();
            } else if (retryCount === 2) {
              sessionStorage.clear();
              localStorage.clear();
              window.location.href = "/";
            }
          }}
          buttonText={retryCount <= 1 ? RETRY_ACTION : null}
        />
      )}
      {confirmationScreen && (
        <>
          <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            className="offer-for-you-popup"
          >
            <BlastComponent />
            <BlastComponent className="blast-2" />
            <Box>
              <Paper elevation={3}>
                <div className="animation-text-box">
                  {preApprovedJourney ? ANIMATION_TEXT3 : ANIMATION_TEXT1}
                </div>
                <div>
                  <img
                    src={
                      journeyType?.includes("Car")
                        ? carAnimation
                        : bikeAnimation
                    }
                    alt=""
                    className="animation-img"
                  />
                </div>
                <Paper className="animation-bottom-text">
                  {ANIMATION_TEXT2}
                </Paper>
              </Paper>
            </Box>
          </Dialog>
        </>
      )}
      <Loader isLoading={loading} />
      <Header
        title={
          isFinalOffer
            ? preApprovedJourney
              ? PRE_APPROVED_OFFER_HEADING
              : FINAL_OFFER_HEADING
            : BASIC_DETAILS_SUBHEADING_1
        }
        subtitle={EMI_GENERATION_TEXT}
        topSlider={isFinalOffer ? 80 : 40}
      />
      <div className="initial-offer-page">
        <div className="top-round-card-header" style={{ marginTop: "50px" }}>
          <div className="expected-emi-cont flex align-center justify-between">
            <p className="card-heading">Expected EMI</p>
            <p className="emi flex align-center">
              <CurrencyRupeeIcon />
              {values?.expectedEMIAmount || 0}
            </p>
          </div>
        </div>
        <div className="page-body-content" style={{ height: "auto" }}>
          <div className="page-body-contents top-section">
            <MobileWidthLayout>
              <LoanAmountInput
                setIsValuesChanged={setIsValuesChanged}
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                max={values.maxLoanAmount}
                min={values.minLoanAmount}
              />
              <TenureAmountInput
                max={values.maxTenure}
                min={values.minTenure}
                setIsValuesChanged={setIsValuesChanged}
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
              />

              <div className="interest-rate-cont flex align-center justify-between">
                <p className="slider-label">{INTEREST_RATE}</p>
                <p>{parseFloat(initialOffer?.interestRate || 0).toFixed(2)}%</p>
              </div>
              <div className="gen-emi-btn-cont">
                <Button
                  className="btn-primary action-btn"
                  variant="contained"
                  color="secondary"
                  onClick={generateEmi}
                  disabled={
                    !isValuesChanged ||
                    errors.loanAmount_text_field ||
                    errors.tenure
                  }
                >
                  <b>{GENERATE_EMI_BTN_TEXT}</b>
                </Button>
              </div>
            </MobileWidthLayout>
          </div>
        </div>
        <div className="bg-white footer-fix-center mt-10">
          <MobileWidthLayout>
            <div className="loan-reference-info">
              <p className="text-small">
                {LOAN_REFERENCE_TEXT} <b>{currentApplication?.leadId?.split('_B')[0] || initialOffer?.loanReferenceNumber}</b>
              </p>
              <br />
              <p className="text-small ">
                {`${PROCEED_YOUR_FINAL} ${
                  initialOffer?.executiveName
                }, +91 ${initialOffer?.executiveContact
                  ?.match(/.{1,5}/g)
                  ?.join(" ")} ${IN_CASE_OF_QUERY}`}
              </p>
            </div>
          </MobileWidthLayout>

          <Footer
            callBackAction={handleRedirect}
            disableButton={!enableSaveBtn || isValuesChanged}
            buttonText={"Save & Continue"}
            apiTriggered={false}
          />
        </div>
      </div>
    </div>
  );
};

export default InitialOfferPage;
