import React from "react";
import "./CustomInput.css";
const CheckboxInput = ({
  disabled,
  values,
  setValues,
  label,
  checked,
  className = "",
  name = "",
  subLabel,
  afterInputChangeCallback,
  showToastMessage,
  openTermsModal,
  ...props
}) => {

  const checkChangeHandler = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;
    let updatedValues = { ...values, [name]: checked };
    if(setValues) setValues(updatedValues);
    afterInputChangeCallback &&
      afterInputChangeCallback(updatedValues, name, checked);
  };

  return (
    <div className="custom-checkbox custom-input">
    {openTermsModal && <div className="checkbox-cover" onClick={()=>{
      openTermsModal()
    }}></div>}
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked || values?.[name] || false}
        className=""
        onChange={checkChangeHandler}
        name={name}
        {...props}
      />
      <div className="flex flex-column checkbox-label">
        {label && <p className={` m-0 ${disabled ? "disabled-label" : ""}`}>{label}</p>}
        {subLabel && (
          <p className={`sub-label ${disabled ? "disabled-label" : ""}`}>
            {subLabel}
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckboxInput;
