/*eslint-disable react-hooks/exhaustive-deps*/
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  LoanAndHeathInsurance,
  MotoroInsuranceData,
  initialModalOption,
  RevisedEMI,
  stringValueToNumber,
  twoWheelerInsuranceData,
} from "./data";
import Masterservice from "../../services/masters";
import { getJourneyType, getVehicleType } from "../../utils/utils";
import {
  SAVE_CONTINUE_ACTION,
  AGREE_TERMS_TEXT,
  INSURANCE_BOTTOM_TEXT,
  MOTOR_INSURANCE,
  LOAN_AND_HEATH_INSURANCE_HEADRE,
  LOAN_AND_HEATH_INSURANCE_BOTTOM_TEXT,
  LOAN_PLACEHOLDER,
  INSURANCE_PLACEHOLDER,
  ATTENSION_BODY_TEXT,
  ATTENSION_BODY_TEXT1,
  GO_BACK_ACTION,
  CLOSE_ACTION,
  MOTOR_INSURANCE_AGREE_TERMS_TEXT,
  ATTENSION_HEADER,
  MOTOR_INSURANCE_SUB_TEXT,
  ATTENSION_BODY_TEXT2,
  PROCEED_ACTION,
  JOURNEY_TYPE_3,
  INSURANCE_HEADING,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  DEDUPE_ERROR,
  ERROR_TEXT,
  JOURNEY_TYPE_4,
  JOURNEY_TYPE_2,
  HEALTH_INSURANCE5_TERMS,
  HEALTH_INSURANCE5_TERMS1,
  INSURANCE_HEADING1,
  RETRY_ACTION,
  RESEND_OTP_ERROR_TEXT
} from "../../utils/cms";
import InsuranceSelection from "./InsuranceSelcetion";
import { useState, useEffect } from "react";
import ConfirmationModal from "./../../components/ConfirmationDialogBox/index";
import InsuranceTerms from "./InsuranceTerms/InsuranceTerms";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import ErrorModal from '../../components/ErrorModal';
import { useNavigate } from "react-router-dom";
import { initailOfferPayloadGenerate } from "../InitialOfferPage/ReuseFunctions";
import { Decryption } from "../../utils/payload";

function MotorInsurance() {
  const [disabledButton, setDisabledButton] = useState(true);
  const [terms, setTermsModal] = useState(false);
  const [switchValue, setSwitchValue] = useState({
    motorPremium: true,
    liAndFlexiPremium: true
  });
  const [modalOptions, setModalOptions] = useState(initialModalOption);
  const [options, setOptions] = useState();
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showHealthConsent, isShowHealthConsent] = useState(false);
  const [healthConsent, setHealthConsent] = useState(false);
  const [showMotorInsurance, setShowMotorInsurance] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const storeValues = useSelector((state) => state);
  const navigate = useNavigate()
  const { pageDetails: { journeyType, currentApplication, encryptedPerfios, config }, userDetails: { mobileNumber } } = storeValues || {};
  const payload = {
    D2CApplicantID: currentApplication?.d2CApplicantID || "",
    leadID: currentApplication?.leadId || "",
    vehicleType: getVehicleType(journeyType),
    journeyType: getJourneyType(journeyType),
  };

  useEffect(() => {
    if ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) && !values?.motorPremium) {
      setValues({ ...values, motorPremium: options?.motorPremium?.[0] });
      setSwitchValue({...switchValue, motorPremium: options?.motorPremium?.[0]?.funded})
      const showMotorInsurance = options?.motorPremium?.[0]?.insurer && options?.motorPremium?.[0]?.grossPremium;
      setShowMotorInsurance(showMotorInsurance);
    }
    if (journeyType === JOURNEY_TYPE_3) {
      setSwitchValue({...switchValue, motorPremium: false});
      setShowMotorInsurance(false);
    }
    if (
      values?.liAndFlexiPremium?.value &&
      (journeyType !== JOURNEY_TYPE_3 ? values?.motorPremium?.value : true)
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
    if (values?.liAndFlexiPremium?.insurancePlan?.toUpperCase()?.includes("2WCOMBO4") || (values?.liAndFlexiPremium?.insurancePlan?.toUpperCase())?.includes("FLEXI")) {
      isShowHealthConsent(true);
    } else {
      isShowHealthConsent(false);
    }
  }, [terms, values]);


  const premiumDetailsToOptionsGenerate = (premiumDetails, insuranceProduct) => {
    let options = premiumDetails?.map((each) => ({
      code: each.insurer,
      fundDisabled: !insuranceProduct && (journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4),
      value: `${journeyType === JOURNEY_TYPE_3 || insuranceProduct ? each.insuranceProduct : each.insurer}`,
      ...each
    }));
    return options || []
  }

  const saveEMI = (response) => {
    Masterservice.getVerificationDetails(
      callBackFunc,
      "insuranceemi",
      "saveExpectedEmi",
      {
        D2CApplicantId: currentApplication?.d2CApplicantID || "",
        insuranceEmi: response?.emiAmount || "0",
        insuranceEmiFlag: response?.emiAmount ? true : false
      }
    );    
  }

  const generateRevisedEMI = () => {
    setLoading(true);
    let emiPayload = initailOfferPayloadGenerate(storeValues, true);
    emiPayload.fundedInsurance = getFundedInsurance()
    Masterservice.getVerificationDetails(
      callBackFunc,
      "insurance",
      "expectedEmi",
      emiPayload
    );    
  };
  

  const callBackFunc = (success, response, type) => {
    if (success) {
      if (type === "getinsurancedetails") {
        setLoading(false);
        setRetryCount(0);
        let motorPremiumOptions = premiumDetailsToOptionsGenerate(response?.motorPremiumDetails)
        motorPremiumOptions.push({
          code: "NA",
          value: "No, I would be getting Motor Insurance from elsewhere",
          grossPremium: "0",
        })
        let flexiOptions = premiumDetailsToOptionsGenerate(response?.liAndFlexiPremiumDetails, true)
        flexiOptions.push({
          code: "NA",
          value: journeyType === JOURNEY_TYPE_3 ? "No, I don’t need Insurance" : "No I would not like to add Insurance",
          grossPremium: "0",
        })
        setOptions({
          motorPremium: motorPremiumOptions,
          liAndFlexiPremium: flexiOptions,
        });
        setResponseData(response);
      }
      if (response?.statusCode !== '200') {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
        return;
      }
      if (type === "expectedEmi") {
        setLoading(false);
        setModalOptions({
          ...modalOptions,
          errorTitle: ATTENSION_HEADER,
          errorContent: ATTENSION_BODY_TEXT2,
          errorSubContent: RevisedEMI(response?.emiAmount),
          openErrorModal: true,
          primaryAction: true,
          primaryBtnText: PROCEED_ACTION,
          secondaryAction: true,
          secondaryBtnText: GO_BACK_ACTION,
          callBackAction: (action) => {
            if (action === "continue") {
              saveEMI(response);
              return;
            }
            setModalOptions(initialModalOption);
          },
        });
      }
      if (type === "saveExpectedEmi") {
        handleSaveAndContinueApi(true);
      }
      if (type === "insurancesync") {
        handleSaveAndContinueApi();
      }
      if (type === "saveinsurancedetails") {
          setLoading(false)
          navigate('/roadmap/4')
          return;
      }
    } else {
      setLoading(false);
      setOpenErrorModal(true);
      setErrorMessage(DEDUPE_ERROR);
      setErrorContent(OTP_GENERATION_FAILED_TRY_AGAIN);
      if (type === "getinsurancedetails") {
        setRetryCount(retryCount + 1);
        if (retryCount + 1 === config?.PAGE_RETRY_LIMIT) setErrorContent(RESEND_OTP_ERROR_TEXT);
      }
    }
  };

  const getMotorInsurance = () => {
    setLoading(true);
    let getInsurancePayload = {
      ...payload, applicantType: currentApplication?.customerType,
      mobileNumber: mobileNumber,
      pan: JSON.parse(Decryption(encryptedPerfios || "") || "{}") || '',
    }
    Masterservice.getVerificationDetails(
      callBackFunc,
      "getinsurancedetails",
      "getinsurancedetails",
      getInsurancePayload
    );
  }  

  useEffect(() => {
    getMotorInsurance();
  }, []);


  const fixedToTwoDeci = (value) => {
    return parseFloat(value).toFixed(2) || 0
  }

  const handleSaveAndContinueApi = (insurancesync) => {
    setLoading(true);
    let LiOption = options?.liAndFlexiPremium?.find(x => (x?.insurancePlan?.toUpperCase())?.includes("LI"))
    let flexiOption = options?.liAndFlexiPremium?.find(x => (x?.insurancePlan?.toUpperCase())?.includes("FLEXI"))

    let saveAndContinuePayload = {
      ...payload,
      sfLoanApplicationId: responseData?.sfLoanApplicationId,
      sfApplicantId: responseData?.sfApplicantId,
      fundMotorInsuranceFlag: journeyType === JOURNEY_TYPE_3 || values?.motorPremium?.code === "NA" ? false : switchValue.motorPremium,
      fundComboInsuranceFlag: values?.liAndFlexiPremium?.code === "NA" ? false : switchValue.liAndFlexiPremium,
      motorInsurer: values?.motorPremium?.code || "",
      motorGrossPremium: stringValueToNumber(values?.motorPremium?.grossPremium || 0),
      comboInsurer: values?.liAndFlexiPremium?.insuranceProduct || values?.liAndFlexiPremium?.code || "",
      comboGrossPremium: fixedToTwoDeci(values?.liAndFlexiPremium?.grossPremium || 0),
      comboInsurancePlan: values?.liAndFlexiPremium?.insurancePlan || "",
      isFlexiOpted: values?.liAndFlexiPremium?.code === "NA" ? false : (values?.liAndFlexiPremium?.insurancePlan?.toUpperCase())?.includes("FLEXI"),
      isLiOpted: values?.liAndFlexiPremium?.code === "NA" ? false : (values?.liAndFlexiPremium?.insurancePlan?.toUpperCase())?.includes("LI"),
      flexiPremium: fixedToTwoDeci(flexiOption?.grossPremium || 0),
      flexiInsurer: flexiOption?.insurer || "",
      liPremium: fixedToTwoDeci(LiOption?.grossPremium || 0),
      liInsurer: LiOption?.insurer || ""
    };
    if (insurancesync) {
      saveAndContinuePayload.motorPlan = values?.motorPremium?.insurancePlan || "";
      saveAndContinuePayload.motorProduct = values?.motorPremium?.insuranceProduct || "";
    }
    setLoading(true);
    Masterservice.getVerificationDetails(
      callBackFunc,
      insurancesync ? "insurancesync" : "saveinsurancedetails",
      insurancesync ? "insurancesync" : "saveinsurancedetails",
      saveAndContinuePayload
    );

  };

  let getFundedInsurance = () => {
    let motorPremium = stringValueToNumber(values?.motorPremium?.grossPremium)
    let comboInsurance = stringValueToNumber(values?.liAndFlexiPremium?.grossPremium)
    return (switchValue?.motorPremium ? motorPremium : 0) + (switchValue?.liAndFlexiPremium ? comboInsurance : 0)
  }
  
  const handleClick = () => {
    let { maxLoanAmount, loanAmount } = responseData || {}
    maxLoanAmount = stringValueToNumber(maxLoanAmount)
    loanAmount = stringValueToNumber(loanAmount)

    const totalAmount = loanAmount + getFundedInsurance()
    let per110Amount = maxLoanAmount + (maxLoanAmount * 0.1)
    if (totalAmount > per110Amount) {
      setModalOptions({
        ...modalOptions,
        errorTitle: ATTENSION_HEADER,
        errorContent: ATTENSION_BODY_TEXT,
        errorSubContent: ATTENSION_BODY_TEXT1,
        openErrorModal: true,
        primaryAction: true,
        hideLoader: true,
        primaryBtnText: GO_BACK_ACTION,
        callBackAction: () => {
          setModalOptions(initialModalOption);
        },
      });
      if ((journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4) && values.motorPremium.fundDisabled) {
        let newVal = {...values.motorPremium, fundDisabled: false}
        setValues({ ...values, motorPremium: newVal });
      }
    } else {
      if ((switchValue.motorPremium && values?.motorPremium?.code !== "NA") || (switchValue.liAndFlexiPremium && values?.liAndFlexiPremium?.code !== "NA")) {
        generateRevisedEMI();
      }
      else {
        handleSaveAndContinueApi(true)
      }
    }
  };
  const handleInformation = (name) => {
    if (name === "motorPremium") {
      setModalOptions({
        ...modalOptions,
        errorTitle: MOTOR_INSURANCE,
        errorContent: MotoroInsuranceData(),
        openErrorModal: true,
        primaryAction: true,
        hideLoader: true,
        primaryBtnText: CLOSE_ACTION,
        callBackAction: (action) => {
          if (action === "continue") {
            setModalOptions(initialModalOption);
          }
        },
      });
    }
    if (name === "liAndFlexiPremium") {
      setModalOptions({
        ...modalOptions,
        errorTitle: journeyType === JOURNEY_TYPE_3 ? INSURANCE_HEADING1 : LOAN_AND_HEATH_INSURANCE_HEADRE,
        errorContent: journeyType === JOURNEY_TYPE_3 ? twoWheelerInsuranceData() : LoanAndHeathInsurance(),
        openErrorModal: true,
        primaryAction: true,
        primaryBtnText: CLOSE_ACTION,
        hideLoader: true,
        callBackAction: (action) => {
          if (action === "continue") {
            setModalOptions(initialModalOption);
          }
        },
      });
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      {terms === false && healthConsent === false && (
        <div className="page-container insurance">
          <Header journeyTitle={journeyType} title={INSURANCE_HEADING} />
          <div className="top-round-card-header" style={{ marginTop: "20px" }}></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              {<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
              {showMotorInsurance && <InsuranceSelection
                heading={MOTOR_INSURANCE}
                subText={MOTOR_INSURANCE_SUB_TEXT}
                fundedText={INSURANCE_BOTTOM_TEXT}
                label={INSURANCE_PLACEHOLDER}
                name="motorPremium"
                values={values}
                setValues={setValues}
                switchValue={switchValue}
                setSwitchValue={setSwitchValue}
                handleInformation={handleInformation}
                options={options?.motorPremium}
                disbleDropDown={journeyType === JOURNEY_TYPE_2 || journeyType === JOURNEY_TYPE_4}
              />}
              <div>
                <InsuranceSelection
                  heading={LOAN_AND_HEATH_INSURANCE_HEADRE}
                  fundedText={LOAN_AND_HEATH_INSURANCE_BOTTOM_TEXT}
                  label={LOAN_PLACEHOLDER}
                  values={values}
                  setValues={setValues}
                  name="liAndFlexiPremium"
                  switchValue={switchValue}
                  setSwitchValue={setSwitchValue}
                  handleInformation={handleInformation}
                  options={options?.liAndFlexiPremium}
                />
              </div>
              {showHealthConsent && <><div className="page-body-checkbox">
                <p>{HEALTH_INSURANCE5_TERMS}
                  <label
                    className="page-body-check-box-click"
                    onClick={() => setHealthConsent(true)}
                  >
                    {HEALTH_INSURANCE5_TERMS1}
                  </label>
                </p>
              </div><br/></>}
              <div className="page-body-checkbox">
                <p>
                  {MOTOR_INSURANCE_AGREE_TERMS_TEXT}
                  <label
                    className="page-body-check-box-click"
                    onClick={() => setTermsModal(true)}
                  >
                    {AGREE_TERMS_TEXT}
                  </label>
                </p>
              </div>
            </div>
            <Footer
              callBackAction={handleClick}
              disableButton={disabledButton}
              buttonText={SAVE_CONTINUE_ACTION}
            />
          </div>
        </div>
      )}
      {(terms === true || healthConsent === true) && (
        <div className="terms-conditions-container">
          <div className="top-round-card-header" style={{ marginTop: "0px" }}></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              <InsuranceTerms
                healthConsent={healthConsent}
                closeTersmAction={() => {
                  setTermsModal(false);
                  setHealthConsent(false);
                }}
                acceptTersmAction={() => {
                  setTermsModal(false);
                  if (healthConsent) {
                    setHealthConsent(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        errorTitle={modalOptions.errorTitle}
        errorContent={modalOptions.errorContent}
        errorSubContent={modalOptions.errorSubContent}
        openErrorModal={modalOptions.openErrorModal}
        secondaryAction={modalOptions.secondaryAction}
        primaryAction={modalOptions.primaryAction}
        secondaryBtnText={modalOptions.secondaryBtnText}
        primaryBtnText={modalOptions.primaryBtnText}
        callBackAction={modalOptions.callBackAction}
        hideLoader={modalOptions.hideLoader}
      />
      {errorMessage && <ErrorModal errorTitle={ERROR_TEXT} errorContent={errorMessage} errorSubContent={errorContent} openErrorModal={openErrorModal} closeErrorModal={() => {
        setOpenErrorModal(false);
        setLoading(false);
        setModalOptions({...modalOptions, hideLoader: true})
        if (!responseData?.loanAmount) {
          if (retryCount !== config?.PAGE_RETRY_LIMIT) {
            getMotorInsurance();
          } else {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/';
          }
        }
      }}
      buttonText={(retryCount !== config?.PAGE_RETRY_LIMIT) ? RETRY_ACTION : null}
      />}
    </>
  );
}

export default MotorInsurance;
