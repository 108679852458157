/*eslint-disable eqeqeq*/
import MasterServices from '../../services/masters';
import { store } from '../../store/index'

export default function handleApiRequest(step, pageName, values, response, callbackFunction) {
    const storeValues = store.getState();
    const { pageDetails: { residenceDetails, officeDetails, currentApplication, reKYC } } = storeValues || {};
    let responseData = {...residenceDetails, customerType: (reKYC ? 'NTB' : currentApplication?.customerType), reKYC: reKYC || residenceDetails?.rekyc || false};
    delete responseData.rekyc;
    if ((step == "ntbAddress" || step == "etbAddress") && (pageName === "ResidenceAddress" || pageName === "ETBResidenceAddress")) {
        let payload, address
        if (pageName === "ETBResidenceAddress") {
            payload = {...responseData}
        }
        if (values?.addressType === "noneAddress") {
            payload = {
                ...responseData,
                noneAddressAvailable: true,
                currentAddressAvailable: false,
                permanentAddressAvailable: false,
            }
        } else if (values?.addressType === "bothAddress") {
            address = {
                addressLine1: responseData?.address?.aadharAddress?.addressLine1,
                addressLine2: responseData?.address?.aadharAddress?.addressLine2,
                cityName: responseData?.address?.aadharAddress?.cityName,
                districtName: responseData?.address?.aadharAddress?.districtName,
                stateName: responseData?.address?.aadharAddress?.stateName,
                pinCode: responseData?.address?.aadharAddress?.pinCode
            }
            payload = {
                ...responseData,
                address: {
                    ...responseData.address,
                    permanentAddress: {...address},
                    currentAddress: {...address}
                }
            }
            payload.permanentAddressAvailable = true;
            payload.currentAddressAvailable = true;
        } else {
            address = {
                addressLine1: values?.addressLine1,
                addressLine2: values?.addressLine2,
                cityName: values?.cityName?.value,
                districtName: values?.districtName?.value,
                stateName: values?.stateName?.value,
                pinCode: values?.pinCode
            }
            if ((pageName === "ResidenceAddress" && values?.addressType === "currentAddress") || (pageName === "ETBResidenceAddress" && values?.addressType === "permanentAddress")) {
                address.d2CAddressId = responseData.address?.permanentAddress?.d2CAddressId
                payload = {
                    ...responseData,
                    address: {
                        ...responseData.address,
                        permanentAddress: address,
                        currentAddress: (pageName === "ETBResidenceAddress") ? {...responseData?.address?.currentAddress} : {...responseData?.address?.aadharAddress}
                    }
                }
                payload.currentAddressAvailable = true;
                payload.permanentAddressAvailable = false;
            }
            if ((pageName === "ResidenceAddress" && values?.addressType === "permanentAddress") || (pageName === "ETBResidenceAddress" && values?.addressType === "currentAddress")) {
                address.d2CAddressId = responseData.address?.currentAddress?.d2CAddressId
                payload = {
                    ...responseData,
                    address: {
                        ...responseData.address,
                        permanentAddress: (pageName === "ETBResidenceAddress") ? {...responseData?.address?.permanentAddress} : {...responseData?.address?.aadharAddress},
                        currentAddress: address
                    }
                }
                payload.permanentAddressAvailable = true;
                payload.currentAddressAvailable = false;
            }
        }
        MasterServices.getVerificationDetails(
            callbackFunction,
            "saveaddressinfo",
            pageName,
            payload
        );
    }
    if (step == 1) {
        let payload;
        responseData = {...officeDetails, customerType: currentApplication?.customerType};
        payload = {
            ...responseData,
            address: {
                ...responseData.address,
                officeAddress: {
                    employerBusinessName: values?.employerName || values?.businessName,
                    addressLine1: values?.addressLine1,
                    addressLine2: values?.addressLine2,
                    cityCode: ((values?.cityName?.code)?.includes('-') ? (values?.cityName?.code)?.split('-')[1] : values?.cityName?.code) || '',
                    cityName: values?.cityName?.value,
                    districtCode: values?.districtName?.code,
                    districtName: values?.districtName?.value,
                    stateCode: values?.stateName?.code,
                    stateName: values?.stateName?.value,
                    pinCode: values?.pinCode                  
                }
            }
        }
        if (pageName === 'officeAddress') {
            payload.businessIsPermanentAddress = false;
            payload.businessIsCurrentAddress = false;
            if (values?.addressType === 'current_address') {
                payload.businessIsCurrentAddress = true;
                payload.address.officeAddress = {...responseData.address?.currentAddress}
            } else if (values?.addressType === 'permanent_address') {
                payload.businessIsPermanentAddress = true;
                payload.address.officeAddress = {...responseData.address?.permanentAddress}
            }
            payload.address.officeAddress.employerBusinessName = values?.businessName;
        }
        MasterServices.getVerificationDetails(
            callbackFunction,
            "saveaddressinfo",
            pageName,
            payload
        );
    }
    if (step == 2) {
        let payload;
        payload = {
            ...values,
        }
        if (values?.maritalStatus === "Single") payload.spouseName = "";
        payload.qualificationCode = values?.qualificationCode?.code;
        payload.qualificationValue = values?.qualificationCode?.value;
        MasterServices.getVerificationDetails(
            callbackFunction,
            "savedemography",
            pageName,
            payload
        );
    }
}
