/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable array-callback-return*/
/*eslint-disable no-useless-computed-key*/
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  WELCOME_HEADER,
  MOBILE_NUMBER,
  AGREE_TERMS_TEXT,
  AGREE_TEXT,
  READ_MORE,
  TERMS_CONDITION,
  PAN_CARD_NUMBER,
  EMAIL_ID_NUMBER,
  PAN_CARD_NUMBER_TEXT,
  NEXT_ACTION,
  ERROR_TEXT,
  MAXIMUM_GENERATED_OTP_ERROR1,
  OTP_GENERATION_FAILED_TRY_AGAIN,
  MAXIMUM_GENERATED_OTP_ERROR,
  OTP_GENERATION_FAILED,
  PAN_ERROR_TEXT,
  PAN_ERROR_SUB_TEXT,
  EMAIL_ERROR_TEXT,
  EMAIL_ERROR_SUB_TEXT,
  DEDUPE_ERROR,
  RESEND_OTP_ERROR_TEXT,
  CONTACT_BRANCH_ERROR,
  BE_DETAILS_2,
  INFORMATION_TEXT,
  EMAIL_ID_NUMBER_TEXT,
  PAN_ERROR_SUB_TEXT1,
  PAN_ERROR_TEXT1,
} from "../../utils/cms";
import CheckboxInput from "../../components/CustomInput/CheckboxInput";
import Terms from "../Terms";
import EmailInput from "../../components/CustomInput/EmailInput/EmailInput";
import PanInput from "../../components/CustomInput/PanInput/PanInput";
import PhoneNumberInput from "../../components/CustomInput/PhoneNumberInput/PhoneNumberInput";
import FullNameInput from "../../components/CustomInput/FullNameInput/FullNameInput";
import ErrorModal from "../../components/ErrorModal";
import MobileOTP from "../../components/MobileOTP/OTP";
import { useSelector, useDispatch } from "react-redux";
import LoginServices from "../../services/login";
import { userBasicDetails } from "../.././store/userDetails";
import TermsAndConditionsWrapper from "../../components/TermsAndConditionsWrapper/TermsAndConditionsWrapper";
import Loader from "../../components/Loader/Loader";
import { pageBasicDetails } from "../../store/pageDetails";
import { useNavigate } from "react-router-dom";
import { checkValidEMAILFormat, checkValidPANFormat, getVehicleType, phoneNumberFormat } from "../../utils/utils";
import { Encryption, Decryption } from "../../utils/payload";
import MasterServices from '../../services/masters';


function WelcomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValues = useSelector((state) => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [values, setValues] = useState(userDetails || {});
  const [isDisabled, setIsDisabled] = useState(true);
  const [terms, openTermsModal] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [accepted, isTermsAccepted] = useState(false);
  const [errors, setError] = useState([]);
  const [resetTimer, setResetTimer] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clearCurrentSession, setClearCurrentSession] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [disableNameField, setDisableNameField] = useState(false);
  const [disableMobileField, setDisableMobileField] = useState(false);
  const [disablePANField, setDisablePANField] = useState(false);
  const [disableEmailField, setDisableEmailField] = useState(false);
  const [showBEDetails, setShowBEDetails] = useState(false);
  let dedupeResponseForRedirect = {};
  
  const callBackFunc = (success, response) => {
    setIsDisabled(true);
    setLoading(false);
    setResetTimer(false);
    if (success) {
      let removeSpaceNumber = values?.mobileNumber?.replace(/\s/g, "");
      dispatch(pageBasicDetails({generatedMobileOTPCount: ((removeSpaceNumber === userDetails?.mobileNumber ? (pageDetails?.generatedMobileOTPCount || 0) : 0)) + 1, invalidMobileOTPCount: 0}));
      if (response?.statusCode === "200") {
        setShowOTP(true);
        return;
      }
      if (response?.statusCode === "429") {
        setShowOTP(false);
        setOpenErrorModal(true);
        setErrorMessage(response?.respDesc);
        return;
      }
    } else {
      setShowOTP(false);
      setOpenErrorModal(true);
      if (response?.statusCode === "500") {
        setErrorMessage(response?.respDesc);
        return;
      }
      if (!response?.response?.data) {
        setErrorMessage(OTP_GENERATION_FAILED);
      }
    }
  };

  const redirectAction = (response) => {
    const resp = response || dedupeResponseForRedirect;
    if (
      resp?.dashBoardInfoList != null &&
      resp?.dashBoardInfoList?.length > 0
    ) {
      navigate("/vehicleLoanDashboard");
    } else if (resp?.dedupeStatus?.isNTB) {
      if (resp?.dedupeStatus?.reKYCNeeded) {
        navigate('/aadhar');
      } else {
        navigate('/roadmap/0');
      }
    } else if (resp?.dedupeStatus?.isETB) {
      if (resp?.dashBoardInfoList?.length > 0) {
        navigate('/vehicleLoanDashboard');
      } else if (resp?.reKYCNeeded) {
        navigate('/aadhar');
      } else {
        navigate('/roadmap/0');
      }
    }
  }

  const callBackFunc1 = (success, response) => {
    if (success) {
      setLoading(false);
      setIsDisabled(true);
      if (response?.emailStatus?.status !== 'valid' && response?.panStatus == null && response?.dashBoardInfoList == null) {
        setOpenErrorModal(true);
        setErrorMessage(EMAIL_ERROR_TEXT);
        setErrorContent(EMAIL_ERROR_SUB_TEXT);
        if (pageDetails?.aggregatorJourney) setDisableEmailField(false)
        return;
      }
      if (
        response?.panStatus?.status !== "E" &&
        response?.dashBoardInfoList == null
      ) {
        if (pageDetails?.aggregatorJourney) {
          setDisablePANField(false);
        }
        setOpenErrorModal(true);
        setErrorMessage(PAN_ERROR_TEXT);
        setErrorContent(PAN_ERROR_SUB_TEXT);
        return;
      }
      if (response?.panStatus?.aadhaarSeedingStatus === 'R' && response?.dashBoardInfoList == null) {
        setOpenErrorModal(true);
        setErrorMessage(PAN_ERROR_TEXT1);
        setErrorContent(' ');
        setClearCurrentSession(true)
        return;
      }      
      if (
        response?.emailStatus?.status !== "valid" &&
        response?.dashBoardInfoList == null
      ) {
        setOpenErrorModal(true);
        setErrorMessage(EMAIL_ERROR_TEXT);
        setErrorContent(EMAIL_ERROR_SUB_TEXT);
        if (pageDetails?.aggregatorJourney) setDisableEmailField(false)
        return;
      }
      if (response?.dedupeStatus?.journeyStop) {
        setOpenErrorModal(true);
        setErrorMessage(CONTACT_BRANCH_ERROR);
        setErrorContent('');
        setClearCurrentSession(true)
        return;
      }
      if (response?.journeyStop) {
        setOpenErrorModal(true);
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(RESEND_OTP_ERROR_TEXT);
        return;
      }
      let currentApplication = {
        d2CApplicantID: response?.d2CApplicantID,
        sfapplicantId: response?.sfapplicantId,
        loanApplicationId: response?.loanApplicationId,
        leadId: response?.leadId + '_B' + response?.sfapplicantId,
        documentId: response?.documentId,
        customerType: (response?.dedupeStatus?.isETB) ? "ETB" : ((response?.dedupeStatus?.isNTB) ? "NTB" : ""),
        registrationNumber: pageDetails?.aggregatorResponse?.d2cResponse?.registrationNumber?.replace(/ +/g, "")?.toUpperCase() || pageDetails?.aggregatorHandshakeResp?.registrationNumber?.replace(/ +/g, "")?.toUpperCase() || '',
        beContactNumber: response?.beContactNumber || pageDetails?.preapprovedMaster?.assignedBeContactDtls || "",
        referenceNumber: response?.referenceNumber
      };
      dispatch(
        pageBasicDetails({ dedupeResponse: response, currentApplication })
      );
      dedupeResponseForRedirect = response;
      if(response?.beName && response?.beContactNumber) {
        setErrorMessage(BE_DETAILS_2?.replace('#bename#', response?.beName).replace('#bemobile#', phoneNumberFormat(response?.beContactNumber)))
        setShowBEDetails(true)
      } else if (pageDetails?.aggregatorHandshakeResp?.pinCode?.length === 6 && response?.dashBoardInfoList == null) {
        setLoading(true);
        const bePayload = {
          d2CApplicantId: pageDetails?.aggregatorHandshakeResp?.d2cApplicantId || "",
          productType: getVehicleType(pageDetails?.journeyType),
          pincode: pageDetails?.aggregatorHandshakeResp?.pinCode,
          loanApplicationId: response?.loanApplicationId || "",
          isAggregator: pageDetails?.aggregatorJourney || false,
        }
        MasterServices.getVerificationDetails(callBackFunc2, "bedetails", "bedetails", bePayload);
      } else {
        redirectAction(response);
      }
    } else {
      setOpenErrorModal(true);
      setIsDisabled(true);
      if (response?.statusCode === "500") {
        setErrorMessage(response?.respDesc);
        return;
      }
      if (!response?.response?.data) {
        setErrorMessage(DEDUPE_ERROR);
        setErrorContent(RESEND_OTP_ERROR_TEXT);
      }
    }
  };

  const handleAction = (showButtonLoader) => {
    setIsDisabled(false);
    if (showButtonLoader) {
      setLoading(true);
    } else {
      setResetTimer(true)
    }
    setLoading(true);
    setResetTimer(true);
    let removeSpaceNumber = values?.mobileNumber?.replace(/\s/g, "");
    if (pageDetails?.generatedMobileOTPCount === 3 && removeSpaceNumber === userDetails?.mobileNumber) {
      setShowOTP(false);
      setOpenErrorModal(true);
      setErrorMessage(MAXIMUM_GENERATED_OTP_ERROR);
      return;
    }
    dispatch(userBasicDetails({ mobileNumber: removeSpaceNumber }));
    if (!userDetails?.jwt) {
      LoginServices.generateOTP(callBackFunc);
    } else {
      LoginServices.dedupeVerification(callBackFunc1);
    }
  };

  const dedupeAction = () => {
    setLoading(true);
    LoginServices.dedupeVerification(callBackFunc1);
  }

  useEffect(() => {
    if (
      values?.userName &&
      values?.mobileNumber?.length == 11 &&
      !errors?.mobileNumber && !errors?.pan &&
      checkValidPANFormat(pageDetails?.encryptedPerfios ? JSON.parse(Decryption(pageDetails?.encryptedPerfios || "") || "{}") : '') &&
      checkValidEMAILFormat(values?.email) &&
      accepted
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [values, accepted, pageDetails]);

  const updateFieldValues = (field, val) => {
    setValues({ ...values, [field]: val });
    dispatch(userBasicDetails({ [field]: val }));
  };


  useEffect(() => {
    let disablePANField = false;
    disablePANField = userDetails?.customerType === 'ETB' || userDetails?.channel === 'IndusMobile' || pageDetails?.aggregatorJourney;
    disablePANField = (pageDetails?.encryptedPerfios) ? JSON.parse(Decryption(pageDetails?.encryptedPerfios || "") || "{}").length === 10 : false;
    if (pageDetails?.encryptedPerfios) {
      const fourthChar = Decryption(pageDetails?.encryptedPerfios)?.[4];
      if (fourthChar === 'P' || fourthChar === 'H' || fourthChar === 'p' || fourthChar === 'h') {
        setDisablePANField(disablePANField);
      } else {
        setDisablePANField(false);
        setError({['pan']: [PAN_ERROR_SUB_TEXT1]})
      }
    } else {
      setDisablePANField(disablePANField);
    }
    if (pageDetails?.preApprovedJourney || pageDetails?.aggregatorJourney) {
      if (userDetails?.userName) setDisableNameField(true);
      if (userDetails?.mobileNumber) setDisableMobileField(true);
    }
    if (pageDetails?.dropoffJourney) {
      if (userDetails?.mobileNumber) setDisableMobileField(true);
    }
    if (pageDetails?.aggregatorJourney) {
      if (userDetails?.email || userDetails?.emailId) setDisableEmailField(true)
    }
  }, []);

  const callBackFunc2 = (success,response)=>{
    setLoading(false);
    if(response?.beName && response?.contactNumber && success) {
      setErrorMessage(BE_DETAILS_2?.replace('#bename#', response?.beName).replace('#bemobile#', phoneNumberFormat(response?.contactNumber)))
      setShowBEDetails(true)
    } else {
      redirectAction(pageDetails?.dedupeResponse);
    }
  }


  return (
    <>
      <Loader isLoading={loading} />
      {terms === false && (
        <div className="page-container">
          <Header journeyTitle={pageDetails?.journeyType} title={WELCOME_HEADER}/>
          <div className="top-round-card-header" style={{ marginTop: "20px" }}></div>
          <div className="page-body-content">
            <div className="page-body-contents">
              <div className="welcome-contents">
                <FullNameInput
                  label="Name"
                  values={values}
                  name="userName"
                  setFieldValue={(val) => {
                    updateFieldValues("userName", val);
                  }}
                  setErrors={setError}
                  errors={errors}
                  maxLength={40}
                  disabled={userDetails?.jwt || disableNameField}
                />
                <PhoneNumberInput
                  values={values}
                  setValues={(val) => {
                    updateFieldValues("mobileNumber", val["mobileNumber"]);
                  }}
                  errors={errors}
                  setErrors={setError}
                  name="mobileNumber"
                  label={MOBILE_NUMBER}
                  disabled={userDetails?.jwt || disableMobileField}
                />
                <PanInput
                  label={PAN_CARD_NUMBER}
                  values={{pan: pageDetails?.encryptedPerfios ? JSON.parse(Decryption(pageDetails?.encryptedPerfios || "") || "{}") : ''}}
                  name="pan"
                  setFieldValue={(val) => {
                    dispatch(pageBasicDetails({ encryptedPerfios: Encryption(val) }));
                  }}
                  setErrors={setError}
                  errors={errors}
                  hintText={PAN_CARD_NUMBER_TEXT}
                  disabled={disablePANField}
                />
                <EmailInput
                  label={EMAIL_ID_NUMBER}
                  values={values}
                  name="email"
                  setFieldValue={(val) => {
                    updateFieldValues("email", val);
                  }}
                  setErrors={setError}
                  errors={errors}
                  hintText={EMAIL_ID_NUMBER_TEXT}
                  disabled={disableEmailField}
                />
                <div>
                  <div className="page-body-checkbox1">
                    <p>
                      {TERMS_CONDITION}
                      <label
                        consent="Mobile"
                        className="page-body-check-box-click"
                        onClick={() => {
                          setReadMore(true);
                          openTermsModal(true);
                        }}
                      >
                        {READ_MORE}
                      </label>
                    </p>
                  </div>
                  <div className="page-body-checkbox">
                    <CheckboxInput
                      values={values}
                      checked={accepted }
                      name="checkbox"
                      className="consent-check-box"
                      setValues={() => isTermsAccepted(true)}
                      disabled={!accepted}
                      openTermsModal={() => !accepted && openTermsModal(true)}
                    />
                    <p>
                      {AGREE_TEXT}
                      <label
                        className="page-body-check-box-click"
                        onClick={() => openTermsModal(true)}
                      >
                        {AGREE_TERMS_TEXT}
                      </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer
              callBackAction={handleAction}
              disableButton={!isDisabled}
              buttonText={NEXT_ACTION}
            />
          </div>
        </div>
      )}

      {terms === true && (
        <TermsAndConditionsWrapper>
          <div className="terms-conditions-container">
            <div className="top-round-card-header"></div>
            <div className="page-body-content">
              <div className="page-body-contents">
                <div className="">
                  <Terms
                    consent={readMore ? "Mobile" : "Name"}
                    closeTersmAction={() => {
                      openTermsModal(false);
                      setReadMore(false);
                    }}
                    acceptTersmAction={() => {
                      openTermsModal(false);
                      isTermsAccepted(true);
                      setReadMore(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TermsAndConditionsWrapper>
      )}
      {showOTP && (
        <MobileOTP
          onClose={(val) => {
            setShowOTP(false);
            setIsDisabled(true);
            if (val) {
              dedupeAction();
            };
          }}
          resendOTP={() => handleAction()}
          resetTimer={resetTimer}
          fullLoginPage={true}
        />
      )}
      {errorMessage && (
        <ErrorModal
          errorTitle={ERROR_TEXT}
          errorContent={errorMessage}
          errorSubContent={
            errorContent
              ? errorContent
              : pageDetails?.generatedMobileOTPCount === 3
              ? MAXIMUM_GENERATED_OTP_ERROR1
              : OTP_GENERATION_FAILED_TRY_AGAIN
          }
          clearCurrentSession={clearCurrentSession}
          openErrorModal={openErrorModal}
          closeErrorModal={() => {
            setOpenErrorModal(false);
            setLoading(false);
          }}
        />
      )}
      {showBEDetails && <ErrorModal errorTitle={INFORMATION_TEXT} errorContent={errorMessage} openErrorModal={showBEDetails} closeErrorModal={(val) => {
          setShowBEDetails(false);
          redirectAction(pageDetails?.dedupeResponse);
      }} />}
    </>
  );
}
export default WelcomePage;
