/*eslint-disable eqeqeq*/
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Timer from "./Timer";
import "./otp.css";
import { OTP_HEADER, OTP_SUB_HEADER, CANCEL_ACTION, ERROR_TEXT, AADHAR_OTP_SUB_HEADER, RESEND_OTP_ERROR_TEXT, SUBMIT_ACTION, CLOSE_ACTION, INCORRECT_OTP_MULTIPLE_TIMES_TEXT, RESEND_OTP_ERROR } from '../../utils/cms'
import { useDispatch, useSelector } from 'react-redux'
import { userBasicDetails } from '../../store/userDetails';
import { pageBasicDetails } from '../../store/pageDetails';
import LoginServices from '../../services/login';
import { useNavigate } from "react-router-dom";
import { generateToastMessage } from '../../store/features/toolsSlice';
import Loader from "../../components/Loader/Loader";
import OtpInput from "./OtpInput/OtpInput";
import { useReadOTP } from "react-read-otp";
import MasterServices from '../../services/masters';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let marginTopForModal = `${(window.innerHeight - 450) / 2}px`

let previousOTP = '';

const MobileOTP = ({ showStepper, onClose, resendOTP, aadharModal, resetTimer, fullLoginPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValues = useSelector(state => state);
  const { userDetails, pageDetails } = storeValues || {};
  const [otpValue, setOTP] = useState("");
  const [invalidOTP, setInvalidOTPError] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false)
  const [showResend, setShowResend] = useState(false);
  const [clearCurrentSession, setClearCurrentSession] = useState(false);
  const initial = pageDetails?.config?.OTP_TIMER_SECONDS || 59;
  const [counterSec, setCounterSec] = useState();
  const [counterMin, setCounterMin] = useState();
  const [autoWebOtp, setAutoWebOtp] = useState(null);
  useReadOTP(setAutoWebOtp);

  const callBackFunc = (success, response) => {
    setDisableButton(true);
    setLoading(false)
    if (!success) {
      if (response?.statusCode === '500') {
        setInvalidOTPError(response?.respDesc)
        return;
      }
      if (response?.statusCode === '402' || response?.statusCode === '400' || !response?.data) {
        setErrorMessage(response?.respDesc || RESEND_OTP_ERROR);
        setOpenErrorModal(true);
        setShowResend(true)
        return;
      }
      return
    }
    if ((response?.statusCode === '200')) {
      dispatch(pageBasicDetails({ generatedMobileOTPCount: 0, invalidMobileOTPCount: 0 }));
      dispatch(generateToastMessage(success, response))
      if (aadharModal) {
        dispatch(userBasicDetails({ ...userDetails }))
        dispatch(pageBasicDetails({ aadhar: response }))
        if (showStepper) {
          dispatch(pageBasicDetails({ reKYC: true }))
          navigate('/verification/0');
          return
        }
        MasterServices.makeMoEngage(
          {
              d2cId: pageDetails?.currentApplication?.d2CApplicantID,
              screenId: "5"
          }
        );         
        navigate('/roadmap/0');
      } else {
        sessionStorage.setItem('jwt', response?.jwtLogin);
        dispatch(userBasicDetails({ jwt: response?.jwtLogin }))
        dispatch(pageBasicDetails({ whatsAppSubscribed: response?.whatsAppSubscribed }))
        if (fullLoginPage) {
          onClose(true);
        } else {
          navigate('/pan');
        }
      }
    }
    else if ((response?.statusCode === '402' || response?.statusCode === '400')) {
      setOTP('')
      setErrorMessage(response?.respDesc);
      setOpenErrorModal(true);
      setShowResend(true)
      return;
    }
    else if ((response?.statusCode === '429' || response?.statusCode === '502')) {
      setClearCurrentSession(true);
      setOpenErrorModal(true);
      setErrorMessage(response?.respDesc)
      return;
    }
    else if ((response?.statusCode === '500')) {
      dispatch(pageBasicDetails({ invalidMobileOTPCount: (pageDetails?.invalidMobileOTPCount || 0) + 1 }));
      if (pageDetails?.invalidMobileOTPCount === 2) {
        setOpenErrorModal(true);
        setClearCurrentSession(true)
        setErrorMessage(INCORRECT_OTP_MULTIPLE_TIMES_TEXT);
        return;
      }
      setInvalidOTPError(response?.respDesc)
      return;
    }

  }

  const verifyOTP = (autowebOTP) => {
    previousOTP = otpValue;
    setDisableButton(false);
    //setLoading(true)
    if (aadharModal) {
      setLoading(true)
      LoginServices.verifyAadharOTP(callBackFunc, otpValue);
    } else {
      if (fullLoginPage) setLoading(true);
      LoginServices.validateOTP(callBackFunc, autowebOTP || otpValue);
    }
  }

  useEffect(() => {
    if (previousOTP !== otpValue) {
      setInvalidOTPError('')
    }
  }, [otpValue])

  useEffect(() => {
    if (!resetTimer) {
      setLoading(false)
    }
  }, [resetTimer])

  useEffect(() => {
    startTime(initial)
  }, [initial])

  const startTime = (initialTime) => {
    if (!initialTime) return
    if (initialTime > 60) {
      const seconds = Math.floor(initialTime % 60)
      setCounterMin(Math.floor(initialTime / 60))
      setCounterSec(seconds === 59 ? 58 : seconds)
    }
    else {
      setCounterSec(initialTime)
      setCounterMin(0)
    }
  }

  useEffect(() => {
    let seconds = getSeconds(counterSec)
    if (counterSec === 59 && counterMin > 0) {
      setCounterMin(counterMin - 1);
    }
    const secTimer =
      seconds > 0 && setInterval(() => setCounterSec(seconds - 1), 1000);
    return () => clearInterval(secTimer);
  }, [counterSec]);

  const getSeconds = (sec) => {
    if (sec === 0 && counterMin > 0) {
      return 60
    }
    return sec
  }

  return (
    <>
      <div>
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="otp"
          sx={{
            '& .MuiDialog-container': {
              marginTop: marginTopForModal,
              marginBottom: marginTopForModal,
              height: "max-content",
              "-webkit-align-items": "unset",
              "-webkit-box-align": "unset",
              "-ms-flex-align": "unset",
              "align-items": "unset"
            },
          }}
        ><Loader isLoading={loading} />
          {!openErrorModal ?
            <div className="otp-popup">
              <DialogTitle className="otp-verification-title">
                {OTP_HEADER}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {aadharModal ? AADHAR_OTP_SUB_HEADER : OTP_SUB_HEADER}
                </DialogContentText>
                <div className={`otp-input ${invalidOTP ? "otp-input-error" : ""}`}>
                  <OtpInput
                    OTPLength={6}
                    onChange={setOTP}
                    verifyOTP={verifyOTP}
                    autoWebOtp={autoWebOtp}
                  />
                  {invalidOTP && <span className="otp-input-error-msg">{invalidOTP}</span>}
                </div>
                {!invalidOTP && <Timer counterSec={counterSec} counterMin={counterMin} initial={initial} startTime={startTime}
                  setCounterSec={setCounterSec} resendOTPAction={() => {
                    resendOTP();
                    //setLoading(true)
                    setShowResend(false)
                  }} showResend={showResend} />}
                <div className="otp-verification-actions">
                  <Button className="otp-verification-cancel-button" variant="outlined" color="secondary"
                    onClick={() => {
                      onClose()
                    }}
                    disabled={!disableButton}
                  >{CANCEL_ACTION}</Button>
                  <Button className="otp-verification-submit-button" variant="contained" color="secondary" onClick={() => { verifyOTP() }} disabled={otpValue?.length !== 6 || !disableButton}>{SUBMIT_ACTION}</Button>
                </div>
              </DialogContent>
            </div>
            : <>
              <div>
                <DialogTitle className="error-title">
                  {ERROR_TEXT}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {errorMessage}<br /><br />{RESEND_OTP_ERROR_TEXT}
                  </DialogContentText>
                  <div className="error-actions">
                    <Button className="error-submit-button" variant="contained" color="secondary" onClick={() => {
                      if (clearCurrentSession) {
                        sessionStorage.clear();
                        window.location.href = '/';
                      } else {
                        setOpenErrorModal(false);
                      }
                    }}>{CLOSE_ACTION}</Button>
                  </div>
                </DialogContent>
              </div>
            </>}
        </Dialog>
      </div>
    </>
  );
}

export default MobileOTP;
