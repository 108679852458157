/*eslint-disable eqeqeq*/
import { TextField } from "@mui/material";
import React, { useState } from "react";
import {
  changeValueToCurrency,
  cursorPositionForCurrencyInput,
  onlyNumbersAccept,
  removeInvaildCharFromAddress,
} from "../../utils/utils";
import "./CustomInput.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const TextFieldInput = ({
  values,
  setValues,
  errors,
  name,
  label,
  startAdornment = null,
  endAdornment = null,
  hintText,
  variant = "standard",
  autoComplete = "off",
  afterInputChangeCallback,
  onChange,
  formatter,
  maxLength,
  disabled,
  onBlur,
  ...props
}) => {
  const [showAdornment, setShowAdornment] = useState(null);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (formatter === "currency") {
      if (value === "") {
        let updatedValues = { ...values, [name]: value };
        setValues(updatedValues);
        afterInputChangeCallback && afterInputChangeCallback(updatedValues, name, value);
        return;
      }
      let caret = e.target.selectionStart;
      value = onlyNumbersAccept(value);
      value = value && changeValueToCurrency.format(value);

      if (value.length > 13) return;
      e.target.value = value;
      cursorPositionForCurrencyInput(e, values?.[name], caret);
      value = e.target.value;
    } else if (formatter === "number") {
      value = onlyNumbersAccept(value);
    } else if (name === "businessName" || name === "employerName") {
      if (value === ' ') return;
      value = removeInvaildCharFromAddress(value)?.replace(/\s\s|\t+/g, " ");
    }
    if (maxLength && value?.length > maxLength) return;
    let updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    afterInputChangeCallback &&
      afterInputChangeCallback(updatedValues, name, value);
  };

  const onFocusHandler = () => {
    setShowAdornment(true);
  };

  const onBlurHandler = (e) => {
    setShowAdornment(false);
    onBlur && onBlur(e);
  };


    
  const startAdornmentGenerator = () => {
    let adornment = startAdornment;
    if (formatter == "currency" && (values[name] || showAdornment)) {
      adornment =  <CurrencyRupeeIcon className="adornment" fontSize="17px" />
    }
    else if (formatter == "currency") {
      adornment = null
    }
    return adornment
  }
  

  return (
    <div className={`custom-input ${errors ? "error-input" : ""}`}>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
      <TextField
        name={name}
        label={label}
        variant={variant}
        disabled={disabled}
        InputProps={{
          style: { color: errors ? "#E64C66" : "#1C1B1B" },
          startAdornment:startAdornmentGenerator(),
          endAdornment: endAdornment,
          inputProps:
            formatter === "currency" || formatter === "number"
              ? {
                  type: "text",
                  inputMode: "numeric",
                }
              : {},
        }}
        value={props.value || values?.[name] || ""}
        autoComplete="off"
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onPaste={(e) => {
          if (formatter == "currency") {
            setTimeout(() => {
              let length = e?.target?.value?.length;
              e.target.selectionStart = length;
              e.target.selectionEnd = length;
            }, 50);
          }
        }}
        {...props}
        onChange={onChange ? onChange : inputChangeHandler}

        />
        </form>
      {hintText && !errors && (
        <span className={`field-label ${disabled ? "Mui-disabled" : ""}`}>
          {hintText}
        </span>
      )}
      {errors && (
        <div className="error-messages">
          {errors?.map((error) => (
            <p className="error-message error">{error}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextFieldInput;
