import React from "react";
import {
  MONTHS_LABEL,
  TENURE_MONTHS_SLIDER_LABEL,
} from "../../../utils/cms";
import SliderComponent from "../SliderComponent/SliderComponent";
import "./TenureAmountInput.css";

const TenureAmountInput = ({
  values,
  setValues,
  setIsValuesChanged,
  errors,
  setErrors,
  min,
  max,
}) => {
 

  const sliderOnchange = (val) => {
    setIsValuesChanged(true);

    if (errors?.tenure) {
      setErrors({ ...errors, tenure: null });
    }
    setValues({
      ...values,
      tenure: val,
    });
  };

  return (
    <div className="tenure-slider-cont">
      <SliderComponent
        label={TENURE_MONTHS_SLIDER_LABEL}
        max={max}
        min={min}
        step={6}
        rangeEndLabel={max}
        rangeStartLabel={min}
        values={values}
        setValues={setValues}
        name={"tenure"}
        errors={errors}
        setErrors={setErrors}
        onChange={sliderOnchange}
      />
      <div className="slider-relative-inp">
        <div className="month-label-input-root">
          <div className="tenure-months">
						{values.tenure} {MONTHS_LABEL}
					</div>
        </div>
      </div>
    </div>
  );
};

export default TenureAmountInput;
