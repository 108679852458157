/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";import TextFieldInput from "../../components/CustomInput/TextFieldInput";
import {
	BASIC_DETAILS_STEP_HEADING,
	VERIFICATION_DETAILS_STEP_SUBHEADING,
} from "../../utils/cms";
import AddressForm from "../../components/AddressDetailsComponent/AddressForm";
import { useSelector } from "react-redux";


const SalariedOfficeDetails = ({
	values,
	setValues,
	setIsDisabled,
	isDisabled,
	setLoading,
	responseData,
	setModalOptions,
	prefill,
	disabled,
	...props
}) => {
	const storeValues = useSelector((state) => state);
	const { pageDetails: { currentApplication } } = storeValues || {};
    const [errors,setErrors] = useState(null)
	useEffect(() => {
		if (
            !errors?.addressLine1 &&
			!errors?.addressLine2 &&
			values.employerName &&
			values.addressLine1?.length >= 10 &&
			values.addressLine2?.length >= 3 &&
			values.cityName &&
			values.districtName &&
			values.stateName &&
			(values.pinCode+"")?.length === 6
		) {
			if (isDisabled) {
				setIsDisabled(false);
			}
		} else {
			if (!isDisabled) {
				setIsDisabled(true);
			}
		}
	}, [values,errors]);

	return (
		<div className="employee-details salary-employed">
			<div className="heading">
				{BASIC_DETAILS_STEP_HEADING.replace(
					"#category#",
					"Office "
				)}
				{<p className="leadNumber"> {`Lead Number: ${ currentApplication?.leadId?.split('_B')[0]}`}</p>}
			</div>
			<TextFieldInput
				label={"Employer Name"}
				fieldType="inputText"
				inputType="text"
				name="employerName"
				values={values}
				disabled={disabled}
				setValues={setValues}
				maxLength={80}
			/>
			<div className="sub-heading">
				{VERIFICATION_DETAILS_STEP_SUBHEADING.replace(
					"#category#",
					"Employer "
				)}
			</div>
			<AddressForm
				values={values}
				setValues={setValues}
				setLoading={setLoading}
				prefill={prefill}
   				errors={errors}
                setErrors ={setErrors}
				disabled={disabled}
			/>
		</div>
	);
};

export default SalariedOfficeDetails;
