/*eslint-disable eqeqeq*/
import {
    RADIO_SALARY_LABEL,
    RADIO_SALARY_SUBLABEL,
    OPTION_1_TITLE,
    RADIO_SALARY_OPTION_1_HEADING,
    RADIO_SALARY_OPTION_1_SUBHEADING,
    OPTION_2_TITLE,
    RADIO_SALARY_OPTION_2_HEADING,
    RADIO_SALARY_OPTION_2_SUBHEADING,
    RADIO_ITR_LABEL,
    RADIO_ITR_SUBLABEL,
    RADIO_ITR_OPTION_1_HEADING,
    RADIO_ITR_OPTION_1_SUBHEADING,
    RADIO_ITR_OPTION_2_HEADING,
    RADIO_ITR_OPTION_2_SUBHEADING,
    RADIO_BANK_LABEL,
    RADIO_BANK_SUBLABEL,
    RADIO_BANK_OPTION_1_SUBHEADING,
    RADIO_GST_LABEL,
    RADIO_GST_OPTION_1_HEADING,
    RADIO_GST_OPTION_1_SUBHEADING,
    RADIO_GST_OPTION_2_HEADING,
    RADIO_GST_OPTION_2_SUBHEADING,
    RADIO_GST_SUBLABEL,
    RADIO_ITR_OPTION_2_SUBHEADING1
} from "../../../utils/cms";

export const PERFIOUSTYPE = 'perfios'
export const PERFIOUS_URL_FETCH_ROUTE = "generatelink"
export const SALARY_CREDITED_TO_BANK = 'scb'
export const ITR = 'itr'
export const AVERAGE_BANK_BALANCE = 'abb'
export const GST = 'gst'
export const ONLINE = 'Online'
const STATEMENT = 'Upload'



export const salaryAccountContent = [
    {
        radioBtn: {
            label: RADIO_SALARY_LABEL,
            subLabel: RADIO_SALARY_SUBLABEL,
            type: SALARY_CREDITED_TO_BANK
        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_SALARY_OPTION_1_HEADING,
                subHeading: RADIO_SALARY_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_SALARY_OPTION_2_HEADING,
                subHeading: RADIO_SALARY_OPTION_2_SUBHEADING
            }
        ]
    },
    {
        radioBtn: {
            label: RADIO_ITR_LABEL,
            subLabel: RADIO_ITR_SUBLABEL,
            type: ITR
        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_ITR_OPTION_1_HEADING,
                subHeading: RADIO_ITR_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_ITR_OPTION_2_HEADING,
                subHeading: RADIO_ITR_OPTION_2_SUBHEADING1
            }
        ]
    },
    {
        radioBtn: {
            label: RADIO_BANK_LABEL,
            subLabel: RADIO_BANK_SUBLABEL,
            type: AVERAGE_BANK_BALANCE

        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_SALARY_OPTION_1_HEADING,
                subHeading: RADIO_BANK_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_SALARY_OPTION_2_HEADING,
                subHeading: RADIO_SALARY_OPTION_2_SUBHEADING
            }
        ]
    }
]

export const selfEmployedAccountContent = [

    {
        radioBtn: {
            label: RADIO_ITR_LABEL,
            subLabel: RADIO_ITR_SUBLABEL,
            type: ITR,
        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_ITR_OPTION_1_HEADING,
                subHeading: RADIO_ITR_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_ITR_OPTION_2_HEADING,
                subHeading: RADIO_ITR_OPTION_2_SUBHEADING
            }
        ]
    },
    {
        radioBtn: {
            label: RADIO_GST_LABEL,
            subLabel: RADIO_GST_SUBLABEL,
            type: GST
        },
        inputField: {
            type: "text",
            label: "GST Number",
            name: 'GST'
        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_GST_OPTION_1_HEADING,
                subHeading: RADIO_GST_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_GST_OPTION_2_HEADING,
                subHeading: RADIO_GST_OPTION_2_SUBHEADING
            }
        ]
    },
    {
        radioBtn: {
            label: RADIO_BANK_LABEL,
            subLabel: RADIO_BANK_SUBLABEL,
            type: AVERAGE_BANK_BALANCE
        },
        detailsContainer: [
            {
                type: ONLINE,
                title: OPTION_1_TITLE,
                heading: RADIO_SALARY_OPTION_1_HEADING,
                subHeading: RADIO_BANK_OPTION_1_SUBHEADING
            },
            {
                type: STATEMENT,
                title: OPTION_2_TITLE,
                heading: RADIO_SALARY_OPTION_2_HEADING,
                subHeading: RADIO_SALARY_OPTION_2_SUBHEADING
            }
        ]
    }
]


export const generateCurrentMonthYear = () => {
    const d = new Date();
    let isJan = d.getMonth() === 0
    let month = (isJan ? 12 : d.getMonth()) + ""
    month = month?.length == 1 ? ("0" + month) : month
    let year = d.getFullYear();
    if (isJan) { year = year - 1 }
    return `${year}-${month}`
}
export const generateLast6MonthYear = () => {
    const d = new Date();
    d.setMonth(d.getMonth() - 6);
    let month = d.getMonth() + ""
    month = month?.length == 1 ? ("0" + month) : month
    let year = d.getFullYear();
    return `${year}-${month}`
}
