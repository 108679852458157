/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { JOURNEY_TYPE_3 } from "../../utils/cms";
import { store } from "../../store/index";
import { acceptAlphabetNumber, removeInvaildCharFromDropDown, removeSpaces } from "../../utils/utils";

const SelectInput = ({
  name = "",
  options = [],
  values,
  setValues,
  errors,
  label,
  hint,
  marginBlock,
  placeholder,
  onChange,
  disabled,
  afterInputChangeCallback,
  twHint,
  TextOnlySearch,
  ...props
}) => {
  const storeValues = store.getState();
  const [searchValue, setSearchValue] = useState("");
  const {
    pageDetails: { journeyType },
  } = storeValues || {};
  const inputChangeHandler = (e, value, type) => {
    let updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    if (type === "clear") {
      setSearchValue("");
    }
    afterInputChangeCallback &&
      afterInputChangeCallback(updatedValues, name, value);
  };

  useEffect(() => {
    if (values?.[name]?.value) {
      setSearchValue(values?.[name]?.value);
    } else {
      setSearchValue("");
    }
  }, [values?.[name]?.value]);

  return (
    <div
      className="custom-select custom-input"
      style={{ marginBlock: marginBlock ? marginBlock + "px" : 0 }}
    >
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
      <Autocomplete
        disabled={!options?.length || disabled}
        options={options}
        getOptionLabel={(option) => option.value || ""}
        autoComplete
        includeInputInList
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            autoComplete="new-password"
          />
        )}
        {...props}
        onChange={onChange ? onChange : inputChangeHandler}
        value={values?.[name] || null}
        inputValue={searchValue}
        onInputChange={(e) => {
          if (!e?.target?.value && e?.target?.value !== "") return;
          let value = e?.target?.value;
          if (TextOnlySearch) {
            value = removeInvaildCharFromDropDown(value);
          } else if (name === "makeCode" || name === "modelCode" || name === "variantCode") {
            value = removeSpaces(value)
          }
          else value = acceptAlphabetNumber(value);
          setSearchValue(value || "");
        }}
        onBlurCapture={() => {
          setSearchValue(values?.[name]?.value || "");
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            onClick={(e) => {
              props?.onClick && props?.onClick(e);
              if (option?.value === values?.[name]?.value) {
                setSearchValue(option.value);
              }
            }}
            key={props["data-option-index"]}
          >
            {option?.value}
          </li>
        )}
        noOptionsText={name === "dealerName" ? 'This dealer is currently not registered with us. Please choose a different dealer.' : 'No options'}
        />
        </form>
      <span
        className={`field-label ${
          !options?.length || disabled ? "Mui-disabled" : ""
        }`}
      >
        {journeyType === JOURNEY_TYPE_3 ? twHint : hint}
      </span>
      {errors?.map((each) => (
        <p className="error">{each}</p>
      ))}
    </div>
  );
};

export default SelectInput;
