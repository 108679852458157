/*eslint-disable no-useless-escape*/
import { JOURNEY_TYPE_1, JOURNEY_TYPE_3, JOURNEY_TYPE_4 } from "./cms";
import { screenData, VKYC_lANDING } from "./screenData";
const pathWithStepper = ["roadmap", "basicdetails", "verification", "dealervehicle"]

export const removeSpaces = (string) => {
    return string?.replace(/\s\s+/g, ' ') ||"";

}
export const removeInvaildCharFromTextField = (value) => {
    return value ? value.replace(/[^A-Za-z\s]/gi, "") : "";
}
export const removeInvaildCharFromDropDown = (value) => {
    return value ? removeSpaces(value.replace(/[^A-Za-z\s.\/,]/gi, "")) : "";
}
export const removeInvaildCharFromMMv = (value) => {
    return value ? removeSpaces(value.replace(/[^A-Za-z\s]/gi, "")) : "";
}

export const removeInvaildCharFromMobileField = (value) => {
    return value ? value.replace(/\D/gi, "") : "";
}

export const removeInvaildCharFromPANField = (value) => {
    return value ? value.replace(/[^A-Z0-9]/gi, "") : "";
}

export const checkValidPANFormat = (value) => {
    return value ? (/^[A-Z]{3}[PH][A-Z]\d{4}[A-Z]{1}?$/i.test(value)) : "";
}

export const checkValidEMAILFormat = (value) => {
    return value ? (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4]\d\.|1\d{2}\.|\d{1,2}\.))((25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\.){2}(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\]?$)/i.test(value)) : "";
}

export const onlyNumbersAccept = (value) => {
    return value ? value.replace(/\D/g, '') : ""
}

export const changeValueToCurrency = Intl.NumberFormat('en-IN');

export const validatingValueHandler = (value) => {
    if (value === 'null' || !value || value === 'undefined') return ''
    return value
}

export const getCurrencyByText = (value) => {
    value = onlyNumbersAccept(value);
    if (value.length > 13) return;
    value = changeValueToCurrency.format(value);
    return value
}

export const phoneNumberFormat = (value) => {
    let format = (value?.toString())?.match(/.{1,5}/g)?.join(' ')
    return format
}

export const getJourneyType = (storedJourneyType) => {
    let journeyType = (storedJourneyType === JOURNEY_TYPE_1 || storedJourneyType === JOURNEY_TYPE_3) ? "New" : (storedJourneyType === JOURNEY_TYPE_4 ? "Refinance" : "Used")
    return journeyType;
}

export const getVehicleType = (storedVehicleType) => {
    let vehicleType = storedVehicleType === "Two Wheeler Loan" ? "H" : "C"
    return vehicleType;
}

export const cursorPositionForCurrencyInput = (e, prevValue, caret) => {
    let inpValue = e.target.value;
    if (caret === 0 && inpValue === "0") { 
        e.target.selectionStart = 1;
        return
    }
    if (caret === 0) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = 0
        return
    }
    if (inpValue && prevValue) {
        if (inpValue?.split(',')?.length > prevValue.split(',')?.length) {
            caret += 1
        }
        else if (inpValue?.split(',')?.length < prevValue.split(',')?.length) {
            caret -= 1
        }
    }
    e.target.selectionStart = caret;
    e.target.selectionEnd = caret
}

export const getName = (name, type) => {
    let finalName = name;
    const [firstName, ...lastName] = name?.split(' ') || [];
    if (type === 'firstname') {
        finalName = firstName;
    } else {
        finalName = lastName?.join(' ') || finalName;
    }
    return finalName;
}

export const checkValidGstNumer = (value) => {
    return value ? (/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}?$/i.test(value)) : false;
}

export const sortByDescending = (data) => {
    data.sort((a, b) => {
        let da = new Date(a.updatedTime),
            db = new Date(b.updatedTime);
        return da - db;
    });
    data.reverse();
    return data;
}

export const checkValidVehicleRegistration=(value)=>
{
    return value ? (/^[A-Z]{2}[A-Z0-9]{2}[A-Z0-9]{1,3}\d{4}$/i.test(value) || /^[A-Z]{3}\d{4}$/i.test(value)) : "";
}

export const removeInvaildCharFromAddress = (value) => {
    return value ? value.replace(/[^A-Za-z0-9_.,:;/#'\s]/gi, "") : "";
}


export const currentScreenDetails = () => {
    let screenId = ""
    let screenName = ""
    let route = window.location.pathname
    const routeSplitArray = route.split('/')
    route = routeSplitArray[1].toLowerCase()
    if (pathWithStepper.includes(route)) {
        route = route + "/" + routeSplitArray[2]
    }
    if (route === 'vkyclanding' && window.location?.search?.includes("?kyc_status=")) {
        screenId = VKYC_lANDING.id;
        screenName = VKYC_lANDING.name;
        route = VKYC_lANDING.route;
        return [screenId, screenName, route];
    }
    screenData
        ?.forEach((screen) => {
        if (screen?.route?.toLowerCase() === route?.toLowerCase()) {
            screenId = screen.id
            screenName = screen.name
        }
    })
    return [screenId, screenName,route]
}

export const acceptAlphabetNumber = (value) => {
    return value ? value.replace(/[^A-Za-z0-9 ]+/, "") : "";
}

export const isDevAndDevelopment = window.location.origin?.includes("localhost") || window.location.origin?.includes("dev")

export const isUatEnvironment = window.location.origin?.includes("uat") && !sessionStorage.getItem('showConsole');

export const isProdEnvironment = (window.location.origin?.includes("prdd2ccfd") || window.location.origin?.includes("induseasydrive")) && !sessionStorage.getItem('showConsole');

export const capitailizeWord = (str) => {
 return str?.length ? ((str.charAt(0).toUpperCase() + str.slice(1))) : ""
}

export const capitaizeSentence = (str) => {
    let result = str?.split(" ")?.map(each => capitailizeWord(each))
    return result?.join(" ") || ""
}