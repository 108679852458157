import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./SnackbarComponent.css";

const SnackbarComponent = (isMobile) => {
  const { snackbar } = useSelector((state) => state.tools);

  return (
    <>
      {!!snackbar && (
        <Snackbar
          open={!!snackbar}
          //onClose={handleClose}
          className={(isMobile?.isMobile) ? 'isMobile' : ''}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            //onClose={handleClose}
            severity={snackbar?.severity}
            sx={{ width: "100%" }}
          >
            {snackbar?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarComponent;
